
<div *ngIf="errorCode==1">
<div class="payment-info_card-authentication payment-info_failureMsg">
    <div class="payment-info_failure-header">Card Authentication Failed</div>
    <div class="payment-info_failure-body">Your card was unable to be authenticated by your bank. This may be related to entering a one-time password, SMS code, timeout, invalid CVV, expired card, or other bank challenge. <br><br> Please retry the card. If the problem persists, please contact your bank or use another payment method.</div>
    <div class="payment-info_failure-button">
        <button class="payment-info_retry" (click)="ChangeClicked()">Retry or Change Method</button>
        <button class="payment-info_cancel" (click)="ChangeClicked()">Cancel</button>
    </div>
</div>
</div>
<div *ngIf="errorCode==2">
<div class="payment-info_unable-to-proceed payment-info_failureMsg">
    <div class="payment-info_failure-header">Unable to Process Card</div>
    <div class="payment-info_failure-body">Your bank was unable to process this card. This may be related to various reasons, including fraud, lack of funds, invaild card, etc. <br><br> Please contact your bank or use another payment method.</div>
    <div class="payment-info_failure-button">
        <button class="payment-info_retry" (click)="ChangeClicked()">Change Method</button>
        <button class="payment-info_cancel" (click)="ChangeClicked()">Cancel</button>
    </div>
</div>
</div>
<div *ngIf="errorCode==3">
<div class="payment-info_not-saved payment-info_failureMsg">
    <div class="payment-info_failure-header">Your card was not saved</div>
   <div class="payment-info_failure-body">We have not been able to save your card. <br><br> Please check your details and try again, or use another payment method.</div>
    <div class="payment-info_failure-button">
        <button class="payment-info_retry" (click)="ChangeClicked()">Retry or Change Method</button>
        <button class="payment-info_cancel" (click)="ChangeClicked()">Cancel</button>
    </div>
</div>
</div>