import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-credit-top-banner',
  templateUrl: './credit-top-banner.component.html',
  styleUrl: './credit-top-banner.component.scss'
})
export class CreditTopBannerComponent {
  dropdownDetail: any;
  finalSeason: any;
  
  constructor(public userInfoService:UserInfoService,private cd:ChangeDetectorRef,private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(){
    this.userInfoService.getseasonDropdown("2024-2025").subscribe((res:any)=>{
      this.dropdownDetail = res;
      this.finalSeason =this.dropdownDetail.inSeason;
      this.userInfoService.sendDropdownValue(this.dropdownDetail.inSeason);
      this.cd.detectChanges();
    })
  }

  selectedSeason(e :any){
    this.finalSeason =(e.target as HTMLInputElement).value
    this.userInfoService.sendDropdownValue(this.finalSeason);
    this.cd.detectChanges();
  }

}
