
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable, combineLatest } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { map } from 'rxjs/operators';
import { UserInfoService } from '../../spartacus/services/user-info.service';
@Component({
 selector: 'app-real-world-comp',
 templateUrl: './real-world-comp.component.html',
 styleUrls: ['./real-world-comp.component.scss']
})
export class RealWorldCompComponent implements OnInit {
 firstheadline: Observable<any> = this.bannerComponent.data$;
 mergedObject1: any = [];
 userLoggedIn: boolean = false;
 selectedTile: number | null = 1; // Default to the first tile
 activePanel: number = 1;
 years: string[] = ['2017-2018', '2018-2019', '2019-2020'];
 constructor(
   private accessService: ContentService,
   public bannerComponent: CmsComponentData<CmsBannerComponent>,
   protected config: OccConfig,
   private cd: ChangeDetectorRef,
   private userInfoService: UserInfoService
 ) {}
 ngOnInit() {
   this.firstheadline.subscribe(res => {
     this.clinicaltabs(res);
   });
   this.userInfoService.userLoggedIn$.subscribe(res => {
     console.log('login check', res);
     this.userLoggedIn = res;
   });
   this.updateContentForTile(this.selectedTile!); // Initial content update
 }
 clinicaltabs(res: any) {
  //  const tilesList = res.realWorldList.split(' ');
  //  const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));
  //  combineLatest(observables)
  //    .pipe(map((results: any) => results.flat()))
  //    .subscribe((mergedResults: any) => {
  //      this.mergedObject1 = mergedResults;
  //      this.cd.detectChanges();
  //      console.log(this.mergedObject1);
  //    });
  {
    const tilesList = res.realWorldList.split(" ");
    console.log('tilesList:', tilesList);
    this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
    
      
      // Check if apiRes.component is an array
      if (Array.isArray(res.component)) {
        this.mergedObject1 = res.component;
      } else if (res.component) {
        // Convert to array if it's not already
        this.mergedObject1 = [res.component];
      } else {
        // Ensure it's always an array
        this.mergedObject1 = [];
      }

      console.log('mergedObject1:', this.mergedObject1);
      this.cd.detectChanges();  // Ensure detectChanges is called correctly
    });
  }
 }
 getImage(image: any): string {
   return this.config.backend?.occ?.baseUrl + image.desktop.url;
 }
 gettabsImage(image: any): string {
   return this.config.backend?.occ?.baseUrl + image.desktop.url;
 }
 gettabsMobileImage(image: any): string {
   return this.config.backend?.occ?.baseUrl + image.tablet.url;
 }
 selectTile(tile: number) {
   if (tile >= 1 && tile <= this.years.length) {
     this.selectedTile = tile;
     this.updateContentForTile(tile);
   }
 }
 updateContentForTile(tile: number): void {
   if (this.mergedObject1 && this.mergedObject1.length > tile - 1) {
     const selectedContent = this.mergedObject1[tile - 1];
     console.log(`Content updated for tile: ${tile}`, selectedContent);
     this.cd.detectChanges();
   } else {
     console.warn(`Content for tile ${tile} not found.`);
   }
 }
 setActivePanel(panel: number) {
  this.activePanel = panel;
}
}