<div
  class="product_image-textSide product_image-textReverse real-world-reverse" *ngIf="fourthnode | async as item"
>
  <div class="container">
    <div class="product_efficacy-header">
      {{item.headLine}}
    </div>
    <div class="product_image-textSide-container">
      <div class="product_image-textSide-Image">
        <div class="simple-banner banner__component--responsive">
          <div class="simple-banner banner__component--responsive" *ngIf="item?.media?.desktop">
            <img 
              class="js-responsive-image"
              alt="the continuous cycle of influenza strain change, new vaccine formulations, and annual vaccine requirements"
              title="the continuous cycle of influenza strain change, new vaccine formulations, and annual vaccine requirements"
              style=""
              [src]="getImage(item.media.desktop)"
            />
          </div>
        </div>
      </div>
      <div class="product_image-textSide-content">
        <div class="product_bold" [innerHTML]="item.text1Content">
        </div>
        <div>
          {{item.text2Content}}
          <div [innerHTML]="item.text3Content">

          </div>
        </div>
      </div>
      <div class="clearboth"></div>
    </div>
  </div>
</div>
