import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpFaqSecondComponent } from './help-faq-second/help-faq-second.component';
import { HelpFaqThirdComponent } from './help-faq-third/help-faq-third.component';
import { ConfigModule } from '@spartacus/core';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { FormsModule } from '@angular/forms';
import { UkLoSecondComponent } from './uk-lo-second/uk-lo-second.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    HelpFaqSecondComponent,
    HelpFaqThirdComponent,
    UkLoSecondComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CmsCommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusFaqAllSlides:{
          component: HelpFaqSecondComponent
        },
        SeqirusAboutUsProductInformationComponent:{
          component: HelpFaqThirdComponent
        },
        SeqirusLOFaqAllSlides:{
          component: UkLoSecondComponent
        }
      }
    })
  ]
})
export class HelpAndFaqModule { }
