<div class="col-xs-12 footer_container">
    <div class="col-xs-12 container-fluid">
        <div class="row">
            <div class="col-md-4 col-xs-12">
                
                <div class="d-none d-md-block d-lg-block">
                    <div class="logo_seqirus" *ngIf="(logoData | async) as data">
                        <img [src]='getImage(data.media)' alt="seqirus" >
                    </div>
                    
                    <div class="footertermsheaderuk" style="color: #fff;">{{explicitFooterData.h1content}}</div>
                    <p class="footerpara-1 footermar" style="color: #fff;" [innerHTML]="explicitFooterData.h2content">{{explicitFooterData.h2content}}</p><br>
                    <div [innerHTML]="explicitFooterData.text1"  class="Explictfooter-link"></div><br>
                    <div [innerHTML]="explicitFooterData.text2" class="Explictfooter-link"></div>

                </div>
            </div>
            
            <div class="col-md-3 col-xs-12 marTop-30 publicFooterAlignment" *ngIf="(explicitFooter$ | async) as explicitFooterNode">
                <ng-container *ngFor="let node of explicitFooterNode.children">
                <div *ngIf="node.title == 'Website Terms of Use'" class="footer_headinguk">{{node.title}}</div>
                <ul *ngIf="node.title == 'Website Terms of Use'" class="footer_menu_listuk">
                    <ng-container *ngFor="let child of node.children">                         
                        <!-- <li><a [routerLink]="child.url" target="_blank">{{child.title}}</a></li>
                        <li></li> -->
                        <li *ngIf="child.title != 'Privacy Policy'"><a [routerLink]="child.url" target="_blank">{{child.title}}</a></li>
                        <li *ngIf="child.title === 'Privacy Policy'"><a [href]="child.url" target="_blank">{{child.title}}</a></li>
                    </ng-container>
                </ul>
                </ng-container>
            </div>

        </div>

        <div class="row">
            <div class="col-md-12 footer_txt_disclaimerukconsent">
                <p class="footer_copynote">{{explicitFooterData.notice}}</p>
            </div>
        </div>
</div>
</div>