import { Component } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-customtab',
  templateUrl: './customtab.component.html',
  styleUrl: './customtab.component.scss'
})
export class CustomtabComponent {
  enablePage= {
    usertab: true,
    account: false,
    permissionadmin: false,
   
  }

  constructor(  private userInfoService: UserInfoService) {
  }
  ngOnInit() {
   
    //this.userInfoService.settabdata(obj);
    //this.userInfoService.gettabdata().subscribe((data: any) => {
      //this.enablePage = data;
      const body=<HTMLElement>document.querySelector("body");
      body.classList.add("myprofileUK")
      if(this.enablePage.usertab){
        body.classList.add("useratabactive");
      }
      else if(this.enablePage.account){
        body.classList.add("accounttabactive");
       
      }
      else if(this.enablePage.permissionadmin){
        body.classList.add("permissionadminactive"); 
      }

  }
  tabclick(tab:string){
    const body=<HTMLElement>document.querySelector("body");
   if(tab=='user'){
    body.classList.add("useratabactive");
    body.classList.remove("accounttabactive");
    body.classList.remove("permissionadminactive");
    this.enablePage= {
      usertab: true,
      account: false,
      permissionadmin: false,
     
    }
   }
   else if(tab=='account'){
    body.classList.add("accounttabactive");
    body.classList.remove("useratabactive");
    body.classList.remove("permissionadminactive");
    this.enablePage= {
      usertab: false,
      account: true,
      permissionadmin: false,
     
    }
  }
    else if(tab=='rba'){
      body.classList.add("permissionadminactive"); 
      body.classList.remove("accounttabactive");
    body.classList.remove("useratabactive");
    this.enablePage= {
      usertab: false,
      account: false,
      permissionadmin: true,
     
    }
    }
   
    
   }
  
  
}
