import { Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-public-footer',
  templateUrl: './custom-public-footer.component.html',
  styleUrl: './custom-public-footer.component.scss'
})
export class CustomPublicFooterComponent {
  constructor( public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig) { }
  publicfooternode: Observable<any> = this.bannerComponent.data$;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
}
