import { Component } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrl: './thank-you.component.scss'
})
export class ThankYouComponent {
  
  pageLabel:Observable<string> | undefined;

  constructor(public thankyouComponent: CmsComponentData<any>,private cmsService:CmsService) {
    this.pageLabel = this.cmsService.getCurrentPage().pipe(map((pageDta) => pageDta.label || ""));
  }
 
  regThankYouData: Observable<any> = this.thankyouComponent.data$;

  isThankYouPage():Observable<boolean> | undefined{
    return this.pageLabel?.pipe(map((label) => label === 'register-thank-you'));
  }

}
