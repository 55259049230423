import { Component, ElementRef, Renderer2, ViewChild , OnInit, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { CmsBannerComponent , OccConfig} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { AnyFn } from '@ngrx/store/src/selector';

@Component({
  selector: 'app-access-resources',
  templateUrl: './access-resources.component.html',
  styleUrl: './access-resources.component.scss'
})
export class AccessResourcesComponent implements OnInit , AfterViewInit{
  data1: any;
  data2: any;
  data3: any;  
  isLoading = true;
  finalArray: any;
  constructor(public component: CmsComponentData<CmsBannerComponent>, private accessService : ContentService, protected config: OccConfig, private renderer: Renderer2, public el : ElementRef, private cd:ChangeDetectorRef) {}
  // public resourse1: Observable<any> = this.accessService.getAccess1(); 
  // public resourse2: Observable<any> = this.accessService.getAccess2(); 
  // public resourse3: Observable<any> = this.accessService.getAccess3(); 
  bannerNode: Observable<any> = this.component.data$;
  public resourseFinal!: Observable<any>;
 
  newFinal:any;
  mergedObject1 :any= [];


 
  getMediaUrl(media:any):string{
    return this.config.backend?.occ?.baseUrl + media;
  }
  @ViewChild('myDiv')
  myDiv!: ElementRef;

  ngAfterViewInit(): void {
    
    
    console.log('Merged data7288237', this.mergedObject1);
    console.log(this.el.nativeElement.querySelectorAll('.carousel-item '));
    if(window.innerWidth < 992)
      {   
        this.renderer.addClass(this.el.nativeElement.querySelector('.global_mobilecarousel'), 'carousel-inner')
        this.renderer.addClass(this.el.nativeElement.querySelector('.carousel '), 'multi-item-carousel')
        this.cloneItems('.multi-item-carousel .carousel-item', 1);
        
      }
      else {
        this.removeCarouselClasses();
      }
    }
    private cloneItems(selector: string, itemsToClone: number) {
      const items = this.el.nativeElement.querySelectorAll(selector);
      items.forEach((item: HTMLElement) => {
        
        let next:any = item?.nextElementSibling;
        for (let i = 0; i < itemsToClone; i++) {
          if (!next) {
            next = item.parentElement?.firstElementChild;
          }
          const cloneChild = next?.firstElementChild?.cloneNode(true);
          if (cloneChild) {
            item.appendChild(cloneChild);
          }
          next = next?.nextElementSibling;
        }
  
        let prev:any = item.previousElementSibling;
        for (let i = 0; i < itemsToClone; i++) {
          if (!prev) {
            prev = item.parentElement?.lastElementChild;
          }
          const cloneChild = prev?.lastElementChild?.cloneNode(true);
          if (cloneChild) {
            item.insertBefore(cloneChild, item.firstChild);
          }
          prev = prev?.previousElementSibling;
        }
      });
    }
    private removeCarouselClasses() {
      this.renderer.removeClass(this.el.nativeElement.querySelector('.global_mobilecarousel'), 'carousel-inner');
      this.renderer.removeClass(this.el.nativeElement.querySelector('.carousel'), 'multi-item-carousel');
    }
    getProduct(index: number): string {
      switch (index) {
        case 0:
          return 'clinical';
        case 1:
          return 'financial';
        case 2:
          return 'clinical2';
        default:
          return 'default';
      }
    }
    // sortBy(prop: string) {
    //   return this.composer.arrcompositions.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    // }
  ngOnInit() {

    this.bannerNode.subscribe(res=>{
      
      this.cd.detectChanges();
        let accessResources = res.productsList;
        let resources = accessResources.split(" ").join(",");
        this.accessService.getAccessResources(resources).subscribe((data:any) => {
          this.finalArray  = data.results;
          this.cd.detectChanges();
        });
        
    });
    
    /*  this.resourseFinal.subscribe((data)=>{
      console.log(data);
      let finalArray  = data.component;
      this.newFinal = finalArray.sort((a:any,b:any)=>parseInt(a.position) - (parseInt(b.position)))
      console.log('final',this.newFinal);
      console.log('final',finalArray)
    }) */
    // combineLatest([this.resourse1, this.resourse2, this.resourse3]).subscribe(([data1, data2, data3]) => {
    //   this.data1 = data1;
    //   this.data2 = data2;
    //   this.data3 = data3;

    //   console.log('Received data1:', this.data1);
    //   console.log('Received data2:', this.data2);
    //   console.log('Received data3:', this.data3);

    //   this.cd.detectChanges();
    //   this.mergedObject1.push(this.data1);
    //   this.mergedObject1.push(this.data2);
    //   this.mergedObject1.push(this.data3);
    //   //  this.mergeObjects( this.data1, this.data2, this.data3);
    //   console.log('Merged data:', this.mergedObject1);
     
    //   this.isLoading = false;
    // });
    
  }
  objectToArray(obj: any): { key: string, value: any }[] {
    return Object.keys(obj).map(key => ({ key, value: obj[key] }));
  }

}
// get(){
  
//   if (document && document.querySelector && document.querySelector(window))
//     {
  
//       document.querySelector(".global_mobilecarousel").addClass("carousel-inner");
//       document.querySelector(".carousel").addClass("multi-item-carousel");
//       document.querySelector('.multi-item-carousel .item').each(function(){
//         var next = document.querySelector(this).next();
//         if (!next.length) next = document.querySelector(this).siblings(':first');
//         next.children(':first-child').clone().appendTo(document.querySelector(this));
//       });
//       document.querySelector('.multi-item-carousel .item').each(function(){
//         var prev = document.querySelector(this).prev();
//         if (!prev.length) prev = document.querySelector(this).siblings(':last');
//         prev.children(':nth-last-child(2)').clone().prependTo(document.querySelector(this));
//       });
      
//     }
//     else{
//       document.querySelector(".global_mobilecarousel").removeClass("carousel-inner");
//       document.querySelector(".carousel").removeClass("multi-item-carousel");
//     }
//   }

