<div *ngIf="bannerNode | async as item" class="main_inner-wrapper">
    <div class="yCmsContentSlot row dashboard-support">
    <div class="col-sm-12 col-md-12">
        <div *ngIf="currentSite === 'seqirusb2b-flu360'" class="col-sm-12 col-lg-6 dashboard-supportsection float_left">
            <div class="dashboard-support-container">
                <ng-container  *ngIf="vssdata | async as item1">
                    <div class="dashboard-support-text">Personal Support</div>
                    <div class="dashboard-support-title">To connect with your local Vaccine Solution Specialist, email or call Nancy:</div>
                    <div class="dashboard-support-emailtext">Email:
                        &nbsp;<a href="mailto:{{item1.emailId}}"
                            class="dashboard-support-emaillink">{{item1.emailId}}</a>
                    </div>
                    
                    
                    <div class="dashboard-support-emailtext">Call:
                        &nbsp;<a href="tel:{{item1.formatPhoneNumber}}" class="dashboard-support-emaillink">{{item1.contact}}</a>
                    </div>
                    </ng-container>
            </div>
        </div>
<div class="col-sm-12 col-lg-6 float_left" [ngClass]="currentSite === 'seqirusb2b-uk'?'dashboard_bottomlinkuk':''">
    <div [ngClass]="currentSite === 'seqirusb2b-uk'?'dashboard-support-containeruk':'dashboard-support-container'">
        
            <div class="dashboard-support-text">{{item.title}}</div>
            <div *ngIf="currentSite === 'seqirusb2b-uk'" class="dashboard-supporttexthidden">{{item.text1}}</div>
            <div [ngClass]="currentSite === 'seqirusb2b-uk'?'dashboard-support-emailtextuk':'dashboard-support-emailtext'" [innerHTML]="item.text2"></div>
            <div [ngClass]="currentSite === 'seqirusb2b-uk'?'dashboard-support-emailtextuk':'dashboard-support-emailtext'" [innerHTML]="item.content"></div>
        
    </div>
</div>
</div>
</div>
</div>