import { Component } from '@angular/core';
import { BaseSiteService, CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-registration-uk-thankyou',
  templateUrl: './registration-uk-thankyou.component.html',
  styleUrl: './registration-uk-thankyou.component.scss'
})
export class RegistrationUkThankyouComponent {
  currentSite: any;
  
  pageLabel:Observable<string> | undefined;

  constructor(public thankyouComponent: CmsComponentData<any>,private cmsService:CmsService) {
    this.pageLabel = this.cmsService.getCurrentPage().pipe(map((pageDta) => pageDta.label || ""));
  }

  ngOnInit(): void {
    
    
  }
 
  regThankYouData: Observable<any> = this.thankyouComponent.data$;
  isThankYouPage():Observable<boolean> | undefined{
    return this.pageLabel?.pipe(map((label) => label === 'register-thank-you' || label === 'regThankYou'));
  }
}
