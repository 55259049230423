<div class="col-xs-12 col-sm-12 myprofile_consentsection no-padding">
    <div class="myprofile_consentcontainer">  
          <div class="myprofile_consentheader">Consent for marketing communications</div>  
          <div class="myprofile_consentcontent"> 
               <span>CSL Seqirus would like to keep you updated on our products and offers. These promotional marketing communications would be sent to the following email address [</span>
               <span class="consentsubheader_mail">{{consentdata?.marketingConsentEmailId}}</span>
               <span>] and managed as described in this</span>
               <span class="consent_privacypolicy">
                   <a target="_blank" href="https://accounts.eu1.gigya.com/accounts.store.downloadPublicConsentDocument?docID=12630585_587824270786_fd6084f03830416db38a57e84e3c79a8">
                        privacy policy</a>.
               </span>
          </div> 
      <div class="myprofile_radiocontainer">
            <label class="myprofile_radio">
                 <input type="radio"  class="myprofile_radiobtn" id="myprofile_radiobtn1" value="true" [checked]="consentdata?.isConsentGranted==true?'checked':''" (change)="onRadiochange(true)"><span class="myprofile_radiobtncontrol"></span><label for="myprofile_radiobtn1">Yes, I'd like to receive CSL Seqirus marketing communications</label>
            </label>
          </div>
          <div class="myprofile_radiocontainer">
            <label class="myprofile_radio">
                 <input type="radio"  class="myprofile_radiobtn" id="myprofile_radiobtn2" value="false" [checked]="consentdata?.isConsentGranted==false?'checked':''"  (change)="onRadiochange(false)"><span class="myprofile_radiobtncontrol"></span><label for="myprofile_radiobtn2">No, I do not want to receive CSL Seqirus marketing communications</label>
            </label>
         </div>
          <button type="button" id="myprofile_updatebtn" (click)="submitconsent()">Update</button>   
          <div class="myprofile_consentsuccess" [ngClass]="successshow?'show':''">Your contact preference is saved.</div>
        </div>  
 </div>
