import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { BaseSiteService } from '@spartacus/core';

@Component({
  selector: 'app-contact-us-topbanner',
  templateUrl: './contact-us-topbanner.component.html',
  styleUrl: './contact-us-topbanner.component.scss'
})
export class ContactUsTopbannerComponent {
  currentSite: string | undefined;
  userLoggedIn: boolean=false;
  userInfo: any;
  constructor(public component: CmsComponentData<any>,private userInfoService:UserInfoService,private cd:ChangeDetectorRef,private baseSiteService:BaseSiteService,private renderer:Renderer2 ){
    
  }
  topContent: Observable<any> = this.component.data$;
  
  ngOnInit(){
        //for looged, loggedout
        this.userInfoService.userLoggedIn$.subscribe(res=>{
          console.log('login check',res);
          this.userLoggedIn = res;
          if(this.userLoggedIn){
            this.userInfoService.getUserData().subscribe(res=>{
              if(res){
              this.userInfo=res;
              console.log('webenabled:',res);
              if(this.userInfo?.webEnabled == false){
                this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
               }
              }
            });
          }
          
          this.cd.detectChanges();
        })
        //to get the current basesite 
        this.baseSiteService.getActive().subscribe((siteId) => {
          this.currentSite = siteId;
          console.log(this.currentSite);
        });
  }

}
