import { ChangeDetectorRef, Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import patternFill from 'highcharts/modules/pattern-fill';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { distinct } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';


patternFill(Highcharts);
interface CustomPoint extends Highcharts.Point {
  value: number;
}
@Component({
  selector: 'app-leftsection',
  templateUrl: './leftsection.component.html',
  styleUrl: './leftsection.component.scss'
})

export class LeftsectionComponent {
  @ViewChildren("checkboxes")
  checkboxes!: QueryList<ElementRef>;
ordersummaryleft:any;
filterchart:any;
  constructor(private userInfoService: UserInfoService,private cd:ChangeDetectorRef){}
  filtertoshow:any;
  
  displayedColumnsupcoming: string[] = ['combined'];
  dataSourceupcoming=new MatTableDataSource<any>();
  product1:string='';
  product2:string='';
  pack1:string='';
  pack2:string='';
  deliver:any;
  process :any;
  searched:any;
  filtercount:number=0
  filteropen:boolean=false;
  searchopen:boolean=false;
  totalShipped :any;
  re_search:boolean=false;
  re_productby:boolean=false;
  percDelivered:any;
  percProcessed:any;
  searchtoshow:boolean=false;
  selectedlocation:any[]=[];
  redrawchatdata:any;
  orderrba:any;
  filterValues = {
    search: '',
    filterbyproduct:[''],
    filterbystatus:[''],
  };
  rightdata:any;
  displayedColumns: string[] = [ 'orders', 'qty','location','viewdetails'];
  
  dataSource=new MatTableDataSource<any>();
  latestdelivery:any;
 /*  latestdelivery={
    "shippingData": [
      {
        "location": "The Green Brill, Aylesbury GB HP18 9SA",
        "orders": "60063573",
        "qty": "65",
        "rbaPermission": "MANAGE",
        "viewdetails":"View Details"
      },
      {
        "location": "The Green Brill, Aylesbury GB HP18 9SA",
        "orders": "60063573",
        "qty": "32",
        "rbaPermission": "MANAGE",
        "viewdetails":"View Details"
      },
      {
        "location": "The Green Brill, Aylesbury GB HP18 9SA",
        "orders": "60063573",
        "qty": "34",
        "rbaPermission": "MANAGE",
        "viewdetails":"View Details"
      },
     
      
    ]
  } */
Highcharts: typeof chart = chart;
columnChart!: Chart;  

customPattern(color: string): Highcharts.PatternObject {
  return {
    pattern: {
      patternIndex: 0, 
      path: {
               d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
               stroke: '#979797',
               strokeWidth: 1
             },
      width: 10,
      borderColor: '#979797',
      height: 10,
     //s r:4,
     // patternTransform: 'rotate(-30)',
      //color: 'blue'
  },
  } as Highcharts.PatternObject;
}
initColumn(delivered :any,  processed:any) {
  
  const column = new Chart({
  
  title: {
    text: ''
},
chart: {
    backgroundColor: 'transparent',
    renderTo: 'ordergraphcontainer'
},
//showInLegend: true,
                colors: ["#AEB7BD ", "#3E464B ", "#CFD8E1 "],
                credits: {
                    enabled: false
                },
                legend: {
                  enabled: true,
                  layout: 'vertical',
                  backgroundColor: 'transparent',
                  padding: 5,
                  itemMarginTop: 10,
                  itemMarginBottom: 10,
                  symbolHeight: 16,
                  symbolWidth: 16,
                  symbolRadius: 10,
                  useHTML: true,
                  labelFormatter: function () {
                    const point = this as CustomPoint;
                      return '<div id="orderslabelname"><span>' + point.name + '</span>&nbsp;</div><div id="orderpercnetage"><span>' + point.value + '</span>&nbsp;doses</div>';
                  },
                  width: 400
              },
  series: [{
    showInLegend: true,
    type: 'pie',
    center: ['50%', '50%'],
    borderColor: '#AEB7BD',
    enableMouseTracking: false,
    innerSize: '80%',
    dataLabels: {
        enabled: false,
    },
    data: [{
      y: parseInt(delivered),
      value:parseInt(delivered),
      borderColor: '#AEB7BD',
      name: 'Delivered',
  }, {
          
    y: parseInt(processed),
    value:parseInt(processed),
    name: 'Outstanding',
    color: {
        pattern: {
            path: {
                d: 'M 0 0 L 6 6 M 5 -1 L 7 1 M -1 5 L 1 7',
                strokeWidth: 1
            },
            height: 6,
            width: 6,
        
            color: '#979797'
        }
    }
       
        
      
        }]
}]

  });
  //column.addPoint(12);
  //this.callStorke(column)
  this.columnChart = column;

 
}
ngAfterViewInit() {
  //const chart  = this.initColumn();  
  
}
ngOnInit() {
  //this.initColumn();
  
  this.userInfoService.leftsection().subscribe((res)=>{
    this.ordersummaryleft=res;
    this.product1=this.ordersummaryleft.products[0].presentationName.split("-")[0];
    this.pack1=this.ordersummaryleft.products[0].presentationName.split("-")[1];
    this.product2=this.ordersummaryleft.products[1].presentationName.split("-")[0];
    this.pack2=this.ordersummaryleft.products[0].presentationName.split("-")[1];
    this.deliver=this.ordersummaryleft?.delivered;
    this.process=this.ordersummaryleft?.outstanding;
  
    this.totalShipped=this.ordersummaryleft?.totalOrdered;
    if (this.totalShipped) {
      if (this.deliver && this.deliver > 0) {
          this.percDelivered = this.deliver;
      } else {
          this.percDelivered = 0;
      }
   
      if (this.process && this.process > 0) {
          this.percProcessed = this.process;
      } else {
          this.percProcessed = 0;
      }
  } else {
      this.percDelivered = 0;
      //percInTransit = 0;
      this.percProcessed = 0;
  }
  this.initColumn( this.percDelivered,this.percProcessed);
  if(this.ordersummaryleft?.season=='Seqirus_InSeason'){
    this.inseason();
  }else{
    this.upcomingseason(this.ordersummaryleft?.leftSectionSeason);
  }
  this.cd.detectChanges();
 });


    this.orderrbafunction();
    this.cd.detectChanges(); 
  
 // this.initDonut();
}

inseason(){
  this.userInfoService.chartfilter().subscribe((res)=>{
    this.filterchart=res; 
    if(this.filterchart?.chartFilterData){
    this.unquievalue(this.filterchart?.chartFilterData)
    }
    });
      this.userInfoService.latestdelivery().subscribe((data)=>{
        this.latestdelivery=data;
        this.dataSource=this.latestdelivery.shipmentData;
      });
     
     /* this.dataSource=this.latestdelivery.shippingData; */
}

unquievalue(value1:any){
   this.filtertoshow=  value1.filter((a:any,i:any) => value1.findIndex((s:any) => a.deliveryLoc === s.deliveryLoc) === i) 
}
filtercheck(event:any,value:string){

  const ele=<HTMLInputElement>document.getElementById("chart_searchinput");
  ele.value="";
  this.filterValues.search="";
  if (event.target.checked) {
  
    this.filtercount++;
    this.selectedlocation.push(value);
    this.filterValues.filterbyproduct=this.selectedlocation;
   
  } else {
    var index = this.selectedlocation.indexOf(value);
    this.selectedlocation.splice(index, 1);
    this.filterValues.filterbyproduct=this.selectedlocation;
    
   this.filtercount--;
  } 

  this.redrawchart()
} 
seasonclick(value:string){
  const obj = {
    popup: true,
    season:value
    
  }
  this.userInfoService.setpopupseason(obj);
  
  this.cd.detectChanges();
}

filterclickopen(){
this.filteropen=!this.filteropen;
this.searchtoshow=false;
}
inputsearch(event:Event){
  const filterValue = (event.target as HTMLInputElement).value;
  if(filterValue && filterValue.length>2)
  {
  
 this.searched= this.filtertoshow.filter((product:any) => {

  const search= Object.keys(product).some(key => {
    return (product.deliveryLoc.toString().toLowerCase().includes(filterValue) || product.orgName.toString().toLowerCase().includes(filterValue));

 });

 return search
});

  if(this.searched.length>0){
    this.searchtoshow =true
    this.filteropen=false
  }
  else{
    this.searchtoshow=false;
    this.filteropen=false;
  }
}else{
  this.searchtoshow=false;
    this.filteropen=false;
}
  this.cd.detectChanges(); 
}


searchedClicked(value:string){
  if(value.length>3){
    this.filterValues.search=value;
  }
  else{
    this.filterValues.search="";
  }
  this.searchtoshow=false;
  this.filteropen=false;
  this.redrawchart()
}

reset(){
  this.searchtoshow=false;
  this.filteropen=false;
  this.filterValues.search="";
  this.filterValues.filterbyproduct=[""];
  const input=<HTMLInputElement>document.getElementById("chart_searchinput");
  input.value="";
  this.filtercount=0;
  this.checkboxes.forEach((element) => {
    element.nativeElement.checked = false;
  });

  this.redrawchart();
}

redrawchart(){
  

 this.redrawchatdata=this.filterchart?.chartFilterData.filter((product:any) => {
    if(this.filterValues.search && this.filterValues.search.length>2){
      this.filterValues.search = this.filterValues.search.trim().toLowerCase();
       
       this.re_search= Object.keys(product).some(key => {
            return product[key].toString().toLowerCase().includes(this.filterValues.search);
          }); 
        
        }
        else {
          this.re_search=true;
        };
        if(this.filterValues.filterbyproduct && this.filterValues.filterbyproduct.length>0){
        this.re_productby = this.filterValues.filterbyproduct.some((type:any) => 
    
        product.deliveryLoc.toLowerCase().includes(type.toLowerCase())
    
        );
        }
        else{
          this.re_productby=true;
        }
       
        return this.re_search && this.re_productby;

      });

    let re_delivery=0;
    let re_outstanding=0;
    let re_total=0;
     this.redrawchatdata.forEach((element:any) => {
      if(element?.delivered){
      re_delivery+=element?.delivered;
      }else{
        re_delivery+=0;
      }
      re_outstanding+=element?.outstanding;
      re_total+=element?.total
    });
  this.totalShipped=re_total;
     this.initColumn( re_delivery,re_outstanding) 

  this.cd.detectChanges();
     

}

upcomingseason(season:string){
  this.userInfoService.upcomingorderUk(season).subscribe((res)=>{
    this.rightdata=res;
    this.product1=this.rightdata?.products[0]?.presentationName.split("-")[0];
    this.pack1=this.rightdata?.products[0]?.presentationName.split("-")[1];
    this.product2=this.rightdata?.products[1]?.presentationName.split("-")[0];
    this.pack2=this.rightdata?.products[0]?.presentationName.split("-")[1];
 
    this.dataSourceupcoming=this.rightdata.seasonData
    this.cd.detectChanges();
  })
}
orderrbafunction(){
 
    this.userInfoService.rbasiteflag().subscribe(data=>{
   
     this.orderrba=data;
     this.cd.detectChanges();
     
      
 
    });
   
  

 }

}



