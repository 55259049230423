import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-influenza-reported',
  templateUrl: './custom-influenza-reported.component.html',
  styleUrl: './custom-influenza-reported.component.scss'
})
export class CustomInfluenzaReportedComponent {
  influenzareportnode : Observable<any> = this.component.data$;

  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,private userInfoService:UserInfoService) {
  }
}
