<div class="container-fluid maincontent createprofile_container" *ngIf="leftNode">
        <div class="col-xs-12 col-md-12 createprofile_leftimagecontainer">
                <h1 class="createprofile_leftHeader col-xs-12 col-sm-9" [ngClass]="currentSite == 'seqirusb2b-uk' && (pageLabel=='changepassword' || pageLabel=='changepasswordsuccess')  ? 'col-md-8 uk_changePass':'col-md-9'" [innerHTML]="leftNode.headline"></h1>
                <p class="create-profile-content margin-B20 col-xs-12 col-md-8 col-sm-9" [ngClass]="currentSite == 'seqirusb2b-uk' && pageLabel=='changepassword' ?'uk_changePass_para':''" [innerHTML]="leftNode.content"></p>
                
        </div>
        <div class="grbno loggedOutLeftDoc margin-L10">{{leftNode.h2content}}</div>
</div>

<div id="country_popup" class="modal country_popup" role="dialog" style="padding-right: 10px;">
        <div class="modal-dialog">
    
            <div class="modal-content">
                <div class="modal-body">
    
                        <div class="col-xs-9"></div>
                    <div class="col-xs-3">
                        <span class="Country_popClose Coutrypopupclose" data-dismiss="modal">×</span>
                    </div>
    
                    <h4 class="modal-title Countrypopheader col-xs-12">Confirmation Needed</h4>
    
                    <p class="CountrypopuSubheader col-xs-12">You have selected a non-US Country Code, this website is intended for US Healthcare Professionals only. If you are a GB Healthcare Professional, please visit our <a class="ukwebsite" href="https://flu360.co.uk">GB website.</a></p>
    
                    <div class="clearboth"></div>
    
    
                        <button class="countryclose Coutrypopupclose" data-dismiss="modal">Close</button>
    
                </div>
    
    
            </div>
        </div>
    </div>
