<div class="flucelvax">
    <div class="breadcrumbs--page-nav hidden-xs hidden-sm" *ngIf="!userLoggedIn">
        <div class="container">
            <p>
                <a href="/">Home</a>
                <span> &gt; </span>
                <a href="/products">Products</a>
                <span> &gt; </span><strong>FLUCELVAX<sup>®</sup></strong>
            </p>
        </div>
    </div>
    <div class="product_box1" *ngIf="bannernode |async as item">
        <div class="product_box1-container ">
            <div class="product_box1-text ">{{item.headLine}}</div>
            <div class="product_box1-content">
                <div [innerHTML]="item.content"></div>
                <a href="/products/flucelvax/the-cell-based-solution" class="product_box1-button">See the Cell-Based
                    Difference</a>
            </div>
            <div class="clearboth"></div>
            <div class="product_box1-text2-gray " [innerHTML]="item.text1content"></div>
            <div class="product_box1-disclaimer " [innerHTML]="item.text2content"></div>
        </div>
    </div>
</div>

<div class="fluad">
    <div class="product_box1" *ngIf="bannernode |async as item">
        <div class="container">
            <div class="product_box1-container ">
                <div class="product_box1-text ">{{item.headLine}}</div>
                <div class="product_box1-content">
                    <div [innerHTML]="item.content"></div>
                    <a href="/products/fluad/the-adjuvant-advantage" class="product_box1-button">{{item.linkName}}</a>
                </div>
                <div class="clearboth"></div>
            </div>
        </div>
    </div>
</div>