import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomGigyaRaasComponent } from './custom-gigya-raas/custom-gigya-raas.component';
import { CmsConfig, ConfigModule, I18nModule, NotAuthGuard } from '@spartacus/core';
import { GigyaRaasModule } from "@spartacus/cdc/components";
import { CustomMyProfileModule } from '../custom-my-profile/custom-my-profile.module';


@NgModule({
  declarations: [CustomGigyaRaasComponent],
  imports: [
    CommonModule,
    I18nModule,
    GigyaRaasModule,
    CustomMyProfileModule,
    ConfigModule.withConfig({
      cmsComponents: {
        GigyaRaasComponent: {
          component: CustomGigyaRaasComponent,
        }
      }
    } as CmsConfig)
  ]
})
export class CustomGigyaRaasModule { }
