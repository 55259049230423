import { ChangeDetectorRef, Component } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-opefficiency-third',
  templateUrl: './custom-opefficiency-third.component.html',
  styleUrl: './custom-opefficiency-third.component.scss'
})
export class CustomOpefficiencyThirdComponent {
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, private cmsService: CmsService, private cd: ChangeDetectorRef, protected config: OccConfig) { }
  thirdheadline: Observable<any> = this.bannerComponent.data$;
 
  isAfluria: boolean = true;
  mergedObject1: any = [];
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
//   ngOnInit() {
//     this.thirdheadline.subscribe(res => {
//       this.productlist(res);
//     });
//   }
//   productlist(res: any) {
//     const tilesList = res.featuredResources.split(" ");
//     const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));

//     combineLatest(observables).pipe(
//       map((results: any) => results.flat())
//     ).subscribe((mergedResults: any) => {
//       this.mergedObject1 = mergedResults;
//       this.cd.detectChanges();
//       console.log(this.mergedObject1);
//     });
//   }
// }
ngOnInit() {
  this.thirdheadline.subscribe(res => {
    this.slideresources(res);
  });
}
slideresources(res: any) {
  const tilesList = res.featuredResources.split(" ");
  console.log('tilesList:', tilesList);
  this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
  
    
    // Check if apiRes.component is an array
    if (Array.isArray(res.component)) {
      this.mergedObject1 = res.component;
    } else if (res.component) {
      // Convert to array if it's not already
      this.mergedObject1 = [res.component];
    } else {
      // Ensure it's always an array
      this.mergedObject1 = [];
    }

    console.log('mergedObject1:', this.mergedObject1);
    this.cd.detectChanges();  // Ensure detectChanges is called correctly
  });
}
// slideresources(res: any) {
//   const tilesList = res.featuredResources.split(" ");
//    this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
//     this.mergedObject1 = res.component;
//     console.log(this.mergedObject1);
//     this.cd.detectChanges;
//    });
 
  //for looged, loggedout
//   this.userInfoService.userLoggedIn$.subscribe(res => {
//     console.log('login check', res);
//     this.userLoggedIn = res;
//   })
// }
}
