<div class="notMob">
<div  *ngIf="firstnode | async as item">
<div class="hidden-sm" *ngIf="item.uid=='SeqirusFluadRWEFirstComponent'">
<div class="breadcrumbs--page-nav hidden-xs hidden-sm" *ngIf="!userLoggedIn">
    <div class="container">
        <p><a href="/">Home</a><span> &gt; </span><a href="/products">Products</a><span> &gt; </span><strong>Real-World Evidence</strong></p></div>
</div>
</div>
<section class="landingpage_videoContainer fluad-video hidden-xs hidden-sm" *ngIf="firstnode | async as item">  
    <div *ngIf="item.uid=='SeqirusFluadRWEFirstComponent'">
    <div *ngIf="item.mediaVideo">
    <video autoplay muted playsinline class="rwe_video"> 
        <source [src]="getImage(item.mediaVideo)" type="video/mp4">
    </video>
</div>
</div>
</section>
</div>
</div>


<section class="landingpage_videoContainer  hidden-md hidden-lg" *ngIf="firstnode | async as item">
    <div *ngIf="item.uid=='SeqirusFluadRWEFirstMobComponent'">
    <div *ngIf="item.mediaVideo">
    <video autoplay muted playsinline class="rwe_video"> 
        <source [src]="getImage(item.mediaVideo)" type="video/mp4">
    </video>
   </div>
</div>
 </section>