<div *ngIf="!userLoggedIn || (userLoggedIn && (!userInfo?.webEnabled))">
    <div class="hero--grad hero--grad-alt" *ngIf="herodata | async as item" [style.backgroundImage]="'url(' + getImage(item.media) + ')'">
        <div class="row-flex">
            <div class="col-12  hero--grad__mobile">
                <img alt="Fluad Quadrivalent logo" [src]="getImage(item.media)" id="prodFluad_img">
            </div>
            <!-- <div class="hero--grad_bgimg background-image"> -->
                <div class="container">
                    <div class="col-12 col-md-6">
                        <div class="hero--grad__content">
                            <div class="content-container content-container--has-corner text-left">
                                <h2 class="hero" [innerHtml]="item.headline"></h2>
                                <a class="no-underline redborder ord_enable global_redbtn" [innerHTML]="item.content"
                                    href="{{item.urlLink}}"></a>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>
</div>