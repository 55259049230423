<!-- <p>flucelvax-referenceparagraph works!</p> -->
<section class="col-12 hidden-xs hidden-sm d-none d-md-block no-padding animationbottom" *ngIf="referncenode | async as item">
    <div class="cbsanimationbelow">
        <div [innerHTML]="item.largeparagraphcontent"></div>
    </div>
</section>
<section class="col-12 d-md-none d-lg-none no-padding cellBasedSolution" *ngIf="referncenode | async as item">
    <div class="mobileanimationbelow">
        <div [innerHTML]="item.largeparagraphcontent"></div>
      </div>
  </section>