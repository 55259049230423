<section class="col-xs-12" id="home_solutionssection" *ngIf="node | async as item" [ngClass]="{'home_solutionssection-uk':item.uid=='SeqirusUKFlu360Solutions-OverviewComponent'}">
    <div [innerHTML]="safeh2content"></div>
    <div id="home_solutiontabs">
         <ng-container *ngFor="let banner of banners | slice:0:3;let i=index">
             <div class="col-xs-12 col-md-4" [ngClass]="{'card-expand':show[i]==true,'card-collapse':show[i]==false,'home_tabs-uk':item.uid=='SeqirusUKFlu360Solutions-OverviewComponent','home_tabs':item.uid=='Flu360Solutions-OverviewComponent'}">
                <div class="solutions--btn" *ngIf="item.uid!='SeqirusUKFlu360Solutions-OverviewComponent'">
                    <div class="solutions--hitarea"></div>
                    <img class="expand--btn" alt="Expand" [ngClass]="show[i]?'not-active':''" src="../../../../assets/images/card-expand-btn.svg" (click)="onClick(i)">
                    <img class="collapse--btn" alt="Collapse" [ngClass]="show[i]?'':'not-active'" src="../../../../assets/images/collapse-btn.svg" (click)="onClick(i)">
                </div>
                <div class="home_tabsimg" id="home_tabimg{{i}}"
                    style="background-image: url('{{ getMediaUrl(banner.media) }}');"></div>
                <div class="home_tabsheader" id="home_tabheader{{i}}">{{banner.h2content}}</div>
                <div class="home_tabscontent">{{banner.paragraphcontent}}</div>
                <div class="content--expand oversectionUK expand--content" [ngClass]="show[i]?'':'not-active'" [innerHTML]="banner.content">
                </div>
            </div>
        </ng-container>
        
    </div>
    
    <div class="col-xs-12 global_greybtncontainer" [innerHTML]="paracontent">
    </div>
</section>
