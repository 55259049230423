import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { I18nModule } from '@spartacus/core';
import { ReturnsComponent } from './returns/returns.component';
import { ReturnsSection1Component } from './returns-section1/returns-section1.component';
import { ReturnBannerComponent } from './return-banner/return-banner.component';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GalleryModule } from 'ng-gallery';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { ReturnNewComponent } from './return-new/return-new.component';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { ReturnsAdditionalComponent } from './returns-additional/returns-additional.component';
import { ReturnsExitPopupComponent } from './returns-exit-popup/returns-exit-popup.component';
import { ReturnsJobcodeComponent } from './returns-jobcode/returns-jobcode.component';



@NgModule({
  declarations: [
    ReturnsComponent,
    ReturnsSection1Component,
    ReturnBannerComponent,
    FileUploadModalComponent,
    ReturnNewComponent,
    ImageDialogComponent,
    ReturnsAdditionalComponent,
    ReturnsExitPopupComponent,
    ReturnsJobcodeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    SlickCarouselModule,
    MatDialogModule,
    NgxFileDropModule,
    GalleryModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        
         ReturnsBannerComponent : {
           component: ReturnBannerComponent,
         },
          SeqirusUKBannerComponent : {
            component:ReturnsSection1Component, 
          },
          ReturnsComponent : {
            component: ReturnsComponent,
          },
          StartNewReturnComponent : {
            component: ReturnNewComponent,
          },
          additionalReturnComponent :{
            component: ReturnsAdditionalComponent
          }
        
     
      }
    } as CmsConfig)
  ]
})
export class ReturnsUkModule { }
