import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthRedirectService,
  AuthService,
  AuthStorageService,
  OAuthLibWrapperService,
  OCC_USER_ID_ANONYMOUS,
  RoutingService,
  StateWithClientAuth,
  UserIdService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { GigyaUidStorageService } from './gigya-uid-storage.service';

/**
 * Custom AuthService to work with Gigya.
 */
@Injectable({
  providedIn: 'root',
})
export class GigyaAuthService extends AuthService {
  constructor(
    protected override store: Store<StateWithClientAuth>,
    protected override userIdService: UserIdService,
    protected override oAuthLibWrapperService: OAuthLibWrapperService,
    protected override authStorageService: AuthStorageService,
    protected override authRedirectService: AuthRedirectService,
    protected override routingService: RoutingService,
    private gigyaUidStorageService: GigyaUidStorageService
  ) {
    super(
      store,
      userIdService,
      oAuthLibWrapperService,
      authStorageService,
      authRedirectService,
      routingService
    );

    this.userIdService
      .getUserId()
      .pipe(filter(id => id === OCC_USER_ID_ANONYMOUS))
      .subscribe(() => this.gigyaUidStorageService.removeGigyaUid());
  }

  /**
   * Checks if user is logged in based on the Gigya UID.
   */
  public override isUserLoggedIn(): Observable<boolean> {
    return this.gigyaUidStorageService.getGigyaUid().pipe(
      map(gigyaUid => Boolean(gigyaUid)),
      distinctUntilChanged()
    );
  }
}
