<div id="publicpage-footer">
    <div class="col-xs-12 footer_container" *ngIf="publicfooternode | async as item">
        <div class="col-xs-12 container-fluid">
            <div class="row">
                <div class="col-md-4 col-xs-12">
                    <div>
                    </div>
                    <!-- <div class="hidden-xs visible-md-* visible-lg-* hidden-sm"> -->
                    <div class="d-none d-md-block d-lg-block">
                        <div class="logo_seqirus">
                            <img  [src]="getImage(item.footerlogo)" alt="white Seqirus logo">
                        </div>
                        <div class="footertermsheaderuk" style="color: #fff;">{{item.heading1}}</div>
                        <p class="footerpara-1 footermar" style="color: #fff;" [innerHTML]="item.text1"></p><br>
                        <div class="footertermsheader1uk" style="color: #fff;">{{item.heading2}}</div>
                        <div class="footertermsuk" style="color: #fff;" [innerHTML]="item.text2"></div><br>
                        <div class="footertermsheader1uk" style="color: #fff;">{{item.heading3}}</div>
                        <div class="footertermsuk" style="color: #fff;" [innerHTML]="item.text3"></div>

                    </div>
                </div>

                <div class="col-md-3 col-xs-12 marTop-30 publicFooterAlignment">
                    <div class="footer_headinguk">{{item.text4}}</div>
                    <ul class="footer_menu_listuk">
                        <li [innerHTML]="item.text5"></li>
                        <li [innerHTML]="item.text6"><li>
                        <li [innerHTML]="item.text7"></li>
                        <li [innerHTML]="item.text8"></li>
                    </ul>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <!-- <div class="hidden-md hidden-lg visible-xs-* visible-sm-* margin-T20 margin-B20"> -->
                    <div class="d-block d-md-none d-lg-none margin-T20 margin-B20">
                        <div class="logo_seqirus">
                        </div>
                        <div class="fotter_txtuk">Visit Our Corporate Site<br>at <a class="footer-anchorlink"
                                href="https://www.seqirus.com/" target="blank">seqirus.com</a>
                            <div class="linkbtton"><i class="fa fa-external-link" aria-hidden="true"></i></div>
                        </div>
                        <div class="footertermsheaderuk" style="color: #fff;">{{item.heading1}}</div>
                        <p class="footerpara-1 footermar" style="color: #fff;" [innerHTML]="item.text1"></p><br>
                        <div class="footertermsheader1uk" style="color: #fff;">{{item.heading2}}</div>
                        <div class="footerterm" style="color: #fff;" [innerHTML]="item.text2"></div><br>
                        <div class="footertermsheader1uk" style="color: #fff;">{{item.heading3}}</div>
                        <div class="footerterm" style="color: #fff;"  [innerHTML]="item.text3"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 footer_txt_disclaimerukconsent">
                    <p class="footer_copynote">{{item.heading4}}</p>

                </div>
            </div>
        </div>
    </div>
</div>