import { ChangeDetectorRef, Component } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';

@Component({
  selector: 'app-carousel-data-qivc',
  templateUrl: './carousel-data-qivc.component.html',
  styleUrl: './carousel-data-qivc.component.scss'
})
export class CarouselDataQivcComponent {
  // thirdnode: Observable<any> = this.bannerComponent.data$;
  // mergedObject1: any = [];
  // toggleArray: any = [];
  // activeTabIndex!: number;
  // subTileData: any = [];
  // selectedTile: any;
  // // fourthTab:boolean = false;
  // constructor(
  //   private accessService: ContentService,
  //   public bannerComponent: CmsComponentData<CmsBannerComponent>,
  //   protected config: OccConfig,
  //   private cd: ChangeDetectorRef
  // ) {}
  // getImage(image: any): string {
  //   return this.config.backend?.occ?.baseUrl + image.desktop.url;
  // }
  // ngOnInit() {
  //   this.thirdnode.subscribe((res: any) => {
  //     console.log('Response from thirdnode:', res); // Log the response
  //     this.productTabs(res);
  //   });
  // }
 
  // productTabs(res: any) {
  //   const prodList = res.AllResources.split(" ");
  //   console.log('Product List:', prodList); // Log the product list
  //   const observables = prodList.map((el: any) => this.accessService.opeffeaturedResources(el));
  //   combineLatest(observables).pipe(
  //     map((results: any) => results.flat())
  //   ).subscribe((mergedResults: any) => {
  //     console.log('Merged Results:', mergedResults); // Log the merged results
  //     this.mergedObject1 = mergedResults;
  //     this.mergedObject1.forEach((_: any, i: number) => this.toggleArray[i] = i === 0);
  //     this.selectedTile = this.mergedObject1[0]; // Default to the first tile
  //     this.cd.detectChanges();
  //     // Fetch sub-tile data for the first tile by default, or handle others as needed
  //     if (this.selectedTile.vaccineFeaturedResources) {
  //       this.fetchSubTileData(this.selectedTile.vaccineFeaturedResources);
  //     }
  //   });
  //  }
  //  fetchSubTileData(resourceString: string) {
  //   const subTileIds = resourceString.split(' ');
  //   const subTileObservables = subTileIds.map((id: string) => this.accessService.opeffeaturedResources(id));
  //   combineLatest(subTileObservables).subscribe((subTileResults: any) => {
  //     console.log('Sub Tile Results:', subTileResults); // Log the entire sub-tile results array
  //     // Log each sub-tile detail
  //     subTileResults.forEach((subTile: any, index: number) => {
  //       console.log(`Sub Tile ${index + 1}:`, subTile);
  //     });
  //     this.subTileData = subTileResults;
  //     this.cd.detectChanges();
  //   });
  //  }
  //  toggleNav(i: number) {
  //   this.toggleArray = this.toggleArray.map((_: any, index: number) => index === i);
  //   this.selectedTile = this.mergedObject1[i]; // Update selected tile content
  //   // Fetch sub-tile data when the tile is toggled and has vaccineFeaturedResources
  //   if (this.selectedTile.vaccineInfoFeaturedResources) {
  //     this.fetchSubTileData(this.selectedTile.vaccineInfoFeaturedResources);
  //   }
  //   this.cd.detectChanges();
  //  }
  }