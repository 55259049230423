import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-multiple-needs',
  templateUrl: './multiple-needs.component.html',
  styleUrl: './multiple-needs.component.scss'
})
export class MultipleNeedsComponent {
  constructor(
    public component: CmsComponentData<CmsBannerComponent>,
    protected config: OccConfig,
    public accessService: ContentService,
    public cd: ChangeDetectorRef,
    private userInfoService: UserInfoService
  ) {}

  bannerNode: Observable<any> = this.component.data$;
  mergedObject1: any[] = [];  // Ensure this is an array
  userLoggedIn: boolean = false;

  ngOnInit() {
    this.bannerNode.subscribe(res => {
      console.log('slideNode response:', res);
      this.slideresources(res);
    });
  }

  getImage(image: any): string {
    if (!image || !image.url) {
      console.error('Invalid image object:', image);
      return '';
    }
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  slideresources(res: any) {
    const tilesList = res.pagesList.split(" ");
    console.log('tilesList:', tilesList);
    this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
    
      
      // Check if apiRes.component is an array
      if (Array.isArray(res.component)) {
        this.mergedObject1 = res.component;
      } else if (res.component) {
        // Convert to array if it's not already
        this.mergedObject1 = [res.component];
      } else {
        // Ensure it's always an array
        this.mergedObject1 = [];
      }

      console.log('mergedObject1:', this.mergedObject1);
      this.cd.detectChanges();  // Ensure detectChanges is called correctly
    });
  }
}
