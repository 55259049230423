<section class="prod-sb" style="background-color: #4ECFF8;">
    <div class="container prod-sb__body" *ngIf ="node | async as item">   
        <div class="prod-sb__left prod-sb__leftUK">
            <img class="prod-sbs__img">
            <img [src]="getImage(item?.media)" >   
                 </div>
                 <div class="prod-sb__right prod-sb__right--dark prod-sb__rightUK">
                <h2 [innerHTML]="item?.headline"></h2>
                </div>
        </div>
</section>