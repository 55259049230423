import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-first-animation',
  templateUrl: './first-animation.component.html',
  styleUrl: './first-animation.component.scss'
})

export class FirstAnimationComponent {
  node2: Observable<any> = this.component.data$;
 
  userLoggedIn: any;
  userInfo: any;
  
  
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    private cmsService:CmsService, 
    protected config: OccConfig,
    private userInfoService:UserInfoService,
     private cd:ChangeDetectorRef) {}
  
  getVideo(video: any): string {
    return this.config.backend?.occ?.baseUrl + video.url;
  }
  ngOnInit(): void{
    //for looged, loggedout
    this.userInfoService.userLoggedIn$.subscribe((res: any)=>{
      console.log('login check',res);
      this.userLoggedIn = res;
      if(this.userLoggedIn){
        this.userInfoService.getUserData().subscribe((res: any)=>{
          if(res){
          this.userInfo=res;
          }
        });
      }
      this.cd.detectChanges();
    })
      //to get the current basesite 
      // this.baseSiteService.getActive().subscribe((siteId) => {
      //   this.currentSite = siteId;
      //   console.log(this.currentSite);
      // });
 }
}
