import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-challenges-banner',
  templateUrl: './custom-challenges-banner.component.html',
  styleUrl: './custom-challenges-banner.component.scss'
})
export class CustomChallengesBannerComponent {
  constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>, private cd: ChangeDetectorRef, protected config: OccConfig, private userInfoService: UserInfoService, private renderer: Renderer2) { }
  userLoggedIn: boolean=false;
  userInfo: any;
  challengesbanner: Observable<any> = this.bannerComponent.data$;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  ngOnInit() {
    this.userInfoService.userLoggedIn$.subscribe(res => {
      // console.log('login check',res);
      this.userLoggedIn = res;
      if (this.userLoggedIn) {
        this.userInfoService.getUserData().subscribe(res => {
          if (res) {
            this.userInfo = res;
          }
        });
      }
      if (this.userInfo?.webEnabled == false) {
        this.renderer.setStyle(document.querySelector('.SideContent'), 'display', 'none');
      }
      this.cd.detectChanges();
    })
  }
}
