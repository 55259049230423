<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 LI_errorTextParent">
    <div class="LI_errorText1 inactive_align">You have been logged out due to inactivity.</div>
    <div class="LI_errorText2 inactive_content_align">Our system automatically logs you out after 25&nbsp;minutes of inactivity for security reasons. You can log back in using the button on this page.</div>
    <div class="LI_errorbtnParent inactive_align">
        <div class="LI_errorbtnParent inactive_align btns"> 
            <a href="/login">
                <button class="LI_errorReturnDashboardBtn inactive_login_btn">Log Back In</button>
            </a> 
            <a href="/">
                <button class="LI_errorViewBtn inactive_home_btn">Go to Home</button>
            </a> 
        </div>
    </div> 
</div>