import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummarybannerComponent } from './summarybanner/summarybanner.component';
import { LeftsectionComponent } from './leftsection/leftsection.component';
import { RightsectionComponent } from './rightsection/rightsection.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { ChartModule } from 'angular-highcharts';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';


@NgModule({
  declarations: [
    SummarybannerComponent,
    LeftsectionComponent,
    RightsectionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CmsCommonModule, 
    ChartModule,
    MatProgressBarModule,
    MatTableModule,
    ConfigModule.withConfig({
      cmsComponents: {
        
      
        SeqirusOrderSummaryCMSComponent:{
          component: SummarybannerComponent ,
        },
        SeqirusOrderSummaryLeftSectionComponent:{
          component: LeftsectionComponent ,
        },
        SeqirusOrderSummaryRightSectionComponent:{
          component: RightsectionComponent ,
        }
     
     
     
      }
    } as CmsConfig)
  ]
})
export class OrderSummaryUkModule { }
