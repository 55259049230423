import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-homepage-reference',
  templateUrl: './homepage-reference.component.html',
  styleUrl: './homepage-reference.component.scss'
})
export class HomepageReferenceComponent {
  paracontent:string="";
  safeh2content:SafeHtml | undefined;
  constructor(public component: CmsComponentData<any>,protected contentService:ContentService,private cdr:ChangeDetectorRef,private sanitizer:DomSanitizer,protected config: OccConfig) {
    
  }

  ngOnInit(){
    
    this.component.data$.subscribe((data) =>{
      
      this.paracontent = data.largeparagraphcontent;
      this.safeh2content = this.sanitizer.bypassSecurityTrustHtml(this.paracontent);
    })
  }

}
