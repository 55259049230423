import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLeftNavigationComponent } from './custom-left-navigation/custom-left-navigation.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { NavigationModule } from '@spartacus/storefront';
import { SessiontimeoutModule } from '../sessiontimeout/sessiontimeout.module';



@NgModule({
  declarations: [
    CustomLeftNavigationComponent
  ],
  imports: [
    CommonModule,
    NavigationModule,
    RouterModule,
    SessiontimeoutModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusLeftNavigationComponent: {
          component: CustomLeftNavigationComponent
        }
      }
    } as CmsConfig)
  ]
})
export class CustomAccountModule { }
