import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-flucelvax-banner',
  templateUrl: './flucelvax-banner.component.html',
  styleUrl: './flucelvax-banner.component.scss'
})
export class FlucelvaxBannerComponent {
  node1: Observable<any> = this.component.data$;
  userLoggedIn: boolean = false;

  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,private userInfoService:UserInfoService) {}

  ngOnInit(): void{
    this.userInfoService.userLoggedIn$.subscribe(res=>{
     console.log('login check',res);
     this.userLoggedIn = res;
   })
  }

  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
}
