import { Component ,Renderer2,ElementRef} from '@angular/core';
import { CmsBannerComponent,CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-fluad-rwe-first',
  templateUrl: './fluad-rwe-first.component.html',
  styleUrl: './fluad-rwe-first.component.scss'
})
export class FluadRweFirstComponent {

userLoggedIn:boolean=false;
firstnode: Observable<any> = this.bannerComponent.data$;
constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>, private userInfoService:UserInfoService) {
   }

ngoninit(){
  this.userInfoService.userLoggedIn$.subscribe(res=>{
    console.log('login check',res);
    this.userLoggedIn = res;
    // if(this.userLoggedIn == true){
    //   this.renderer.setStyle(document.querySelector('.SideContent'),'display','block');
    // }
  })
   }

getImage(image: any): string {
  return this.config.backend?.occ?.baseUrl + image.url;
}


}
