import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartbannerComponent } from './cartbanner/cartbanner.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { LocationpageComponent } from './locationpage/locationpage.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExitPopupComponent } from './exit-popup/exit-popup.component';
import { ProductpageComponent } from './productpage/productpage.component';
import { ReviewpageComponent } from './reviewpage/reviewpage.component';
import { ConfirmOrderThankYouPageComponent } from './confirm-order-thank-you-page/confirm-order-thank-you-page.component';
import { SaveOrderThankYouPageComponent } from './save-order-thank-you-page/save-order-thank-you-page.component';
import { SorryPageComponent } from './sorry-page/sorry-page.component';
import { AmendProductPageComponent } from './amend-product-page/amend-product-page.component';
import { AmendReviewPageComponent } from './amend-review-page/amend-review-page.component';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { AmendOrderThankYouPageComponent } from './amend-order-thank-you-page/amend-order-thank-you-page.component';
import { InseasonConfirmOrderThankYouPageComponent } from './inseason-confirm-order-thank-you-page/inseason-confirm-order-thank-you-page.component';
import { AmendSaveOrderThankYouPageComponent } from './amend-save-order-thank-you-page/amend-save-order-thank-you-page.component';


@NgModule({
  declarations: [
    CartbannerComponent,
    LandingpageComponent,
    LocationpageComponent,
    ExitPopupComponent,
    ProductpageComponent,
    ReviewpageComponent,
    ConfirmOrderThankYouPageComponent,
    SaveOrderThankYouPageComponent,
    SorryPageComponent,
    AmendProductPageComponent,
    AmendReviewPageComponent,
    AmendOrderThankYouPageComponent,
    InseasonConfirmOrderThankYouPageComponent,
    AmendSaveOrderThankYouPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    CmsCommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        StartOrderLocationPageComponent: {
          component: LocationpageComponent
        },
        StartOrderExitPopupComponent: {
          component: ExitPopupComponent
        },
        TopBanerStartOrderPageComponent : {
          component: CartbannerComponent ,
        },
        SeqirusStartOrderCommitmentsComponent:{
          component:LandingpageComponent,
        },
        StartOrderFlexComponent: {
          component:ProductpageComponent
        },
        StartOrderRefrencesComponent: {
          component: ReviewpageComponent
        },
        ThankYouSaveOrderComponent: {
          component: SaveOrderThankYouPageComponent 
        },
        ThankYouConfirmOrderComponent: {
          component: ConfirmOrderThankYouPageComponent 
        },
        AmendOrderProductFlexComponent: {
          component: AmendProductPageComponent
        },
        AmendOrderReviewFlexComponent: {
          component: AmendReviewPageComponent
        },
        ThankYouAmendConfirmOrderComponent: {
          component: AmendOrderThankYouPageComponent 
        },
        ThankYouAmendSaveOrderComponent: {
          component: AmendSaveOrderThankYouPageComponent 
        },
        SeqirusOrderSorryMessageComponent: {
          component: SorryPageComponent
        },
        ThankYouInseasonConfirmOrderComponent: {
          component: InseasonConfirmOrderThankYouPageComponent 
        }
      }
    } as CmsConfig)
  ]
})
export class StartOrderUKModule { }
