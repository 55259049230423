import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, map, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-slide-resources',
  templateUrl: './custom-slide-resources.component.html',
  styleUrl: './custom-slide-resources.component.scss'
})
export class CustomSlideResourcesComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, public accessService: ContentService, public cd: ChangeDetectorRef, private userInfoService: UserInfoService,private renderer: Renderer2) {
  }
  slideNode: Observable<any> = this.component.data$;
  mergedObject1: any ;
  userInfo : any;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  userLoggedIn: boolean = false;
  
  ngOnInit() {
    this.slideNode.subscribe(res => {
      this.slideresources(res);
    });
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      // console.log('login check',res);
      this.userLoggedIn = res;
      if(this.userLoggedIn){
        this.userInfoService.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
          }
        });
      }
      // if(this.userInfo?.webEnabled == true){
      //   this.renderer.setStyle(document.querySelector('.SideContent'),'display','block');
      //  }
      // this.cd.detectChanges();
    })

  }

  slideresources(res: any) {
    const tilesList = res.flu360VaccineSlideResources.split(" ");
     this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
      this.mergedObject1 = res.component;
      console.log(this.mergedObject1);
      this.cd.detectChanges;
     });
   
    // //for looged, loggedout
    // this.userInfoService.userLoggedIn$.subscribe(res => {
    //   console.log('login check', res);
    //   this.userLoggedIn = res;
    // })
  }
}