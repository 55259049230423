<section class="col-xs-12" id="home_resourcessection">
    <div *ngIf="bannerNode | async  as item1" class="col-xs-12 global_headerwithdash" id="home_dashleftpadding">
        <div class="global_dashheader">{{item1.headLine}}</div>
        <div class="global_line"></div>
 </div>
    <div *ngIf="finalArray?.length > 0" class="home_desktopresourcessection col-xs-12 ">
       
                <div *ngFor="let item of finalArray;  let  i= index" class="home_resources item active col-xs-4">
                    <div class="item__third">
                    <div class="home_resourcescontent">
                        <div class="home_resourceimg col-xs-12" id="home_clinicalimg" aria-label='item.tileImage.altText' style="background-image: url('{{getMediaUrl(item.tileImage.url)}}')"></div>
                        <div class="home_resourceheader col-xs-12" id="home_clinicalheader">{{item.tileType}}</div>
                        <div class="home_resourcesubheader col-xs-12" id="home_clinicalsubheader" [innerHTML]="item.tileHeadLine">{{item.tileHeadLine}}</div>
                        <div class="home_resourcetext col-xs-12" id="home_clinicaltext">{{item.tileDescription}}</div>
                        <div class="home_resourcelink col-xs-12" id="home_clinicallink">
                            <a [href]='item.tileLink'>{{item.tileLinkLabel}}
                                <div class="global_blackarrow"></div></a>
                        </div>
                    </div>
                  
                
            
        </div>
    </div>
        <!-- <div *ngIf="resourse2 | async  as item" class="home_resources item active col-md-4">
            <div class="item__third">
                <div class="home_resourcescontent">
                    <div class="home_resourceimg col-xs-12" id="home_clinicalimg" alt="Guide providing education on flu basics" style="background-image: url('{{getMediaUrl(item.picture)}}')"></div>
                    <div class="home_resourceheader col-xs-12" id="home_clinicalheader">{{item.solutionType}}</div>
                    <div class="home_resourcesubheader col-xs-12" id="home_clinicalsubheader">{{item.headline}}</div>
                    <div class="home_resourcetext col-xs-12" id="home_clinicaltext">{{item.description}}</div>
                    <div class="home_resourcelink col-xs-12" id="home_clinicallink">
                        <a href="/flu-vaccine-resources/flu-basics-guide">Access Resource
                            <div class="global_blackarrow"></div>
                        </a>
                    </div>
                
            </div>
        </div>
    </div> -->
    <!-- <div *ngIf="resourse3 | async  as item" class="home_resources item active col-md-4">
        <div class="item__third">
            <div class="home_resourcescontent">
                <div class="home_resourceimg col-xs-12" id="home_clinicalimg" alt="Guide providing education on flu basics" style="background-image: url('{{getMediaUrl(item.picture)}}')"></div>
                <div class="home_resourceheader col-xs-12" id="home_clinicalheader">{{item.solutionType}}</div>
                <div class="home_resourcesubheader col-xs-12" id="home_clinicalsubheader">{{item.headline}}</div>
                <div class="home_resourcetext col-xs-12" id="home_clinicaltext">{{item.description}}</div>
                <div class="home_resourcelink col-xs-12" id="home_clinicallink">
                    <a href="/flu-vaccine-resources/flu-basics-guide">Access Resource
                        <div class="global_blackarrow"></div>
                    </a>
                </div>
            
        </div>
    </div>
</div> -->
    </div>
  
  <!-- data---{{data6  | json}}
  merge --- {{mergedObject1 |json}} -->

<div  id="home_resourcetabs" class="carousel slide col-xs-12" data-interval="false" >
   
    <ol class="carousel-indicators d-xs-block d-sm-block  d-lg-none">
      <li data-target="#home_resourcetabs" data-slide-to="0" class="active"></li>
      <li data-target="#home_resourcetabs" data-slide-to="1"></li>
      <li data-target="#home_resourcetabs" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner global_mobilecarousel col-xs-12 d-xs-block d-sm-block  d-lg-none" #myDiv>
      <div *ngIf="finalArray?.length > 0">
        <div  *ngFor="let item of finalArray ; let j= index" class="carousel-item  home_resources" [ngClass]="{'active': j === 0}">
        <div *ngFor="let item1 of finalArray;  let  i= index" class="item__third">    
           <div class="home_resourcescontent">                
                <div class="home_resourceimg col-xs-12" [id]="'home_' + getProduct(i) + 'img'" alt="Guide providing education on flu basics" style="background-image: url('{{getMediaUrl(item1.tileImage.url)}}')"></div>
                <div class="home_resourceheader col-xs-12" [id]="'home_' + getProduct(i) + 'header'">{{item1.tileType}}</div>
                <div class="home_resourcesubheader col-xs-12" [id]="'home_' + getProduct(i) + 'subheader'" [innerHTML]="item1.tileHeadLine">{{item1.tileHeadLine}}</div>
                <div class="home_resourcetext col-xs-12" [id]="'home_' + getProduct(i) + 'text'">{{item1.tileDescription}}</div>
                <div class="home_resourcelink col-xs-12" [id]="'home_' + getProduct(i) + 'link'">                    
                    <a href="item1.tileLink">{{item1.tileLinkLabel}}
                        <div class="global_blackarrow"></div></a>
                </div>
           </div>          
        </div>
    </div>
</div>
</div>
 
    
    <a class="left carousel-control d-xs-block d-sm-block d-md-none d-lg-none"  data-target="#home_resourcetabs" data-slide="prev">
      
      </a>
      <a class="right carousel-control d-xs-block d-sm-block d-md-none d-lg-none"  data-target="#home_resourcetabs" data-slide="next">
        
      </a>
   
 </div>
    

  <div class="col-xs-12 global_greybtncontainer">
    <div class="global_greybtn"><a href="/flu-vaccine-resources">View All Resources</a></div>
</div>    
</section>