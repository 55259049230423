import { AfterViewInit, Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-help-faq-third',
  templateUrl: './help-faq-third.component.html',
  styleUrl: './help-faq-third.component.scss'
})
export class HelpFaqThirdComponent implements AfterViewInit{
  private mainContentHeight !: number;
  userLoggedIn: boolean = false;
  constructor(public component: CmsComponentData<CmsBannerComponent>,private renderer: Renderer2, private el: ElementRef,private userInfoService:UserInfoService){

  }
  thirdnode: Observable<any> = this.component.data$;
  ngAfterViewInit() {
    const mainContent = this.el.nativeElement.querySelector('#help_contactsection');
    this.mainContentHeight = mainContent.offsetHeight;
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const gotopbtn = this.el.nativeElement.querySelector('#help_backtop');
    const mainContent = this.el.nativeElement.querySelector('#help_contactsection');
    const mainContentBottom = mainContent.offsetTop + this.mainContentHeight;

    if (window.scrollY > 1000 && window.scrollY < mainContentBottom - window.innerHeight) {
      this.renderer.setStyle(gotopbtn, 'display', 'block');
    } else {
      this.renderer.setStyle(gotopbtn, 'display', 'none');
    }
  }
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  // ngOnInit(): void{
  //   //for looged, loggedout
  //   this.userInfoService.userLoggedIn$.subscribe(res=>{
  //    console.log('login check',res);
  //    this.userLoggedIn = res;
  //  })
  // }
}
