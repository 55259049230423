import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CmsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { CustomMemberModalComponent } from '../custom-member-modal/custom-member-modal.component';

@Component({
  selector: 'app-custom-request-thankyou',
  templateUrl: './custom-request-thankyou.component.html',
  styleUrl: './custom-request-thankyou.component.scss'
})
export class CustomRequestThankyouComponent {
  newCompanyName: string = '';
  constructor(private dialog: MatDialogRef<CustomRequestThankyouComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private dialogData: MatDialog,
    private commonService: CommonApiService,
  ) { }
  ngOnInit() {
  }
  close() {
    this.dialog.close();
  }
  getNewCompName(value: string) {
    this.newCompanyName = value;
  }
  submitBusinessDetails() {
    let payload = {
      url: this.data.url,
      data: {
        addressType: 'Business',
        addressId: this.data.payload.addressId,
        globalLocationNumber: this.data.payload.globalLocationNumber,
        postalCode: this.data.payload.postalCode,
        city: this.data.payload.city,
        state: this.data.payload.state,
        country: this.data.payload.country,
        modifiedQualifiers: 'orgName',
        orgName: this.newCompanyName,
        line1: this.data.payload.line1,
        line2: this.data.payload.line2
      }
    }
    this.commonService.postAPICall(payload).subscribe({
      next: (res: any) => {
        this.dialog.close(true);
        this.openDialog({type:'request-thank-you'});
      },
      error: (e) => {
      },
    })
  }
  openDialog(data: any): void {
    this.dialogData.open(CustomMemberModalComponent, {
      width: '600px',
      height: '350px',
      data: data,
    });
  }
}
