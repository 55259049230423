import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService, OccEndpointsService } from '@spartacus/core';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { UserInfoService } from '../spartacus/services/user-info.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SeqirusAuthGuard {
  currentRoute: string='';
  constructor(private authService: AuthService,
     private router: Router,private occ:OccEndpointsService,
    private userInfoService:UserInfoService,private cookieService:CookieService) {
  }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Observable<boolean | UrlTree> | boolean | any {
    
  //  return this.authService.isUserLoggedIn().pipe(
  //     map(isLoggedIn => {
  //       if (!isLoggedIn) {
  //         console.log(route, 'auth guard');
  //         this.router.navigate(['/login']);
  //         return false;
  //       }     
  //       return true;
  //     })
  //   );
  // }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.authService.isUserLoggedIn().pipe(
      switchMap(isLoggedIn => {
        if(!this.occ.getBaseUrl().includes('seqirusb2b-flu360') && (!this.cookieService.check('visitor_type') || this.cookieService.get('visitor_type') != 'healthcare')){
          console.log(route); 
          console.log(state);
          let url = state.url.split('?')[0].split('/');
          this.currentRoute = url[url.length - 1];
          sessionStorage.setItem('navigateToUrl',state.url);
          if(this.currentRoute !== ''){
          this.router.navigate(['/']);
          
         }
        //  return true;
        }
        else if (!isLoggedIn) {
          this.router.navigate(['/login']);
          return of(false);
        }
        
        return this.userInfoService.getUserData().pipe(
          map(userData => {
            // console.log(userData)
            if (userData && userData.webEnabled) {
              return true;
            } else {
              return true;
            }
          }),
          catchError(error => {
            this.router.navigate(['/error-page']);
            return of(false);
          })
        );
      })
    );
  }
}





