import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsHeadlineComponent } from './terms-headline/terms-headline.component';
import { FirstTermsAndConditionsComponent } from './first-terms-and-conditions/first-terms-and-conditions.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';



@NgModule({
  declarations: [
    TermsHeadlineComponent,
    FirstTermsAndConditionsComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusuktermsComponent:{
          component:TermsHeadlineComponent
        },
        SeqirusuktermslistComponent:{
          component:FirstTermsAndConditionsComponent,
        },
      },
        }as CmsConfig)
  ]
})
export class UkTermsAndConditionsModule { }
