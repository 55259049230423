import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeoutModalComponent } from './timeout-modal/timeout-modal.component';
import { InactivePageComponent } from './inactive-page/inactive-page.component';
import { CmsConfig, ConfigModule } from "@spartacus/core";
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    TimeoutModalComponent, 
    InactivePageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents:{
        InactiveFlexComponent:{
          component: InactivePageComponent
        },
      }
    }as CmsConfig)
  ],
  exports: [
    TimeoutModalComponent
  ]
})
export class SessiontimeoutModule { }
