import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CmsConfig, ConfigModule } from "@spartacus/core";
import { FirstCompComponent } from "./first-comp/first-comp.component";

@NgModule({
    declarations: [
        FirstCompComponent
    ],
    imports:[
        CommonModule,
        ConfigModule.withConfig({
            cmsComponents:{
                SeqirusLoginPageProductBannerComponent:{
                    component: FirstCompComponent
                },
            }
        }as CmsConfig)
    ]
})

export class ProductsUkModule { }