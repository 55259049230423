import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { FormsModule } from '@angular/forms';
import { ChartModule } from 'angular-highcharts';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { OrderOverviewComponent } from './order-overview/order-overview.component';
import { CurrentSeasonStatusComponent } from './current-season-status/current-season-status.component';
import { NewOrdersComponent } from './new-orders/new-orders.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    OrderSummaryComponent,
    OrderOverviewComponent,
    CurrentSeasonStatusComponent,
    NewOrdersComponent
  ],
  imports: [
    CommonModule,
    CmsCommonModule,
    I18nModule,
    FormsModule,
    ChartModule,
    MatTableModule,
    MatProgressBarModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusOrderSummaryBannerSectionComponent : {
          component: OrderSummaryComponent ,
        },
        // SeqirusOrderSummaryProductSectionComponent:{
        //   component: OrderOverviewComponent
        // },
        SeqirusOrderSummaryChartSectionComponent:{
          component: CurrentSeasonStatusComponent
        },
        // SeqirusOrderSummaryNewOrderSectionComponent:{
        //   component: NewOrdersComponent
        // },
        SeqirusOrderSummaryShipmentSectionComponent:{
          component: OrderOverviewComponent
        }
     
      }
    } as CmsConfig)
  ]
})
export class SummaryModule { }
