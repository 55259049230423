<div *ngIf="isVisible">
  <div class="loading" *ngIf="showloader" >
    <div class="loading_txt">
     <div class="load_txt">Loading</div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
     </div>
</div>
    <div class="col-xs-12 col-md-12 col-lg-12 no-padding UkCreateReturn" >
        <section class="col-md-12 no-padding newReturnText" >
            <div class="col-md-6 newReturns_subheader">
                Verify No Returns
            </div>
        </section>
        
        <section class="col-xs-12 ReturnSelectLocation" id="newReturn_selectlocation" *ngIf="noReturnlanding" > 
                        
          <div class="newReturn_selectparent " id="newReturn_selectlocation-1" >
           
            <div class="newReturn_selectbox">
            
            <div class="col-xs-12 col-md-8 no-padding newReturn_selectheader">Select Locations<div class="createSelText">Please select the location(s) that you are confirming have no returns to submit. Once you have confirmed there are no returns you will be unable to submit a return claim for that location.</div></div>
            <div class="col-xs-12 col-md-4 no-padding newReturn_selectcount">Verify: <span class="newReturn_loccount">{{ selectedCount }}</span>&nbsp;<span>location(s)</span></div>
           
            <div class="col-xs-12 cart_selectline"></div>
            <div class="clearboth"></div>
            <div class="col-xs-12 newreturn_selectoptions">
                        
              <div class="newReturn_customsearch">
                <i class="global_searchicon"></i><input type="search" placeholder="Search for location" id="newreturn_searchbox" (keyup)="applyFilter($event)"><button (click)="applyFilter($event)">Search</button>
              </div>
              <div class="newReturn_selectunselect">
                <div class="newReturn_selectoption" id="newReturn_selectall" (click)="selectAll()">Select All</div><div class="newReturn_selectdivision"></div><div class="newReturn_selectoption" id="newReturn_clearall" (click)="clearAll()">Clear Selections</div>		
              </div>
            
            </div>
           
            <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <!-- Checkbox Column -->
              <ng-container matColumnDef="checked">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="toggleAllRows($event)"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element"  >
                    <span *ngIf="element.checked === 'false'" class="return_unavbl">Return Unavailable</span>
                    <span *ngIf="element.checked === 'true'"></span>
                    <span *ngIf="element.checked === 'myReturn'" class="return_link" (click)="navigateToReturns($event, element)">See My Return</span>
                </td>
                
              </ng-container>
            
              <!-- Location Name Column -->
              <ng-container matColumnDef="locname">
        
                <td mat-cell *matCellDef="let element"> {{element.locname}} </td>
              </ng-container>
            
              <!-- Address Column -->
              <ng-container matColumnDef="address">
        
                <td mat-cell *matCellDef="let element"> {{element.address}} </td>
              </ng-container>
            
              <!-- State Column -->
              <ng-container matColumnDef="state">
        
                <td mat-cell *matCellDef="let element"> {{element.state}} </td>
              </ng-container>
            
              
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)" [ngClass]="{
                'non_selectable': row.checked === 'false',
                'selected-row': selection.isSelected(row),
                'non_selectable_return': row.checked === 'myReturn'
              }"></tr>
            </table>
          </div>
            <div *ngIf="dataSource.filteredData.length === 0" class="norecord">
              No matching records found
            </div>
        
          </div>
         
          
          <div class="newReturn_startorderbtnsection">
                        
            <a class="newReturn_exitBtn returnPopupshow" style="text-decoration: underline !important;" (click)="openExistpopup()">Exit</a>
            <button type="submit" class="newReturn_startorderbtn" id="toproductpage" [disabled]="selectedCount === 0"(click)="submitSelectedRows()" >Review Locations</button>
          
          </div>
        
           
        
        </div>
    </section>

        <section class="col-xs-12" *ngIf="noReturnsetails">
			
				<div class="Reviewbox">
					<div class="noreturnbackbox">
						<div class="NoReturnback">
							<div class="global_blackarrow"></div>
							
								Back to Location Selection
							
						</div>
						
							<div class="noReturn_selectcount" style="display: block;">Verify: <span class="newReturn_loccount">{{ selectedCount }}</span>&nbsp;<span>location(s)</span></div>
						
					</div>
					
					<div class="greyline"></div>
					<div class="Reviewsubtext">Review Locations for No Returns</div>
					
					<div class="greyline"></div>
					<div class="Reviewtablebox">
					
						<table class="selectedlocdetails">
                            <tr class="reviewlocation" *ngFor="let row of selectedRows">
                            <td>{{row.locname}}</td>
                            <td>{{row.address}}</td>
                            <td>{{row.state}}</td>
                            <td class="selectedtick"></td>
                            
                        </tr>
                           
                        </table>

					</div>
				</div>
					<!-- //bottom submit btn -->
					<div class="ReviewconfirmSection">
						
				
					<div class="completetext">Complete No Return Submission</div>
				
				<div class="row">
				
						<div class="Returnsubmitbox col-md-7 leftside">
							<label class="Retunrcheck-button">
								<input type="checkbox" class="Return_check" id="NoReturncheck" name="NoReturncheck" [(ngModel)]="checkboxChecked" (change)="onCheckboxChange()">
								<span class="Retuncheck-button__control"></span>
								  <label class="Returnlabeltext">
								  
									I confirm that there are no returns for the location(s) above and I will not be able to make any further returns claims.
								  
							      </label>
                                 
							 </label>
                             <label id="imagecheck0-error" class="error help-block" for="imagecheck0" *ngIf="checkboxErrorMessage"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{ checkboxErrorMessage }}</label>
							
							 <div class="clearboth"></div>
						  </div>
					
					<div class="NoReturnbtnsection col-md-5 no-padding-right">
					
						<a class="NoReturn_exit returnPopupshow" (click)="openExistpopup()">Exit</a> <button type="button" class="confirmbtn" id="ConfirmReturn" (click)="validateNoreturnForm()" >Confirm no returns</button>
					
					</div>
				</div>

		
					</div>
				<div>

</div>
		</section>

        <section class="col-xs-12 col-md-12" id="NoReturnThank" *ngIf="noReturnThanks"> 
					
            <div class="Returnthank_box">
                <div class="thankReturn_head">Thank You for Submitting Your Return Request</div>
                <div class="thankreturn_text">You will receive an email confirmation shortly.</div>
                
                <a class="nopopshow returnPopupshow" (click)="goToreturn()">
                <div class="Returnbutton">View Returns</div>
                </a>
            </div>
        
        </section>
    </div>
        
</div>