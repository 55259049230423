<div *ngIf="isVisible">
    
    <div class="loading" *ngIf="showloader" >
      <div class="loading_txt">
       <div class="load_txt">Loading</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
       </div>
    </div>

    
        <div class="col-xs-12 UkCreateReturn" id="newReturnProduct"  >
         <div *ngIf="addtnlreturnSection">
            <section class="col-md-12 no-padding newReturnText" >
                <div class="col-md-6 newReturns_subheader">
                    Additional Return
                </div>
            </section>
            <div class="productbox" *ngIf="additionalReturnData">
            
           
            <!-- we can repeat from here -->
       
            
                  
                  <div class="specificlocation" id="AdditioanlReturnProduct">
                    <div >
                    <div class="newReturn_productheader">Products to Return</div>
                    
                   
                    <div class="newReturn_location">{{ additionalReturnData.returnsOrderData.companyName }}</div>
                   
                    <div class="row productrow" >
						<div class="col-xs-3 no-padding locationdetail">
							<div class="col-xs-12 no-padding newReturn_locationdetails">
                                {{ additionalReturnData.returnsOrderData.addressLine1 }}<br>
                                {{ additionalReturnData.returnsOrderData.town }}<br>
                                {{ additionalReturnData.returnsOrderData.postalCode }}</div>
						</div>

						<div *ngIf="hasProduct('aQIV')" class="col-xs-5 no-padding Returnaqivbox addtnlMaxCheck" >
							<div class="productname">aQIV</div>
							<div class="year">(65 years and above)</div>
							<div class="enterdosestext">Enter as doses not boxes</div>
							<table class="prodcutbatch aqivreturn">

								<tbody><tr class="borderB10">
									<th>Batch number</th>
									<th>Doses Previously Returned</th>
									<th>Additional Doses to Return</th>
									<th>Total Doses Returned</th>
								</tr>
								
								<ng-container *ngFor="let product of additionalReturnData.returnsOrderData.locations[0].returnsProduct">
								<tr *ngIf="product.productName === 'aQIV'" >
									<td>{{ product.batchNumber }}</td>
									<td class="previous">{{ product.selectedQuantity.toString() }}</td>

									<td class="aqivReturninput returnparent has-feedback has-success">
                                        <input type="text" autocomplete="no" class="addbatch form-control returninput" [(ngModel)]="product.additionalQuantity" (keypress)="restrictInput($event)" (ngModelChange)="updateTotals()"  aria-invalid="false"></td> 
										
									<td class="bacthtotal">{{ calculateTotalDosesReturned(product).toString() || '' }}</td>
								</tr>
                            </ng-container>
								
								
								<!--  loop end's here-->
								<tr class="rowgap">
								</tr>
							</tbody></table>
							
							<table class="Returntotalbox">
								<tbody><tr class="totalrow">
									<td>Total aQIV Doses</td>
									<td class="prevtotal">{{ calculateTotalSelectedDoses('aQIV') }}</td>
									<td class="returnparent has-feedback has-success"><input type="text" class="addtotal form-control blanktotalvalue" [value]="calculateTotalAdditionalDoses('aQIV') || ''" readonly aria-invalid="false"></td>
									<td class="completetotal">{{ calculateTotalDoses('aQIV') || '' }}</td>
								</tr>
                                <tr><td colspan="4"></td></tr>

								
                               
                                
							</tbody></table>
                           
							<div class="clearboth"></div>


						</div>
						<div class="col-xs-5 no-padding Returnqivcbox addtnlMaxCheck" *ngIf="hasProduct('QIVc')">
							<div class="productname">QIVc</div>
							<div class="year">(6 months and above)</div>
							<div class="enterdosestext">Enter as doses not boxes</div>
							<table class="prodcutbatch qivcreturn">

								<tbody><tr class="borderB10">
									<th>Batch number</th>
									<th>Doses Previously Returned</th>
									<th>Additional Doses to Return</th>
									<th>Total Doses Returned</th>
								</tr>
								
								 
								
                                <ng-container *ngFor="let product of additionalReturnData.returnsOrderData.locations[0].returnsProduct">
								<tr *ngIf="product.productName === 'QIVc'">
									<td>{{ product.batchNumber }}</td>
									<td class="previous">{{ product.selectedQuantity.toString() }}</td>
									<td class="QIVcReturninput returnparent has-feedback has-success">
                                        <input type="text" autocomplete="no" class="addbatch form-control returninput" [(ngModel)]="product.additionalQuantity" (ngModelChange)="updateTotals()" (keypress)="restrictInput($event)" aria-invalid="false">
										
									
									</td>
									<td class="bacthtotal">{{ calculateTotalDosesReturned(product).toString() || '' }}</td>
								</tr>
                                </ng-container>
								<!-- end's here-->
								<tr class="rowgap"></tr>


							</tbody></table>
							<table class="Returntotalbox">
								<tbody><tr class="totalrow">
									<td>Total QIVc Doses</td>
									<td class="prevtotal">{{ calculateTotalSelectedDoses('QIVc') }}</td>
									<td class="returnparent has-feedback has-success"><input type="text" class="addtotal form-control" [value]="calculateTotalAdditionalDoses('QIVc')|| ''" readonly aria-invalid="false"> 
										</td>
									<td class="completetotal">{{ calculateTotalDoses('QIVc')|| '' }}</td>
								</tr>
                                <tr><td colspan="4"></td></tr>
                                
								
							</tbody></table>

							<div class="clearboth"></div>

						</div>
					</div>
                    <div class="row productrow">
                        <div class="col-xs-3 no-padding"></div>
                        <div class="col-xs-5 no-padding Returnaqivbox addtnlMaxCheck" *ngIf="aqivErrorMessage"><span class="batcherror"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{ aqivErrorMessage }}</span></div>
                        <div class="col-xs-5 no-padding Returnqivcbox addtnlMaxCheck" *ngIf="qivcErrorMessage"><span class="batcherror"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{ qivcErrorMessage }}</span></div>
                    </div>
                    
                    
                    <div class="row blankparent">
						<div class=" col-xs-10 no-padding blankerror"></div>
					</div>
                    
                    
      
                    <div class="newReturn_location">Upload Documentation</div>
                    <div class="Returnimagebox marginB25">
                      <div class="leftside">
                        <div class="imageuploadsub">Please upload image(s) of your unused vaccines</div>
                        <button class="uploadbtn mappingpop-0" id="" type="button" (click)="openFileUploadModal(additionalReturnData.returnsOrderData.locationId)">Upload &amp; View Images</button>
                        <span class="uploadedquat"><span class="uploadednum" >{{ getUploadedImageCount(additionalReturnData.returnsOrderData.locationId) }}</span>
                          Image(s) uploaded</span>
                        <div class="imageformattext">The maximum image size to be uploaded is 5mb. Please ensure that the image format is JPEG, or PNG</div>
                        
                      
                        <div class="Returncheckbox">
                          <label class="Retunrcheck-button"> <input type="checkbox" class="Return_check dynimagecheck"  [(ngModel)]="imgcheckboxChecked" (change)="imgonCheckboxChange()" [disabled]="imgcheckboxDisabled"> <span class="Retuncheck-button__control"></span> <label class="Returnlabeltext">I am choosing not to upload an image and I consent to CSL Seqirus contacting me for a manual inspection before approving the return. </label>
                          </label>
                          <label id="imagecheck0-error" class="error help-block" *ngIf="imgcheckboxErrorMessage">
                           <i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{imgcheckboxErrorMessage}}
                          </label>
      
                          <div class="clearboth"></div>
                        </div>
      
                      </div>
      
                      <div class="rightside">
                        <div class="newReturn_location">Example images:</div>
                        <div class="Returnimageexample">
                          <div class="leftsideexm">
                            <img src="assets/images/ReturnImageExample3.png" class="img-fluid">
                          </div>
                          <div class="rightsideexm">
                            <img src="assets/images/ReturnImageExample2.jpg" class="img-fluid">
                          </div>
                        </div>
                      </div>
                    </div>
    
    
    
    
                  </div>
                  </div>
                
      
            </div>
    
        
            <div class="btmsec">
            <div class="completetext">Complete Return Request</div>
            <div class="row">
              <div class="Returnsubmitbox col-md-7 leftside">
                <label class="Retunrcheck-button"> <input type="checkbox" class="Return_check" id="finalcheck" name="finalcheck" [(ngModel)]="checkboxChecked" (change)="onCheckboxChange()"> <span class="Retuncheck-button__control"></span> <label class="Returnlabeltext">I agree to destroy the unused vaccines in accordance with the Disposal of Unwanted Medicines guidelines and/or other applicable requirements, when instructed by CSL Seqirus. </label>
                </label>
                <label id="finalcheck-error" class="error help-block" for="finalcheck" *ngIf="checkboxErrorMessage">
                  <i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{ checkboxErrorMessage }}
                </label>
                <div class="clearboth"></div>
              </div>
      
              <div class="submitbtnsection col-md-5 no-padding-right">
                <a class="prodReturn_exitBtn returnPopupshow" (click)="openExistpopup()">Exit</a>
                <button class="productsumbitbtn" id="productSubmit" type="button" (click)="processAndSendPayloads()">Submit Return Request</button>
              </div>
            </div>
            </div>
            </div>
            <section class="UkCreateReturn col-xs-12 col-md-12 no-padding" id="NoReturnThank" style="display:block;" *ngIf="adlReturnThanks"> 
					
                <div class="Returnthank_box">
                    <div class="thankReturn_head">Thank You for Submitting Your Return Request</div>
                    <div class="thankreturn_text">You will receive an email confirmation shortly.</div>
                    
                    <a class="nopopshow returnPopupshow" (click)="goToreturn()">
                    <div class="Returnbutton">View Returns</div>
                    </a>
                </div>
            
            </section>
        <div>
    
            
    
    
</div>

