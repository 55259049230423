import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { BaseSiteService, CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { catchError, combineLatest, concatMap, forkJoin, from, map, mergeMap, Observable, of, switchMap, toArray } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { HelpnfaqsearchPipe } from '../../cms-common/Pipes/helpnfaqsearch.pipe';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-help-faq-second',
  templateUrl: './help-faq-second.component.html',
  styleUrl: './help-faq-second.component.scss'
})
export class HelpFaqSecondComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>, public accessService: ContentService,private cmsService:CmsService,public cd: ChangeDetectorRef,private userInfoService:UserInfoService,  protected config: OccConfig,private baseSiteService:BaseSiteService,private renderer:Renderer2,private activatedRoute: ActivatedRoute) { }
  secondnode: Observable<any> = this.component.data$;
  activeIndexes: Set<string> = new Set();
  activeAccordion: number | null = null;
  mergedObject1: any = [];
  mergedObject2: any = [];
  filteredData: any[] = [];
 seallbtn:boolean = false;
 searchvalue :string=''; 
 searchresults:boolean = false;
 shownoresult:boolean =false;
 userLoggedIn: boolean=false;
 userInfo: any;
 currentSite: string | undefined;
 isModalOpen = false;
 popupdata:any;
 isLoading = false;
 private activeDiv: HTMLElement | null = null;
//  filterValues = {
//   search: '',
//   filterbyproduct:[],
//   filterbystatus:[],
// };

  ngOnInit() {
    this.isLoading = true;
    this.secondnode.subscribe(res => {
      this.loadFaqData(res);
    });
    
    //for looged, loggedout
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      // console.log('login check',res);
      this.userLoggedIn = res;
      if(this.userLoggedIn){
        this.userInfoService.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
          }
        });
      }
      if(this.userInfo?.webEnabled == false){
        this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
       }
      this.cd.detectChanges();
    })
    //to get the current basesite 
    this.baseSiteService.getActive().subscribe((siteId) => {
      this.currentSite = siteId;
      console.log(this.currentSite);
    });
    this.cmsService.getComponentData('contactusPagePopup').subscribe(res=>{
      this.popupdata=res;
  });

  }
sublist:any[] = [];
  loadFaqData(res: any) {
    // const tilesList = res.FaqAllResources.split(" ");
    // this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
    //  this.mergedObject1 = res.component;
    //  console.log('mergedObject1:',this.mergedObject1);
    //  console.log('faqTopics:',res.component.faqTopics);
    //  for(let i=0; i<=this.mergedObject1.length; i++){
    //   const subtilesList = this.mergedObject1[i]?.faqTopics?.split(" ");
    //   this.accessService.opeffeaturedResources(subtilesList).subscribe((res:any)=>{
    //     res.component.forEach ((item: any)=>{
    //       this.mergedObject2.push(item);
    //       console.log('mergedObject2:', this.mergedObject2);
    //       console.log(this.mergedObject2.length);
    //     })
    //   })
    //  }
    // this.sublist = [];
    // for(let i=0; i<this.mergedObject1?.length; i++){
    //   const subtilesList = this.mergedObject1[i]?.faqTopics?.split(" ");
    //     //  subtilesList.forEach((el: any) => {
    //       // for(let j=0; j<subtilesList?.length; j++){
    //       // this.sublist.push(subtilesList[j]);
    //     //  }
    //     if(subtilesList.length<=9){
    //      this.accessService.opeffeaturedResources(subtilesList).subscribe((res:any)=>{
    //       res.component.forEach ((item: any)=>{
    //         this.mergedObject2.push(item);

    //         console.log(this.mergedObject2.length);
    //       })
    //       console.log('mergedObject2:', this.mergedObject2);
    //     })
    //   }
    //   // else{
    //   //   const sublistcount = subtilesList.length;
    //   //   const sublistloop = sublistcount%9;
    //   //   let sliceIndex = 0;
    //   //   let lastIndex = 9;
    //   //   for(let j=0; j<sublistloop; j++){
    //   //    const spliceList = subtilesList.splice(sliceIndex, lastIndex);
    //   //    this.accessService.opeffeaturedResources(spliceList).subscribe((res:any)=>{
    //   //     res.component.forEach ((item: any)=>{
    //   //       this.mergedObject2.push(item);
    //   //       console.log('mergedObject2:', this.mergedObject2);
    //   //       console.log(this.mergedObject2.length);
    //   //     })
    //   //   })
    //   //   sliceIndex = lastIndex;
    //   //   lastIndex = lastIndex + 9;
    //   //   }
        
    //   // }
    // }

    // console.log(this.sublist);
    //  this.cd.detectChanges;
    // });
    const tilesList = res.FaqAllResources.split(" ");
    const tileObservables = tilesList.map((el: any) => this.accessService.helpandaq(el));
  
    combineLatest(tileObservables).pipe(
      switchMap((results:any) => {
        this.mergedObject1 = results;
        const subtilesObservables = results.flatMap((result:any) => {
          const subtilesList = result.faqTopics.split(" ");
          return subtilesList.map((el: any) => this.accessService.helpandaq(el).pipe(
            map(result2 => ({ ...result2, parentId: result.uid }))
          ));
        });
        return combineLatest(subtilesObservables);
      })
    ).subscribe(results2 => {
      this.mergedObject2 = results2;
      this.isLoading = false;
      this.cd.detectChanges();
      console.log('The result of tiles is', this.mergedObject1);
      console.log('The result of sub tiles is', this.mergedObject2);
    });
  }
  //when user searches something the following method will be useful for showing relavant searches
  applyFilter(mysearchvalue:any) { 
    console.log(this.mergedObject2)
    if(mysearchvalue.length>0){
    let final =mysearchvalue.trim().toLowerCase(); 
    // console.log(mysearchvalue);
    // this.searchvalue = final;
    // console.log('my final is',final);
    const filteredData1 = new HelpnfaqsearchPipe().transform(this.mergedObject2, final);
    if(this.filteredData){
      this.searchresults = true;
    }
    //when the search doesn't match or the user gives empty the no result will be opened
    if(final != " " && filteredData1.length<1){
  this.shownoresult=true;
    }
    else{
      this.shownoresult=false;
    }
    //iam assigning the filteredData1 to one variable so that i can use in html
    this.filteredData = filteredData1;
    console.log(filteredData1);
    console.log(filteredData1);
    return filteredData1
    // this.mergedObject2 = filteredData1;
  }
  else{
    this.shownoresult=false;
    this.searchresults = false;
  }
 }
//the following is to show all the accordions initally
  toggleDropdown(i: number, j: number) {
    const key = `${i}-${j}`;
    if (this.activeIndexes.has(key)) {
      this.activeIndexes.delete(key);
    } else {
      this.activeIndexes.add(key);
    }
  } isActive(i: number, j: number): boolean 
  {
    const key = `${i}-${j}`;
    return this.activeIndexes.has(key);
  }
  //to show only the particualr accordion clicked by user
  showAccordion(index: number, myid: string) {
    this.activeAccordion = index; // Always show only the selected section
    this.seallbtn = true; // Show the "See All Topics" button
    // const div = event.target as HTMLElement;
    const div= <HTMLElement> document.getElementById(myid);
    // this.renderer.addClass(div, 'btn-active-class');
    if (this.activeDiv) {
      this.renderer.removeClass(this.activeDiv, 'btn-active-class');
    }
    if (div){
    this.renderer.addClass(div, 'btn-active-class');
    }
    this.activeDiv = div;
    this.cd.detectChanges();
  }

  //when clicking on SEE ALL TOPICS button to make every accordion appear again
  sealltopics() {
    this.activeAccordion = null; // Reset to show all accordions
    this.seallbtn = false; // Hide the "See All Topics" button
    this.renderer.removeClass(this.activeDiv, 'btn-active-class');
    this.cd.detectChanges();
  }
  //search functions
  toggleSearchAccordion(index: any) {     
    if (this.activeIndexes.has(index)) 
      {       
        this.activeIndexes.delete(index);     
      } else {       
        this.activeIndexes.add(index);     
      }   
    }   
    issearchActive(index: any){    
       return this.activeIndexes.has(index);  
       }
  //i wrote this for to show the results in accordions 
  toggleAccordion(item: any) {
    item.isOpen = !item.isOpen;
  }
  //for popups
  openModal() {
    this.isModalOpen = true;
    document.body.style.overflowY = 'auto';
    document.getElementById('commonleaving_abandonedPop')!.style.display = 'block';
  }
  closeModal() {
    this.isModalOpen = false;
    document.body.style.overflowY = 'auto';
    document.getElementById('commonleaving_abandonedPop')!.style.display = 'none';
  }
  ngAfterViewChecked(){
      setTimeout(()=>{
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(decodeURIComponent(params['returns']));
      if(params['returns']){
        this.showAccordion(2, 'helpbtn_3')
      }
      })
  },1000);
  }
}