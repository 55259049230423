import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RealWorldCompComponent } from './real-world-comp/real-world-comp.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { DownloadReferenceComponent } from './download-reference/download-reference.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    RealWorldCompComponent,
    DownloadReferenceComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents:{
        FlucelvaxRealWorldEvidence:{
          component:RealWorldCompComponent
        },
        SeqirusReferenceParagraphComponent:{
          component:DownloadReferenceComponent
        }
      }
    } as CmsConfig)
  ]
})
export class RweFlucelvaxModule { }
