import { ChangeDetectorRef, Component } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Router } from '@angular/router';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-confirm-order-thank-you-page',
  templateUrl: './confirm-order-thank-you-page.component.html',
  styleUrl: './confirm-order-thank-you-page.component.scss'
})
export class ConfirmOrderThankYouPageComponent {
  enablePage: any;
  seasonValue: any;
  thankYouContent: Observable<any> = this.PageParagraphComponent.data$;
  data: any;
  constructor(private userInfoService: UserInfoService, private cdr: ChangeDetectorRef,private router: Router,private cmsService: CmsService,public PageParagraphComponent : CmsComponentData<any>,private contentservice:ContentService) { }
  ngOnInit() {
    this.userInfoService.getstartorderseason().subscribe((seasonValue: any) => {
      this.seasonValue = seasonValue;
      this.userInfoService.getEnablePage().subscribe((data: any) => {
        this.enablePage = data;
        this.cdr.detectChanges();
      })
    })
    this.thankYouContent.subscribe((res: any) => {
      this.data=res;
    });
  }
  handleOnOrdersClick() {
    const obj = {
      showConfirmThankYouPage : false
    }
    this.userInfoService.setEnablePage(obj);
    this.router.navigate(
      ['/orders/my-orders'],
      { queryParams: { season: this.seasonValue} }
   );
  }
} 
