
<div class="panel-group navUserSec d-none d-lg-block d-xl-block"  *ngIf="isUserLoggedIn">
    <ng-container *ngTemplateOutlet="leftNavigationTemplate"></ng-container>
</div>
<div class="d-lg-none d-xl-none" *ngIf="isUserLoggedIn">
    <div *ngIf="isOpen">
        <ng-container *ngTemplateOutlet="leftNavigationTemplateMobile"></ng-container>
    </div>
</div>
<app-timeout-modal *ngIf="isUserLoggedIn && userInfo?.webEnabled && currentSite === 'seqirusb2b-uk' || 'seqirusb2b-flu360'" ></app-timeout-modal>
<ng-template #leftNavigationTemplate>
    <div *ngIf="bannerNode | async as item" id="accordionsidebar">
        <div *ngFor="let parentNode of item.children" [id]="currentSite == 'seqirusb2b-uk' ? 'banner-containeruk':'banner-container'"
            class="pl-0"> 
            <ng-container *ngFor="let node of parentNode.children; let i=index">
                <div *ngIf="node.title != 'Start Order' && node.title != 'Place an Order' && node.title != 'Returns'" class="panelbox card">
                    <div *ngIf="!node.children">
                        <h4 class="dashboard-title accordion-toggle panel-heading">
                            <a class="panel-title" [routerLink]="node.url" [ngClass]="{'active': isActive(node.url)}"  [innerHTML]="node.title"></a>
                        </h4>
                    </div>
                    <ng-container *ngIf="node.children">
              
                       <ng-container *ngIf="rbaflag?.RBAPermissions==true;else normalflow" >
                       

                            <div *ngIf="rbaflag?.hasAllLocationNotNone==true && node.title == 'Orders'" class="panel-heading showglyphicon accordion-toggle collapsed " data-toggle="collapse"
                            data-parent="#accordionsidebar" [attr.data-target]="'#collapse'+i" (click)="isExpanded()" [ngClass]="{'bbor':currentSite==='seqirusb2b-uk' && exp==true}">
                        <h4 class="panel-title">{{node.title}}</h4>
                       <div class="panel-icon"><i class="glyphicon" [ngClass]="glyphiconIcon" ></i></div>
                    </div>

                    <div *ngIf="(rbaflag?.hasAllLocationNotNone==true || rbaflag?.hasManageFin==true)  && node.title == 'Financials & Returns'" class="panel-heading showglyphicon accordion-toggle collapsed " data-toggle="collapse"
                    data-parent="#accordionsidebar" [attr.data-target]="'#collapse'+i" (click)="isExpanded()" [ngClass]="{'bbor':currentSite==='seqirusb2b-uk' && exp==true}">
                <h4 class="panel-title">{{node.title}}</h4>
               <div class="panel-icon"><i class="glyphicon" [ngClass]="glyphiconIcon" ></i></div>
            </div>
            <div *ngIf="node.title != 'Financials & Returns' && node.title!= 'Orders'" class="panel-heading showglyphicon accordion-toggle collapsed " data-toggle="collapse"
                    data-parent="#accordionsidebar" [attr.data-target]="'#collapse'+i" (click)="isExpanded()" [ngClass]="{'bbor':currentSite==='seqirusb2b-uk' && exp==true}">
                <h4 class="panel-title">{{node.title}}</h4>
               <div class="panel-icon"><i class="glyphicon" [ngClass]="glyphiconIcon" ></i></div>
            </div>
            

           

                       </ng-container>
                       <ng-template #normalflow>
                        <div class="panel-heading showglyphicon accordion-toggle collapsed " data-toggle="collapse" (click)="handleIconToggle(node)"
                        data-parent="#accordionsidebar" [attr.data-target]="'#collapse'+i" (click)="isExpanded()" [ngClass]="{'bbor':currentSite==='seqirusb2b-uk' && exp==true}">
                    <h4 class="panel-title">{{node.title}}</h4>
                   <div class="panel-icon"><i class="glyphicon" [ngClass]="glyphiconIcon" ></i></div>
                </div>
                       </ng-template>
                          
                        
                         
                   
                        <ng-container *ngIf="rbaflag?.RBAPermissions==true;else normalchildflow">
                            <ng-container *ngIf="currentSite=='seqirusb2b-uk'">
                        <div [attr.id]="'collapse'+i" class="panel-collapse collapse">
                            <div class="panel-body" >
                                <ul class="desktop_sub_menu" *ngFor="let innerNode of node.children;">

                                    <li *ngIf="node.title != 'Prescribing Information' && innerNode.title != 'Returns' && innerNode.title !='Credits' && innerNode.title !='All Invoices' && innerNode.title !='Organisation Locations'" ><a [routerLink]="innerNode.url" [innerHTML]="innerNode.title" [ngClass]="{'active': isActive(innerNode.url)}"></a></li>
                                    <li *ngIf="node.title == 'Prescribing Information'"><a [href]="geturl(innerNode?.url)" target="_blank" [innerHTML]="innerNode.title"></a></li>
                                    <li *ngIf="innerNode.title == 'Organisation Locations' && rbaflag?.currentUserAccountPermission !='NONE'"><a [href]="geturl(innerNode)" target="_blank" [innerHTML]="innerNode.title"></a></li>

                                    <li *ngIf="innerNode.title == 'Credits'  && rbaflag?.hasManageFin == true"><a [href]="geturl(innerNode?.url)" target="_blank" [innerHTML]="innerNode.title"></a></li>
                                    <li *ngIf="innerNode.title == 'All Invoices'  && rbaflag?.hasManageFin == true"><a [href]="geturl(innerNode?.url)" target="_blank" [innerHTML]="innerNode.title"></a></li>

                                    
                                    <div *ngIf="innerNode.title === 'Returns' && rbaflag?.hasAllLocationNotNone == true">
                                        <li *ngIf="innerNode.title === 'Returns'"><a [routerLink]="innerNode.url" [queryParams]="{returns: 'true'}"  id="return_links">{{innerNode.title}}</a></li>
                                    </div>
                                </ul>
                            </div>
                        
                        </div>
                       
                     

                    </ng-container>
                    </ng-container>
                    <ng-template #normalchildflow>
                        <div [attr.id]="'collapse'+i" class="panel-collapse collapse"  >
                            <div class="panel-body">
                                <ul class="desktop_sub_menu" *ngFor="let innerNode of node.children;">
                                    <li *ngIf="node.title != 'Prescribing Information' && innerNode.title != 'Returns'"><a [routerLink]="innerNode.url" [innerHTML]="innerNode.title" [ngClass]="{'active': isActive(innerNode.url)}"></a></li>
                                    <li *ngIf="node.title == 'Prescribing Information'"><a [href]="geturl(innerNode?.url)" target="_blank" [innerHTML]="innerNode.title"></a></li>
                                    <div *ngIf="innerNode.title === 'Returns'">
                                        <li *ngIf="innerNode.title === 'Returns'"><a [routerLink]="innerNode.url" [queryParams]="{returns: 'true'}"  id="return_links">{{innerNode.title}}</a></li>
                                    </div>
                                </ul>
                            </div>
                        
                        </div>

                    </ng-template>
                    </ng-container>
                </div>
                <div *ngIf="node.title === 'Start Order'">
                <h4 class="startOrder-container margin-T30">
                    <a class="create-account startorder" [routerLink]="node.url" [innerHtml]="node.title"></a>
                </h4>
            </div>
            
            <ng-container *ngIf="rbaflag?.RBAPermissions==true && currentSite=='seqirusb2b-uk'">
                <ng-container *ngIf="rbaflag?.showDashboardOrderUK==true "> 
                    <div class="dashboard-place margin-T30"  *ngIf="node.title === 'Place an Order'">
                
                        <a class="p_order placeorder" [routerLink]="node.url"> <span class="" [innerHtml]="node.title"></span>
                        </a>
                    </div>
                </ng-container>

            </ng-container>  
            <ng-container  *ngIf="rbaflag?.RBAPermissions==false && currentSite=='seqirusb2b-uk'">
                <div class="dashboard-place margin-T30"  *ngIf="node.title === 'Place an Order'">
                
                    <a class="p_order placeorder" [routerLink]="node.url"> <span class="" [innerHtml]="node.title"></span>
                    </a>
                </div>
            </ng-container>
         
           
            </ng-container>
        </div>
    </div>
</ng-template>

    <ng-template #leftNavigationTemplateMobile>
        <div id="leftNavigationTemplateMobile">
            <div *ngIf="bannerNode | async as item" id="accordionsidebar">
                <div *ngFor="let parentNode of item.children" id="banner-container"
                    class="mob-menu pl-0"> 
                    <ng-container *ngFor="let node of parentNode.children; let i=index">
                        <div *ngIf="node.title != 'Start Order'" class="panelbox card">
                            <div *ngIf="!node.children; else content">
                                <h4 [ngClass]="node.title==='My Dashboard'?'dashboardUksection':''" class="dashboard-title accordion-toggle panel-heading mobile_view">
                                    <a [ngClass]="node.title==='Returns'?'panel-heading-mob':''" class="panel-title panel-title-mob" [routerLink]="node.url" [ngClass]="{'active': isActive(node.url)}" [innerHTML]="node.title"></a>
                                </h4>
                            </div>
                            <ng-template #content>
                                <div class="panel-heading mobile_view showglyphicon accordion-toggle collapsed " (click)="handleIconToggle(node)" data-toggle="collapse"
                                    data-parent="#accordionsidebar" [attr.data-target]="'#collapse'+i">
                                    <h4 class="panel-title panel-title-mob">{{node.title}}</h4>
                                    <i class="glyphicon"  [ngClass]="glyphiconIcon" ></i>
                                </div>
                                <div [attr.id]="'collapse'+i" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <ul class="desktop_sub_menu desktop_sub_menu-mob" *ngFor="let innerNode of node.children;">
                                        <li *ngIf="node.title != 'Prescribing Information'"><a [routerLink]="innerNode.url" [ngClass]="{'active': isActive(innerNode.url)}" [innerHTML]="innerNode.title"></a></li>
                                        <li *ngIf="node.title == 'Prescribing Information'"><a [href]="getImage(innerNode)"
                                            target="_blank" [innerHTML]="innerNode.title"></a>
                                          </li>
                                    </ul>
                                        <!--<ul class="desktop_sub_menu desktop_sub_menu-mob" *ngFor="let innerNode of node.children;">
                                            <li><a [routerLink]="innerNode.url" [innerHTML]="innerNode.title"></a></li>
                                        </ul>-->
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <ng-container  *ngIf="node.title === 'Start Order'" >
                        <div class="default-mob-menu-item">
                            <div class="col-xs-6" style="margin-top: 20px; margin-bottom: 20px;">
                                <div class="mobstartorderbox startOrder-container-mob" style="margin-top:20px; margin-bottom:20px">
                                    <a class="dashborad-create-account mobstartorderbtn" [routerLink]="node.url" [innerHtml]="node.title"></a>
                                </div>
        
                            </div>
                            <div class="col-xs-6 mob-login-icon">
                                <a class="log-in" href="/logout">Log out
                                    <span class="glyphicon glyphicon-log-in margin-T30"></span></a></div> 
                        </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>
