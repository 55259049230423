<div class="product_image-textCenter flucelvax_image-center" *ngIf="node1 | async as item">
    <div class="simple-banner banner__component--responsive">
        <img class="js-responsive-image1" alt="Adult man holding his young daughter" title="Adult man holding his young daughter" src="../../../app/../assets/images/Image_FLX_Landing_01_LI_Desktop.webp">
    </div>
    <div class="container">
        <div class="product_image-textcontainer">
            <div class="product_image-textHeader">{{item.headline1}}</div>
            <div class="product_image-text1" [innerHTML]="item.paragraphcontent"></div>
            <!-- <a href="{{item2.linkUrl}}" [innerHTML]="item2.linkName"></a> -->
            <a href="/products/flucelvax/clinical-data">See the clinical Data</a>
        </div>
    </div>  
</div>

<div class="fluad-firstcomponent">
    <div class="breadcrumbs--page-nav d-none d-md-block" *ngIf="!userLoggedIn">
        <div class="container">
            <p>
                <a href="/">Home</a><span> &gt; </span><a href="/products">Products</a><span> &gt; </span><strong>FLUAD</strong>
            </p>
        </div>
    </div>
    <div class="product_image-textCenter fluad_image-center" *ngIf="node1 | async as item">
        <div class="simple-banner banner__component--responsive">
            <img class="js-responsive-image d-none d-md-block" alt="A mature woman and man, side-by-side facing the same direction, gazing into the distance" title="A mature woman and man, side-by-side facing the same direction, gazing into the distance" [src]="getImage(item.media)">
            <img class="js-responsive-image d-md-none d-sm-block" alt="A mature woman and man, side-by-side facing the same direction, gazing into the distance" title="A mature woman and man, side-by-side facing the same direction, gazing into the distance" src="../../../assets/images/Homepage-Image1-Mobile-2x.webp">
        </div>
        <div class="product_image-textinside d-md-none d-sm-block" [innerHTML]="item.content"></div>
        <div class="container">
            <div class="product_image-textcontainer">
                <div class="product_image-textHeader">
                    <div class="product_image-textHeader1 d-none d-md-block" [innerHTML]="item.linkname"></div>
                    <div class="product_image-textHeader1 d-md-none d-sm-block">{{ item.headline1 }}</div>
                    <div class="product_image-subtext product_image-subtext1 d-md-none d-sm-block" [innerHTML]="item.largeparagraphcontent"></div> 
                    <div class="product_imagePlus">+</div>
                    <div class="product_image-textHeader2 d-md-block" [innerHTML]="item.linkurl"></div>
                    <div class="product_image-textHeader2  d-sm-block">{{ item.h2content }}</div>
                    <div class="product_image-subtext product_image-subtext2 d-md-none d-sm-block" [innerHTML]="item.paragraphcontent"></div> 
                </div>
                <div class="product_image-text1 d-none d-md-flex">
                    <div class="product_image-subtext1" [innerHTML]="item.largeparagraphcontent"></div> 
                    <div class="product_image-subtext3"></div>
                    <div class="product_image-subtext2" [innerHTML]="item.paragraphcontent"></div> 
                </div>  
            </div>
            <div class="product_box1-disclaimer">{{ item.h3content }}</div>
        </div>
    </div>
</div>
