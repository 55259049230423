import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-orders-cartbanner',
  templateUrl: './orders-cartbanner.component.html',
  styleUrl: './orders-cartbanner.component.scss'
})
export class OrdersCartbannerComponent {
  startcart: Observable<any> = this.PageParagraphComponent.data$;
  enablePage: any;
  seasonValue: any;
  constructor(public PageParagraphComponent: CmsComponentData<any>, private userInfoService: UserInfoService,private cdr: ChangeDetectorRef,) {

  }
  ngOnInit() {
    this.userInfoService.getstartorderseason().subscribe((seasonValue: any) => {
      this.seasonValue = seasonValue;
      this.userInfoService.getEnablePage().subscribe((data: any) => {
        this.enablePage = data;
        this.cdr.detectChanges();
      })
    })
  }
}
