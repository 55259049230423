

  <div class="col-xs-12 home_prodSectionUK" id="home_prodSection" *ngFor="let banner of banners">
        <div class="col-xs-12 col-md-12 home_prodCol" >

           <div class="col-xs-12 prod_content" >
               <div class="col-xs-12 home_prodimg" id="home_prodimg1" alt="aQIV Product Logo" style="background-image: url('{{getMediaUrl(banner.media)}}');"></div>
               <div class="col-xs-12 home_prodheader no-pad" id="">{{banner.h2content}}</div>
               <div class="col-xs-12 home_prodcontent no-pad" [innerHTML]="banner.content"></div>
           </div>
           <div class="col-xs-12" id="home_learnmorebtn">
               <a [href]="banner.urlLink">{{ banner.paragraphcontent }}</a>
           </div>
      
        </div>

</div>
