<section class="col-xs-12 col-md-12 invoices_midsection" *ngIf= "this.firstvalue != 'N/A'">
    <div *ngIf="isLoading" class="loading">
        <div class="loading_txt">
        <div class="load_txt">Loading</div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
<div class="col-xs-12 col-md-5 tablecontainer"> 
    <div class="form-group invoice_datasearch">
        <div class="col-xs-10 col-md-10 searchbox">
            <div class="input-group invoice_order">  
                <div class="input-group-addon"><i class="fa fa-search"></i></div>
                <input class="form-control" id="invoice_search" name="lookup"  type="text" (keyup)="inputSearch($event)">
            </div>
        </div>
        <div class="col-xs-1 col-md-1 filterbox" (click)="filteropen()">
            <img alt="Filter" src="assets/images/filter.png" width="25" alt="filter">
            <span *ngIf="count>0" class="select_counter">{{count}}</span>
            <div class="filterText">Filter</div>
        </div>
        <div class="col-xs-1 col-md-1 sortbox" (click)="sortopen()">
            <img src="assets/images/sort.png" width="25" alt="sort">
            <div class="filterText">Sort</div>
        </div>
    </div>
    <div class="invoice_filtersection">
        <div class="filteropen" (click)="onFilterClick($event)" [ngClass]="togglefilterView?'open':''">
            <div class="statussection">
                <div class="filterheader">BY STATUS</div>
                <div class="invoice_checkboxparent" *ngFor="let list of getstatus;let i=index">
                    <label class="checkbox-button"> <input type="checkbox" class="invoice_checkbox" name="status" value={{list}} (click)="incrementcountstatus($event,i);applyFilter()" > <span class="checkbox-button__control" ></span></label>
                    <label>{{list}}</label>
                </div>
            </div>
            <div class="invoice_reset" id="invoice_resetfilter" (click)="resetFilter()">RESET</div>
                                <div class="clearfix"></div>
        </div> 
        <div class="sortopen" (click)="onFilterClick($event)"[ngClass]="togglesortView?'open':''">
            <div class="invoicesection">
                <div class="filterheader">INVOICE NUMBER</div>                                   
                <div class="invoice_radioparent">
                    <label class="radio-button">
                        <input type="radio" class="invoice_radio" name="radiobtn"  value="1to9"(click)="sortTest('1to9')"/>
                        <span class="radio-button__control"></span>
                    </label>
                    <label>1 TO 9</label>
                </div>
                <div class="invoice_radioparent">
                    <label class="radio-button">
                        <input type="radio" class="invoice_radio" name="radiobtn"  value="9to1" (click)="sortTest('9to1')"/>
                        <span class="radio-button__control"></span>
                    </label>
                    <label>9 TO 1</label>
                </div>
            </div>                                
            <div class="locationsection">
                <div class="filterheader">LOCATION</div>
                <div class="invoice_radioparent">
                    <label class="radio-button">
                        <input type="radio" class="invoice_radio" name="radiobtn" (click)="sortTest('AtoZ')" value="AtoZ"/>
                        <span class="radio-button__control"></span>
                    </label>
                    <label>A TO Z</label>
                </div>
                <div class="invoice_radioparent">
                    <label class="radio-button">
                        <input type="radio" class="invoice_radio" name="radiobtn" (click)="sortTest('ZtoA')"  value="ZtoA"/>
                        <span class="radio-button__control"></span>
                    </label>
                    <label>Z TO A</label>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="datesection">
                <div class="filterheader">DATE ISSUED</div>
                <div class="invoice_radioparent">
                    <label class="radio-button">
                        <input type="radio" class="invoice_radio" id="invoice_radioIssued" name="radiobtn"  (click)="sortTest('MostRecent')" value="MostRecent"/>
                        <span class="radio-button__control"></span>
                    </label>
                    <label>MOST RECENT</label>
                </div>
                <div class="invoice_radioparent">
                    <label class="radio-button">
                        <input type="radio"  class="invoice_radio" name="radiobtn"  (click)="sortTest('LeastRecent')" value="LeastRecent"/>
                        <span class="radio-button__control"></span>
                    </label>
                    <label>LEAST RECENT</label>
                </div>
            </div>
            <div class="invoice_reset invoice_resetUK" (click)="resetSort()" id="invoice_resetsortUK">RESET</div>
            <div class="clearfix"></div>
        </div>                           
        <div class="clearfix"></div>
    </div>
    <div id="invoicefilterTable_wrapper" class="dataTables_wrapper no-footer">
    <table id="invoicefilterTable" mat-table [dataSource]="dataSource" matSort class="">
        <ng-container matColumnDef="allinvoices" >
            <th class="odertableheader" mat-header-cell *matHeaderCellDef> order Data </th>
                 <td mat-cell *matCellDef="let element" (click)="openrightsection(element.invoiceNumber,element.status)"> 
                    <div class="invoice_firstrow">
                        <div class="invoice_no"><div class="invoice_date"><span>INVOICE DATE: </span>{{element.invoiceDate}}</div><span>Invoice </span><span>#{{element.invoiceNumber}}</span></div>
                        <div class="invoice_status" [ngClass]="element.status?element.status:''">{{element.status}}</div>
                    </div>

                    <div *ngIf="element.aQIVdoses" class="invoice_unit"><span>{{element.aqivProductLabel}} </span><span class="addComma">{{element.aQIVdoses}}</span></div>
			        <div *ngIf="element.qivcdoses" class="invoice_unit"><span>{{element.qivcProductLabel}}  </span><span class="addComma">{{element.qivcdoses}}</span></div>
			        <div *ngIf="element.fluaddoses" class="invoice_unit"><span>{{element.fluadProductLabel}}  </span><span class="addComma">{{element.fluaddoses}}</span></div>
			        <div *ngIf="element.flucelvaxdoses" class="invoice_unit"><span>{{element.flucelvaxProductLabel}}  </span><span class="addComma">{{element.flucelvaxdoses}}</span></div>
			        <div *ngIf="element.aTIVdoses" class="invoice_unit"><span>{{element.ativProductLabel}}  </span><span class="addComma">{{element.aTIVdoses}}</span></div>

                    <div class="invoice_location"><span>Delivery Location: </span> <span>{{element.location}}</span></div>
                    <!-- <input type="hidden" class="searchorder" value="' + full['salesOrderNumber'] + '"><div class = "hidden rbaInvoicePermissions">' + full['rbaPermission'] + '</div> -->
                </td>
                     
            
                 </ng-container>
                 
                     <!-- Header and Row Declarations -->
                     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                     <tr mat-row id="{{row.invoiceNumber}}" *matRowDef="let row; columns: displayedColumns;"></tr>
                     <tr class="mat-row" *matNoDataRow>
                       <td class="mat-cell no-matching">No matching records found</td>
                     </tr>
    </table>
    <div class="custom-paginator">
        <button class="pagination-buttonOrg" (click)="goToFirstPage();scrollToTop()" [disabled]="isFirstPage()" id="first-page">
          <img alt="Navigate to previous page" src="assets/images/pagination-double-prev-arrow-icon.svg" class="locations_arrows">
        </button>
        <button class="pagination-buttonOrg" (click)="previousPage();scrollToTop()" [disabled]="isFirstPage()" id="previous-page">
          <img alt="Navigate to previous page" src="assets/images/pagination-prev-btn.svg" class="locations_arrows">
        </button>
        <button [hidden]="!isTableHasData" class="pagination-buttonOrg" *ngFor="let pge of getPages()" [class.active-page]="isCurrentPage(pge)" (click)="goToPage(pge);scrollToTop()">
          {{pge}}
        </button>
        <button class="pagination-buttonOrg" (click)="nextPage();scrollToTop()" [disabled]="isLastPage()" id="next-page">
          <img alt="Navigate to next page" src="assets/images/pagination-next-btn.svg" class="locations_arrows">
        </button>
        <button class="pagination-buttonOrg" (click)="goToLastPage();scrollToTop()" [disabled]="isLastPage()" id="last-page">
          <img alt="Navigate to last page" src="assets/images/pagination-double-next-arrow-icon.svg" class="locations_arrows">
        </button>
        </div>
    </div>
</div>
<div class="col-xs-12 col-md-7 invoices_rightsection">
    <ng-container *ngIf="rightsectionresponse">
    <div class="col-xs-12 col-md-12 invoice_details" id="invoice_{{rightsectionresponse.invoiceNumber}}">
        <div class="invoice_topHeader">
            <div class="invoice_no">Invoice Number: #
                <span class="invoice_fetch_id">{{rightsectionresponse.invoiceNumber}}</span>
                    <div class="invoice_download">
                    <span>Download Invoice PDF</span>
                    <span>
                    <img src="assets/images/icon-download.svg" width="15" alt="download" (click)="downloadInvoice(rightsectionresponse.invoiceNumber)">
                    </span>
                </div>
                
            </div>
            <div class="invoice_detailpdf">
                <div>Need Help?</div>
                <a href="/help-and-faq" target="_blank">See our FAQs on payments & invoice</a>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="row invoice_topHeader">
            <div class="col-md-3 invoice_duedate">
                <label>Payment Due on</label>
                <div>{{rightsectionresponse.paymentDueDate}}</div>
            </div>
            <div class="col-md-3 invoice_issueddate">
                <label>Invoice Date</label>
                <div>{{rightsectionresponse.invoiceDate}}</div>
            </div>
            <div class="col-md-3 invoice_totalcost">
                <label>Amount Due</label>
                <div>
                    <span>
                <ng-container *ngIf="rightsectionresponse.status == 'PAID';else otherstatus">
                    &#163;{{0 | number : '1.2-2'}}
                </ng-container>
                <ng-template #otherstatus>
                    &#163;{{rightsectionresponse.total | number : '1.2-2'}}
                </ng-template>
            
                </span>
                </div>
            </div>
            <div class="col-md-3 invoice_detailstatusParent">
                <div class="invoice_detailstatus {{rightsectionresponse.invoiceStatus}}">{{rightsectionresponse.invoiceStatus}}</div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="invoice_topHeader">
            <div class="invoice_detailbox1">
                <div class="invoice_boxdetail invoice_detailPO">
                    <div class="invoice_detaillabel invoice_detailPOlabel">Customer PO Number</div>
                    <div class="invoice_detailno invoice_detailPOno">{{rightsectionresponse.poNumber}}</div>
                    <div class="invoice_detailissued invoice_detailPOissued">Issued on: <span>{{rightsectionresponse.poDate}}</span>
                    </div>
                </div>
    
            </div>
            <div class="invoice_detailbox2">
                <div class="invoice_boxdetail invoice_detailorder">
                    <div class="invoice_detaillabel invoice_detailorderlabel">Sales Order Number</div>
                    <div class="invoice_detailno invoice_detailorderno">#{{rightsectionresponse.salesOrderNumber}}</div>
                </div>
                <div class="invoice_boxdetail invoice_detaildelivery">
                    <div class="invoice_detaillabel invoice_detaildeliverylabel">Delivery Number
                </div>
                    <div class="invoice_detailno invoice_detaildeliveryno">{{rightsectionresponse.deliveryNumber}}</div>
                </div>
            </div>
            <div class="invoice_detailbox3">
                <div class="invoice_boxdetail invoice_detailship">
                    <div class="invoice_detaillabel invoice_detailshiplabel">Ship To</div>
                    <div class="invoice_detailno invoice_detailshipno">#{{rightsectionresponse.shipTo.shipToId}}</div>
                    <div class="invoice_detaillabel invoice_detailshiploc">Location Name</div>
                    <div class="invoice_detailissued invoice_detailshipaddress">
                    {{rightsectionresponse.shipTo.shipToStreet}}<br>{{rightsectionresponse.shipTo.shipToCity}}<br>{{rightsectionresponse.shipTo.zipCode}}
                        
                    </div>
                </div>
                <div class="invoice_boxdetail invoice_detailbil">
                    <div class="invoice_detaillabel invoice_detailbilllabel">Sold To</div>
                    <div class="invoice_detailno invoice_detailbillno">#{{rightsectionresponse.soldTo.soldToId}}</div>
                    <div class="invoice_detaillabel invoice_detailbillloc">Location Name</div>
                    <div class="invoice_detailissued invoice_detailbilladdress">
                    {{rightsectionresponse.soldTo.soldToStreet}}<br>{{rightsectionresponse.soldTo.soldToCity}}<br>{{rightsectionresponse.soldTo.zipCode}}
                        
                    </div>
                </div>
            </div>
            <div class="invoice_products">
                <div class="invoice_productsHeader">Products Invoiced</div>
                <table class="invoice_productsTable">
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Material #</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                    <ng-container *ngFor="let items of rightsectionresponse.lineItems">
                        <tr>
                        <td>{{items.productName}}</td>
                        <td>{{items.materialNumber}}</td>
                        <td class="addCommaInvoices">{{items.doses}}</td>
                        <td>&#163;{{items.unitPrice | number : '1.2-2'}}
                        </td>
                        <td>&#163;{{items.netAmount | number : '1.2-2'}}
                        </td>
                        </tr>
                    </ng-container>
                    </tbody>
                    
                    
                </table>
            </div>
            <div class="invoice_detailtotalcal invoice_detailsubtotal">
                <div class="invoice_detailtotalcallabel">Subtotal (GBP)</div>
                <div class="invoice_detailtotalcalLine"></div>
                <div class="invoice_detailtotalcalamount invoice_detailsubtotalAmount">&#163;{{rightsectionresponse.subTotal | number : '1.2-2'}}</div>
            </div>
            <div class="invoice_detailtotalcal invoice_detailtax">
                <div class="invoice_detailtotalcallabel">VAT</div>
                <div class="invoice_detailtotalcalLine"></div>
                <div class="invoice_detailtotalcalamount invoice_detailtaxAmount">&#163;{{rightsectionresponse.vat | number : '1.2-2'}}</div>
            </div>
            <div class="invoice_detailtotalcost">
                <div class="invoice_detailtotalcostlabel">Total Cost</div>
                <div class="invoice_detailtotalcostamount">&#163;{{rightsectionresponse.total | number : '1.2-2'}}</div>
                <div class="clearfix"></div>
            </div>
        </div>
    
    </div>
    </ng-container>
</div>
</section>
