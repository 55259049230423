import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopHeaderComponent } from './top-header/top-header.component';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { CmsConfig, ConfigModule, UrlModule } from '@spartacus/core';
import { CategoryNavigationModule, NavigationModule, provideCmsStructure } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { UserAccountModule } from '@spartacus/user/account';
import { UserAccountRootModule } from '@spartacus/user/account/root';
import { UserAccountOccModule } from '@spartacus/user/account/occ';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    TopHeaderComponent,
    NavHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    FormsModule,
    UserAccountModule,
    UserAccountRootModule,
    UserAccountOccModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        NavigationComponent: {
          component:TopHeaderComponent
        },
        // BannerComponent:{
        //   component:NavHeaderComponent
        // }
        MainHeaderComponent:{
          component:NavHeaderComponent
        }
        // RotatingImagesComponent:{
        //   component:NavHeaderComponent
        // },
      }
  
      } as CmsConfig)  
  ],

})
export class CustomHeaderModule { }
