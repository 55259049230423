<div class="flucelvax-top-navigation" *ngIf="topheadline | async as item">
    <!--desktop version-->
    <section class="product_common-banner flucelvax_common-banner desktop-banner d-md-block d-lg-block d-none d-sm-none">
        <div class="simple-banner banner__component--responsive common-image" *ngIf="item?.media">
            <img class="js-responsive-image" alt="cellular-level depiction of circulating influenza virus"
                title="cellular-level depiction of circulating influenza virus"
                [src]="getImage(item.media)">
        </div>
    
    </section>
    <!--for flucelvax mobile top banner-->
    <section class="product_common-banner1 flucelvax_common-banner mobile-banner d-none d-sm-block d-md-none d-lg-none d-xl-none">
        <div class="simple-banner banner__component--responsive" *ngIf="item?.media">
            <img class="js-responsive-image" alt="cellular-level depiction of circulating influenza virus"
                title="cellular-level depiction of circulating influenza virus"
                [src]="getMobileImage(item.media)">
        </div>
    </section> 
</div>
