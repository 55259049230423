<button (click)="topFunction()" id="mobile_help_backtop" title="Go to Top">
    <i class="fa fa-long-arrow-up"></i><span>Back to Top</span>
</button>
<div id="help_contactsection" class="col-xs-12" *ngIf="thirdnode | async as item">
    <div class="col-xs-12 help_contactheader">{{item.text1}}</div>
    <div class="col-xs-12 help_contactcontent" [innerHTML]="item.text2"></div>
    <div class="col-xs-12" id="help_contactbtnsection">
        <a [routerLink]="'/contact-us'">
            <div id="help_contactbtn">{{item.text3}}</div>
        </a>
    </div>
    <!-- for desktop version-->
    <button (click)="topFunction()" id="help_backtop" title="Go to top"><i class="fa fa-long-arrow-up"></i><span>Back to
            Top</span></button>

</div>