<div *ngIf="isLoading" class="loading">
    <div class="loading_txt">
        <div class="load_txt">Loading</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<div *ngIf="seasonValue && (enablePage && enablePage.showLocationPage)">
    <div *ngIf="locationData | async as data">
        <section class="col-xs-12 cart_neworder">
            <div class="col-xs-6 cart_neworderheader">New {{seasonValue | seasonspilt}} Order</div>
        </section>
        <section class="col-xs-12" id="cart_selectlocation-5">
            <div class="cart_selectparent prebookselectlocation" id="cart_selectlocation-1" style="display: block;">

                <div class="prebok_selectbox">
                    <div class="col-xs-12  pre_select_box">
                        <div class="col-xs-12 col-md-7 normalorderselect prebook_selectheader">Select Locations<div>
                                {{data.text2}}</div>
                        </div>
                        <div class="col-xs-12 col-md-5 prebook_selectcount">Ordering to:&nbsp;<span
                                class="cart_loccount" *ngIf="searchText.value === ''">{{selection.selected.length}}
                            </span> <span>locations</span></div>
                    </div>
                    <div class="col-xs-12 cart_selectline"></div>
                    <div class="col-xs-12 cart_selectoptions pre_select_box">
                        <div class="cart_customsearch">
                            <i class="global_searchicon"></i><input type="search" #searchText class="backcolor"
                                (keyup)="applyFilter(searchText.value)"
                                placeholder="Search for location" id="cart_searchbox"><button>Search</button>
                        </div>
                        <div class="cart_selectunselect">
                            <div class="cart_selectoption" id="cart_selectall" (click)="handleOnSelectAllBtnClick()">
                                Select All</div>
                            <div class="cart_selectdivision"></div>
                            <div class="cart_selectoption" id="cart_clearall" (click)="handleOnClearSelection()">Clear
                                Selections</div>
                        </div>
                    </div>
                    <div class="col-xs-12 prebooklocation_header">
                        <span class=" col-xs-4 pb_headtext">Locations</span>
                    </div>
                    <div class="tablecontainer"
                        style="position: relative; overflow-y: auto; max-height: 360px; width: 100%;">
                        <table mat-table [dataSource]="dataSource">
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" [ngClass]="element.checked ? 'disbleRow' : ''">
                                    <div class="cart_summarycheckbox"><label class="checkbox-button"><input
                                                type="checkbox" class="cartSummary_checkbox" name="checkbox"
                                                [(ngModel)]="element.rowChecked"><span
                                                class="checkbox-button__control"></span></label></div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="locname">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" [ngClass]="element.checked ? 'disbleRow' : ''">
                                    {{element.locname}} </td>
                            </ng-container>
                            <ng-container matColumnDef="state">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" [ngClass]="element.checked ? 'disbleRow' : ''">
                                    {{element.address}} </td>
                            </ng-container>
                            <ng-container matColumnDef="address">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" [ngClass]="element.checked ? 'disbleRow' : ''">
                                    {{element.state}} </td>
                            </ng-container>
                            <ng-container matColumnDef="orderPlaced">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" [ngClass]="element.checked ? 'disbleRow' : ''">
                                    <span *ngIf="element.checked">Order Placed</span><span
                                        *ngIf="!element.checked"></span></td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="!row.checked && handleOnRowClick(row)"
                                [ngClass]="selection.isSelected(row) ? 'selected' : ''"></tr>
                        </table>
                    </div>
                </div>
                <div class="cart_startorderbtnsection">
                    <a class="OM_exitBtn showPopupclick" (click)="handleOnExitClick()">Exit</a>
                    <input type="hidden" class="selectLocSeasonValue" id="selectLocSeasonValue-1">
                    <button *ngIf="seasonValue == inseason" type="button" class="cart_startorderbtn"
                        id="preSeasonNew-1" (click)="handleRequestDoseBtnClick()"
                        [disabled]="selection.selected.length == 0">Request Doses</button>
                    <button *ngIf="seasonValue == preseason" type="button" class="cart_startorderbtn"
                        id="preSeasonNew-1" (click)="handleRequestDoseBtnClick()"
                        [disabled]="selection.selected.length == 0">Select Products</button>
                </div>
                <div class="clearboth selectlocationOrderBtn">
                    <div class="view_order locationbottombtn">
                        <a (click)="handleOnOrderLinkClick()" title="View My Current Orders"
                            class="showPopupclick pl-0">View
                            My Current Orders&nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>