import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, switchMap, throwError, of, filter, BehaviorSubject, catchError, map } from 'rxjs';
import { AuthService, OccEndpointsService, UserIdService } from '@spartacus/core';
import { ViewportScroller } from '@angular/common';
import {  BillingPayload, Bussinesspayload, LicenceValidationPayload, Payingpayload, ShippingPayload } from '../../registration/register.model';
declare var clickToAddress:any;

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  
  private  apiUrllicense = this.occ.buildUrl(`${this.fetchUserUrl()}/register/validateLicence?fields=DEFAULT`);
  private  apiBussinessSave = this.occ.buildUrl(`${this.fetchUserUrl()}/register/saveBusinessData?fields=DEFAULT`);
  private  apiBillPayingSave = this.occ.buildUrl(`${this.fetchUserUrl()}/register/saveBillPayData?fields=DEFAULT`);
  private  apiShippingSave = this.occ.buildUrl(`${this.fetchUserUrl()}/register/saveShippingData?fields=DEFAULT`);
  private  apiSaveTemplate = this.occ.buildUrl(`${this.fetchUserUrl()}/register/saveTemplateform?fields=DEFAULT`);
  private  apiGetUserdetails = this.occ.buildUrl(`${this.fetchUserUrl()}?fields=DEFAULT`);
  private apiGetNewcustomer = this.occ.buildUrl(`${this.fetchUserUrl()}/register/newcustomer?fields=FULL`);
  public asmenabled:boolean = false;
  private joinAccRespData = new BehaviorSubject<any>(null);
  currentJoinAccData = this.joinAccRespData.asObservable();
  
  constructor(private http: HttpClient, 
    private occ:OccEndpointsService,private viewportScroller: ViewportScroller,private userIdService: UserIdService, private authService: AuthService) { 
      this.asmenabled = localStorage.getItem('asm_enabled') === 'true';
      if(window.location.href.includes('liveassist') || window.location.href.includes('?asm=true')) {
        localStorage.setItem('showASM', 'true');
        // this.asmenabled = true;
      }
  }

  fetchUserUrl() {
    let partUrl = "/users/current";
    this.userIdService.getUserId().subscribe(item => {
      if(item && this.asmenabled)
        partUrl = `/users/${item}`;
    });
    return partUrl;
  }
  
  public togglemabmenu:boolean=false;

  getnavigation(){
    const url = this.occ.buildUrl("/cms/components/AboutLink"); 
    return this.http.get(url);
  } 
  getimage(){
    const url = this.occ.buildUrl("/cms/components/SiteLogoComponent"); 
     return this.http.get(url);
  } 
  getimage1(){
    const url = this.occ.buildUrl("/cms/components/SeqirusSiteLogoComponent"); 
     return this.http.get(url);
  }
  // getNavigation(componentName:string){
  //   const url = this.occ.buildUrl("/cms/components/"+componentName); 
  //    return this.http.get(url);
  // }
  getNavigation(componentName:string){
    const url = this.occ.buildUrl("/cms/components/"+componentName);
     return this.http.get(url);
  }
  getpdfFlucelvax(){
    return this.http.get(this.occ.buildUrl(`/SeqirusPDF/FlucelvaxPrescribingInfoPDF?fields=DEFAULT`),{responseType:'text'});
   }
   getpdfafluria(){
     return this.http.get(this.occ.buildUrl(`/SeqirusPDF/AfluriaPrescribingInfoPDF?fields=DEFAULT`),{responseType:'text'});
    }
    getpdffluad(){
     return this.http.get(this.occ.buildUrl(`/SeqirusPDF/FluadPrescribingInfoPDF?fields=DEFAULT`),{responseType:'text'});
    }
  getMultipleComponentData(banners: any) {
    const url = this.occ.buildUrl("/cms/components");
    let params = new HttpParams();
    banners.forEach((banner: string) => {
       params = params.append('componentIds',banner);
     
    }) 
    params = params.set('sort','uid');
    return this.http.get(url,{params});
  }
  getAccessResources(resources:string){
    console.log(resources);
    const url = this.occ.buildUrl("/users/anonymous/flu-vaccine-resources/accessResource/"); 
    const params = new HttpParams().set('productIds',resources);
    //const params = new HttpParams().set('productIds',encodeURIComponent(resources));
     return this.http.get(url,{params});
  }
  getMultipleResources(){
    const url = this.occ.buildUrl("/cms/components?fields=DEFAULT&currentPage=0&pageSize=16&componentIds=seqirusClinicalSupportTile%seqirusFinancialGuidanceTile%seqirusOperationalEfficiencyTile"); 
     return this.http.get(url);
  }
  getAccess1(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct1"); 
     return this.http.get(url);
  }
  getAccess2(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct2"); 
     return this.http.get(url);
  }
  getAccess3(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct3"); 
     return this.http.get(url);
  }

  getBannerNode(){
    const url = this.occ.buildUrl("/cms/components/HomepageUserGuidePDFComponent"); 
    return this.http.get(url);
  }
  multiplewhatif(tilename: string){
    const url = this.occ.buildUrl(`/cms/components/${tilename}`); 
    return this.http.get(url);
  }
  getSearchAccount(params:HttpParams):Observable<any>{
    return this.http.get(this.occ.buildUrl(`${this.fetchUserUrl()}/join-account/searchAccount`),{params:params,responseType:'text'});
  }

  getRequestAssistance(params: HttpParams): Observable<any> {
    //console.log(params);
    return this.http.get(this.occ.buildUrl(`${this.fetchUserUrl()}/join-account/requestAssistance`),{params:params,responseType:'text',observe:'response'});
  
  }
  submitJoinAccount(data: any): Observable<any> {
    return this.http.post(this.occ.buildUrl(`${this.fetchUserUrl()}/join-account/submit`),data);
  
  }
  
  getLeftContentForCreateAccount(){
    const url = this.occ.buildUrl("/cms/components/RegistrationBanner"); 
     return this.http.get(url);
  }
  vsscontent(){
    const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-account/vss?fields=FULL`); 
    return this.http.get(url);
  }
  verifyRecaptcha(captchaResp:string) {
    
    const headers = new HttpHeaders({
      'Accept':'application/json'
    })
    return this.http.get(this.occ.buildUrl(`${this.fetchUserUrl()}/captcha-check/${captchaResp}`),{headers});
  }
  verifyLogoutRecaptcha(captchaResp:string) {
    
    const headers = new HttpHeaders({
      'Accept':'application/json'
    })
    return this.http.get(this.occ.buildUrl(`/users/anonymous/captcha-check/${captchaResp}`),{headers});
  }
  savedCreditDetail(){
    const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/fetch-saved-cards?fields=DEFAULT`);
    return this.http.get(url);
  }

  iFrame(obj: any){
    const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/addNewCardIngenico?fields=DEFAULT`);
    return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
    // return this.http.post(url, {headers:{'Content-Type':'application/json'+'hostedCheckoutId'+returnUrl}});
  }
  //opefficiency 
  getComponentNavigation(componentName:string){
    // const url = this.occ.buildUrl("/cms/components/"+componentName);
    //  return this.http.get(url);
    const url = this.occ.buildUrl(`/cms/components/`);
    let params = new HttpParams().set('componentIds',componentName);
    //const params = new HttpParams().set('productIds',encodeURIComponent(resources));
    // params = params.set('sort','uid');
     return this.http.get(url,{params});
  }
 
  opeffeaturedResources(tilename: any){
    const url = this.occ.buildUrl(`/cms/components/`);
    let params = new HttpParams().set('componentIds',tilename);
    //const params = new HttpParams().set('productIds',encodeURIComponent(resources));
    if(!tilename.includes('flucelvaxChildGraphComponent' ) && !tilename.includes('flucelvaxChildSafetyProfileComponent')){
    params = params.set('sort','uid');
    }
     return this.http.get(url,{params});
 
  }
  helpandaq(tilename: string){
    const url = this.occ.buildUrl(`/cms/components/${tilename}`); 
    return this.http.get(url);
  }
  //opefficiency second component carousel
  getcarouselslides(tilename: string){
    const url = this.occ.buildUrl(`/cms/components/${tilename}`); 
    return this.http.get(url);
  }
  clinicalSupportSlide1(){
    const url = this.occ.buildUrl("/cms/components/SeqirusFlu360ContentSlide1");
    return this.http.get(url);
  }
  clinicalSupportSlide2(){
    const url = this.occ.buildUrl("/cms/components/SeqirusFlu360ContentSlide2");
    return this.http.get(url);
  }
  clinicalSupportSlide3(){
    const url = this.occ.buildUrl("/cms/components/SeqirusFlu360ContentSlide3");
    return this.http.get(url);
  }
  startorder_Landing(componentid:String){
    const url = this.occ.buildUrl("/cms/components/"+componentid);
    return this.http.get(url);
  }
  startapi(){
    const url = this.occ.buildUrl(`${this.fetchUserUrl()}/Start-order/start`);
    return this.http.get(url);
  }
  allproductsslides(tilename: string){
    const url = this.occ.buildUrl(`/cms/components/${tilename}`); 
    return this.http.get(url);
  }
  getcarouselstiles(tilename: string){
    const url = this.occ.buildUrl(`/cms/components/${tilename}`);
    return this.http.get(url);
  }
  InflucarouselOptions(tilename: string){
    const url= this.occ.buildUrl(`/cms/components/${tilename}`);
    return this.http.get(url);
  }
  getUKProductDetails(season: string) {
    const getProductUrl = this.occ.buildUrl(`${this.fetchUserUrl()}/Start-order/getProductPageDetails?fields=DEFAULT&season=${season}&selectedLoc=60063573&selectedLoc=60063572&selectedLoc=60063571`);
    return this.http.get(getProductUrl);
  }

  updateJoinAccResponse(response: any) {
    this.joinAccRespData.next(response);
  }

  fetchifyCall(){
    return new clickToAddress({
      accessToken: '252fa-0e273-cf8ab-03341',
      countryMatchWith: 'text', 
      enabledCountries: ['United States'],
      domMode: 'name',
      gfxMode: 1,
      style: {
        ambient: 'light', // Use white as main interface color
        accent: 'default' // Use default secondary color for interface
      },
      texts: {
      default_placeholder: 'Start with post/zip code or street',
      },
      showLogo: true,
      disableAutoSearch:false,
     
    })
  }
  
  validateLicence(payload: LicenceValidationPayload): Observable<any> {
    const params = new HttpParams()
      .set('licenceName', payload.licenceName)
      .set('licenceNumber', payload.licenceNumber)
      .set('licenceState', payload.licenceState);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<any>(this.apiUrllicense, { headers, params });
  }
  
  saveBussiness(payload: Bussinesspayload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(this.apiBussinessSave, payload, { headers });
  }
  
  savePaying(payload: Payingpayload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(this.apiBillPayingSave, payload, { headers });
  }
  
  saveShipping(payload: ShippingPayload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    return this.http.post<any>(this.apiShippingSave, payload, { headers });
  }
  
  uploadExcelFile(file: File, uid: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('loginEmailId', uid);
    return this.http.post(this.apiSaveTemplate, formData, { responseType: 'text' }).pipe(
      catchError(this.handleError)
    );
      
  }
  
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  
  getUserData(): Observable<any> {
    return this.http.get<any>(this.apiGetUserdetails);
  }
  
  getnewUserData(): Observable<any> {
    return this.http.get<any>(this.apiGetNewcustomer);
  }
  
  
  SaveBilling(billingAddress: BillingPayload): Observable<BillingPayload> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<BillingPayload>(this.apiBillPayingSave, billingAddress, { headers });
  }
  scrollToTop(): void {
    window.scrollTo({ top: 0});
  }
  // gettoolsAndResources(currentPage: number, pageSize: number, tileType: string , tileProduct: string ): Observable<any> {
  //   const url = this.occ.buildUrl(`/users/anonymous/flu-vaccine-resources/getPaginatedProducts`);
  //   const params = `?currentPage=${currentPage}&pageSize=${pageSize}&selectedType=${tileType}&selectedProduct=${tileProduct}`;
  //   return this.http.get(url + params);
  // }
  //   getFilteredResources() :  Observable<any>{
  //     const url = this.occ.buildUrl('users/anonymous/flu-vaccine-resources/getFilters?fields=DEFAULT');
  //     return this.http.get(url);
  //   }
    // getResourceDetails(productId : string): Observable<any> {
    //   const url = this.occ.buildUrl(`users/anonymous/cms/pages/resourcesDetailsProductPage?productCode`);
    //   return this.http.get(url);
    //  }
    //  getResourceDetails(productId : string): Observable<any> {
    //   const url = this.occ.buildUrl(`users/anonymous/cms/pages/resourcesDetailsProductPage?productCode`);
    //   return this.http.get(url);
    //  }
    // getSpecificContent(productCode :string): Observable<any> {
    //   const url = this.occ.buildUrl(`users/anonymous/p/${productCode}`);
    //   return this.http.get(url);
    // }
    // getdetailedcomponentsdata(productCode : string){
    //   const url = this.occ.buildUrl('users/anonymous/flu-vaccine-resources/'+productCode+'?fields=DEFAULT');
    //   return this.http.get(url);
    // }
    // getdetailedcomponentsdata(productCode : string){
    //   const url = this.occ.buildUrl('users/anonymous/flu-vaccine-resources/'+productCode+'?fields=DEFAULT');
    //   console.log(url);
    //   return this.http.get(url);
    // }
    
  gettoolsAndResources(currentPage: number, pageSize: number, tileType: string, tileProduct: string): Observable<any> {
    let url: string;
    const params = `?currentPage=${currentPage}&pageSize=${pageSize}&selectedType=${tileType}&selectedProduct=${tileProduct}`;
    return this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          url = this.occ.buildUrl(`/users/current/flu-vaccine-resources/getPaginatedProducts`);
        } else {
          url = this.occ.buildUrl(`/users/anonymous/flu-vaccine-resources/getPaginatedProducts`);
        }
        console.log(url + params);
        return this.http.get(url + params);
      })
    );
  }
  
    getFilteredResources(): Observable<any> {
      let url: string;
      return this.authService.isUserLoggedIn().pipe(
        switchMap((isLoggedIn: boolean) => {
          if (isLoggedIn) {
            url = this.occ.buildUrl('users/current/flu-vaccine-resources/getFilters?fields=DEFAULT');
          } else {
            url = this.occ.buildUrl('users/anonymous/flu-vaccine-resources/getFilters?fields=DEFAULT');
          }
          console.log(url);
          return this.http.get(url);
        })
      );
    }

    getResourceDetails(productId: string): Observable<any> {
      let url: string;
      return this.authService.isUserLoggedIn().pipe(
        switchMap((isLoggedIn: boolean) => {
          if (isLoggedIn) {
            url = this.occ.buildUrl(`users/current/cms/pages/resourcesDetailsProductPage?productCode=${productId}`);
          } else {
            url = this.occ.buildUrl(`users/anonymous/cms/pages/resourcesDetailsProductPage?productCode=${productId}`);
          }
          console.log(url);
          return this.http.get(url);
        })
      );
    }

    getSpecificContent(productCode: string): Observable<any> {
      let url: string;
      return this.authService.isUserLoggedIn().pipe(
        switchMap((isLoggedIn: boolean) => {
          if (isLoggedIn) {
            url = this.occ.buildUrl(`users/current/p/${productCode}`);
          } else {
            url = this.occ.buildUrl(`users/anonymous/p/${productCode}`);
          }
          console.log(url);
          return this.http.get(url);
        })
      );
    }

    //For Detailed Page
    getdetailedcomponentsdata(productCode: string): Observable<any> {
      let url: string;
      return this.authService.isUserLoggedIn().pipe(
        switchMap((isLoggedIn: boolean) => {
          if (isLoggedIn) {
            url = this.occ.buildUrl('users/current/flu-vaccine-resources/' + productCode + '?fields=DEFAULT');
          } else {
            url = this.occ.buildUrl('users/anonymous/flu-vaccine-resources/' + productCode + '?fields=DEFAULT');
          }
          console.log(url);
          return this.http.get(url);
        })
      );
    }
    getDetailedAccessResources(resources: string): Observable<any> {
      console.log(resources);
      return this.authService.isUserLoggedIn().pipe(
        switchMap((isLoggedIn: boolean) => {
          let url: string;
          if (isLoggedIn) {
            url = this.occ.buildUrl('users/current/flu-vaccine-resources/accessResource/');
          } else {
            url = this.occ.buildUrl('users/anonymous/flu-vaccine-resources/accessResource/');
          }
          console.log(url); // Log the URL to the console
          const params = new HttpParams().set('productIds', resources);
          return this.http.get(url, { params });
        })
      );
    }
}

