import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-fifth',
  templateUrl: './fifth.component.html',
  styleUrl: './fifth.component.scss'
})
export class FifthComponent {
  banner : Observable<any> = this.component.data$;

  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,private userInfoService:UserInfoService) {
  }
  userLoggedIn: boolean = false;
  ngOnInit(){
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      console.log('login check',res);
      this.userLoggedIn = res;
    })
  }
}
