<div id="influenza-burden" *ngIf="influenzareportnode | async as item">
    <div class="influnenza-season-section">
        <div class="container">
            <div class="row-flex">
                <div id="influnenza-season-header" class="col-lg-12 col-md-12 col-sm-12 col-sm-12 text-center">
                    <h2 [innerHTML]="item.headLine"></h2>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row-flex">
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="two-row--card">
                        <div class="two-row--card-content">
                            <div class="two-row--card-top" [innerHTML]="item.text1content"></div>
                            <hr>
                            <div class="two-row--card-bottom" [innerHTML]="item.text2content"></div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="two-row--card">
                        <div class="two-row--card-content">
                            <div class="two-row--card-top" [innerHTML]="item.text3content"></div>
                            <hr>
                            <div class="two-row--card-bottom" [innerHTML]="item.text4content"></div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-sm-mobile">
                    <div class="influenza-season-footnotes">
                        <p [innerHTML]="item.content"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>