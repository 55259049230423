import { ChangeDetectorRef, Component } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable, combineLatest } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { map } from 'rxjs/operators';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-clinical-flucelvax-first',
  templateUrl: './clinical-flucelvax-first.component.html',
  styleUrl: './clinical-flucelvax-first.component.scss'
})
export class ClinicalFlucelvaxFirstComponent {
  fourthnode: Observable<any> = this.bannerComponent.data$;
  userLoggedIn: boolean = false;
  mergedObject1: any = [];
  study = [false,false];
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, private cd: ChangeDetectorRef, private userInfoService: UserInfoService) { }
   
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  getMobileImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.mobile.url;
  }
  openModal(): void {
    const modal = document.getElementById('childadult_abandonedPop');
    if (modal) {
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  }
  closeModal(): void {
    const modal = document.getElementById('childadult_abandonedPop');
    if (modal) {
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
  }

  toggleStudy(i:number){
    this.study[i]=!this.study[i]
  }

  ngOnInit() {
    this.fourthnode.subscribe(res => {
      this.clinicaltabs(res);
    });
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      console.log('login check',res);
      this.userLoggedIn = res;
      // if(this.userLoggedIn == true){
      //   this.renderer.setStyle(document.querySelector('.SideContent'),'display','block');
      // }
    })
  }

//   clinicaltabs(res: any) {
 
//   const tilesList = res.GraphChartList.split(" ");
//   this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
//    this.mergedObject1 = res.component;
//    console.log(this.mergedObject1);
//    this.cd.detectChanges;
//   });
// }
clinicaltabs(res: any) {
  const tilesList = res.GraphChartList.split(" ");
  console.log('tilesList:', tilesList);
  this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
  
    
    // Check if apiRes.component is an array
    if (Array.isArray(res.component)) {
      this.mergedObject1 = res.component;
    } else if (res.component) {
      // Convert to array if it's not already
      this.mergedObject1 = [res.component];
    } else {
      // Ensure it's always an array
      this.mergedObject1 = [];
    }

    console.log('mergedObject1:', this.mergedObject1);
    this.cd.detectChanges();  // Ensure detectChanges is called correctly
  });
}
}