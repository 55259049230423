<section class="col-xs-12 col-sm-12 credits_titlesection">
    
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-8">
        <div class="credits_header">Credits</div>
        <div class="credits_dropdown">
            <select id="credits_dropdownselect" *ngIf="dropdownDetail" (change)="selectedSeason($event)" [(ngModel)]="finalSeason">
                <option  *ngFor="let obj of dropdownDetail.seqirusSeasonList; let i= index" [value]="obj" id="credits_dropdownselect-{{i}}"> Viewing Season: {{obj.replace('-20','/')}} </option>
            </select>
        </div>
      </div>
</section>
