import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
declare function OptanonWrapper(): void;
declare global
{// Extend the Window interface to include Optanon
interface Window {
 Optanon?: {
   ShowPreferences: () => void;
 };
}
}
@Component({
  selector: 'app-cookie-header',
  templateUrl: './cookie-header.component.html',
  styleUrl: './cookie-header.component.scss'
})
export class CookieHeaderComponent {

  node: Observable<any> = this.bannerComponent.data$;
  constructor(
    private cmsService: CmsService,
    protected config: OccConfig,
    public bannerComponent: CmsComponentData<CmsBannerComponent>
  ) {}
  ngOnInit(): void {
    this.loadOneTrustScript();
  }
  // Dynamically load the OneTrust script
  loadOneTrustScript(): void {
    const existingScript = document.querySelector('script[data-domain-script]');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.charset = 'UTF-8';
      script.setAttribute(
        'data-domain-script',
        'b38baaca-68ee-447d-b401-6ba552db2657-test'
      );
      document.body.appendChild(script);
      script.onload = () => {
        if (typeof OptanonWrapper === 'function') {
          OptanonWrapper();
        }
      };
    } else {
      if (typeof OptanonWrapper === 'function') {
        OptanonWrapper();
      }
    }
  }
  // Show OneTrust cookie settings modal
  showCookieSettings(): void {
    if (window.Optanon) {
      window.Optanon.ShowPreferences();
      console.log('failed');    }
  }
 }