import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OccConfig } from '@spartacus/core';

@Component({
  selector: 'app-returns-exit-popup',
  templateUrl: './returns-exit-popup.component.html',
  styleUrl: './returns-exit-popup.component.scss'
})
export class ReturnsExitPopupComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,protected config: OccConfig,private dialogRef: MatDialogRef<ReturnsExitPopupComponent>,) {
    
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  goToreturn(): void {
    window.location.reload();
  }
}
