
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-filter-by-change',
  templateUrl: './filter-by-change.component.html',
  styleUrls: ['./filter-by-change.component.scss']
})
export class FilterByChangeComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
}
