import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { FirstHeaderComponent } from './first-header/first-header.component';
import { SecondSlotComponent } from './second-slot/second-slot.component';




@NgModule({
  declarations: [
    FirstHeaderComponent,
    SecondSlotComponent,
   
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusCookieHeader:{
          component:FirstHeaderComponent
        },
        SeqirusCookieComponent:{
          component:SecondSlotComponent
        }
      }
    })
  ]
})
export class USCookiePolicyModule { }
