import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BannerCompComponent } from './banner-comp/banner-comp.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { SecondBannerComponent } from './second-banner/second-banner.component';
import { CarouselDataQivcComponent } from './carousel-data-qivc/carousel-data-qivc.component';
// import { FeatureResourceComponent } from './feature-resource/feature-resource.component';



@NgModule({
  declarations: [
      SecondBannerComponent,
    CarouselDataQivcComponent,
    // FeatureResourceComponent
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        // SeqirusAllProductsVaccineBannerComponent:{
        //   component:BannerCompComponent
        // },
        SeqirusProductQIVcSecondBannerComponent:{
          component:SecondBannerComponent
        },
        // SeqirusVaccinePortfolio:{
        //   FeatureResourceComponent
        // }
            }
      } as CmsConfig)
    ]
  })
export class UKQivcModule { }
