// import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
 
@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
})
export class PaymentInformationComponent implements OnInit {
  cardsData: any[] = [];
  creditdetails: any = { cardsData: [] };
  cardservice: any;
  apiResponse: any;
  Key: string = '';
  token: any;
  cardType: any;
  selectedtoken: string = '';
  confirmDeletemodal: boolean = false;
  event: any;
  occEndPointsService: any;
  http: any;
  iframeUrl: string = '';
  getIframeUrl: any;
  iFramedetails: any;
  paymentDetailsList!: Object;
  iFrame!: Object;
  checkOutId: any;
  returnUrl: string = '';
  trustedUrl!: SafeResourceUrl;
 
  constructor(
    private sanitizer: DomSanitizer,
    private contentservice: ContentService,
    private userinfoservice: UserInfoService,
    private cdr: ChangeDetectorRef
  ) {}
 
  ngOnInit(): void {
    this.contentservice.savedCreditDetail().subscribe((data: any) => {
      this.creditdetails = data;
      console.log(this.creditdetails.cardsData);
      this.cdr.detectChanges();
    });
const obj = {
   //returnURL :"https://www.flu360.com"
   returnURL :"https://dev3.flu360.com/savedCardIframePage"
   // returnURL :"http://local.flu360:4200/savedCardIframePage"
     

};

this.contentservice.iFrame(obj).subscribe((data: any) => {
  this.iFramedetails = data;
  this.checkOutId = data.hostedCheckoutId;
  console.log(this.iFramedetails);
   this.updateIframeUrl(data.partialRedirectUrl);
   
   localStorage.setItem('paymentInfoIframeHostedId',this.checkOutId )
});
  }
 
updateIframeUrl(returnUrl:string): void {
  console.log('Updating iframe URL to:', returnUrl);
   this.returnUrl = returnUrl; // Set the actual URL you want to display
//  this.returnUrl = 'https://payment.pay1.preprod.checkout.worldline-solutions.com/checkout/11122-ed273adc127f4dd783dfb31088a653ea:06757e57-976f-71ff-9659-ec3c26c260d8:7302a145511d494dbe0d8b480574d673'
  this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.returnUrl);
  this.cdr.detectChanges();
  }
 
cleanURL(): SafeResourceUrl {
return this.trustedUrl;
}
 
reloadPageAndRedirect(returnUrl: string): void {
  window.location.href = this.returnUrl;
}
//redirect
// onPaymentSuccess(): void {
//   window.location.href = 'http://www.flu360.com';
// }
  getExpiryClass(expiryDate: string): string {
    const currentDate = new Date();
    const [month, year] = expiryDate.split('/').map(Number);
    const expiry = new Date(year, month - 1);
 
    if (expiry < currentDate) {
      return 'expired';
    } else if (
      expiry.getFullYear() === currentDate.getFullYear() &&
      expiry.getMonth() === currentDate.getMonth() + 1
    ) {
      return 'expiring-soon';
    } else {
      return 'future-expiry';
    }
  }
 
  getExpiryMessage(expiryDate: string): string {
    const currentDate = new Date();
    const [month, year] = expiryDate.split('/').map(Number);
    const expiry = new Date(year, month - 1);
 
    if (expiry < currentDate) {
      return `Expired ${expiryDate}`;
    } else if (
      expiry.getFullYear() === currentDate.getFullYear() &&
      expiry.getMonth() === currentDate.getMonth() + 1
    ) {
      return `Expiring soon ${expiryDate}`;
    } else {
      return `Expires ${expiryDate}`;
    }
  }
 
  handleOnDelete(event: Event) {
    this.selectedtoken = (event.target as HTMLInputElement).value;
    console.log('Delete button clicked for token:', this.selectedtoken);
    this.confirmDeletemodal = true;
    console.log(this.confirmDeletemodal);
    this.cdr.detectChanges();
  }
 
  confirmDelete(): void {
    if (this.selectedtoken) {
      this.userinfoservice.deleteCreditDetails(this.selectedtoken).subscribe(
        (_response: any) => {
          this.creditdetails.cardsData = this.creditdetails.cardsData.filter(
            (cards: any) => cards.token !== this.selectedtoken
          );
          console.log('Deleted token:', this.selectedtoken);
          this.cdr.detectChanges();
        },
        (error: any) => {
          console.error('Error deleting card:', error);
          alert('There was an error deleting the card. Please try again.');
        }
      );
    }
  }
}
// // this.contentservice.iFrame(obj).subscribe((data: any) => {
// //   console.log('API Response:', data); // Log the entire response
// //   this.updateIframeUrl(data.partialRedirectUrl);
// // }, error => {
// //   // console.error('API call error:', error);
// // });
// //   }
 
// updateIframeUrl(url: string) {
//  {
//   console.log('Iframe URL:', url); // Log the URL for debugging
//   this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
//   this.cdr.detectChanges();
// }
// }
 
// this.contentservice.iFrame(obj ).subscribe((data: any) => {
//   this.iFramedetails = data;
//   this.checkOutId = data.hostedCheckoutId;
//   console.log(this.iFramedetails);
//   this.cdr.detectChanges();
//   this.updateIframeUrl();
// });
// }
 
// updateIframeUrl(): void {
// this.returnUrl = 'https://www.flu360.com'; // Set the actual URL you want to display
// this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.returnUrl);
// }
 
// cleanURL(): SafeResourceUrl {
// return this.trustedUrl;
// }