import { ChangeDetectorRef, Component } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable, combineLatest } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { map } from 'rxjs/operators';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-clinical-fluad-first',
  templateUrl: './clinical-fluad-first.component.html',
  styleUrls: ['./clinical-fluad-first.component.scss']
})
export class ClinicalFluadFirstComponent {
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, private cd: ChangeDetectorRef,private userInfoService: UserInfoService) { }
  firstheadline: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];
  userLoggedIn: boolean = false;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  ngOnInit() {
    this.firstheadline.subscribe(res => {
      this.clinicaltabs(res);
    });
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      console.log('login check',res);
      this.userLoggedIn = res;
      // if(this.userLoggedIn == true){
      //   this.renderer.setStyle(document.querySelector('.SideContent'),'display','block');
      // }
    })
  }

  clinicaltabs(res: any) {
    // const tilesList = res.boostedNavList.split(" ");
    // const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));

    // combineLatest(observables).pipe(
    //   map((results:any) => results.flat())
    // ).subscribe((mergedResults: any) => {
    //   this.mergedObject1 = mergedResults;
    //   this.cd.detectChanges();
    //   console.log(this.mergedObject1);
    // });
    const tilesList = res.boostedNavList.split(" ");
    this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
     this.mergedObject1 = res.component;
     console.log(this.mergedObject1);
     this.cd.detectChanges;
    });
  }
  gettabsImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  gettabsMobileImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.tablet.url;
  }
}