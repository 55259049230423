<div class="col-md-12 col-sm-12 col-xs-12 no-padding">
    <div class="myprofile_content_location myprofile_location_us ">
        <ng-container *ngIf="currentSite === 'seqirusb2b-uk';else locationheader">
            <div class="col-md-6 col-sm-12 col-xs-12 myprofile_locationheader">Location Contacts & Addresses</div>
        </ng-container>
        <ng-template #locationheader><div class="col-md-6 col-sm-12 col-xs-12 myprofile_locationheader">My Location</div></ng-template>
        
        <div class="col-md-6 col-sm-12 col-xs-12 myprofile_locationmangeheader">
            <ng-container *ngIf="currentSite === 'seqirusb2b-uk';else usmanage">
                <a class="anchorunderline" [routerLink]="['/organization-location']">Manage All Locations<div class="global_blackarrow"></div></a>
            </ng-container>
            <ng-template #usmanage>
            <a class="anchorunderline" [routerLink]="['/organization-location']">Manage Locations<div class="global_blackarrow"></div></a>
        </ng-template>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 myprofile_selectoptions">
            <div class="myprofile_locationsearch"> <i class="myprofile_global_searchicon"></i>
                <input type="search" #search placeholder="Search for location" (keyup.enter)="applyFilter($event, searchValue)" [(ngModel)]="searchValue">
                <!-- id="myprofile_location_searchbox" -->
                <button (click)="applyFilter($event,search.value)">Search</button>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 margin-T20">
            <ng-container *ngIf="currentSite === 'seqirusb2b-uk';else ustable">
                <div class="row myprofilelocation myprofile_us example-container ukmanagelocationtable">
                    <table  class="ukmanageLocation-table" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

                   

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" sortActionDescription="Sort by name">
                            Location Name
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" sortActionDescription="Sort by weight">
                            Address
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  disableClear="true"sortActionDescription="Sort by number">
                            Type
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" sortActionDescription="Sort by symbol">
                            Email Contact
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsuk;sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsuk;"></tr>
                </table>
                <div *ngIf="searchResultEmpty" class="no-results-message">
                    No matching records found
                  </div>
                  
                </div>
            </ng-container>
            <ng-template #ustable>
            <div class="row myprofilelocation myprofile_us example-container table-container">
                
                    <table  class="demo-table custom_sort_us" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                            Type
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
                            Address
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            
                <div *ngIf="searchResultEmpty" class="no-results-message">
                    No matching records found
                  </div>
                  
                </div>
            </ng-template>
        </div>
    </div>
</div>