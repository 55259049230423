import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {
    constructor(
        private httpClient:HttpClient,
        protected config: OccConfig) { }

    public downloadFile(url: string) {
        return this.httpClient.get(this.config.backend?.occ?.baseUrl + url,
            {observe:'response',responseType:'blob'}
        );
    }
    // downloadPdf(url: string): Observable<Blob> { return this.httpClient.get(url, { responseType: 'blob' }); } 
}
