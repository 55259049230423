<section class="parallexsection col-md-12 no-padding hidden-xs hidden-sm">
    <div class="space-holder">
        <div class="Animationsticky">
            <div class="animationhorizontal">
                <section role="feed" class="parallexcards">
                    <div class="parallexgroup" id="cellgroup">
                        <div class="centeralign cellcontainer">
                            <div class="celltext">
                                <div class="cellheader">{{mergedObject1[2]?.h1}}</div>
                                <ul class="animationsubtext  col-md-10" [innerHTML]="mergedObject1[2]?.content"></ul>
                            </div>
                            <div class="cellimage">
                                <img [src]="getImage(mergedObject1[2]?.mediaPicture)">
                            </div>
                        </div>
                    </div>
                    <div class="parallexgroup centeralign" id="egggroup">
                        <div class="eggtext">
                            <div class="eggheader">{{mergedObject1[1]?.h1}}</div>
                            <ul class="animationsubtext  col-md-10" [innerHTML]="mergedObject1[1]?.content"></ul>
                        </div>
                        <div class="eggimage">
                            <img [src]="getImage(mergedObject1[1]?.mediaPicture)">
                        </div>
                    </div>
                    <div class="parallexgroup Intorscreen">
                        <div class="afterintotext" [innerHTML]="mergedObject1[0]?.headLine"></div>
                        <div class="introarrowsymbol">
                            <img src="/assets/images/GreenArrow.png">
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>