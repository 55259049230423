import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-influenza-65-plus',
  templateUrl: './influenza-65-plus.component.html',
  styleUrl: './influenza-65-plus.component.scss'
})
export class Influenza65PlusComponent {
  // items : Observable<any> = this.cmsService.getComponentData('SeqirusInfluenzaPlusFirstComponent');
  items: Observable<any> = this.bannerComponent.data$; 
  constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>) {
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  getMobileImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.mobile.url;
  }
}

  // getImage(image: any): string {
  //   return this.config.backend?.occ?.baseUrl + image.url;
  // }
 


