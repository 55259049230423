import { Component ,Renderer2,ElementRef} from '@angular/core';
import { CmsBannerComponent,CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-fluad-rwe-fourth',
  templateUrl: './fluad-rwe-fourth.component.html',
  styleUrl: './fluad-rwe-fourth.component.scss'
})
export class FluadRweFourthComponent {

fourthnode: Observable<any> = this.bannerComponent.data$;
constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>, private userInfoService:UserInfoService) {
   }

getImage(image: any): string {
  return this.config.backend?.occ?.baseUrl + image.url;
}
}
