<div class="product_rwe-cards" *ngIf="firstheadline | async as item">
  <div class="container">
    <div class="product_fluad-rweHeader" [innerHTML]="item.headLine">
      
    </div>
    <div class="product_efficacy-text" [innerHTML]="item.content">
    </div>
    <div class="product_efficacy-text" [innerHTML]="item.textContent">
    </div>
    <div class="product_rwe-tabContainer">
      <div class="panel with-nav-tabs panel-primary">
        <div class="panel-heading">
          <ul class="nav nav-tabs" *ngIf="mergedObject1 && mergedObject1.length > 0">
            <li [ngClass]="{ active: left }" (click)="onClickLeft()">
              <a
                href="#rwe-tab1primary"
                [ngClass]="{ cus_cur: left }"
                data-toggle="tab"
                aria-expanded="false"
                >{{ mergedObject1[0].headLine }}<br />{{
                  mergedObject1[0].subHeadLine
                }}</a
              >
            </li>
            <li [ngClass]="{ active: right }" (click)="onClickRight()">
              <a
                href="#rwe-tab2primary"
                [ngClass]="{ cus_cur: right }"
                data-toggle="tab"
                aria-expanded="true"
                >{{ mergedObject1[0].headLine }}<br />{{
                  mergedObject1[0].subHeadLine
                }}</a
              >
            </li>
          </ul>
        </div>
        <div class="panel-body">
          <div class="tab-content">
            <div
              class="tab-pane fade"
              [ngClass]="{ active: left, in: left }"
              id="rwe-tab1primary"
            >
              <div *ngFor="let item2 of mergedObject2; let i = index">
                       

                <div
                  class="product_card margin-B40"
                  id="product_card2"
                  [ngClass]="{ cus_card: cardStates[i] }"
                >
                  <div class="product_card-left">
                    <div class="product-rwe_Header">{{ item2.head1Line }}</div>
                    <div
                      class="product-rwe_text product-rwe_double"
                      [innerHTML]="item2.mid1Line"
                    ></div>
                    <div class="product-rwe_">{{ item2.subHead1Line }}</div>
                    <div class="product-rwe_Header" *ngIf="item2.head2Line">
                      {{ item2.head2Line }}
                    </div>
                    <div
                      class="product-rwe_text"
                      *ngIf="item2.mid2Line"
                      [innerHTML]="item2.mid2Line"
                    ></div>
                    <div class="product-rwe_" *ngIf="item2.subHead2Line">
                      {{ item2.subHead2Line }}
                    </div>
                    <div
                      class="product-hideShow_card show-card"
                      [ngClass]="{ cus_hide: cardStates[i] }"
                      (click)="onClickCard1(i)"
                    >
                      Show Study Data<i class="glyphicon glyphicon-plus"></i>
                    </div>
                    <div
                      class="product-hideShow_card hide-card"
                      [ngClass]="{ cus_block: cardStates[i] }"
                      (click)="onClickCard1(i)"
                    >
                      Hide Study Data<i class="glyphicon glyphicon-minus"></i>
                    </div>
                  </div>
                  <div
                    class="product_card-vertical"
                    [ngClass]="{ cus_block: cardStates[i] }"
                  ></div>
                  <div
                    class="product_card-right"
                    [ngClass]="{ cus_card_ex: cardStates[i] }"
                  >
                    <div
                      class="product-rwe_text1"
                      [innerHTML]="item2.text1Content"
                    ></div>
                    <div class="product-rwe_text2">
                      <div class="product_semibold">STUDY DESIGN:</div>
                      <div [innerHTML]="item2.text2Content"></div>
                    </div>
                    <div class="product-rwe_text3">
                      <div class="product_semibold">KEY LIMITATIONS:</div>
                      <div [innerHTML]="item2.text3Content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 

              <div
                class="tab-pane fade"
                [ngClass]="{ active: right, in: right }"
                id="rwe-tab2primary"
              >

                <div *ngFor="let item3 of mergedObject3; let i=index">

                  <div
                  class="product_insideTab-header margin-B40 product_semibold" *ngIf="i==0"
                >
                  Single-season studies:
                </div>
                <div
                  class="product_card margin-B40"
                  id="product_card5"
                  [ngClass]="{ cus_card: cardStates[i+4] }"
                >
                  <div class="product_card-left">
                    <div class="product-rwe_Header">{{item3.head1Line}}</div>
                    <div class="product-rwe_text" [innerHTML]="item3.mid1Line">
                  
                    </div>
                    <div class="product-rwe_">{{item3.subHead1Line}}</div>
                    <div
                      class="product-hideShow_card show-card"
                      [ngClass]="{ cus_hide: cardStates[i+4] }"
                      (click)="onClickCard1(i+4)"
                    >
                      Show Study Data<i class="glyphicon glyphicon-plus"></i>
                    </div>
                    <div
                      class="product-hideShow_card hide-card"
                      [ngClass]="{ cus_block: cardStates[i+4] }"
                      (click)="onClickCard1(i+4)"
                    >
                      Hide Study Data<i class="glyphicon glyphicon-minus"></i>
                    </div>
                  </div>
                  <div
                    class="product_card-vertical"
                    [ngClass]="{ cus_block: cardStates[i+4] }"
                  ></div>
                  <div
                    class="product_card-right"
                    [ngClass]="{ cus_card_ex: cardStates[i+4]}"
                  >
                    <div class="product-rwe_text1" [innerHTML]="item3.text1Content">
                    </div>
                    <div class="product-rwe_text2">
                      <div class="product_semibold">STUDY DESIGN:</div>
                      <div [innerHTML]="item3.text2Content">
                      </div>
                    </div>
                    <div class="product-rwe_text3">
                      <div class="product_semibold">KEY LIMITATIONS:</div>
                      <div [innerHTML]="item3.text3Content">

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="product_insideTab-header margin-B40 margin-T30 product_semibold" *ngIf="i==3"
                >
                  Results shown from studies that span multiple flu seasons
                  reflect the aggregate results of antigenically mismatched and
                  matched seasons.
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
