import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-second-slot',
  templateUrl: './second-slot.component.html',
  styleUrl: './second-slot.component.scss'
})
export class SecondSlotComponent {
  node1: Observable<any> = this.component.data$;
  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,){
  }
}
