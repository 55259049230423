import { ChangeDetectorRef, Component } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { combineLatest, map, Observable } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topnavigation',
  templateUrl: './topnavigation.component.html',
  styleUrl: './topnavigation.component.scss'
})
export class TopnavigationComponent {
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, private cmsService: CmsService, private cd: ChangeDetectorRef,protected config: OccConfig, private router: Router) {
    let url = this.router.url.split('/');
    this.currentRoute = url[url.length - 1];
   }

  topheadline: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];
  currentRoute: string = '';

  ngOnInit() {
    this.topheadline.subscribe(res => {
      this.navtabs(res);
    });
  }

  navtabs(res: any) {
    // const tilesList = res.productNavList.split(" ");
    // const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));

    // combineLatest(observables).pipe(
    //   map((results:any) => results.flat())
    // ).subscribe((mergedResults: any) => {
    //   this.mergedObject1 = mergedResults;
    //   this.cd.detectChanges();
    //   console.log(this.mergedObject1);
    // });
    {
      const tilesList = res.productNavList.split(" ");
      console.log('tilesList:', tilesList);
      this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
      
        
        // Check if apiRes.component is an array
        if (Array.isArray(res.component)) {
          this.mergedObject1 = res.component;
        } else if (res.component) {
          // Convert to array if it's not already
          this.mergedObject1 = [res.component];
        } else {
          // Ensure it's always an array
          this.mergedObject1 = [];
        }
  
        console.log('mergedObject1:', this.mergedObject1);
        this.cd.detectChanges();  // Ensure detectChanges is called correctly
      });
    }
  }
  collapsedIndex: number | null = null;

  toggleCollapse(index: number): void {
    this.collapsedIndex = this.collapsedIndex === index ? null : index;
  }

  isCollapsed(index: number): boolean {
    return this.collapsedIndex === index;
  }
  ismobnavCollapsed = false;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  getMobileImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.mobile.url;
  }
}
