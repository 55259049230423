<div class="col-sm-12 col-xs-12 verifylink_parent_header no-padding-left">
    <div class="verifylink_right_header"><ol class="breadcrumb">
<li>
<a [routerLink]="'/'">Home</a>
</li>

<li class="bc_bold"><strong>Create Account</strong></li>
</ol>
</div>
        <div class="verifylink_helpheader" *ngIf="firstnode | async as item">
        <span class="verifylink_troubletext">{{item.content}}</span>
                <a [routerLink]="item.link"><span class="verifylink_helptext">{{item.h1content}}<div class="verifylink_blackarrow"></div></span></a>
        </div>
    </div>