<div id="landing">
	<div class="container-fluid maincontent">
		<div class="row">
			<div class="col-md-12">
				<div class="container-fluid maincontent explictpage-background">
					<div class="row marBottom40">
						<div class="col-md-10 col-xs-10 middlebg">
							<div class="explicitcontent">
							<h2 class="explicitheading">{{ukSplashPageData.title}}</h2>
									<p class="explicitpara1">{{ukSplashPageData.content}}</p>
                                    <p class="explicitpara3">{{ukSplashPageData.paragraphcontent}}</p>
								 <a id="healthCare" class="arrowaligned" routerLink='' (click)="updateCookie()">
									<div class="linkbg">{{ukSplashPageData.text2}} </div>
									<div class="arrow"><i class="global_blackarrow"></i></div>

								</a>
								<br> <a [routerLink]='ukSplashPageData.urlLink' class="gaexplicitConsent arrowaligned">
                                     	<div class="linkbg margintop">{{ukSplashPageData.h2content}} </div>
                                     	<div class="arrow1"><i class="global_blackarrow"></i></div>
                                     </a>
								<br> <a [href]="ukSplashPageData.urlLink1" class="gaexplicitConsent arrowaligned" target="blank">
									<div class="linkbg margintop">{{ukSplashPageData.h3content}}</div>
									<div class="arrow1"><i class="global_blackarrow"></i></div>

								</a>
							 
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
