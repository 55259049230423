<div class="modal fade modal_order" [ngClass]="{ 'show': showModal, 'modal-hidden': !showModal }" id="autologoutpopup" role="dialog" data-backdrop="static" data-keyboard="false" [style.display]="showModal ? 'block' : 'none'">
    <div class="modal-dialog modal-lg">
         <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
                <button type="button" (click)="resetSession()" class="close reset" data-dismiss="modal">×</button>
                <h4 class="order_popup_header">
                    Your session is about to time out. Any in-progress<br>updates won't be saved.</h4>
                <div class="order_popup_header_sub">
                    <div id="menu_countdown">
                        Session timeout in:&nbsp;<span id="minutes">{{minutes}}</span> : <span id="seconds">{{seconds < 10 ? '0' + seconds : seconds}}</span>
                    </div>
                </div>
            </div>
            <div class="modal-body modal-pop">
                <button type="button" class="ongoing_popup reset" (click)="resetSession()" data-dismiss="modal">Continue Session</button>
                <button type="button" class="logout_popup" [routerLink]="['/logout']">Log Out</button>
                <div class="autoLogoutPopupDocUk" *ngIf="currentSite === 'seqirusb2b-uk'">GBR-FLU360-23-0009 May 2024</div>
            </div>
        </div>
    </div>
</div>