import { ChangeDetectorRef, Component } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';

@Component({
  selector: 'app-clinical-fluad-second',
  templateUrl: './clinical-fluad-second.component.html',
  styleUrl: './clinical-fluad-second.component.scss'
})
export class ClinicalFluadSecondComponent {
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, private cmsService: CmsService, private cd: ChangeDetectorRef,protected config: OccConfig) { }
  secondheadline: Observable<any> = this.bannerComponent.data$;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  getMobileImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.tablet.url;
  }
}
