import { Injectable,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GetgtmidService {
  //private document: Document;
  constructor(@Inject(DOCUMENT) private document: Document) {

  }
 
    getGtmId(): string  {
    const hostname = this.document.location.hostname;

   
    if (hostname.includes('localhost')) {
      console.log("working")
      return 'GTM-WWZ4T9K' ;
    } else if (hostname.includes('uk.example.com')) {
       return 'GTM-UK-ID'
    }
    return ""

    }
  
}





