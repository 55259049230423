<div class="col-md-12 col-sm-12 col-xs-12 myprofile_flex_items padding-right40">
    <div class="myprofile_content " *ngIf="accountDetails as item">
        <div class="row">
            <div class="col-md-9 col-sm-9 col-xs-9">
                <div class="myprofile_tophybrisheader">CSL Seqirus Account Information</div>
            </div>
            <ng-container *ngIf="item.status=='Active'">
                <div class="col-md-3 col-sm-4 col-xs-4" *ngIf="!toggleEdit" (click)="edit()">
                    <div class="myprofile_edit" id="myprofile_editbtn">Edit<img src="/assets/images/edit.png" width="20"
                            alt="Edit">
                    </div>
                </div>
            </ng-container>    
            
        </div>
        <div class="myprofile_view" *ngIf="!toggleEdit">
            <div class="row" style="margin-top: 20px;">
                <div class="col-md-5 col-sm-6 col-xs-12">
                    <div class="myprofile_hybrisheader">Account Number</div><span
                        class="myprofile_hybrisheader_txt">{{item?.accountNumber?item?.accountNumber:'N/A'}}</span>
                </div>
                <div class="col-md-7 col-sm-6 col-xs-8">
                    <div class="myprofile_hybrisheader">My Company</div> <span
                        class="myprofile_hybrisheader_txt">{{item?.organizationName?item?.organizationName:'N/A'}}</span>
                </div>

            </div>
            <div class="row margin-T20">
                <div class="col-md-5 col-sm-6 col-xs-12">
                    <div class="myprofile_hybrisheader">Status</div><span class="myprofile_hybrisheader_txt_sub"
                        id="status">{{item?.status}}</span>
                </div>
                <div class="col-md-7 col-sm-6 col-xs-12">
                    <div class="myprofile_hybrisheader_sub_txt"># of Locations</div>
                    <span
                        class="myprofile_company_txt">{{item?.locationCount?item?.locationCount:'N/A'}}</span>
                </div>
            </div>
            <div class="row margin-T20">
                <div class="col-md-5 col-sm-6 col-xs-12">
                    <div class="myprofile_hybrisheader_sub_txt">Address</div>
                    <div class="myprofile_company_address">
                        {{item?.line1?item?.line1:'N/A'}} &nbsp; </div>
                </div>
                <div class="col-md-7 col-sm-6 col-xs-12">
                    <div class="myprofile_hybrisheader_sub_txt">GS1 Global Location Number</div>
                    <span
                        class="myprofile_company_txt myprofile_company_gln">{{ formatNumber(item?.globalLocationNumber) }}</span>
                </div>
            </div>
        </div>
        <div class="myprofile_editview" *ngIf="toggleEdit">
            <div class="row">
                <div class="col-md-9 col-sm-12 col-xs-12 margin-T10">
                    <div class="myprofile_editheader_txt ">Contact Information &nbsp;&nbsp;&nbsp;<a 
                         (click)="showRequestModal('request')"
                            id="myprofile_request"><span class="myprofile_editheader_txt_sub">Request Changes</span></a>
                    </div>
                </div>

            </div>
            <form  id="myprofile_editForm" [formGroup]="customerRegisterForm" method="post" novalidate="novalidate">
                <div class="row margin-T10">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <label class="myprofile_editcompany_txt" for="Account-no">Account Number</label>
                        <input class="myprofile_input form-control" formControlName="Accountno" id="Account-no" name="Account-no" value="" >
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <label class="myprofile_editcompany_txt" for="company-name">Company Name</label>
                        <input class="myprofile_input form-control" formControlName="companyname" id="company-name" name="company-name" 
                            value="" >
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <label class="myprofile_editcompany_txt" for="locations-count"># of Locations</label>
                        <input class="myprofile_input form-control" formControlName="locationscount" id="locations-count" name="locations-count"
                            value=""  >
                    </div>
                </div>
                <div class="row margin-T20">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <label class="myprofile_editbusinessinfo_txt">Business Information</label>
                        <label class="myprofile_editbusinessinfo_txt_sub">Business Address</label>
                    </div>
                </div>
                <div class="row margin-T10">
                    <div class="col-md-7 col-sm-6 col-xs-12">
                        <label class="myprofile_editcompany_txt" for="myprofile_GLN">GS1 Global Location Number (GLN)<i (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()" class="fa fa-question-circle-o locations_glntool locations_tooltip">
                                <span *ngIf="showtool" class="locations_tooltiptext"> Please enter your 13-digit GLN in this
                                    field.<br><br>For more information on GLNs, please visit the <a [routerLink]="['/help-and-faq']"
                                        target="_blank">flu360 Support</a> page.</span>
                                    </i>
                         </label>
                        <input class="myprofile_Address_input form-control" id="myprofile_GLN"
                            name="orgAddressForm.globalLocationNumber" formControlName="globalLocationNumber" value="" placeholder="_______._____._" maxlength="13">
                    </div>
                </div>
                <div class="row margin-T10">
                    <div class="col-md-7 col-sm-6 col-xs-12">
                        <label class="myprofile_editcompany_txt" for="myprofile_Address1">Address Line 1*</label>
                        <div class="input-group">
                        <input class="myprofile_Address_input form-control" id="myprofile_Address1"
                            name="orgAddressForm.Line1" formControlName="line1" value="">
                            <span class="input-group-addon" *ngIf="getFormControl('line1').touched && (getFormControl('line1').hasError('required'))">
                                <span class="glyphicon glyphicon-exclamation-sign"></span>
                            </span>
                        </div>
                            <ng-container *ngIf="getFormControl('line1').touched && (getFormControl('line1').hasError('required'))">
                                <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                                <label id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter your Address1</label>
                            </ng-container>    
                    </div>
                </div>
                <div class="row margin-T10">
                    <div class="col-md-7 col-sm-6 col-xs-12">
                        <label class="myprofile_editcompany_txt" for="myprofile_Address2">Address Line 2</label>
                        <input class="myprofile_Address_input form-control" id="myprofile_Address2" value=""
                            name="orgAddressForm.line2" formControlName="line2">
                    </div>
                </div>
                <div class="row margin-T10">
                    <div class="col-md-7 col-sm-6 col-xs-12">
                        <label class="myprofile_editcompany_txt" for="myprofile_city">City*</label>
                        <div class="input-group">
                          <input class="myprofile_Address_input form-control" id="myprofile_city"
                            name="orgAddressForm.city" formControlName="city" value="">
                            <span class="input-group-addon" *ngIf="getFormControl('city').touched && (getFormControl('city').hasError('required') || getFormControl('city').hasError('pattern'))">
                                <span class="glyphicon glyphicon-exclamation-sign"></span>
                            </span>
                        </div>
                            <ng-container *ngIf="getFormControl('city').touched && (getFormControl('city').hasError('required') || getFormControl('city').hasError('pattern'))">
                                <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                                <label *ngIf="getFormControl('city').hasError('required')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter your City</label>
                                <label *ngIf="getFormControl('city').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter Only Letters</label>
                            </ng-container>

                    </div>
                </div>
                <div class="row margin-T10 stateZip">
                    <div class="col-md-3 col-sm-6 col-xs-6">
                        <label class="myprofile_editcompany_txt" for="myprofile_state">State*</label>
                        <select class="myprofile_state form-control" formControlName="state" id="myprofile_state">
                            <ng-container *ngFor="let state of statesList">
                                <option [value]="state">{{state}}</option>
                            </ng-container>    

                        </select>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-6">
                        <label class="myprofile_editcompany_txt" for="myprofile_zipcode">zip code*</label>
                        <div class="input-group">
                        <input class="myprofile_Address_input form-control myprofile_zipcode" id="myprofile_zipcode"
                            name="orgAddressForm.postalCode" value="" formControlName="postalCode">
                            <span class="input-group-addon" *ngIf="getFormControl('postalCode').invalid && getFormControl('postalCode').touched">
                                <span class="glyphicon glyphicon-exclamation-sign"></span>
                            </span>
                        </div>
                            <ng-container *ngIf="getFormControl('postalCode').touched && (getFormControl('postalCode').hasError('required') || getFormControl('postalCode').hasError('pattern'))">
                                <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                                <label *ngIf="getFormControl('postalCode').hasError('required')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid US ZIP/Postal code</label>
                                <label *ngIf="getFormControl('postalCode').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid US ZIP/Postal code</label>
                            </ng-container>
                    </div>
                </div>
                <input type="hidden" name="orgAddressForm.addressId" value="SP0000376350">
                <input type="hidden" name="orgAddressForm.modifiedQualifiers" value="" id="modifiedQualifiers"
                    modified-id="orgAddressForm.addressId">
                <div class="row margin-T30">
                    <div class="col-md-5 col-sm-6 col-xs-12"> </div>
                    <div class="col-md-7 col-sm-6 col-xs-12">
                        <div class="col-md-6 col-sm-6 col-xs-6 no-padding">
                            <button (click)="edit()" type="button" class="myprofile_cancel"
                                id="myprofile_cancel">Cancel</button>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 no-padding">
                            <button (click)="submitBusinessDetails()" type="button" class="myprofile_submit" id="myprofile_submit">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>