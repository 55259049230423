import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomtabComponent } from './customtab/customtab.component';
import { AccountsectionComponent } from './accountsection/accountsection.component';
import { UserSectionComponent } from './user-section/user-section.component';
import { PermissionadminComponent } from './permissionadmin/permissionadmin.component';
import { NonadminComponent } from './nonadmin/nonadmin.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';

import { MatTabsModule } from '@angular/material/tabs';
import { CustomMyProfileModule } from '../custom-my-profile/custom-my-profile.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    CustomtabComponent,
    AccountsectionComponent,
    UserSectionComponent,
    PermissionadminComponent,
    NonadminComponent,
   
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormsModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    CustomMyProfileModule,
    ConfigModule.withConfig({
      cmsComponents: {

        SeqirusMyProfileFlexComponent:{
          component:CustomtabComponent
        },
        MyProfileUserDetailsComponent:{
          component:UserSectionComponent
        },
        AccountDetailsComponent:{
          component:AccountsectionComponent
        },
        UserAdminComponent:{
          component:PermissionadminComponent
        },
        UserNonAdminComponent:{
          component:NonadminComponent
        },
        
        
      },
    } as CmsConfig)
  ]
})
export class MyprofileUKModule { }
