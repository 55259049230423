<div class="tools-and-resources">
     <div class="tools_datasearch">
     <!-- Filter by Section -->
     <div class="tools_filtername">
     <div class="tools_filterLabel">Filter by</div>
     <img alt="Filter" src="../../../assets/images/filter.png" width="25" />
     </div>
     <!-- Solution Suite Filter -->
     <div class="tools_filterbox">
     <div class="tools_filterboxparent" (click)="toggleFilter('solution')">
     <div class="tools_filterboxname">
              Solution Suite<span></span>
     </div>
     <span
     class="tools_productarrow glyphicon"
     [ngClass]="{
       'glyphicon-menu-down': !showSolutionFilter,
       'glyphicon-menu-up': showSolutionFilter
     }"
></span>
     <div class="clearfix"></div>
     </div>
     <div class="solutionopen" *ngIf="showSolutionFilter">
     <div class="statussection">
     <div
                class="tools_checkboxparent"
                *ngFor="let solution of ['CLINICAL', 'FINANCIAL', 'OPERATIONAL']"
     >
     <label class="checkbox-button">
     <input
                    type="checkbox"
                    name="solution"
                    [value]="solution"
                    (change)="onCheckboxChange('solution', solution, $event)"
                    class="tools_checkbox"
                  />
     <span class="checkbox-button__control"></span>
     </label>
     <label>{{ solution }}</label>
     </div>
     </div>
     </div>
     </div>
     <!-- Products Filter -->
     <div class="tools_productbox">
     <div class="tools_productboxparent" (click)="toggleFilter('product')">
     <div class="tools_productboxname">
              Products<span></span>
     </div>
     <span
              class="tools_productarrow glyphicon"
              [ngClass]="{
                'glyphicon-menu-down': !showProductFilter,
                'glyphicon-menu-up': showProductFilter
              }"
     ></span>
     <div class="clearfix"></div>
     </div>
     <div class="productopen" *ngIf="showProductFilter">
     <div class="statussection">
     <div
                class="tools_checkboxparent"
                *ngFor="let product of ['FLUAD', 'FLUCELVAX', 'AFLURIA']"
     >
     <label class="checkbox-button">
     <input
                    type="checkbox"
                    name="product"
                    [value]="product"
                    (change)="onCheckboxChange('product', product, $event)"
                    class="tools_checkbox"
                  />
     <span class="checkbox-button__control"></span>
     </label>
     <label>{{ product }}</label>
     </div>
     </div>
     </div>
     </div>
     </div>
    
          
     
  
  ⁠⁠⁠⁠⁠
  <!-- Resource Tiles -->
  <div class="resourceFilterContainer">
  <table
     id="resourcefilterTableForNewPage"
     class="dataTable no-footer"
     role="grid"
     *ngIf="details.length"
  >
  <thead>
  
  </thead>
  <tbody>
  <!-- Dynamically Render Tiles -->
  <tr *ngFor="let resource of details">
  <td>
  <div class="tools_img">
  <img [alt]="resource.tileDescription" [src]="getImage(resource.tileImage)" >
  </div>
  <div class="tools_solutiontype">{{ resource.tileType }}</div>
  <div class="tools_title" [innerHTML]="resource.tileHeadLine"></div>
  <div class="tools_text">{{ resource.tileDescription }}</div>
  <!-- <a [routerLink]="[resource.tileLink]" class="tools_accessparent"> -->
     <!-- <a [routerLink]="'/p/'+resource.tileLink" class="tools_accessparent"> -->
          <a [routerLink]="'p/flu-clinic-resources-toolkit'"><div class="tools_accessparent">
  <div class="tools_accesstext">{{ resource.tileLinkLabel }}</div>
  <img class="arrow" alt="arrow" src="../../../assets/images/blackarrow.png" width="19" />
  </div>
  </a>
  </td>
  </tr>
  </tbody>
  </table>
  <!-- Pagination Section -->
  <div
     class="dataTables_paginate paging_full_numbers"
     *ngIf="totalPages > 1"
  >
  <!-- First Page Button -->
  <a
       class="paginate_button first"
       [class.disabled]="currentPage === 0"
       (click)="goToPage(0)"
  >
  <img alt="Navigate to first page" src="../../../assets/images/pagination-prev-btn.svg" />
  </a>
  <!-- Previous Page Button -->
  <a
       class="paginate_button previous"
       [class.disabled]="currentPage === 0"
       (click)="goToPage(currentPage - 1)"
  >
  <img alt="Navigate to previous page" src="../../../assets/images/pagination-double-prev-arrow-icon.svg" />
  </a>
  <!-- Page Numbers -->
  <span>
  <a
         *ngFor="let page of [].constructor(totalPages); let i = index"
         class="paginate_button"
         [class.current]="i === currentPage"
         (click)="goToPage(i)"
  >
         {{ i + 1 }}
  </a>
  </span>
  <!-- Next Page Button -->
  <a
       class="paginate_button next"
       [class.disabled]="currentPage === totalPages - 1"
       (click)="goToPage(currentPage + 1)"
  >
  <img alt="Navigate to next page" src="../../../assets/images/pagination-next-btn.svg" />
  </a>
  <!-- Last Page Button -->
  <a
       class="paginate_button last"
       [class.disabled]="currentPage === totalPages - 1"
       (click)="goToPage(totalPages - 1)"
  >
  <img alt="Navigate to last page" src="../../../assets/images/pagination-double-next-arrow-icon.svg" />
  </a>
  </div>
  </div>
<!-- </div> -->
  ⁠⁠⁠⁠⁠⁠⁠</div>
  