import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrl: './reference.component.scss'
})
export class ReferenceComponent {
  
  constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>) {
  }
  object1:Observable<any> = this.bannerComponent.data$; 
}
