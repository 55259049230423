import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CmsConfig, ConfigModule } from "@spartacus/core";
import { PrescribingInformationUkComponent } from "./prescribing-information-uk.component";

@NgModule({
    declarations: [
        PrescribingInformationUkComponent
    ],
    imports:[
        CommonModule,
        ConfigModule.withConfig({
            cmsComponents:{
                SeqirusPrescribingInfoComponent:{
                    component: PrescribingInformationUkComponent
                },
            }
        }as CmsConfig)
    ]
})

export class PrescribingInformationUkModule { }