
<div *ngIf="helpNode | async as item" class="helpWidget ">
    <ng-container *ngIf="item.uid === 'HomepageUserGuidePDFComponent'">
        <span class="userguidegrey">{{item.headline}} </span>
        <a class="userguideanchor anchorunderline" [href]="getImage(item.media)" target="_blank"><span
                class="userguideblack">{{item.h2content}}</span>
            <div class="global_blackarrow"></div>
        </a>
    </ng-container>
</div>
