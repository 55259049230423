import { Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-first-comp',
  templateUrl: './first-comp.component.html',
  styleUrl: './first-comp.component.scss'
})
export class FirstCompComponent {
  topheadline: Observable<any> = this.bannerComponent.data$;
  
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  constructor(
      public bannerComponent: CmsComponentData<CmsBannerComponent>,
      protected config: OccConfig,
      private userInfoService:UserInfoService) {}
  
      userLoggedIn: boolean = false;
      ngOnInit(){
        this.userInfoService.userLoggedIn$.subscribe(res=>{
          console.log('login check',res);
          this.userLoggedIn = res;
        })
      }

}
