import { ChangeDetectorRef, Component, HostListener, Renderer2 } from '@angular/core';
import { UserInfoService } from './spartacus/services/user-info.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavigationEnd, Router } from '@angular/router';
import { BaseSiteService } from '@spartacus/core';
import { state } from '@angular/animations';
import { filter } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'flu360_csf';
  userData: any;
  isMobileWidth: boolean=false;
  currentSite: string='';
  currentRoute: any;

  constructor(private userInfoService: UserInfoService,private cookieService:CookieService,private deviceDetectorService:DeviceDetectorService,
    private baseSiteService:BaseSiteService,private router:Router,private cdr:ChangeDetectorRef,private renderer:Renderer2) { }

  ngOnInit() {
    this.userInfoService.fetchUserData();  
    
    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite=site;
      if(site == 'seqirusb2b-uk'){
        this.checkWindowWidth(site);
        
      }
    })

    this.router.events.subscribe((event) => {
        
      if(event instanceof NavigationEnd){
        if(event.url.includes('login')){
        if(this.currentSite != 'seqirusb2b-flu360' && (!this.cookieService.check('visitor_type') || this.cookieService.get('visitor_type') != 'healthcare')){
          
          sessionStorage.setItem('navigateToUrl','login');
          this.router.navigate(['/']);
         
      }
      }
      }
  });
    
  }

  @HostListener('window:resize',['$event'])
  onResize(event:any){
    this.checkWindowWidth(this.currentSite);
  }

  checkWindowWidth(site:String) {
   const width = window.innerWidth;
   this.isMobileWidth = width < 1024;
   if(site == 'seqirusb2b-uk'){
    if(this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()){
      this.router.navigate(['/mobileUKRedirection']);
    }
    else if(this.deviceDetectorService.isDesktop()){
      if(this.isMobileWidth){
        this.renderer.setStyle(document.querySelector('cx-storefront'),'display','none');
       }
       else{
        this.renderer.removeStyle(document.querySelector('cx-storefront'),'display');
    
       }
    }
  }
   
   this.cdr.detectChanges()
  }
//   ngAfterViewInit(){
    
//         //document.getElementById('iframeHolder')?.style.display = none
//         // setTimeout(() => {
//         //   console.log(document.getElementById('iframePopup'));
//         //   if(document.getElementById('iframePopup') as HTMLElement  &&  localStorage.getItem('isCancelled') === 'true'){
//         //     (document.getElementById('iframePopup') as HTMLElement).style.visibility = 'hidden'
//         //     }
//         // }, 5000);
//         setTimeout(() => {
//           console.log(document.getElementById('iframeHolder'));
//           if(document.getElementById('iframeHolder') as HTMLElement  &&  localStorage.getItem('isCancelled') === 'true'){
//             (document.getElementById('iframeHolder') as HTMLElement).style.visibility = 'hidden'
//             }
//         }, 5000);
//     console.log('ngafterview')
//   }
//   ngOnChanges(){
//     console.log('on changes')
//   }
// ngDoCheck(){
  
//   console.log('do check')
// }
// ngAfterContentInit(){
//   console.log('after content')
// }
// ngAfterContentChecked(){
//   console.log('content checked')
// }

// ngAfterViewChecked(){
//   console.log('view checked')
// }
// ngOnDestroy(){
//   console.log('on destroy')
// }



}

