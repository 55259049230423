import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exit-popup',
  templateUrl: './exit-popup.component.html',
  styleUrl: './exit-popup.component.scss'
})
export class ExitPopupComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,private cdr: ChangeDetectorRef,private userInfoService: UserInfoService, private router: Router) {

  }
  exitPopupData: any;
  showExitPopUp: boolean = false;
  showFeedbackContent: boolean = false;
  ngOnInit() {
    this.component.data$.subscribe((data:any)=> {
        this.exitPopupData = data;
        this.cdr.detectChanges();
    })
    this.userInfoService.getPopValue().subscribe((val: boolean) => {
      this.showExitPopUp = val;
      this.cdr.detectChanges();
    })
  }
  handleOnCloseButton() {
    this.userInfoService.setPopUpValue(false);
  }
  handleOnExitBtnClick() {
    this.showFeedbackContent = true;
  }
  handleOnSkipBtnClick() {
    this.showFeedbackContent = false;
    this.userInfoService.setPopUpValue(false);
    this.router.navigate(['/my-account']);
  }
}
