<div *ngIf="isLoading" class="loading">
    <div class="loading_txt">
    <div class="load_txt">{{'orgLocation.loading' | cxTranslate}}</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<form class="locations_addform" id="locations_addform" (ngSubmit)="addNewLocation()" [formGroup]="addShippingLocForm">
    <div class="locations_addhead">{{'orgLocation.shippingcontactuk' | cxTranslate}} <i class="fa-regular fa-circle-question locations_qm locations_tooltip"><span class="locations_tooltiptext">
            {{'orgLocation.shipLoctooltip' | cxTranslate}}</span>
        </i>
        </div>
    <div class="row">
        
        <div class="col-xs-12 col-md-6 form-group has-feedback">
            <label for="loc_firstname">{{'orgLocform.shipFname' | cxTranslate}}</label>
            <div class="input-group">
                <input class="form-control" id="loc_firstname" name="loc_firstname"  formControlName="loc_firstname"/>
                <span class="input-group-addon" *ngIf=" addShippingLocForm.get('loc_firstname')?.touched &&  addShippingLocForm.get('loc_firstname')?.invalid">
                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_firstname') && addShippingLocForm.get('loc_firstname')?.touched && !addShippingLocForm.get('loc_firstname')?.hasError('required') && addShippingLocForm.get('loc_firstname')?.hasError('invalidName')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_firstname') && addShippingLocForm.get('loc_firstname')?.touched && addShippingLocForm.get('loc_firstname')?.hasError('required')">{{'orgLocform.errorFirstName' | cxTranslate}}</div>
        </div>
        <div class="col-xs-12 col-md-6 form-group has-feedback">
            <label for="loc_lastname">{{'orgLocform.shipLname' | cxTranslate}}</label>
            <div class="input-group">
                <input class="form-control" id="loc_lastname" name="loc_lastname" formControlName="loc_lastname"/>
                <span class="input-group-addon" *ngIf=" addShippingLocForm.get('loc_lastname')?.touched && addShippingLocForm.get('loc_lastname')?.invalid">
                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_lastname')?.touched && !addShippingLocForm.get('loc_lastname')?.hasError('required') && addShippingLocForm.get('loc_lastname')?.hasError('invalidName')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_lastname')?.touched && addShippingLocForm.get('loc_lastname')?.hasError('required')">{{'orgLocform.errorLastName' | cxTranslate}}</div>
        </div>
        <div class="row" style="margin:0">
        <div class="col-xs-12 col-md-6 form-group">
            <label for="loc_email">{{'orgLocform.shipemail' | cxTranslate}}</label>
            <div class="input-group">
                <input class="form-control" id="loc_email" name="loc_email" [ngClass]="{'is-invalid': addShippingLocForm.get('loc_email')?.invalid}" formControlName="loc_email"/>
                <span class="input-group-addon" *ngIf=" addShippingLocForm.get('loc_email')?.touched &&  addShippingLocForm.get('loc_email')?.invalid">
                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_email')?.touched && addShippingLocForm.get('loc_email')?.hasError('pattern')">{{'orgLocform.errorValidEmail' | cxTranslate}}</div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_email')?.touched && addShippingLocForm.get('loc_email')?.hasError('required')">{{'orgLocform.errorEmail' | cxTranslate}}</div>
        </div>
        </div>
        
        <div class="col-xs-7 col-md-6 form-group">
            <label for="loc_phone">{{'orgLocform.shipPhn' | cxTranslate}}</label>
            <div class="input-group">
                <input class="form-control" id="loc_phone" maxlength="11" name="loc_phone" [ngClass]="{'is-invalid': addShippingLocForm.get('loc_phone')?.invalid}" formControlName="loc_phone"/>
                <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_phone')?.touched &&  addShippingLocForm.get('loc_phone')?.invalid">
                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_phone')?.touched && addShippingLocForm.get('loc_phone')?.hasError('pattern')">{{'orgLocform.errorValidPhnuk' | cxTranslate}}</div>
                            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_phone')?.touched && addShippingLocForm.get('loc_phone')?.hasError('required')">{{'orgLocform.errorValidPhnuk' | cxTranslate}}</div>
                            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_phone')?.touched && !addShippingLocForm.get('loc_phone')?.hasError('pattern') && addShippingLocForm.get('loc_phone')?.hasError('minlength')">{{'orgLocform.errorValidPhnuk' | cxTranslate}}</div>
                            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_phone')?.touched && !addShippingLocForm.get('loc_phone')?.hasError('pattern') && addShippingLocForm.get('loc_phone')?.hasError('maxlength')">{{'orgLocform.errorValidPhnuk' | cxTranslate}}</div>
        </div>
        <div class="col-xs-5 col-md-3 form-group">
            <label for="loc_ext">{{'orgLocform.shipExt' | cxTranslate}}</label>
            <div class="input-group">
                <input class="form-control" id="loc_ext" name="loc_ext" [ngClass]="{'is-invalid': addShippingLocForm.get('loc_ext')?.invalid}" formControlName="loc_ext"/>
                <span class="input-group-addon" *ngIf=" addShippingLocForm.get('loc_ext')?.invalid">
                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_ext')?.touched && !addShippingLocForm.get('loc_ext')?.hasError('pattern') && addShippingLocForm.get('loc_ext')?.hasError('maxlength')">{{'orgLocform.errorExt' | cxTranslate}}</div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_ext')?.touched  && addShippingLocForm.get('loc_ext')?.hasError('pattern')">{{'orgLocform.errorValidExt' | cxTranslate}}</div>
        </div>
    </div>
    <div class="locations_addhead">{{'orgLocform.shipAddruk' | cxTranslate}}</div>
    <div class="row">
        <div class="col-xs-12 col-md-6 form-group"><label for="loc_orgname">{{'orgLocform.shipOrgNameuk' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_orgname" name="loc_orgname" class="form-control" formControlName="loc_orgname"/>
            <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_orgname')?.touched && addShippingLocForm.get('loc_orgname')?.invalid">
                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
            </span> 
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_orgname')?.touched && addShippingLocForm.get('loc_orgname')?.hasError('pattern')">{{'orgLocform.errorValidOrgname' | cxTranslate}}</div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_orgname')?.touched && addShippingLocForm.get('loc_orgname')?.hasError('required')">{{'orgLocform.errorOrgName' | cxTranslate}}</div>
        </div>
        
        <div class="col-xs-12"><label for="loc_addlp">{{'orgLocform.shipAddrLookup' | cxTranslate}}</label><i class="fa fa-search loc_addsearchicon"></i><input type="text" id="add_AddressLookup" name="add_AddressLookup" formControlName="add_AddressLookup"/></div>
        <div class="col-xs-12 col-md-6 form-group"><label for="loc_add1">{{'orgLocform.shipAddrLine1' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_add1" name="loc_add1" formControlName="loc_add1" class="form-control" />
            <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_add1')?.touched && addShippingLocForm.get('loc_add1')?.invalid">
                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
            </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_add1')?.touched && addShippingLocForm.get('loc_add1')?.hasError('required')">{{'orgLocform.errorAddr' | cxTranslate}}</div>
        </div>
        <div class="col-xs-12 col-md-6 form-group"><label for="loc_add2">{{'orgLocform.shipAddrLine2' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_add2" name="loc_add2" formControlName="loc_add2" class="form-control"/></div></div>
        <div class="col-xs-7 col-md-6 form-group"><label for="loc_city">{{'orgLocform.shipCity' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_city" name="loc_city" formControlName="loc_city" class="form-control"/>
            <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_city')?.touched && addShippingLocForm.get('loc_city')?.invalid">
                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
            </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_city')?.touched && addShippingLocForm.get('loc_city')?.hasError('pattern')">{{'orgLocform.errorvalidCity' | cxTranslate}}</div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_city')?.touched && addShippingLocForm.get('loc_city')?.hasError('required')">{{'orgLocform.errorCity' | cxTranslate}}</div>
        </div>
        
        <div class="col-xs-7 col-md-3 form-group"><label for="loc_zip">{{'orgLocform.shipZipuk' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_zip" name="loc_zip" class="form-control" formControlName="loc_zip"/>
            <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_zip')?.touched && addShippingLocForm.get('loc_zip')?.invalid">
                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
            </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_zip')?.touched && addShippingLocForm.get('loc_zip')?.hasError('pattern')">{{'orgLocform.errorzipcodeuk' | cxTranslate}}</div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_zip')?.touched && addShippingLocForm.get('loc_zip')?.hasError('required')">{{'orgLocform.errorzipcodeuk' | cxTranslate}}</div>
        </div>
        <div class="col-xs-12 col-md-3 form-group"><label for="loc_country">{{'orgLocform.shipCountry' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_country" name="loc_country" class="form-control" formControlName="loc_country">
            <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_country')?.touched && addShippingLocForm.get('loc_country')?.invalid">
                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
            </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_country')?.touched && !addShippingLocForm.get('loc_country')?.hasError('required') && addShippingLocForm.get('loc_country')?.hasError('invalidName')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_country')?.touched && addShippingLocForm.get('loc_country')?.hasError('required')">{{'orgLocform.errorcountry' | cxTranslate}}</div>
        
        </div>					
    </div>
    <div class="locations_addhead">{{'orgLocation.licInfouk' | cxTranslate}}</div>
    <div class="row">
        <div class="col-xs-12 col-md-6 form-group"><label for="loc_licname">{{'orgLocform.shipLicNameuk' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_licname" name="loc_licname" formControlName="loc_licname" class="form-control"/>
            <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_licname')?.touched && addShippingLocForm.get('loc_licname')?.invalid">
                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
            </span>
        </div>
        <div class="error help-block" *ngIf="addShippingLocForm.get('loc_licname')?.touched && !addShippingLocForm.get('loc_licname')?.hasError('required') && addShippingLocForm.get('loc_licname')?.hasError('invalidName')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
        <div class="error help-block" *ngIf="addShippingLocForm.get('loc_licname')?.touched && addShippingLocForm.get('loc_licname')?.hasError('required')">{{'orgLocform.errorValidLicName' | cxTranslate}}</div>
     
        </div>
        <div class="col-xs-12 col-md-6 form-group"><label for="loc_licnum">{{'orgLocform.shipLicNumuk' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_licnum" maxlength="7" name="loc_licnum" formControlName="loc_licnum" class="form-control"/>
            <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_licnum')?.touched && addShippingLocForm.get('loc_licnum')?.invalid">
                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
            </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_licnum')?.touched && !addShippingLocForm.get('loc_licnum')?.hasError('required') && addShippingLocForm.get('loc_licnum')?.hasError('invalidLicNum')">{{'orgLocform.errorValidLicNumuk' | cxTranslate}}</div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_licnum')?.touched && addShippingLocForm.get('loc_licnum')?.hasError('required') || addShippingLocForm.get('loc_licnum')?.hasError('pattern')">{{'orgLocform.errorValidLicNumuk' | cxTranslate}}</div>
        
        </div>
        <div class="col-xs-12 col-md-6 form-group"><label for="loc_nhsnumb">{{'orgLocform.nhsCodeuk' | cxTranslate}}<sup></sup></label><div class="input-group"><input type="text" id="loc_nhscode" formControlName="loc_nhsnumb" name="loc_nhsnumb" class="form-control">
            <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_nhsnumb')?.touched && addShippingLocForm.get('loc_nhsnumb')?.invalid">
                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
            </span>
            </div>
            <div class="error help-block" *ngIf="addShippingLocForm.get('loc_nhsnumb')?.touched && addShippingLocForm.get('loc_nhsnumb')?.value && addShippingLocForm.get('loc_nhsnumb')?.hasError('nhserror1')">{{'orgLocform.errorNhsGpadd' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="addShippingLocForm.get('loc_nhsnumb')?.touched && addShippingLocForm.get('loc_nhsnumb')?.value && addShippingLocForm.get('loc_nhsnumb')?.hasError('nhserror2')">{{'orgLocform.errorNhsPharmaadd' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="addShippingLocForm.get('loc_nhsnumb')?.touched && addShippingLocForm.get('loc_nhsnumb')?.value && addShippingLocForm.get('loc_nhsnumb')?.hasError('nhserror3')">{{'orgLocform.errorNhsAlladd' | cxTranslate}}</div>
                    
        </div>
        
        		
    </div>

    <div class="row locations_addlocsection">
        <button type="submit" class="locations_addloc">{{'orgLocation.reqLocLabel' | cxTranslate}}</button>
    </div>

</form>
<!--Thank you Modal-->
<div *ngIf="showThankyouModal">
<div id="locations_tymodal" class="modal fade in show" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body row">
                <h4 class="modal-title locations_tyheader col-xs-9">Thank You for Requesting a New Location</h4>
                <div class="col-xs-3"><span class="locations_closebtn" data-dismiss="modal" (click)="onClose()">&times;</span></div>
                <p class="locations_tysubheader col-xs-12">We will review and verify your new location information, within 5 business days.</p>
            </div>
        </div>
    </div>
</div>
</div>
<!--Thank you modal end-->
