import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-second-banner',
  templateUrl: './second-banner.component.html',
  styleUrl: './second-banner.component.scss'
})
export class SecondBannerComponent {
  node:Observable<any> = this.bannerComponent.data$; 
  constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>) {
  }

getImage(image: any): string {
  return this.config.backend?.occ?.baseUrl + image.desktop.url;
}
}
