<div id="asm-login-container">
  <form [formGroup]="csAgentLoginForm" (ngSubmit)="onSubmit()" *ngIf="!csAgentTokenLoading">
    <label id="asm-email-wrapper">
      <input required="true" type="text" formControlName="userId"
        [attr.aria-label]="'asm.loginForm.userId.label' | cxTranslate"
        [attr.placeholder]="'asm.loginForm.userId.label' | cxTranslate" />
    </label>
    <label id="asm-password-wrapper">
      <input required="true" type="password" formControlName="password"
        [attr.aria-label]="'asm.loginForm.password.label' | cxTranslate"
        [attr.placeholder]="'asm.loginForm.password.label' | cxTranslate" />
    </label>
    <button type="submit" [disabled]="!csAgentLoginForm.valid">
      {{ 'asm.loginForm.submit' | cxTranslate }}
    </button>
  </form>
</div>
<cx-dot-spinner *ngIf="csAgentTokenLoading" aria-hidden="false"
  [attr.aria-label]="'common.loading' | cxTranslate"></cx-dot-spinner>