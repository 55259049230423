<section *ngIf="concentDetails$ | async as item" class="col-xs-12 col-sm-12 myprofile_consentsection myprofile_consentsectionUS no-padding">
        <div class="myprofile_consentcontainer">
          <div class="myprofile_consentheader col-md-9 col-xs-8 no-padding">Contact Permission</div>
          <div class="col-md-3 col-xs-4" (click)="edit()">
                <div *ngIf="!toggleEdit" class="myprofileconsent_editbtn" id="myprofileconsent_editbtn">Edit<img src="/assets/images/edit.png"  width="20" alt="Edit"></div>
            </div>
            <div class="clearboth"></div>
          <div class="myprofile_consentcontent">CSL Seqirus can contact me with additional industry news, business updates, and product information.</div>
          <div class="myprofile_radiocontainer myprofile_radiocontainerView" *ngIf="!toggleEdit">
            <label class="myprofile_radio">
                 <input type="radio" name="myprofile_radiobtn" class="myprofile_radiobtn" id="myprofile_radiobtn" checked="checked">
                 <span class="myprofile_radiobtncontrol consent_radiobtncontrol"></span>
                 <label for="myprofile_radiobtn1" class="myprofile_radioText">
                  {{!item?.isConsentGranted?'No, CSL Seqirus may not contact me.':'Yes, CSL Seqirus may contact me.'}}</label>
             </label>
          </div>
           <div class="myprofile_editcontainer" *ngIf="toggleEdit">
            <div class="myprofile_radiocontainer myprofile_radiocontaineryes">
                <label class="myprofile_radio">
                    <input (click)="getValue(true)" [checked]="item?.isConsentGranted" type="radio" name="consent_radiobtn" class="myprofile_radiobtn myprofile_consentRadiobtn" id="myprofile_radiobtn1" value="true">
                    <span class="myprofile_radiobtncontrol consent_radiobtncontrol"></span>
                    <label for="myprofile_radiobtn1">Yes, CSL Seqirus may contact me.</label>
                </label>
              </div>
            <div class="myprofile_radiocontainer myprofile_radiocontainerno">
                <label class="myprofile_radio">
                    <input (click)="getValue(false)" [checked]="!item?.isConsentGranted" type="radio" name="consent_radiobtn" class="myprofile_radiobtn myprofile_consentRadiobtn" id="myprofile_radiobtn2" value="false">
                    <span class="myprofile_radiobtncontrol consent_radiobtncontrol"></span>
                    <label for="myprofile_radiobtn2">No, CSL Seqirus may not contact me.</label>
                </label>
              </div>
            <button type="button" class="consentupdatebtn" (click)="edit()">Cancel</button>
            <button type="button" class="consentupdatebtn submit_consent" (click)="submitConcent()">Submit</button>
          </div>
        </div>
    </section>