<ng-container *ngIf="!userLoggedIn || (userLoggedIn && (!userInfo?.webEnabled))">
   <div class="main helpnfaqcontainer" id="helpsection">
      <div *ngIf="secondnode | async as data" class="help-faq-container">
         <div class="col-xs-12 help_searchheader">{{data.header1}}</div>
         <div class="col-xs-12 help_searchbar">
            <i class="fa fa-search" aria-hidden="true"></i>
            <input type="text" placeholder="Search frequently asked questions" id="help_searchbox"
               [(ngModel)]="searchvalue" (keydown.enter)="applyFilter(searchvalue)">
            <div id="help_searchbtn" (click)="applyFilter(searchvalue)">Go</div>
         </div>
         <!--This is to show no results when user searches something invalid-->
         <div *ngIf="shownoresult">
            <div class="col-xs-12 help_noresultsection" *ngIf="secondnode | async as data">
               <div class="help_noresultheader">{{data.subHeadLine}}</div>
               <div class="help_noresultcontent" [innerHTML]="data.subContent"></div>
            </div>
         </div>
         <!--when user searches something valid respective accordions will come-->
         <div *ngIf="searchresults && !shownoresult">
            <div class="col-xs-12 help_searchresults">Search Results</div>
            <div class="col-xs-12 search_accordion">
               <div id="searchAccordion">
                  <div class="dropdown-item">
                     <div *ngFor="let item4 of filteredData; let i = index" [ngClass]="{'show': issearchActive(i)}">
                        <div class="dropdown-outercontainer">
                           <button class="simple-dropdown-button" type="button" (click)="toggleSearchAccordion(i)">
                              <span class="accordion-text">{{item4.text2}}</span>
                              <span class="arrow-icon">
                                 <i class="fa fa-chevron-down"></i>
                              </span>
                           </button>
                           <div id="collapse{{i}}" class="dropdown-content" [ngClass]="{'show': issearchActive(i)}">
                              <div class="dropdown-body">
                                 <div class="inline-div" *ngIf="item4?.text1" [innerHTML]="item4?.text1"></div>
                                 <div class="inline-div" *ngIf="item4?.title">
                                    &nbsp;
                                    <a (click)="openModal()">"{{item4?.title}}"
                                       <span class="linkbtton faBlack"><i class="fa fa-external-link"
                                             aria-hidden="true"></i></span>
                                    </a>
                                 </div>
                                 <div class="inline-div help_searchsection" [innerHTML]="item4.content"></div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div *ngIf="!shownoresult && !searchresults">
            <div class="col-xs-12 help_sectionbtns">
               <div class="helpsectionbtn" id="helpbtn_1" (click)="showAccordion(0)">
                  <div class="helpbtnicons" id="helpbtnicon1" role="img" aria-label="White general support gear icon">
                  </div>
                  <div>General Support</div>
               </div>
               <div class="helpsectionbtn" id="helpbtn_2" (click)="showAccordion(1)">
                  <div class="helpbtnicons" id="helpbtnicon2"></div>
                  <div>Creating an Account</div>
               </div>
               <div class="helpsectionbtn" id="helpbtn_8" (click)="showAccordion(2)">
                  <div class="helpbtnicons" id="helpbtnicon8" role="img"
                     aria-label="Person outline account icon in white"></div>
                  <div>Medical Information</div>
               </div>
            </div>
            <div class="col-xs-12 help_accordion">
               <div id="faqDropdown">
                  <div *ngFor="let item2 of mergedObject1; let i = index"
                     [ngClass]="{'show': activeAccordion === null || activeAccordion === i}">
                     <h3 *ngIf="activeAccordion === null || activeAccordion === i">{{item2.faqTitleType}}</h3>
                     <div class="dropdown-item" *ngIf="activeAccordion === null || activeAccordion === i">
                        <div *ngFor="let item3 of mergedObject2; let j = index">
                           <div *ngIf="item3.parentId === item2.uid" class="dropdown-outercontainer">
                              <button class="simple-dropdown-button" [ngClass]="{'active': isActive(i, j)}"
                                 type="button" (click)="toggleDropdown(i, j)">
                                 <span class="accordion-text">{{item3.text2}}</span>
                                 <span class="arrow-icon">
                                    <i class="fa fa-chevron-down"></i>
                                 </span>
                              </button>
                              <div id="content{{i}}-{{j}}" class="dropdown-content"
                                 [ngClass]="{'show': isActive(i, j)}">
                                 <div class="outer-container dropdown-body">
                                    <div class="inline-div" *ngIf="item3?.text1" [innerHTML]="item3?.text1"></div>
                                    <div class="inline-div" *ngIf="item3?.title">
                                       &nbsp;
                                       <a (click)="openModal()">"{{item3?.title}}"
                                          <span class="linkbtton faBlack"><i class="fa fa-external-link"
                                                aria-hidden="true"></i></span>
                                       </a>
                                    </div>
                                    <div class="help_searchsection inline-div" [innerHTML]="item3.content"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="help_seeallsection" class="col-xs-12" *ngIf="seallbtn">
                     <button id="help_seeallbtn" (click)="sealltopics()">
                        See All Topics
                     </button>
                  </div>
               </div>
            </div>
         </div>

      </div>
      <!--the following is popup data-->
   </div>
   <div *ngIf="popupdata">
      <div class="commonleaving_abandonedPop modal  fade" [ngClass]="{'show': isModalOpen}" role="dialog"
         data-backdrop="static" data-keyboard="false" id="commonleaving_abandonedPop" tabindex="-1">
         <div class="modal-body knowledgeHub_abandonedPopContent">
            <div class="knowledgeHub_abandonedPopHeader">
               <div class="knowledgeHub_abandonedPopTitle">{{popupdata.headline}}</div>
               <div class="knowledgeHub_abandonedPopClose" (click)="closeModal()">X</div>
               <div class="clearboth"></div>
            </div>
            <div class="knowledgeHub_abandonedPopText">
               <p [innerHTML]="popupdata.message1"></p>
               <p [innerHTML]="popupdata.message2"></p>
            </div>
            <div class="knowledgeHub_abandonedPopBtns">
               <button class="knowledgeHub_abandonedPopBtn commonleave_popContBtn">
                  <a href="{{popupdata.link}}">{{popupdata.label1}}</a>
               </button>
               <button class="knowledgeHub_abandonedPopBtn knowledgeHub_popCanceltBtn"
                  (click)="closeModal()">{{popupdata.label2}}
               </button>
            </div>
            <div class="knowledgedocnumberUk">{{popupdata.jobBagCode}}</div>
         </div>
      </div>
   </div>
</ng-container>