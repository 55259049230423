import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { OccEndpointsService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {
  userId: string='';


  constructor(public http: HttpClient) {
    let authData = localStorage.getItem('spartacus⚿⚿auth');
    if(authData){
      this.userId = JSON.parse(authData).userId;
    }
  }
  /**
     * Handles error
     * @template T
     * @param [operation]
     * @param [result]
     * @returns
     */
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(
        `Api status code: ${error.status}`,
        `Api status message : ${error.message}`
      );

      //console.log('ERRORRR : ', error);

      let errorMessage = '';
      if (error.error && error.error.errors) {
        for (var key in error.error.errors) {
          if (error.error.errors.hasOwnProperty(key)) {
            for (var j = 0; j < error.error.errors[key].length; j++) {
              errorMessage += error.error.errors[key][j];
            }
          }
        }
      } else {
        errorMessage = error.error.message;
      }

      error.error.message = errorMessage;
      return throwError(error);
    };
  }
  getApiService(url: string, params: any) {
    return this.http.get(url, { params: params })
  }

  //Patch API call
  patchAPICall(requestData: any) {
    let authData = localStorage.getItem('spartacus⚿⚿auth');
    let headers: HttpHeaders = new HttpHeaders();
    let token = '';
    if (authData) {
      token = JSON.parse(authData).token.access_token;
      headers = headers.append('Authorization', `Bearer ${token}`)
    }

    if (requestData.contentType) {
      headers = headers.append('Accept', requestData.contentType);
    } else {
      headers = headers.append('Accept', 'application/json');
    }
    let params = new HttpParams();
    for (const key in requestData.data) {
      if (requestData.data.hasOwnProperty(key)) {
        params = params.append(key, requestData.data[key]);
      }
    }
    return this.http.patch<any>(requestData.url, requestData.data, { headers, params })
      .pipe(
        catchError(this.handleError('error ', []))
      );
  }

  uploadPostCall(url: string, data: any) {
    let authData = localStorage.getItem('spartacus⚿⚿auth');
    let headers: HttpHeaders = new HttpHeaders();
    let token = '';
    if (authData) {
      token = JSON.parse(authData).token.access_token;
      headers = headers.append('Authorization', `Bearer ${token}`)
    }

    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(url, data, { headers })
      .pipe(
        catchError(this.handleError('error ', []))
      );
  }
  // Post API Call
  postAPICall(requestData: any) {
    //console.log(requestData);
    let authData = localStorage.getItem('spartacus⚿⚿auth');
    let headers: HttpHeaders = new HttpHeaders();
    let token = '';
    if (authData) {
      token = JSON.parse(authData).token.access_token;
      if(token){
      headers = headers.append('Authorization', `Bearer ${token}`)
      }
    }
    if (requestData.contentType) {
      headers = headers.append('Accept', requestData.contentType);

    } else {
      headers = headers.append('Accept', 'application/json');
    }
    let params = new HttpParams();
    if (requestData.paramsData) {
      for (const key in requestData.paramsData) {
        if (requestData.paramsData.hasOwnProperty(key)) {
          params = params.append(key, requestData.paramsData[key]);
        }
      }
    }
    return this.http.post<any>(requestData.url, requestData.data,{headers,params,responseType:requestData.responseType},)
      .pipe(
        catchError(this.handleError('error ', []))
      );
  }
  getAPICallNoAuth(requestData: any) {
    // console.log(requestData)
    let headers: HttpHeaders = new HttpHeaders();
    if(requestData.token){
      headers = headers.append('Authorization', `Bearer ${requestData.token}`)
    }
    if (requestData.contentType) {
      headers = headers.append('Accept', requestData.contentType);
    } else {
      headers = headers.append('Accept', 'application/json');
    }
    let params = new HttpParams();
    if (requestData.paramsData) {
      for (const key in requestData.paramsData) {
        if (requestData.paramsData.hasOwnProperty(key)) {
          params = params.append(key, requestData.paramsData[key]);
        }
      }
    }

    return this.http.get<any>(requestData.url, { headers, params,responseType:requestData.responseType })
      .pipe(
        catchError(this.handleError('error ', []))
      );
  }
  postAPICallNoAuth(requestData: any) {
    //console.log(requestData)
    let headers: HttpHeaders = new HttpHeaders();
    if(requestData.token){
      headers = headers.append('Authorization', `Bearer ${requestData.token}`)
    }
    if (requestData.contentType) {
      headers = headers.append('Accept', requestData.contentType);
    } else {
      headers = headers.append('Accept', 'application/json');
    }
    let params = new HttpParams();
    if (requestData.paramsData) {
      for (const key in requestData.paramsData) {
        if (requestData.paramsData.hasOwnProperty(key)) {
          params = params.append(key, requestData.paramsData[key]);
        }
      }
    }

    return this.http.post<any>(requestData.url, requestData.data, { headers, params })
      .pipe(
        catchError(this.handleError('error ', []))
      );
  }
  // Get API Call
  getAPICall(requestData: any) {
    let authData = localStorage.getItem('spartacus⚿⚿auth');
    let headers: HttpHeaders = new HttpHeaders();
    let token = '';
    if (authData) {
      token = JSON.parse(authData).token.access_token;
      headers = headers.append('Authorization', `Bearer ${token}`)
    }
    if (requestData.contentType) {
      headers = headers.append('Accept', requestData.contentType);
      headers = headers.append('Content-Type', requestData.contentType);
    } else {
      headers = headers.append('Accept', 'application/json');
      headers = headers.append('Content-Type', 'application/json');
    }
    if (requestData.contentType) {
      headers = headers.append('Content-Type', requestData.contentType);
    } else {
      headers = headers.append('Content-Type', 'application/json');
    }
    let params = new HttpParams();
    for (const key in requestData.data) {
      if (requestData.data.hasOwnProperty(key)) {
        params = params.append(key, requestData.data[key]);
      }
    }
    return this.http.get<any>(requestData.url, { headers, params })
      .pipe(
        catchError(this.handleError('error ', []))
      );
  }

  // Put API Call
  putAPICall(requestData: any) {
    let headers: HttpHeaders = new HttpHeaders();
    let authData = localStorage.getItem('spartacus⚿⚿auth');
    let token = '';
    if (authData) {
      token = JSON.parse(authData).token.access_token;
      headers = headers.append('Authorization', `Bearer ${token}`)
    }
    if (requestData.contentType) {
      headers = headers.append('Accept', requestData.contentType);
    } else {
      headers = headers.append('Accept', 'application/json');
    }
    let params = new HttpParams();
    for (const key in requestData.data) {
      if (requestData.data.hasOwnProperty(key)) {
        params = params.append(key, requestData.data[key]);
      }
    }
    return this.http.put<any>(requestData.url, requestData.data, { headers })
      .pipe(
        catchError(this.handleError('error ', []))
      );
  }

  // Delete API Call
  deleteAPICall(requestData: any) {
    let headers: HttpHeaders = new HttpHeaders();
    let authData = localStorage.getItem('spartacus⚿⚿auth');
    let token = '';
    if (authData) {
      token = JSON.parse(authData).token.access_token;
      headers = headers.append('Authorization', `Bearer ${token}`)
    }
    if (requestData.contentType) {
      headers = headers.append('Accept', requestData.contentType);
    } else {
      headers = headers.append('Accept', 'application/json');
    }
    return this.http.delete<any>(requestData.url, { headers })
      .pipe(
        catchError(this.handleError('error ', []))
      );
  }
}
