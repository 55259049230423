import { NgModule } from "@angular/core";
import { FirstCompComponent } from "./first-comp/first-comp.component";
import { CommonModule } from "@angular/common";
import { CmsConfig, ConfigModule } from "@spartacus/core";
import { DownloadFileService } from "../spartacus/services/download-file.service";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
    declarations: [
        FirstCompComponent
    ],
    imports:[
        CommonModule,
        HttpClientModule,
        ConfigModule.withConfig({
            cmsComponents:{
                SeqirusFluworldvaccinecomponent:{
                    component: FirstCompComponent
                },
            }
        }as CmsConfig)
    ],
    providers:[
        DownloadFileService
    ]
})

export class AboutUkModule { }