import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-seqirus-join-flu',
  templateUrl: './seqirus-join-flu.component.html',
  styleUrl: './seqirus-join-flu.component.scss'
})
export class SeqirusJoinFluComponent {
  joinflunode:Observable<any> = this.bannerComponent.data$; 
  // clinicalNode : Observable<any> = this.cmsService.getComponentData('SeqirusClinicalSupportFourthComponent');
  // oppeffNode : Observable<any> = this.cmsService.getComponentData('SeqirusOperationalEfficiencyFourthComponent');
  constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>) {
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
}
