import { Component } from '@angular/core';

@Component({
  selector: 'app-return-banner',
  templateUrl: './return-banner.component.html',
  styleUrl: './return-banner.component.scss'
})
export class ReturnBannerComponent {

}
