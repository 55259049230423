import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-clinical-second',
  templateUrl: './clinical-second.component.html',
  styleUrls: ['./clinical-second.component.scss']
})
export class ClinicalSecondComponent implements OnInit {
  constructor(
    public component: CmsComponentData<CmsBannerComponent>,
    protected config: OccConfig,
    public accessService: ContentService,
    public cd: ChangeDetectorRef,
    private userInfoService: UserInfoService
  ) {}

  slideNode: Observable<any> = this.component.data$;
  mergedObject1: any[] = [];  // Ensure this is an array
  userLoggedIn: boolean = false;

  ngOnInit() {
    this.slideNode.subscribe(res => {
      console.log('slideNode response:', res);
      this.slideresources(res);
    });
  }

  getImage(image: any): string {
    if (!image || !image.url) {
      console.error('Invalid image object:', image);
      return '';
    }
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  slideresources(res: any) {
    const tilesList = res.flu360ContentSlideResources.split(" ");
    console.log('tilesList:', tilesList);
    this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
    
      
      // Check if apiRes.component is an array
      if (Array.isArray(res.component)) {
        this.mergedObject1 = res.component;
      } else if (res.component) {
        // Convert to array if it's not already
        this.mergedObject1 = [res.component];
      } else {
        // Ensure it's always an array
        this.mergedObject1 = [];
      }

      console.log('mergedObject1:', this.mergedObject1);
      this.cd.detectChanges();  // Ensure detectChanges is called correctly
    });
  }
}

    // For logged in, logged out
    // this.userInfoService.userLoggedIn$.subscribe(userres => {
    //   console.log('Login check:', userRes);
    //   this.userLoggedIn = userRes;
    //   this.cd.detectChanges();  // Ensure changes are detected when the login status changes
    // });
