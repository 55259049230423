import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-clinical-top-image',
  templateUrl: './clinical-top-image.component.html',
  styleUrl: './clinical-top-image.component.scss'
})
export class ClinicalTopImageComponent {
  isUserLoggedIn: boolean=false;
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, private cmsService: CmsService, private cd: ChangeDetectorRef,protected config: OccConfig,public usersrvice:UserInfoService) { }
  topheadline: Observable<any> = this.bannerComponent.data$;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  getMobileImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.mobile.url;
  }

  ngOnInit() {
    this.usersrvice.userLoggedIn$.subscribe(res=>{
      this.isUserLoggedIn=res;
    });
  }
}
