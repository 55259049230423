import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { AuthService, BaseSiteService, OccEndpointsService, StateWithProcess, StateWithUser, UserIdService } from '@spartacus/core';
import { Observable, tap, catchError, shareReplay, of, switchMap, BehaviorSubject, take } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { NavigationEvent } from '@spartacus/storefront';
import { Store } from '@ngrx/store';
import {
  CsAgentAuthService,
} from '@spartacus/asm/root';
import { RefundData, AppliedCreditDetails } from '../../financial-dashboard-uk/credits-section/credits-section.component';
@Injectable({
  providedIn: 'root'
})
export class UserInfoService{

  userInfo$ = new BehaviorSubject<any>(null);//Observable<any> | undefined;
  seasonValue$ = new BehaviorSubject<string>('');
  invoiceCount$ = new BehaviorSubject<string>('0')
  profileInfo$ = new BehaviorSubject<any>(null);
  checkoutId$ = new BehaviorSubject<any>('');
  isCancelled$ = new BehaviorSubject<any>(false);
  userLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  dataInvoice$: Observable<any> | undefined;
  orderData$: Observable<any> | undefined;
  isMobileHeader$ = new BehaviorSubject<boolean>(false); // <---- You give 'false' as an initial value
  showPopUp$= new BehaviorSubject<any>(false);
  private orderDataSubject = new BehaviorSubject<any>(null);
  public dashboardOrderData$ = this.orderDataSubject.asObservable();
  private orgLocRespData = new BehaviorSubject<any>(null);
  currentOrgLocData = this.orgLocRespData.asObservable();
  private filterValSub= new BehaviorSubject<string>("");
  filterData$ = this.filterValSub.asObservable();
  paidInvoiceCount$ = new BehaviorSubject<string>('0');
  startseasonValue$ = new BehaviorSubject<string>('');
  enablePage$ = new BehaviorSubject<any>('');
  myprofiletab$ = new BehaviorSubject<any>('');
  summarypopup$=new BehaviorSubject<any>('');
  public asmEnabled:boolean = false;
  multipleSoldToData$ = new BehaviorSubject<any>(null);
  currentSite: string='';
  unlinkaccount$ = new BehaviorSubject<any>('');

  constructor(
    private http: HttpClient,
    private occ: OccEndpointsService,
    private router: Router,
    private authService: AuthService,private baseSiteService:BaseSiteService,
    private userIdService: UserIdService,
  ) {
    this.asmEnabled = localStorage.getItem('showASM') === 'true';
      if(window.location.href.includes('liveassist') || window.location.href.includes('?asm=true')) {
        localStorage.setItem('showASM', 'true');
        // this.asmEnabled = true;
      }
  }

  fetchUserUrl() {
    let partUrl = "/users/current";
    this.userIdService.getUserId().subscribe(item => {
      if(item && this.asmEnabled)
        partUrl = `/users/${item}`;
    });
    return partUrl;
  }
  checkUserLoggedIn(){
    this.userLoggedIn$ = this.authService.isUserLoggedIn();
    this.userLoggedIn$.subscribe(data=>{
      //console.log("entered",data);
    });
  }
  fetchMultipleSoldToForUser(){
    const url = this.occ.buildUrl(`${this.fetchUserUrl()}/fetchMultipleSoldTo`);
    return this.http.get(url);
  }
  sendDropdownValue(value: string) {
    this.seasonValue$.next(value);
  }
  getDropdownValue() {
    return this.seasonValue$.asObservable();
  }
  setInvoiceCount(value: string) {
    this.invoiceCount$.next(value);
  }
  getInvoiceCount() {
    return this.invoiceCount$.asObservable();
  }
  setPaidInvoiceCount(paidInvoiceCount: any) {
    this.paidInvoiceCount$.next(paidInvoiceCount);
  }
  getPaidInvoiceCount() {
    return this.paidInvoiceCount$.asObservable();
  }
  setcheckOutId(value: string) {
    this.checkoutId$.next(value);
    localStorage.setItem('checkoutId', value);
  }

  getcheckOutId() {
    return this.checkoutId$.asObservable();
  }
//   setCancelStatus(){
//    // let new = localStorage.getItem('isCancelled')
//     this.isCancelled$.next(localStorage.getItem('isCancelled'))
//   }
// getCancelStatus(){
//   return this.isCancelled$
// }

  loginRedirection(res: any) {
    this.baseSiteService.getActive().subscribe((site)=>{
      this.currentSite=site;
    });
    if (res.webEnabled) {

        if(this.currentSite == 'seqirusb2b-flu360'){
          this.router.navigate(['/my-account']);
        }
        if(this.currentSite == 'seqirusb2b-uk'){

          this.fetchMultipleSoldToForUser().subscribe((data)=>{
            this.setMultipleSoldToData(data);

          })
        }


    }
    else {
      if(this.currentSite == 'seqirusb2b-uk'){
        this.router.navigate(['register/join-account']);
      }
      else{
        this.router.navigate(['/homepage']);
      }
    }
  }
  setMultipleSoldToData(data: any) {
    this.multipleSoldToData$.next(data);

  }
  getMultipleSoldToData(){
    return this.multipleSoldToData$.asObservable();
  }

  setMobileHeader(flag: boolean) {
    return this.isMobileHeader$.next(flag)
  }
  /**
   * Get current user
   */
  public getMobileHeader(): Observable<boolean> {
    return this.isMobileHeader$.asObservable();
  }
  fetchUserData(): Observable<any> | any {
      this.authService.isUserLoggedIn().pipe(shareReplay(1)).subscribe(isloggedIn => {
        if (isloggedIn) {
          let url = this.occ.buildUrl(this.fetchUserUrl());
          this.http.get(url).subscribe({
            next: (v: any) => {
              //console.log(v),
              v.webEnabled = v.orgUnit.uid !== 'SeqirusUK';
              if (v) {
                this.setUserData(v);
                if (!localStorage.getItem('loggedIn')) {
                  this.loginRedirection(v);
                localStorage.setItem('loggedIn', v.uid);
                }

              }
            },

            error: (e) => console.error(e),
          })
        }
        else{
          localStorage.removeItem('loggedIn');
        }
      })
}
setUserData(result: any){
  this.userInfo$.next(result);
}
getUserData(){
  return this.userInfo$.asObservable();
}
setProfileData(result: any){
  this.profileInfo$.next(result);
}
getUKMyProfileData(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/profile`);
       return this.http.get(url);
}
getCustomerSearch(searchText:any) {
  const url = this.occ.buildUrl(`/asm/autocomplete?customerId=${searchText}&isSearchByProfile=false`);
       return this.http.get(url);
}
getProfileData(){
  return this.profileInfo$.asObservable();
}

getInvoiceData(): Observable < any > {
  let url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/invoicesAndCreditDetails`);
  // if(!this.dataInvoice$) {
  this.dataInvoice$ = this.http.get(url).pipe(
    shareReplay(1),
    catchError(error => {
      console.error('Error fetching data', error);
      return of(null);
    })
  );
//}
return this.dataInvoice$;
    }
    getseasonDropdown(season:any){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/seasonDropdown/${season}?fields=FULL`);
       return this.http.get(url);
    }
    getOrderSummary(){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getOrderSummary?fields=FULL`);
       return this.http.get(url);
    }
    getMyOrdersShipmentTable(){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getMyOrdersShipmentTable?fields=FULL`);
       return this.http.get(url);
    //getMyOrdersShipmentTable?fields=FULL
    }
getOrdersDashboard(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-account/dashboard?fields=FULL`);
  // if (!this.orderData$) {
    this.orderData$ = this.http.get(url).pipe(
      shareReplay(1),
      catchError(error => {
        console.error('Error fetching data', error);
        return of(null);
      })
    );
    this.orderDataSubject.next(this.orderData$);
  // }
  return this.orderData$;
}

getOrgLocData():Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/fetchOrgLocTableData`);

  return this.http.get(url);
}
saveOriginalLocData(originalLocData: any) {

  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/saveOriginalLocationData`);
  this.http.post(url,originalLocData,{headers:{'Content-Type':'application/json'}}).subscribe(data => {
    if(data == 200){
      console.log("success");
    }
  });
}

updateLocationData(updateLocData:any):Observable<any>{
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/updateOrgLocations`);
  return this.http.post(url,updateLocData,{headers:{'Content-Type':'application/json'}})
}

validateLicense(data: HttpParams) :Observable<any>{
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/register/validateLicence`);
  return this.http.get(url,{params:data,responseType: 'text'});
}

addOrgLocation(addLocData: any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/addOrgLocation`);
  return this.http.post(url,addLocData,{headers:{'Content-Type':'application/json'}})
}


filterproduct(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/filterProducts?fields=FULL`);
  return this.http.get(url);
    // console.log(res)

}
UkMyorderleftsection(season:string){
  //https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/ordersUK?fields=DEFAULT&selectedSeason=2024-2025
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/ordersUK?fields=DEFAULT&selectedSeason=${season}`);
  return this.http.get(url);
}
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/getOrderbyID?fields=DEFAULT&orderId=0010319474&selectedSeason=2024-2025


UkMyorderRightsection(season:string,orderid:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getOrderbyID?fields=FULL&orderId=${orderid}&selectedSeason=${season}`);
  return this.http.get(url);
}
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/getDeliveryScheduleDetails?fields=DEFAULT&orderId=0010319179&selectedSeason=2023-2024
MyorderRightTable(season:string,orderid:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getDeliveryScheduleDetails?fields=FULL&orderId=${orderid}&selectedSeason=${season}`);
  return this.http.get(url);
}
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/CalenderEvents?fields=FULL&orderId=0010319179&selectedSeason=2023-2024&lang=en&curr=GBP
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/calenderEvents?fields=DEFAULT&orderId=0010319179&selectedSeason=2023-2024
MyCalenderUk(season:string,orderid:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/calenderEvents?fields=DEFAULT&orderId=${orderid}&selectedSeason=${season}`);
  return this.http.get(url);
}
filterstatus(){

  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/filterStatus?fields=FULL`);
  return this.http.get(url);
}
Myorderleftsection(season:string){

  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/ordersList/${season}?fields=FULL`);
  return this.http.get(url);
}
MyorderRightsection(season:string,orderid:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/orderDetails/${season}/${orderid}?fields=FULL`);
  return this.http.get(url);
}
AllInvoicesRightsection(season:string,invoiceId:string, status:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/invoicesDetailsAjax?fields=FULL&invoiceNumber=${invoiceId}&invoiceStatus=${status}`);
  return this.http.get(url);
}
fetchSavedCards(){

  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/fetch-saved-cards?fields=FULL`);
  return this.http.get(url);
}
getIframeUrlTerms(season: string) {
 // const seasonValue = season === 'inSeason' ? '2024-2025' : '2025-2026';
  const iframeUrl = this.occ.buildUrl(`/SeqirusPDF/terms-conditions-sales-${season}?fields=DEFAULT`);
  return this.http.get(iframeUrl, { responseType: 'text' })
}
savedCardClicked(obj: any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/saved-card-payment?fields=FULL`);
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}
redirectIframeUrl(obj: any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/createHostedCheckout?fields=FULL`);
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}
getAllInvoices(season:any):Observable<any>{
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/dashboard/all-invoices?fields=DEFAULT&selectedSeason=${season}`);
  return this.http.get(url);
  ///"+season+"?fields=FULL"
}
getCredits(season:any):Observable<any>{
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/dashboard?fields=DEFAULT&selectedSeason=2023-2024`)
  return this.http.get(url);
  ///"+season+"?fields=FULL"
}
downloadInvoiceApi(invoicenum :string) {

  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/downloadInvoice?invoiceNumber=${invoicenum}`);
  return this.http.get(url, { responseType: 'arraybuffer' });
}
financialdashboard(selectedseason:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/dashboard?fields=FULL&selectedSeason=${selectedseason}`);
  return this.http.get(url);
}
financialdashboardUK(selectedseason:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/credits?fields=FULL&selectedSeason=${selectedseason}`);
  return this.http.get(url);
}
financialdownloadInvoice(invoicenumber:string) {

  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/viewCreditReport?invoiceNumber=${invoicenumber}`);
  return this.http.get(url, { responseType: 'arraybuffer' });
}
financialcurrentseason(){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/dashboard/currentSeason`);
  return this.http.get(url);

}


//createHostedCheckout
//financials/dashboard?fields=DEFAULT&selectedSeason=2023-2024
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-flu360/users/current/financials/invoicesDetailsAjax?fields=FULL&invoiceNumber=9130226308&invoiceStatus=DueSoon

deleteCreditDetails(token: string) {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/delete-saved-card/${token}?fields=FULL`);
  return this.http.get(url);
}
//edit
updateOrder(orderData:any){
  const url= this.occ.buildUrl(`${this.fetchUserUrl()}/cart/editOrder/addToCart?fields=DEFAULT`);
  return this.http.post(url,orderData,{ responseType: 'text' });

}
MembershipDetails(){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/membership-status?fields=DEFAULT`);
  return this.http.get(url);
}
editconfirmorder(PORefNumber:any,sapOrderID:any){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/checkout/confirmOrder?PORefNumber=${PORefNumber}&fields=DEFAULT&sapOrderID=${sapOrderID}&season=editorder`)
  console.log(PORefNumber);
  console.log(sapOrderID);
  return this.http.get(url, {responseType: 'text'});
}
paymentStatusIframe(obj: any, id:any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/payment-status?fields=FULL&hostedCheckoutId=${id}&isFramed=true`);
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}
paymentInformationStatusIframe(id: any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/new-card-status?fields=FULL&hostedCheckoutId=${id}&isFramed=true`);
  return this.http.get(url,{responseType: 'text'})
}
checkCartExist() {
  const checkOrderUrl = this.occ.buildUrl(`${this.fetchUserUrl()}/Start-order/checkCartExistforSeason?fields=DEFAULT&season=`);
  return this.http.get(checkOrderUrl, { responseType: 'text' });
}
setPopUpValue(result: boolean){
  this.showPopUp$.next(result);
}
getPopValue(){
  return this.showPopUp$.asObservable();
}
updateOrgLocData(data: any) {
  this.orgLocRespData.next(data);
}
sendFilterValue(filterValue: any) {
 this.filterValSub.next(filterValue);
}
sendstartorderseason(value:string){
  this.startseasonValue$.next(value);
}
getstartorderseason(){
  return this.startseasonValue$.asObservable();
}
setEnablePage(value:any){
  this.enablePage$.next(value);
}
getEnablePage(){
  return this.enablePage$.asObservable();
}
//ordersummaryuk
setpopupseason(value:any){
  this.summarypopup$.next(value);
}
getpopupseason(){
  return this.summarypopup$.asObservable();
}

leftsection(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getOrderSummary?fields=FULL`);
  return this.http.get(url);
}
chartfilter(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getOrdersChartFilterData?fields=FULL`);
  return this.http.get(url);
}

upcomingorderUk(season:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getUpcomingOrders?fields=DEFAULT&selectedSeason=${season}`);
  return this.http.get(url);
}

latestdelivery()
{
  const url=this.occ.buildUrl("users/current/orders/getMyOrdersDeliveredTable?fields=FULL");
  return this.http.get(url);
}
getFluadPdf() {
  const url = this.occ.buildUrl(`SeqirusPDF/FluadPrescribingInfoPDF?fields=DEFAULT`);
  return this.http.get(url, { responseType: 'text' });
}
getAfluriaPdf() {
  const url = this.occ.buildUrl(`SeqirusPDF/AfluriaPrescribingInfoPDF?fields=DEFAULT`);
  return this.http.get(url, { responseType: 'text' });
}
getFlucelvaxPdf() {
  const url = this.occ.buildUrl(`SeqirusPDF/FlucelvaxPrescribingInfoPDF?fields=DEFAULT`);
  return this.http.get(url, { responseType: 'text' });
}
changeB2BAccount(account: string) {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/setDefaultB2Bunit/`);
  const params = new HttpParams().set('account',account);
  return this.http.post(url,{},{params});

}
//myprofile uk
 //myprofile uk
 rbasiteflag(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/customer-permissions?fields=DEFAULT`);
  return this.http.get(url);

 }
settabdata(value:any){
    this.myprofiletab$.next(value);
  }
gettabdata(){
   return this.myprofiletab$.asObservable();
  }
  getconsentuk() {
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/isConsentGranted?fields=DEFAULT`);
      return this.http.get(url);
    }
    setconsentuk(value:boolean) {
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/marketingconsent/${value}`);
      return this.http.post(url,{ responseType: 'text' });
    }

    unlinkaccount(value:number) {
      const url = this.occ.buildUrl("users/current/my-profile/unlinkB2bUnit/"+value);
      return this.http.post(url,{ responseType: 'text' });
    }

    settingrbachanges(value:string,permission:string) {
      const url = this.occ.buildUrl("users/current/saveChangedSettingPermissionData/"+value+"/"+permission);
      return this.http.post(url,{ responseType: 'text' });
    }

    getaccountdetailsuk(){
      const url = this.occ.buildUrl("/users/current/my-profile/account-data?fields=DEFAULT");
      return this.http.get(url);

    }
    getaccountlink(){
      const url = this.occ.buildUrl("/users/current/my-profile/unlinkTable?fields=DEFAULT");
      return this.http.get(url);

    }
    rbaintialdata(){
      const url = this.occ.buildUrl("/users/current/profile-permissions?fields=DEFAULT");
      return this.http.get(url);
    }
    accountrba(){
      const url = this.occ.buildUrl("/users/current/adminCustomerAccount?fields=DEFAULT");
      return this.http.get(url);
    }
    locationrba(locid:string){
      const url = this.occ.buildUrl("/users/current/adminCustomerLoc/"+locid+"?fields=DEFAULT");
      return this.http.get(url);
    }
    userrba(userid:string){
      const url = this.occ.buildUrl("/users/current/adminCustomerUserTab/"+userid+"?fields=DEFAULT");
      return this.http.get(url);
    }
    nonadmin(){
      const url = this.occ.buildUrl("/users/current/nonAdminCustomer?fields=DEFAULT");
      return this.http.get(url);
    }
    changeaccountpermssion(obj: any){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/saveChangedAccountPermissionData?fields=FULL`);
      return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
    }
    changelocationpermssion(obj: any){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/saveChangedLocPermissionData?fields=FULL`);
      return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
    }
    sendunlink(value:string){
      this.unlinkaccount$.next(value);
    }
    getunlink(){
      return this.unlinkaccount$.asObservable();
    }

updateUserdata() {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}`);
  this.http.get(url).subscribe((data:any)=>{
    data.webEnabled = data.orgUnit.uid !== 'SeqirusUK';
    this.setUserData(data);
  })
}

sendCustomerNotification(value: any) {
  const url = this.occ.buildUrl("/users/anonymous/customerNotification");
  const params = new HttpParams().set('emailId',value);
  return this.http.post(url,{},{params});
}

submitRefundData(refundData:RefundData){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/submitRefunds`);
  return this.http.post(url,refundData,{headers:{'Content-Type':'application/json'}})

}

updateAppliedCreditDetails(appliedCreditDetails:AppliedCreditDetails){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/updateAppliedCreditsDetails`);
  return this.http.post(url,appliedCreditDetails, {headers:{'Content-Type':'application/json'}})
}




}
