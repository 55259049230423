<div class="container" *ngIf="object1 | async as item">
    <div id="influenza-seasons-cards">
        <div class="row-flex m1-mobile">
            <div id="influnenza-complications-header" class="col-lg-12 col-md-12 col-sm-12 col-sm-12 text-center">
                <h2>
                    <span>{{item.headLine}}</span>
                    <div class="header-line"></div>
                </h2>
            </div>
            <div class="col-lg-5 col-md-3 col-sm-12 col-xs-12">
                <div id="season-card" class="two-row--card season-card-left season-left-width pt-6">
                    <div class="two-row--card-content">
                        <div class="two-row--card-top season-left-width">
                            <p class="card-callout">
                            <div class="seasons-two-row--card-bottom">
                             <p class="card-content centered-text" [innerHTML]="item.content">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 influenza-season-footnotes">
                    <p class="ash" [innerHTML]="item.bottomContent">
                    </p>
            </div>
            </div>
         
            <div class="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                <div id="season-card-right" class="two-row--card bg--white">
                    <div class="two-row--card-content">
                        <div class="two-row--card-top">
                            <p class="text--grey-110 card-header" [innerHtml]="item.text1content"></p>
                        </div>
                        <div class="two-row--card-bottom display--flex season-card-right-desktop">
                            <div class="mini-card bg--grey-0">
                                <div class="mini-card-1-container">
                                    <p class="ash1" [innerHtml]="item.text2content"></p>
                                </div>
                            </div>
                            <div class="mini-card bg--grey-0">
                                <div class="mini-card-2-container">
 
                                    <p class="ash1" [innerHTML]="item.text3content"></p>
                                </div>
                            </div>
                            <div class="mini-card bg--grey-0 text-left-align">
                                <div class="mini-card-3-container">
                                    <p class="one-block-text" [innerHtml]="item.text4content"></p>
 
                                </div>
                            </div>
                        </div>
                    </div>
                 
            </div>
        </div>
      <!--mobile-carousel-->
      <div class="mobile-container">
      <div class="col-xs-12">
        <div id="season-card-right" class="two-row--card bg--white">
            <div class="two-row--card-content">
                <!-- <div class="two-row--card-top">
                    <p class="text--grey-110 card-header" [innerHtml]="item.text1content"></p>
                </div> -->
                <div class="two-row--card-bottom display--flex season-card-right-mobile">
                    <div class="mini-card bg--grey-0" *ngIf="currentIndex === 0">
                        <div class="mini-card-1-container">
                            <p class="ash1" [innerHtml]="item.text2content"></p>
                        </div>
                    </div>
                    <div class="mini-card bg--grey-0" *ngIf="currentIndex === 1">
                        <div class="mini-card-2-container">
                            <p class="ash1" [innerHTML]="item.text3content"></p>
                        </div>
                    </div>
                    <div class="mini-card bg--grey-0 text-left-align" *ngIf="currentIndex === 2">
                        <div class="mini-card-3-container">
                            <p class="one-block-text" [innerHtml]="item.text4content"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dots">
            <span class="dot" (click)="currentSlide(0)" [ngClass]="{'active': currentIndex === 0}"></span>
            <span class="dot" (click)="currentSlide(1)" [ngClass]="{'active': currentIndex === 1}"></span>
            <span class="dot" (click)="currentSlide(2)" [ngClass]="{'active': currentIndex === 2}"></span>
        </div>
    </div>
    </div>