import { Component } from '@angular/core';
import { Router, RouterHashLocationFeature } from '@angular/router';
import { CmsComponentData } from '@spartacus/storefront';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-uk-splash-page',
  templateUrl: './uk-splash-page.component.html',
  styleUrl: './uk-splash-page.component.scss'
})
export class UkSplashPageComponent {
  ukSplashPageData: any;
  currUrl!: string | null;

  constructor(private cookieService: CookieService,private router:Router,private component:CmsComponentData<any>) {
   
  }
  ngOnInit(){
    this.component.data$.subscribe(data=>{
      this.ukSplashPageData = data;
    })
    
  }

  updateCookie(){
    const visitorType = this.cookieService.get('visitor_type');
    if (!visitorType || visitorType !== 'healthcare') {
      const inSixtyMinutes = new Date(new Date().getTime() + 60 * 60 * 1000);
      this.cookieService.set('visitor_type', 'healthcare', inSixtyMinutes, '/', '', true, 'None');
      if(sessionStorage.getItem('navigateToUrl') && sessionStorage.getItem('navigateToUrl') != ''){
        this.currUrl = sessionStorage.getItem('navigateToUrl')??'/';
        this.router.navigateByUrl(this.currUrl)
      }
      else{
        this.router.navigate(['/homepage']);
      }
    }
  }

}
