import { ChangeDetectorRef, Component } from '@angular/core';
import { BaseSiteService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { DownloadFileService } from '../../spartacus/services/download-file.service';


@Component({
  selector: 'app-first-comp',
  templateUrl: './first-comp.component.html',
  styleUrl: './first-comp.component.scss'
})
export class FirstCompComponent {
  topheadline: Observable<any> = this.bannerComponent.data$;
  currentSite: string="";
  activeIndex1: number = 0;
  transitioning: boolean = false;
  mergedObject1: any[] = [];
  totalSlides: number = this.mergedObject1.length;
  constructor(
    private cmsService: CmsService,
    protected config: OccConfig,
    public bannerComponent: CmsComponentData<CmsBannerComponent>,
    public accessService: ContentService,
    public cd: ChangeDetectorRef,
    private baseSiteService:BaseSiteService,
    private userInfoService:UserInfoService,
    private downloadFileService:DownloadFileService) {}
  

ngOnInit() {
  this.topheadline.subscribe(res => {
    console.log('slideNode response:', res);
    this.slideresources(res);
  });
}
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  goprevious() {
    if (this.transitioning) return;
    this.transitioning = true;
 
    const fromIndex = this.activeIndex1;
    this.activeIndex1= (this.activeIndex1 > 0) ? this.activeIndex1- 1 : this.mergedObject1.length - 1;
    this.triggerSlideAnimation(fromIndex, this.activeIndex1, 'prev');
  }
 
  gonext() {
    if (this.transitioning) return;
    this.transitioning = true;
 
    const fromIndex = this.activeIndex1;
    this.activeIndex1 = (this.activeIndex1 < this.mergedObject1.length - 1) ? this.activeIndex1 + 1 : 0;
    this.triggerSlideAnimation(fromIndex, this.activeIndex1, 'next');
  }
 
  triggerSlideAnimation(fromIndex: number, toIndex: number, direction: 'next' | 'prev') {
    const fromSlide = document.querySelectorAll('.carousel-item')[fromIndex] as HTMLElement;
    const toSlide = document.querySelectorAll('.carousel-item')[toIndex] as HTMLElement;
 
    if (fromSlide && toSlide) {
      // Apply outgoing and incoming classes for sliding effect
      toSlide.classList.add(`carousel-item-${direction}-enter`,'carousel-item-${direction}');
      fromSlide.classList.add(`carousel-item-${direction}-exit`, 'carousel-item-${direction}');
 
      setTimeout(() => {
        toSlide.classList.add('active');
        toSlide.classList.remove(`carousel-item-${direction}-enter`,'carousel-item-${direction}');
        fromSlide.classList.remove('active', `carousel-item-${direction}-exit`,'carousel-item-${direction}');
        this.transitioning = false;
      },0); 
      // Match the transition duration with CSS
    }

  }
  setActiveIndex(index: number){
    if(index!==this.activeIndex1){
      const fromIndex = this.activeIndex1;
      this.activeIndex1=index;
      const direction = index> fromIndex ? 'next'  : 'prev';
      this.triggerSlideAnimation(fromIndex,index,direction);
    }
    // this.activeIndex = index;
  }
  getProduct(index: number): string {
    console.log('switched')

    switch (index) {
      case 0:
        return 'clinical';
      case 1:
        return 'financial';
      case 2:
        return 'clinical2';
      default:
        return 'default';
        
    }
   
  }
  slideresources(res: any) {
    const tilesList = res.carouselList.split(" ");
    console.log('tilesList:', tilesList);
    this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
    
      
      // Check if apiRes.component is an array
      if (Array.isArray(res.component)) {
        this.mergedObject1 = res.component;
      } else if (res.component) {
        // Convert to array if it's not already
        this.mergedObject1 = [res.component];
      } else {
        // Ensure it's always an array
        this.mergedObject1 = [];
      }

      console.log('mergedObject1:', this.mergedObject1);
      this.cd.detectChanges();  // Ensure detectChanges is called correctly
    });
  }
}





