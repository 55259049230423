import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrl: './landingpage.component.scss'
})
export class LandingpageComponent {
  constructor(public PageParagraphComponent : CmsComponentData<any>,private contentservice:ContentService,private cd:ChangeDetectorRef,private userInfoService: UserInfoService,private router: Router){}
  listarray=[];
  startlandingcontent: Observable<any> = this.PageParagraphComponent.data$;
  startdata:any;
  landing:any=[];
  landingapi:any;
  seasonModal:boolean= false;
  @ViewChild('seasonModal')
  seasonModalTemplate: any;
  seasonclick:string=''
  seasonclicked: string='';
  enablePage: any;
  isLoading: boolean = false;
  inseason: string = '';
  preseason: string = '';
  //inseasonFormattedText: string = '2024/2025';
  //preseasonFormattedText: string = '2025/2026';
  ngOnInit() {
    this.startlandingcontent.subscribe(res => {
      this.startdata=res;
      const list=this.startdata.ourCommitmentsList;
      this.listarray=list.split(" ");
   
    });
  
      for(let i=0;i<this.listarray.length;i++){
        this.contentservice.startorder_Landing(this.listarray[i]).subscribe(res => {
          this.landing[i]=res;
          this.cd.detectChanges();
        });
        console.log(this.landing)
      }
      this.contentservice.startapi().subscribe(data => {
      this.landingapi=data;
      this.inseason=this.landingapi?.currentInSeason;
      this.preseason=this.landingapi?.prePreSeason;
      this.cd.detectChanges();
      });
      if(!this.router.url.includes('editOrder')) {
      const obj = {
        showLandingPage: true,
        showLocationPage: false,
        showProductPage: false,
        showReviewPage: false
      }
      this.userInfoService.setEnablePage(obj);
      }
      this.userInfoService.getEnablePage().subscribe((data:any) => {
        this.enablePage = data;
      })
    
  }
  openModal(seasonvalue:string){
  this.seasonModal=true;
  this.seasonclick=seasonvalue;
  }
  continue(value:string){
    this.userInfoService.sendstartorderseason(value);
    const obj = {
      showLandingPage: false,
      showLocationPage: true,
      showProductPage: false,
      showReviewPage: false
    }
    this.userInfoService.setEnablePage(obj);
  }
  selectlocationseason(){
    this.userInfoService.getstartorderseason().subscribe(value => {
      this.seasonclicked = value;
    });
  }
  handleOnOrdersClick() {
    this.router.navigate(
      ['/orders/my-orders'],
      { queryParams: { season: this.inseason} }
   );
  }
}

