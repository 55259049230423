import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { I18nModule } from '@spartacus/core';
import { FormsModule} from "@angular/forms";
import { InputMaskModule } from 'primeng/inputmask';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RegistrationComponentComponent } from './registration-component/registration-component.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ThankYouComponent,
    RegistrationComponentComponent,
  ],
  imports: [
    InputMaskModule,
    CommonModule,
    CmsCommonModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        
        SeqirusRegisterBusinessComponent : {
          component: RegistrationComponentComponent ,
        },

        // RegisterThankyouCMSTabParagraphComponent : {
        //   component: ThankYouComponent,
        // },
     
     
      }
    } as CmsConfig)
  ],
})
export class RegistrationModule {
  
 }
