<div class="modal-body">
										
    <h4 class="modal-title newReturn_popHeader col-xs-9">Are you sure you want to exit?</h4>
    <div class="col-xs-3">
        <span class="newReturn_popClose" data-dismiss="modal" (click)="closeDialog()">×</span>
    </div>
    <p class="newReturn_popSubHeader col-xs-12">Please note you will lose your progress for this return. No locations or dose quantities will be saved.</p>
    <div class="clearboth"></div>
    <div class="newReturnPopBtnParent">
        <button class="newReturn_popExitBtn" (click)="goToreturn()">Exit &amp; Lose Progress</button>
        <button class="newReturn_popBackBtn" (click)="closeDialog()">Go Back to Return</button>
    </div>
    
</div>
