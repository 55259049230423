import { ChangeDetectorRef, Component } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-amend-review-page',
  templateUrl: './amend-review-page.component.html',
  styleUrl: './amend-review-page.component.scss'
})
export class AmendReviewPageComponent {
  seasonValue = '';
  enableSaveOrder: boolean = false;
  aqivCost: any = 0;
  qivcCost: any = 0;
  reviewPageData: any;
  checkbox: boolean = false;
  enablePage: any
  isLoading: boolean = false;
  totalCost: any = 0;
  amendreviewdelivery:any;
  isamendFlow: boolean = false;
  aqiv: any;
  qivc: any;
  maxDeliverySlots: any;
  deliverySlotArray: any;
  inseason: string = '';
  preseason: string = '';
  landingapi: any;
  orderStatus: string = '';
  constructor(private cdr: ChangeDetectorRef, private userInfoService: UserInfoService, private startOrderService: StartOrderService, private contentservice: ContentService) {

  }
  ngOnInit() {
    this.contentservice.startapi().subscribe(data => {
      this.landingapi=data;
      this.inseason=this.landingapi?.currentInSeason;
      this.preseason=this.landingapi?.prePreSeason;
      this.cdr.detectChanges();
    });
    this.userInfoService.getstartorderseason().subscribe((seasonValue: any) => {
      this.seasonValue = seasonValue;
      this.amendreviewdelivery=seasonValue.split("-")[0];
      this.userInfoService.getEnablePage().subscribe((data: any) => {
        this.enablePage = data;
        if (this.enablePage.showAmendReviewPage) {
          this.isLoading = true;
          this.scrollToTop();
          this.startOrderService.getProductData().subscribe((data:any) => {
            if(data && this.enablePage.showAmendReviewPage) {
            this.reviewPageData = data;
            this.maxDeliverySlots = (this.reviewPageData.constraintList.maxDeliverySlots) - 1;
            this.deliverySlotArray = this.reviewPageData.reviewData.cartEntries[0].products[0].deliverySlots ? this.reviewPageData.reviewData.cartEntries[0].products[0].deliverySlots : this.reviewPageData.reviewData.cartEntries[0].products[1].deliverySlots;
            this.constructReviewDataSource();
            this.isLoading = false;
            this.cdr.detectChanges();
            this.startOrderService.setProductData(null);
            }
          }, (error) => {
            console.log('error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
          });
        }
      })
    })
  }
  numberWithCommas(number: number) {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  handleOnInputValueChange(value: any, field: string, index: number) {
    if (field == 'firstName') {
      var letters = /^[A-Za-z ]+$/;
      this.reviewPageData.reviewData.cartEntries[index].deliveryContact.firstNameErrorMsg = (value == '' ? 'Please enter a first name' : (value.match(letters) ? '' : 'Please enter only letters'));
    } else if (field == 'email') {
      this.reviewPageData.reviewData.cartEntries[index].deliveryContact.emailErrorMsg = (value == '' ? 'Please enter a valid email address.' : (value.includes('@') && value.includes('.com') ? '' : 'Please enter a valid email address.'));
    } else if (field == 'phone') {
      var letters = /^[0-9]+$/;
      this.reviewPageData.reviewData.cartEntries[index].deliveryContact.phoneNumberErrorMsg = (value == '' ? 'Please enter a valid UK format phone number.' : (value.match(letters) ? value.length > 11 ? 'Please enter no more than 11 characters.' : '' : 'Please enter only Numbers.'));
    } else if (field == 'licenseInfoName') {
      var letters = /^[A-Za-z ]+$/;
      this.reviewPageData.reviewData.cartEntries[index].licenseInfo.nameErrorMsg = (value == '' ? 'Please enter a licence name.' : (value.match(letters) ? '' : 'Please enter only letters.'));
    } else if (field == 'licenseInfoNumber') {
      var letters = /^[A-Za-z0-9]+$/;
      this.reviewPageData.reviewData.cartEntries[index].licenseInfo.numberErrorMsg = (value == '' ? 'Please enter a valid licence number.' : (value.match(letters) ? '' : 'Please enter only Letters and Numbers'));
    } else if (field == 'checkbox') {
      this.reviewPageData.reviewData.cartEntries[index].licenseInfo.checked = value;
      this.reviewPageData.reviewData.cartEntries[index].licenseInfo.checkboxErrorMsg = this.reviewPageData.reviewData.cartEntries[index].licenseInfo.checked ? '' : 'Please select the checkbox';
    } else if (field == 'commonCheckbox') {
      this.reviewPageData.reviewData.cartEntries[index].licenseInfo.checkboxErrorMsg = this.reviewPageData.reviewData.cartEntries[index].licenseInfo.checked ? '' : 'Please select the checkbox';
    } else if (field == 'nhs') {
      if(this.reviewPageData.reviewData.businessType == 'GP Medical Practice') {
        const regex = /^[A-Za-z]{1}[0-9]{5}$/;
        this.reviewPageData.reviewData.cartEntries[index].nhsErrorMsg = value.match(regex) ? '' : 'Please provide a valid NHS code e.g. Y02906';
      }
      if(this.reviewPageData.reviewData.businessType == 'Pharmacy') {
        const regex = /^[F|f]([A-Za-z]|[0-9]){4}$/;
        this.reviewPageData.reviewData.cartEntries[index].nhsErrorMsg = value.match(regex) ? '' : 'Please provide a valid NHS code e.g. F1J4D';
      }
      if (this.reviewPageData.reviewData.businessType == "Hospitals - Public" || this.reviewPageData.reviewData.businessType == "Hospitals - Private" || this.reviewPageData.reviewData.businessType == "Nursing Homes" || this.reviewPageData.reviewData.businessType == "Correctional Facility" || this.reviewPageData.reviewData.businessType == "Wholesaler") {
        const regex = /^.{6,6}$/;
        this.reviewPageData.reviewData.cartEntries[index].nhsErrorMsg = value.match(regex) ? '' : 'Please provide a valid NHS code';
      }
    }
  }
  handleOnCommonCheckboxvalueChange(event: any) {
    let array: any = [];
    this.reviewPageData.reviewData.cartEntries.forEach((entry: any, index: number) => {
      this.handleOnInputValueChange(entry.deliveryContact.name, 'firstName', index);
      this.handleOnInputValueChange(entry.deliveryContact.email, 'email', index);
      this.handleOnInputValueChange(entry.deliveryContact.phone, 'phone', index);
      this.handleOnInputValueChange(entry.licenseInfo.name, 'licenseInfoName', index);
      this.handleOnInputValueChange(entry.licenseInfo.number, 'licenseInfoNumber', index);
      this.handleOnInputValueChange(entry.licenseInfo.checked, 'commonCheckbox', index);
      if (entry.deliveryContact.firstNameErrorMsg != '' || entry.deliveryContact.emailErrorMsg != '' || entry.deliveryContact.phoneNumberErrorMsg != '' || entry.licenseInfo.nameErrorMsg != '' || entry.licenseInfo.numberErrorMsg != '' || entry.licenseInfo.checkboxErrorMsg != '') {
        array.push(false);
        event.preventDefault();
      } else {
        array.push(true);
      }
    })
    this.checkbox = array.every((val: any) => val == true);
    if(!this.checkbox) {
      event.preventDefault();
    }
    if (this.checkbox && !this.enableSaveOrder && this.seasonValue ==  this.preseason) {
      this.enableSaveOrder = true;
    }
    this.cdr.detectChanges();
  }
  handleOnOrderClick(value: string) {
    this.orderStatus = value;
    let cartEntries: any = [];
    // let productList:any = [
    //   {
    //     productCode: Number(this.reviewPageData.reviewData.cartEntries.products[0].materialID),
    //     productQuantity: this.reviewPageData.reviewData.cartEntries.products[0].qty,
    //     createOrderDeliverySlotData: this.reviewPageData.reviewData.cartEntries.products[0].deliverySlots
    //   },
    //   {
    //     productCode: Number(this.reviewPageData.reviewData.cartEntries.products[1].materialID),
    //     productQuantity: this.reviewPageData.reviewData.cartEntries.products[1].qty,
    //     createOrderDeliverySlotData: this.reviewPageData.reviewData.cartEntries.products[1].deliverySlots
    //   },
    // ]
    this.reviewPageData.reviewData.cartEntries.forEach((entry: any) => {
      const obj: any = {
        requestType: this.reviewPageData.priceResponse.requestType,
        sor: this.reviewPageData.priceResponse.SOR,
        creditDays: this.reviewPageData.priceResponse.creditDays,
        poNumber: entry.poNumber,
        orderID: entry.orderID,
        orderStatus: entry.orderStatus,
        qty50to64y: entry.qty50to64y,
        nhsCode: entry.nhsCode,
        orderModifiedAttribures: "",
        season: this.reviewPageData.selectedSeason,
        shippinglocID: entry.shippinglocID,
        // locID: entry.shippinglocID,
        licenseInfo: entry.licenseInfo,
        deliveryContact: entry.deliveryContact,
        products: entry.products,
      }
      cartEntries.push(obj)
    })
    const payload = {
      priceResponse : this.reviewPageData.priceResponse,
      saveOrderClosed: this.reviewPageData.saveOrderClosed,
      selectedSeason: this.reviewPageData.selectedSeason,
      confirmedOrder: this.reviewPageData.confirmedOrder,
      orderType: value,
      reviewData: {
        businessType: this.reviewPageData.reviewData.businessType,
        soldToID: this.reviewPageData.reviewData.soldToID,
        salesContact: this.reviewPageData.reviewData.salesContact,
        cartEntries: cartEntries
      }
    };
    this.startOrderService.placeOrder(payload).subscribe((data:any) =>{
      this.isLoading = false;
      if(data == 'success') {
          const obj = {
            showLandingPage: false,
            showLocationPage: false,
            showProductPage: false,
            isProductBackBtnClicked: false,
            showAmendReviewPage: false,
            showAmendThankYouPage: this.orderStatus == 'confirmOrder',
            showAmendSaveThankYouPage: this.orderStatus == 'OrderPending',
          }
          this.userInfoService.setEnablePage(obj);
          this.scrollToTop();
          this.cdr.detectChanges();
      } else {
        const obj = {
          showLandingPage: false,
          showLocationPage: false,
          showProductPage: false,
          isProductBackBtnClicked: false,
          showAmendReviewPage: false,
          showAmendThankYouPage : false,
          showAmendSaveThankYouPage: false,
          showSorryPage: true
        }
        this.userInfoService.setEnablePage(obj);
        this.cdr.detectChanges();
        this.scrollToTop();
      }
    }, (error) => {
      console.log('error: ', error);
      this.isLoading = false;
      const obj = {
        showLandingPage: false,
        showLocationPage: false,
        showProductPage: false,
        isProductBackBtnClicked: false,
        showAmendReviewPage: false,
        showAmendThankYouPage :false,
        showAmendSaveThankYouPage: false,
        showSorryPage: true
      }
      this.userInfoService.setEnablePage(obj);
      this.scrollToTop();
      this.cdr.detectChanges();
    })
  }
  handleOnBackBtnClick() {
    const obj = {
      showLandingPage: false,
      showLocationPage: false,
      showProductPage: false,
      isProductBackBtnClicked: false,
      showAmendReviewPage: false,
      showAmendThankYouPage : false,
      showAmendSaveThankYouPage: false,
      showConfirmThankYouPage: false,
      showSaveThankYouPage: false,
      isDeliveryBackBtnClicked: true
    }
    this.userInfoService.setEnablePage(obj);
    this.scrollToTop();
  }
  constructReviewDataSource() {
    let aqiv: any = [];
    let qivc: any = [];
    let totalProductCost: any =[];
    this.reviewPageData.reviewData.cartEntries.forEach((entry:any) => {
      entry.deliveryContact['firstNameErrorMsg'] = '';
      entry.deliveryContact['emailErrorMsg'] = '';
      entry.deliveryContact['phoneNumberErrorMsg'] = '';
      entry.licenseInfo['nameErrorMsg'] = '';
      entry.licenseInfo['numberErrorMsg'] = '';
      entry.licenseInfo['checkboxErrorMsg'] = '';
      entry.licenseInfo['checked'] = false;
      entry['nhsCode'] = '';
      entry['nhsErrorMsg'] = '';
      entry['poNumber'] = '';
      // const filterLocation = this.reviewPageData.shippingData.filter((location:any) =>{return entry.shippinglocID == location.locID});
      // entry['address'] = filterLocation[0].address;
      entry['locname'] = this.reviewPageData.orderSummary.delLocation;
      // entry['state'] = filterLocation[0].state;
      let  sumSaleProduct:any = [];
      let productArray: any = [];
        if(entry.products.length == 1) {
          this.reviewPageData.productList.forEach((el:any) => {
            if(el.code != entry.products[0].materialID) {
              const obj = {
                  materialID: el.code.toString(),
                  qty: 0,
                }
                productArray.push(obj);
            } else {
              productArray.push(entry.products[0])
            }
          })
          entry.products = productArray;
          }
      entry.products.forEach((product: any,index: number) => {
        const filterProduct = this.reviewPageData.productList.filter((prodList:any) =>{return product.materialID == prodList.code});
        const filterMaterial = this.reviewPageData.priceResponse.materials.filter((material:any) =>{return product.materialID == material.materialID});
        product['baseName'] = filterProduct[0].baseName;
        const value = product.qty == 0 ? 0 : product.qty * Number(filterMaterial[0].salePrice);
        sumSaleProduct.push(value);
        if(product.baseName == 'AQIV') {
          aqiv.push(product.qty);
        } else if(product.baseName == 'QIVC') {
          qivc.push(product.qty);
        }
        if(product.deliverySlots) {
          product.deliverySlots.forEach((value: any) => {
            if(!value.quantity) {
              value['quantity'] = 0;
            }
          })
        }
      })
      totalProductCost.push(parseFloat((sumSaleProduct.reduce((partialSum: number, value: number) => partialSum + value, 0)).toFixed(2)))
      entry['prodCost'] = this.numberWithCommas((sumSaleProduct.reduce((partialSum: number, value: number) => partialSum + value, 0)).toFixed(2));
      this.aqivCost = this.numberWithCommas((aqiv.reduce((partialSum: number, value: number) => partialSum + value, 0)));
      this.qivcCost = this.numberWithCommas((qivc.reduce((partialSum: number, value: number) => partialSum + value, 0)));
      this.totalCost = this.numberWithCommas((totalProductCost.reduce((partialSum: number, value: number) => partialSum + value, 0)).toFixed(2))
    })
  }
}
