import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent ,BaseSiteService} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
@Component({
  selector: 'app-my-orders-dashboard',
  templateUrl: './my-orders-dashboard.component.html',
  styleUrl: './my-orders-dashboard.component.scss'
})
export class MyOrdersDashboardComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    public userInfoService:UserInfoService,protected baseSiteService: BaseSiteService,private cd:ChangeDetectorRef) {
  }
  bannerNode: Observable<any> = this.component.data$;
  seasonData:any;
  //seasonData :Observable<any> =  this.userInfoService.getseasonDropdown();
  seasons$ = this.userInfoService.seasonValue$;
  data:any;
  currentSite :any;
  enablePage: any;
  activeSeason: any;
  isLoading=false;
  selectedSeason(e :any){ 
    this.isLoading=true;
   
    this.userInfoService.sendstartorderseason(e.target.value);
 /* 
    const loading=<HTMLElement>document.getElementsByClassName("loading")[0];
    this.activeSeason =  e.target.value;
    loading.classList.add("show"); */
    
    this.userInfoService.sendDropdownValue((e.target as HTMLInputElement).value)
    this.userInfoService.getseasonDropdown((e.target as HTMLInputElement).value).subscribe((res:any)=>{
      this.seasonData=res;
      this.isLoading=false;
      this.cd.detectChanges();
    },
   
      
    );
  
    
   // console.log('event', (e.target as HTMLInputElement).value)
  }
 
    
    
  
  ngOnInit(){
    const obj = {
      showukOrdersLeftSection: true,
      showukOrdersBanner: true,
      showukCartBanner: false,
      showukCartReviewSection: false,
    }
    this.baseSiteService.getActive().subscribe(res => {
      this.currentSite = res;
      
    });

    //if null default season should be peaked changes needs to be done both side
    this.userInfoService.setEnablePage(obj);
    this.userInfoService.getEnablePage().subscribe((data: any) => {
      this.enablePage = data;
      if(this.currentSite !='seqirusb2b-uk') {
        this.userInfoService.getseasonDropdown("2025-2026").subscribe(data=>{
          this.seasonData=data;
          //hard code needs to be remove from this by default season attr which will get from backend
          this.userInfoService.sendDropdownValue('2025-2026');
        });
      }
      else {
        this.activeSeason = '2024-2025';
        this.userInfoService.getseasonDropdown("2024-2025").subscribe(data=>{
          this.seasonData=data;
          
          this.cd.detectChanges();
          //hard code needs to be remove from this by default season attr which will get from backend
          this.userInfoService.sendDropdownValue('2024-2025');
        });
      }
      // this.userInfoService.getseasonDropdown("2025-2026").subscribe(data=>{
      //   this.seasonData=data;
      //   //hard code needs to be remove from this by default season attr which will get from backend
      //   this.userInfoService.sendDropdownValue('2025-2026');
      // });
    })
   
   this.cd.detectChanges();
  }
}
