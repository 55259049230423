import { ChangeDetectorRef, Component } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DownloadFileService } from '../../spartacus/services/download-file.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
 
@Component({
  selector: 'app-custom-quick-coding-reference',
  templateUrl: './custom-quick-coding-reference.component.html',
  styleUrl: './custom-quick-coding-reference.component.scss'
})
export class CustomQuickCodingReferenceComponent {
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, private cd: ChangeDetectorRef, private userInfoService: UserInfoService, private http: HttpClient, private downloadservice: DownloadFileService,private sanitizer: DomSanitizer) {
 
   }
  firstheadline: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];
  ngOnInit() {
    this.firstheadline.subscribe(res => {
      this.referencetabs(res);
    });
       
  }
  referencetabs(res: any) {
   
    {
      const tilesList = res.productInfoList.split(" ");
      console.log('tilesList:', tilesList);
      this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
     
       
        // Check if apiRes.component is an array
        if (Array.isArray(res.component)) {
          this.mergedObject1 = res.component;
          const item = this.mergedObject1.splice(2,1);
          this.mergedObject1.unshift(item[0]);
        } else if (res.component) {
          // Convert to array if it's not already
          this.mergedObject1 = [res.component];
        } else {
          // Ensure it's always an array
          this.mergedObject1 = [];
        }
 
        console.log('mergedObject1:', this.mergedObject1);
        this.cd.detectChanges();  // Ensure detectChanges is called correctly
      });
    }
  }
  getpdf(pdf: any): string {
    return this.config.backend?.occ?.baseUrl + pdf.url;
  }
  
  pdfDownload(url: string):void {
    this.downloadservice.downloadFile(url)
      .subscribe(response =>
        {
          let fileName = 'AboutusPage.pdf';
          let blob:Blob=response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.target = '_blank';
          a.click();
        },
      );
    }
  
  }