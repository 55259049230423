<div class="prod-sbs">
    <div class="container" *ngIf="secondnode | async as item">
        <div class="prod-sbs__left">
            <h3 class="prod-sbs__header header--3" [innerHTML]="item.headline"></h3>
            <div class="prod-sbs__key-features">
                <span class="prod-sbs__key-features-header" [innerHTML]="item.h2content"></span>
                <ul class="prod-sbs__key-features-list" [innerHTML]="item?.content"></ul>
                <p class="prod-sbs__left_disclaimer" style="font-size: 14px;" [innerHTML]="item.largeparagraphcontent"></p>
            </div>
        </div>
        <div class="prod-sbs__right">
            <p class="prod-sbs__img" *ngIf="item?.mediaPicture">
                <img alt="product image" [src]="getImage(item.mediaPicture)">
            </p>
        </div>
    </div>
</div>