import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private displayedErrorCodes: Set<number> = new Set();

  constructor(private toastr: ToastrService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'An unknown error occurred!';
        if (error.error instanceof ErrorEvent) {
          // Client-side error
          errorMessage = `Client Error: ${error.error.message}`;
        } else {
          // Server-side error
          switch (error.status) {
            case 400:
              errorMessage = 'Bad Request: Please check your input.';
              break;
            case 401:
              errorMessage = 'Unauthorized: Please log in again.';
              if (!this.displayedErrorCodes.has(401)) {
                this.displayedErrorCodes.add(401);
                this.router.navigate(['/logout']).then(() => {
                  this.toastr.error(errorMessage, 'Error');
                });
              }
              break;
            case 403:
              errorMessage = 'Forbidden: You do not have permission to access this resource.';
              break;
            case 404:
              errorMessage = 'Not Found: The requested resource was not found.';
              break;
            case 500:
              errorMessage = 'Internal Server Error: Please try again later.';
              break;
            default:
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
        }
        if (error.status !== 401 && !this.displayedErrorCodes.has(error.status)) {
          this.displayedErrorCodes.add(error.status);
          this.toastr.error(errorMessage, 'Error');
        }
        return throwError(errorMessage);
      })
    );
  }
}