import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CustomfilterPipe } from '../../cms-common/Pipes/customfilter.pipe';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

export interface InvoiceDetail {
  loc: string;
  ordernum: number;
  prodname: string;
  season:string;
  status:string;
  unit:number;

}

@Component({
  selector: 'app-invoice-details-section',
  templateUrl: './invoice-details-section.component.html',
  styleUrl: './invoice-details-section.component.scss'
})
export class InvoiceDetailsSectionComponent {

  dropdownValue: any;
  showingLabel: string = "Showing";
  pageSize: number = 6;
  pageSizeControl = new FormControl(6);
  totalLength = 0;
  currentPage: number = 1;
  pageSizeOptions: number[] = [5, 10, 15];
  dataSource:any=[];
  dataSource1= new MatTableDataSource<any>();
  displayedColumns:string[]=['allinvoices'];
  dataSourceWithPageSize = new MatTableDataSource<InvoiceDetail>;
  
  filterValues = {
    search: '',
    filterbyproduct:[],
    filterbystatus:[''],
  
  };
  apiresponse: any;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  togglefilterView: boolean=false;
  togglesortView: boolean=false;
  count: any=0;
  getstatus: any;
  selectedstatus: any[]=[];
  firstvalue: string='';
  rightsectionresponse: any;
  isTableHasData: boolean=true;
  isLoading: boolean=false;
  searchText: string='';

  
   constructor(public userInfoService:UserInfoService,private cd:ChangeDetectorRef,private eleRef:ElementRef) {
  }
 
  @HostListener('document:click',['$event'])
  onDocumentClick(event:MouseEvent):void{
      /* if(this.togglefilterView){
        if(!this.eleRef.nativeElement.contains(event.target)){
            this.togglefilterView=false;
        }
      
        else {
          if(event.target != this.eleRef.nativeElement.querySelector('filterbox')){
            this.togglefilterView=false;
          }
          else if(event.target === this.eleRef.nativeElement.querySelector('filterbox')){
            this.togglefilterView=true;
          }
        }
      }
      else{
        if(this.eleRef.nativeElement.contains(event.target) && event.target === this.eleRef.nativeElement.querySelector('filterbox')){
          this.togglefilterView=true;
        }
      } */
        if(this.togglefilterView){
          this.togglefilterView =false;
          if(event.target === this.eleRef.nativeElement.querySelector('filterbox')){
            this.togglefilterView = true;
          }
        }
      if(this.togglesortView){
        this.togglesortView =false;
        if(event.target === this.eleRef.nativeElement.querySelector('sortbox')){
          this.togglesortView = true;
        }
      }
    
  }

  ngOnInit(){
    this.getstatus=[ "UNPAID", "DUE SOON", "OVERDUE", "PAID" ];
  
    this.userInfoService.getDropdownValue().subscribe(value => {
      this.dropdownValue = value;
      this.searchText ='';
      const searchField = document.getElementById("invoice_search") as HTMLInputElement;
      if(searchField){
        searchField.value='';
      }
    this.userInfoService.getAllInvoices(this.dropdownValue).subscribe(value=>{
      
      this.userInfoService.setPaidInvoiceCount(value.invoiceList.paidInvoiceCount);
      this.userInfoService.setInvoiceCount(value.invoiceList.openInvoiceCount);
      this.cd.detectChanges();
      let invoiceListuk = JSON.parse(value.invoiceListJson);
      this.apiresponse=invoiceListuk.invoices;
      this.dataSource = new MatTableDataSource(this.apiresponse);
      this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse);
      this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort; 
        this.totalLength = this.apiresponse.length;
        this.updateTableData()
        this.firstvalue=this.apiresponse[0].invoiceNumber;
        this.cd.detectChanges();
        if(this.firstvalue && this.firstvalue != 'N/A'){
          this.openrightsection(this.firstvalue,this.apiresponse[0].status);
          }
    });
    
    })
    
  }

  updateTableData() {
    const starti = (this.currentPage - 1) * this.pageSize;
    const endi = starti + this.pageSize;
    this.dataSource.data = this.apiresponse.slice(starti, endi);
  }

  inputSearch(event:Event){
    this.searchText = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.searchText || ''.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage()
    }
    if(this.dataSource.filteredData.length > 0){
      this.isTableHasData = true;
      }
      else{
        this.isTableHasData = false;
      }
    

  }
  applyFilter() {
     
    const filteredData1 = new CustomfilterPipe().transform(this.apiresponse, this.filterValues);
   this.dataSource = new MatTableDataSource(filteredData1);
   this.cd.detectChanges();
   this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse);
    this.dataSource.paginator = this.paginator;
       this.dataSource.sort = this.sort; 
   this.totalLength = filteredData1.length;
   const starti = (this.currentPage - 1) * this.pageSize;
   const endi = starti + this.pageSize;
   this.dataSource.data = filteredData1.slice(starti, endi); 
  
  }
  
  filteropen(){
    event?.stopPropagation();
    this.togglefilterView=!this.togglefilterView;
    this.togglesortView=false;
  }
  sortopen(){
    event?.stopPropagation();
    this.togglefilterView=false;
    this.togglesortView=!this.togglesortView;
    
  } 
  resetFilter(){
    this.selectedstatus=[];
    this.count =0;
    const statusCheckboxes = document.querySelectorAll('.invoice_checkbox');
    statusCheckboxes.forEach((ch)=>{
      (ch as HTMLInputElement).checked=false;
    });
    this.filterValues.filterbystatus=this.selectedstatus;
    this.applyFilter();
  }
  resetSort(){
    const statusCheckboxes = document.querySelectorAll('.invoice_radio');
    statusCheckboxes.forEach((ch)=>{
      (ch as HTMLInputElement).checked=false;
    });
    const sortState: Sort = {active: '', direction: ''};    
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }
  incrementcountstatus(event:any,i:number){
   
    if (event.target.checked) {

      this.count++;
      this.selectedstatus.push(event.target.value);
      this.filterValues.filterbystatus=this.selectedstatus;
     
    } else {
      var index = this.selectedstatus.indexOf(event.target.value);
      this.selectedstatus.splice(index, 1);
      this.filterValues.filterbystatus=this.selectedstatus;
     this.count--;
    } 
  } 

  sortTest(val:any){
    
    let newdir :string = 'desc'
    if(val == '1to9' || val == '9to1'){
    const sortState: Sort = {active: 'invoiceNumber', direction: val == '1to9'?'asc': 'desc'};    
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    this.dataSource.sort = this.sort;
    this.cd.detectChanges();
   
    }
    else if(val == 'AtoZ' || val == 'ZtoA'){
      const sortState: Sort = {active: 'location', direction: val == 'AtoZ'?'asc': 'desc'};    
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
      this.dataSource.sort = this.sort;
      
    }
    else if(val == 'LeastRecent' || val == 'MostRecent'){
      const sortState: Sort = {active: 'invoiceDateSorting', direction: val == 'LeastRecent'?'asc': 'desc'};    
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
      this.dataSource.sort = this.sort;
     
    }
  }

  openrightsection(invoiceId:string, status:string){
    this.rightsectionresponse=null;
    const temp= <HTMLElement>document.getElementsByClassName("activeinvoice")[0];
    if(temp){
    temp.classList.remove("activeinvoice");
    }
    const temp1= <HTMLElement> <unknown>document.getElementById(invoiceId);
    if(temp1){
    temp1.classList.add('activeinvoice');
    }
    this.isLoading = true;
    
    this.userInfoService.AllInvoicesRightsection(this.dropdownValue, invoiceId,status ).subscribe(value=>{
     
      this.rightsectionresponse=value;
      this.isLoading = false;
      this.cd.detectChanges();
      
     });
    
  }

  downloadInvoice(invoicenum : string) {  
     this.isLoading = true;
    
     this.userInfoService.downloadInvoiceApi(invoicenum).
     subscribe((data: ArrayBuffer) => {
          
          // this.cd.detectChanges()
           // Create a Blob from the ArrayBuffer
           const file = new Blob([data], { type: 'application/pdf' });
           const fileURL = URL.createObjectURL(file);
           //window.open(fileURL); // Open in a new tab
  
           const a = document.createElement('a');
           a.href = fileURL;
           a.target = '_blank';
           a.download = invoicenum;
           document.body.appendChild(a);
           a.click();
          this.isLoading = false;
          this.isLoading = false;
          this.cd.detectChanges()
         },
        
         (error) => {
           console.log('getPDF error: ', error);
          this.isLoading = false;
           this.cd.detectChanges()
         }
         
       );
  
  }

  onPageSizeChange(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
    this.updateTableData();
  }
  goToLastPage() {
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    this.currentPage = totalPages;
    this.updateTableData();
  } 
  scrollToTop(): void { 
    window.scrollTo({ top: 0, behavior: 'smooth' });
   }
 
   goToFirstPage() {
    this.currentPage = 1;
    this.updateTableData();
  }
  
  getPages(): any {
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    const pages: any = [];
    const startPage = Math.max(1, this.currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 2);
 
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
 
 
    }
    if (endPage < totalPages - 1) {
      pages.push('...');
    }
    if (endPage < totalPages) {
      pages.push(totalPages);
    }
    return pages;
  }
   goToPage(page: number) {
    this.currentPage = page;
    const starti = (page - 1) * this.pageSize;
    this.dataSource.data = this.apiresponse.slice(starti, starti + this.pageSize);
  } 
   previousPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  } 
     nextPage() {
    const totlpages = Math.ceil(this.totalLength / this.pageSize);
    if (this.currentPage < totlpages) {
      this.goToPage(this.currentPage + 1);
    }
  } 
  isFirstPage(): boolean {
    return this.currentPage == 1;
  }
  isLastPage(): boolean {
    return this.currentPage == Math.ceil(this.totalLength / this.pageSize);
  }
  isCurrentPage(page: number): boolean {
    return this.currentPage == page;
  }

  onFilterClick(event:Event){
    event.stopPropagation();
  }

}
