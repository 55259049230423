import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-flucel-fifth',
  templateUrl: './flucel-fifth.component.html',
  styleUrl: './flucel-fifth.component.scss'
})
export class FlucelFifthComponent {
  node1: Observable<any> = this.component.data$;
  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,){
  }

  getDesktopImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
}
