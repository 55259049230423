import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-each-year',
  templateUrl: './custom-each-year.component.html',
  styleUrl: './custom-each-year.component.scss'
})
export class CustomEachYearComponent {
  Eachyearnode : Observable<any> = this.component.data$;

  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,private userInfoService:UserInfoService) {
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
}
