import { Component } from '@angular/core';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
//import { CmsBannerComponent,CmsLinkComponent,SeqirusHomePageParagraphComponent } from '@spartacus/core';

@Component({
  selector: 'app-middle-greybanner',
  templateUrl: './middle-greybanner.component.html',
  styleUrl: './middle-greybanner.component.scss'
})
export class MiddleGreybannerComponent  {
 constructor(public PageParagraphComponent : CmsComponentData<CmsParagraphComponent>){}
 middlecontent: Observable<any> = this.PageParagraphComponent.data$; 
 middledata:any;

 ngOnInit(): void {
  this.middlecontent.subscribe(res => {
    this.middledata=res;
  });
  
 }
}
