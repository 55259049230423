import { Component } from '@angular/core';
import { CmsBannerComponent,CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-having-trouble',
  templateUrl: './having-trouble.component.html',
  styleUrl: './having-trouble.component.scss'
})
export class HavingTroubleComponent {

firstnode: Observable<any> = this.bannerComponent.data$;
constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>, private userInfoService:UserInfoService) {
   }

 
    

}
