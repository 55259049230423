import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { HeaderComponent } from './header/header.component';
import { CookieHeaderComponent } from './cookie-header/cookie-header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    CookieHeaderComponent
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
              SeqirusCookieComponent:{
                component:HeaderComponent
              },
                
            }
    } as CmsConfig)
  ]
})
 
export class UkCookiePolicyModule { }
