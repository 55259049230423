import { Component } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-flucelvax-referenceparagraph',
  templateUrl: './flucelvax-referenceparagraph.component.html',
  styleUrl: './flucelvax-referenceparagraph.component.scss'
})
export class FlucelvaxReferenceparagraphComponent {
  constructor( public bannerComponent: CmsComponentData<CmsBannerComponent>) { }
  referncenode: Observable<any> = this.bannerComponent.data$;
}
