/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  OCC_USER_ID_ANONYMOUS,
  UserIdService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import {
  GetAccountGigyaSubscriptions,
  GetAccountInfoGigyaProfile,
  GetAccountInfoGigyaResponse,
  UpdateInfoGigyaSubscriptions,
} from './get-account-gigya-response';
import { GigyaFullAccountResponse } from './gigya-full-account-response';
import { GigyaLoginResponse } from './gigya-login-response';
import { GigyaResponse } from './gigya-response';
import { GigyaSocialLoginResponse } from './gigya-social-login-response';
import { GigyaUidStorageService } from './gigya-uid-storage.service';

/**
 * Typing of the methods from the Ciam SDK.
 */
interface Ciam {
  createFullAccount: (data: {
    user: {
      email?: string;
      password?: string;
      data?: {
        acquisitionSource?: string;
        subscribe?: boolean;
        ecommerce?: {
          profile: {
            titleCode?: string;
            title?: string;
          };
        };
      };
      profile?: {
        firstName?: string;
        lastName?: string;
        gender?: 'm' | 'f' | 'u';
      };
    };
    callback: (response: GigyaLoginResponse) => void;
  }) => void;
  login: (data: {
    user: {
      email: string;
      password: string;
    };
    callback: (response: GigyaLoginResponse) => void;
  }) => void;
  logout: (data: { callback?: (response: GigyaResponse) => void }) => void;
  socialAccount: (data: {
    provider: string;
    callback?: (response: GigyaSocialLoginResponse) => void;
  }) => void;
  getAccountInfo: (
    callback?: (response: GetAccountInfoGigyaResponse) => void
  ) => void;
  setAccountInfo: (data: {
    user: {
      password?: string;
      newPassword?: string;
      profile?: GetAccountInfoGigyaProfile;
      data?: {
        ecommerce?: {
          profile: {
            title: string;
            titleCode: string;
          };
        };
        subscribe?: boolean;
      };
      subscriptions?: UpdateInfoGigyaSubscriptions;
    };
    callback: (response: GigyaResponse) => void;
  }) => void;
  resetPassword: (data: {
    user: { email: string };
    callback?: (response: GigyaResponse) => void;
  }) => void;
  changePassword: (data: {
    user: { newPassword: string };
    callback?: (response: GigyaResponse) => void;
  }) => void;
  isAvailableLoginID: (data: {
    user: { email: string };
    callback: (response: any) => void;
  }) => void;
  createLiteAccount: (data: {
    user: {
      email?: string;
      data?: {
        terms?: boolean;
        subscribe?: boolean;
        acquisitionSource?: string;
      };
      profile?: {
        country?: string;
      };
      subscriptions?: GetAccountGigyaSubscriptions;
    };
    callback: (response: any) => void;
  }) => void;
}

interface Gigya {
  createFullAccount: (data: {
    user: {
      email?: string;
      password?: string;
      data?: {
        acquisitionSource?: string;
        subscribe?: boolean;
        ecommerce?: {
          profile: {
            titleCode?: string;
            title?: string;
          };
        };
      };
      profile?: {
        firstName?: string;
        lastName?: string;
        gender?: 'm' | 'f' | 'u';
      };
    };
    callback: (response: GigyaLoginResponse) => void;
  }) => void;
  login: (data: {
    user: {
      email: string;
      password: string;
    };
    callback: (response: GigyaLoginResponse) => void;
  }) => void;
  logout: (data: { callback?: (response: GigyaResponse) => void }) => void;
  socialAccount: (data: {
    provider: string;
    callback?: (response: GigyaSocialLoginResponse) => void;
  }) => void;
  getAccountInfo: (
    callback?: (response: GetAccountInfoGigyaResponse) => void
  ) => void;
  setAccountInfo: (data: {
    user: {
      password?: string;
      newPassword?: string;
      profile?: GetAccountInfoGigyaProfile;
      data?: {
        ecommerce?: {
          profile: {
            title: string;
            titleCode: string;
          };
        };
        subscribe?: boolean;
      };
      subscriptions?: UpdateInfoGigyaSubscriptions;
    };
    callback: (response: GigyaResponse) => void;
  }) => void;
  resetPassword: (data: {
    user: { email: string };
    callback?: (response: GigyaResponse) => void;
  }) => void;
  changePassword: (data: {
    user: { newPassword: string };
    callback?: (response: GigyaResponse) => void;
  }) => void;
  isAvailableLoginID: (data: {
    user: { email: string };
    callback: (response: any) => void;
  }) => void;
  createLiteAccount: (data: {
    user: {
      email?: string;
      data?: {
        terms?: boolean;
        subscribe?: boolean;
        acquisitionSource?: string;
      };
      profile?: {
        country?: string;
      };
      subscriptions?: GetAccountGigyaSubscriptions;
    };
    callback: (response: any) => void;
  }) => void;
}

/**
 * Service that groups all logic regarding Gigya and the Ciam SDK.
 */
@Injectable({
  providedIn: 'root',
})
export class GigyaService {
  public get ciam(): Ciam | undefined {
    return (window as any as { ciam: Ciam | undefined })?.ciam;
  }

  public get gigya(): Gigya | undefined {
    return (window as any as {gigya:any | undefined})?.gigya?.accounts;

  }

  constructor(
    private gigyaUidStorageService: GigyaUidStorageService,
    private userIdService: UserIdService,
    private store: Store
  ) {}

  /**
   * Registers a user into the system with Gigya.
   */
  public register({
    email,
    password,
    profile,
    titleCode,
    subscribe,
  }: {
    email: string;
    password: string;
    profile?: {
      firstName?: string;
      lastName?: string;
      gender?: 'm' | 'f' | 'u';
    };
    titleCode?: string;
    subscribe?: boolean;
  }): Observable<GigyaLoginResponse> {
    return new Observable(subscriber =>
      this.ciam?.createFullAccount({
        user: {
          email,
          password,
          profile,
          data: {
            acquisitionSource: 'website_account',
            subscribe: !!subscribe,
            ecommerce: {
              profile: {
                titleCode,
                title: titleCode,
              },
            },
          },
        },
        callback: response => {
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }

  /**
   * Logs in a user with Gigya.
   */
login({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Observable<GigyaLoginResponse> {
    console.log(email,password)
    return new Observable(subscriber =>
      this.gigya?.login({
        user: { email, password },
        callback: (response:any) => {
          console.log(response)
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }

  /**
   * Logs out a user with Gigya.
   */
  public logout(): Observable<GigyaResponse> {
    return new Observable(subscriber =>
      this.ciam?.logout({
        callback: response => {
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }

  /**
   * Retrieves the account information of a user from Gigya.
   */
  public getAccountInfo(): Observable<GetAccountInfoGigyaResponse> {
    return new Observable(subscriber =>
      this.ciam?.getAccountInfo(response => {
        this.removeTokenOnAuthError(response);
        subscriber.next(response);
        subscriber.complete();
      })
    );
  }

  /**
   * Updates the account information of a user in Gigya.
   */
  public setAccountInfo(
    profile: GetAccountInfoGigyaProfile,
    title: string
  ): Observable<GigyaResponse> {
    return new Observable(subscriber =>
      this.ciam?.setAccountInfo({
        user: {
          profile,
          data: {
            ecommerce: {
              profile: {
                title,
                titleCode: title,
              },
            },
          },
        },
        callback: (response: GigyaResponse) => {
          this.removeTokenOnAuthError(response);
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }

  /**
   * Updates the subscriptions in Gigya.
   */
  public update(
    profile: GetAccountInfoGigyaProfile,
    subscriptions: UpdateInfoGigyaSubscriptions
  ): Observable<GigyaResponse> {
    return new Observable(subscriber =>
      this.ciam?.setAccountInfo({
        user: { profile, subscriptions },
        callback: (response: GigyaResponse) => {
          this.removeTokenOnAuthError(response);
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }
  /**
   * Subcribes user to brand' newsletter in Gigya.
   */
  public subscribe(
    email: string,
    subscriptions?: GetAccountGigyaSubscriptions
  ): Observable<GigyaResponse> {
    return new Observable(subscriber =>
      this.ciam?.createLiteAccount({
        user: {
          email,
          data: {
            subscribe: true,
          },
          ...(subscriptions ? { subscriptions } : {}),
        },
        callback: response => {
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }

  /**
   * Subcribes user to brand' newsletter in Gigya for full accounts.
   */
  public subscribeFullAccount(): Observable<GigyaResponse> {
    return new Observable(subscriber =>
      this.ciam?.setAccountInfo({
        user: {
          data: {
            subscribe: true,
          },
        },
        callback: response => {
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }

  /**
   * Initializes the social form. It should be called once from the component that
   * uses it, and then it will bind events to the social buttons.
   */
  public socialForm(provider: string): Observable<GigyaSocialLoginResponse> {
    return new Observable(subscriber =>
      this.ciam?.socialAccount({
        provider,
        callback: response => {
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }

  /**
   * Changes the password storing it in Gigya.
   */
  public changePasswordForm(
    password: string,
    newPassword: string
  ): Observable<GigyaResponse> {
    return new Observable(subscriber =>
      this.ciam?.setAccountInfo({
        user: { password, newPassword },
        callback: response => {
          this.removeTokenOnAuthError(response);
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }

  /**
   * Initializes the reset password form. It should be called once from the component that
   * uses it, and then it will bind an event to the submit button.
   */
  public resetPasswordForm(email: string): Observable<GigyaResponse> {
    return new Observable(subscriber =>
      this.ciam?.resetPassword({
        user: { email },
        callback: response => {
          // Error code when there is no user with given email.
          const emailNotFound = 403047;

          // Do not show an error if the email doesn't exist.
          if (response.errorCode === emailNotFound) {
            subscriber.next({ success: true, message: 'Success' });
            return;
          }

          subscriber.next(response);
        },
      })
    );
  }

  /**
   * Initializes the change password from email form. This is the second part of resetting a
   * password, when the user lands from an email he received. It should be called once from the
   * component that uses it, and then it will bind an event to the submit button.
   */
  public changePasswordFromEmailForm(
    newPassword: string
  ): Observable<GigyaResponse> {
    return new Observable(subscriber =>
      this.ciam?.changePassword({
        user: { newPassword },
        callback: response => {
          subscriber.next(response);
        },
      })
    );
  }

  /**
   * Check that the user has an account created
   */
  public hasFullAccount(email: string): Observable<GigyaFullAccountResponse> {
    return new Observable(subscriber =>
      this.ciam?.isAvailableLoginID({
        user: { email },
        callback: (response: any) => {
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }

  /**
   * Create an account for the guest user
   */
  public createLiteAccount(
    email: string,
    country: string,
    subscriptions?: { optionId: string; subscribe: string }[]
  ): Observable<GigyaResponse> {
    const subMap: { [prop: string]: { email: { isSubscribed: string } } } = {};
    if (subscriptions) {
      for (const sub of subscriptions) {
        subMap[sub.optionId] = {
          email: { isSubscribed: sub.subscribe },
        };
      }
    }

    const params_data = {
      user: {
        email,
        data: {
          terms: true,
          acquisitionSource: 'website_checkout',
        },
        profile: {
          country,
        },
        subscriptions: subMap as unknown as GetAccountGigyaSubscriptions,
      },
    };

    return new Observable(subscriber =>
      this.ciam?.createLiteAccount({
        ...params_data,
        callback: (response: any) => {
          subscriber.next(response);
          subscriber.complete();
        },
      })
    );
  }

  /**
   * Removes Gigya token if the session expired.
   */
  private removeTokenOnAuthError(response: GigyaResponse): void {
    if (!response.success) {
      this.userIdService
        .getUserId()
        .pipe(
          take(1),
          filter(userId => userId !== OCC_USER_ID_ANONYMOUS),
          switchMap(() => this.gigyaUidStorageService.removeGigyaUid()),
          tap(() => this.userIdService.clearUserId()),
          tap(() => this.store.dispatch(new AuthActions.Logout()))
        )
        .subscribe();
    }
  }

}
