import { ChangeDetectorRef, Component } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-nonadmin',
  templateUrl: './nonadmin.component.html',
  styleUrl: './nonadmin.component.scss'
})
export class NonadminComponent {
  constructor(private userInfoService: UserInfoService,private cd:ChangeDetectorRef) {
    
  }
  enablepage:any;
  ngOnInit() {
    
   
  }
}
