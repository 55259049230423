<div class="col-xs-12" id="orders_topcontent">
    <div class="col-xs-12 col-md-12 order_banner_uk" id="orders_topcontentleftcontent">
       <div class="col-xs-12 orders_titeltop_uk no-padding">Order Summary</div>
          <div class="col-xs-12 orders_righttitle_uk col-md-7 no-padding">
             You can see a snapshot of your current orders here for the present and upcoming seasons. To see more detailed information, head to <a [routerLink]="'/orders/my-orders'"><u>My Orders</u></a>.</div>
       </div>
 </div>

 <section>
   <div id="ordersummary_OM_seasonConfirmPopup" class="modal fade" role="dialog" style="padding-right: 10px;" [ngClass]="enablepopup?.popup?'show':'hide'">
       <div class="modal-dialog">
           <div class="modal-content">
           <div class="modal-body">
                   <h4 class="modal-title OMconfirm_popHeader col-xs-10">This is an order for the {{enablepopup?.season | seasonspilt}} season</h4>
                       <div class="col-xs-2">
                       <span class="OMconfirm_popClose" data-dismiss="modal" (click)="closepop()">×</span>
                   </div>
                   <p class="OMconfirm_popSubHeader col-xs-12">Would you like to continue?</p>
                   <div class="clearboth"></div>
                   <div class="OMconfirm_PopBtnParent">
                       <button class="OM_popBackBtn seasonPopUpBackButton" data-dismiss="modal">Choose a Different Season</button>
                           <button class="OM_popContBtn" data-dismiss="modal" (click)="continue(enablepopup?.season);closepop()">Continue</button>
                   </div>
               </div>
           </div>
       </div>
   </div>
</section>
