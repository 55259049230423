<div class="flucelvax-firstvideo" >
    <section class="landingpage_videoContainer d-none d-md-block" *ngIf ="node2 | async as item" >
        <video muted="" autoplay="" playsinline="" title="egg as used in flu vaccine production" *ngIf="item?.media" [src]="getVideo(item.media)" type="video/mp4" class="flucelvaxEggVideo" ></video> <!-- Remove Interstitial Message changes-->
    </section>
    <!--hidden-->
    <section class="landingpage_videoContainer d-md-none d-block" *ngIf ="node2 | async as item" >
        <video muted="" autoplay="" playsinline="" title="egg as used in flu vaccine production"*ngIf="item?.media" [src]="getVideo(item.media)" type="video/mp4" class="flucelvaxEggVideo"></video> <!-- Remove Interstitial Message changes-->
    </section>
</div>
 
<div class="fluad-firstvideo" *ngIf ="node2 | async as item">
    <section class="landingpage_videoContainer d-none d-md-block" *ngIf="item.uid=='SeqirusFluadFirstComponent'">
        <video muted autoplay title="shield of adjuvant advantage" class="flucelvaxAdjAdvVideo">
            <source [src]="getVideo(item?.mediaVideo)" type="video/mp4">
        </video>
    </section>
    <section class="landingpage_videoContainer d-md-none d-block" *ngIf="item.uid=='SeqirusFluadFirstMobComponent'">
        <video muted autoplay title="shield of adjuvant advantage" class="flucelvaxAdjAdvVideo">
            <source [src]="getVideo(item?.mediaVideo)" type="video/mp4">
        </video>
    </section>
</div>