<div class="container coding-and-billing-container d-none d-md-block">
    <div id="quick-coding-header--row" class="row-flex" *ngIf="firstheadline | async as item">
        <div *ngIf="item.uid=='seqirusCodingBillingQuickReference'">
            <!--above headings-->
            <div class="col-flex-lg-12 col-flex-md-12 col-flex-sm-12 col-flex-xs-12 padding-L40">
                <h2 class="coding--header" *ngIf="item?.headLine">{{item.headLine}}</h2>
                <p class="coding--subhead" *ngIf="item?.subHeadLine">{{item.subHeadLine}}</p>
            </div>
            <div class="col-flex-lg-12 col-flex-md-12 col-flex-sm-12 col-flex-xs-12 tabs--desktop-layout d-none d-md-block">
                <!--tabs content-->
                <div class="tab--desktop-holder">
                    <div class="tabs--container">
                        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms"
                            class="custom-tab-group">
                            <div *ngFor="let item2 of mergedObject1; let i= index">
                                <mat-tab label="{{item2.h2content}}" class="label">
                                    <div class="tabs--container--holder">
                                        <div class="tabs--container-content" style="background: rgb(255, 255, 255);">
                                            <div style="padding-top: 50px; padding-bottom: 80px;">
                                                <div [ngClass]="{'fluad-chart-container-desktop': i === 0, 'flucelvax-chart-container-desktop': i === 1, 'afluria-chart-container-desktop': i !== 0 && i !== 1}"
                                                    [innerHTML]="item2.content">
                                                </div>
                                            </div>
                                            <a [href]="getpdf(item2.mediaPdf)" target="_blank">{{item2.pdfLinkContent}}<img
                                                    alt="Download" src="assets/images/icon-download.svg"></a>
                                        </div>
                                    </div>
                                </mat-tab>
                            </div>
                        </mat-tab-group>
                    </div>
                </div>
                <!--bottom references-->
                <div class="tabs-footnote--container">
                    <p>{{item?.bottom1Content}}</p>
                    <p [innerHTML]="item?.bottom2Content"></p>
                </div>
            </div>
        </div>
    </div>
</div>
<!--for mobile version-->
<div class="container coding-and-billing-container mobile--coding-and-billing-container d-block d-md-none">
    <div id="quick-coding-header--row" class="row-flex" *ngIf="firstheadline | async as item">
        <div *ngIf="item.uid=='seqirusCodingBillingQuickMobReference'">
            <!--above headings-->
            <div class="col-flex-lg-12 col-flex-md-12 col-flex-sm-12 col-flex-xs-12 padding-L40">
                <h2 class="coding--header" *ngIf="item?.headLine">{{item?.headLine}}</h2>
                <p class="coding--subhead" *ngIf="item?.subHeadLine">{{item?.subHeadLine}}</p>
            </div>

            <div class="row-flex text-center ml-0-mobile">
                <div class="col-md-12 col-sm-12 col-xs-12 mobile-expansion">
                     <!--accordion content-->
                    <mat-accordion>
                        <div *ngFor="let item2 of mergedObject1; let i= index">
                            <mat-expansion-panel class="custom-panel">
                                <!--for above header-->
                                <mat-expansion-panel-header class="custom-panel-header">
                                    <mat-panel-title class="custom-panel-title" *ngIf="i == 0">
                                        FLUAD®
                                    </mat-panel-title>
                                    <mat-panel-title class="custom-panel-title" *ngIf="i == 1">
                                        FLUCELVAX®
                                    </mat-panel-title>
                                    <mat-panel-title class="custom-panel-title" *ngIf="i == 2">
                                        AFLURIA®
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div [innerHTML]="item2?.content"></div>
                                <div class="chart-footer">
                                    <hr>
                                    <!--for download pdf-->
                                    <a href="{{item2?.mediaPdf?.url}}">{{item2?.pdfLinkContent}}
                                        <img alt="Download" [src]="'assets/images/icon-download.svg'">
                                    </a>
                                    <!--for italic footnote-->
                                    <p class="italic-footnote" [innerHTML]="item2?.h2content"></p>
                                    <p class="italic-footnote" [innerHTML]="item2?.position"></p>
                                    <p class="italic-footnote text-align-left" [innerHTML]="item2?.name"></p>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </mat-accordion>
                </div>
            </div>
            <!--bottom references-->
            <div class="tabs-footnote--container mobile-view-footer col-md-12 col-sm-12 col-xs-12 d-block d-md-none">
                <p>{{item?.bottom1Content}}</p>
                <p [innerHTML]="item?.bottom2Content"></p>
            </div>
        </div>
    </div>
</div>