import { Component ,Renderer2,ElementRef} from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fluad-rwe-third',
  templateUrl: './fluad-rwe-third.component.html',
  styleUrl: './fluad-rwe-third.component.scss'
})
export class FluadRweThirdComponent {

  constructor(public component: CmsComponentData<CmsBannerComponent>,private renderer: Renderer2, private el: ElementRef){
  }  

  thirdnode: Observable<any> = this.component.data$;
}
