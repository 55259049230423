import { ChangeDetectorRef, Component,Renderer2,ViewChild, inject } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
export interface PeriodicElement {
  amount: number;
  amountDue: number;
  clearingDate: string;
  dueDate:string;
  invoiceDate:string;
  invoiceNumber:number;
  invoiceQuantity:number;
  location:string;
  salesOrderNumber:number;
  selected:boolean;
  status:string;

} 
export interface credit {
  billTo:any;
  invoiceDate:string;
  invoiceNumber:number;
  formattedAmountWithTax:string;
  status:string;
}

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.scss'
})

export class InvoiceComponent {

  financialresponse:any;
  viewingseason:string='';
  viewingkey:boolean=false;
   selectedseason:string='';
   toggleseason:boolean=false;
   overdueflag:boolean=false;
   openinvoiceflag:boolean=true;
   paidinvoiceflag:boolean=false;
   isLoading:boolean = false;
   filterValues = {
    overdue: '',
    duespon: '',
    open:''

  };
  @ViewChild(MatSort) sort!: MatSort;
  multifilter=0;
   opendata = new MatTableDataSource<PeriodicElement>;
   paiddata = new MatTableDataSource<PeriodicElement>;
   pending=new MatTableDataSource<credit>;
   creditavaible=new MatTableDataSource<credit>;
   applied=new MatTableDataSource<credit>;
   displayedColumns: string[] = ['invoiceNumber','status','amountDue','amount','invoiceDate','dueDate','selected']; 
   paidcolumn: string[] = ['invoiceNumber','status','amount','clearingDate','selected']; 

   creditcolumns:string[]=['billTo','invoiceDate','invoiceNumber','formattedAmountWithTax','status'];
   displayedmobColumns: string[] = ['combined'];

  
  constructor(private userInfoService: UserInfoService,private cd:ChangeDetectorRef,private renderer: Renderer2) {
  }
  private _liveAnnouncer = inject(LiveAnnouncer);
  ngOnInit(): void {
    this.isLoading = true;
    this.seasonchange(this.selectedseason);
    
   
  }
 
  seasonchange(season:string){
    this.userInfoService.financialdashboard(season).subscribe(value=>{
      this.financialresponse=value;
      
      
      if(this.financialresponse?.currentSelectedSeason){
        this.viewingseason=this.financialresponse?.currentSelectedSeason;
      }
      else{
        this.viewingseason=this.financialresponse?.presentSeason;
      }
      for(let i=0;i<this.financialresponse?.overdueinvoiceseason.length;i++){
        if(this.financialresponse?.overdueinvoiceseason[i].value==true){
          this.overdueflag=true;
          
        }
        if(this.viewingseason==this.financialresponse?.overdueinvoiceseason[i].key){
          this.viewingkey=this.financialresponse?.overdueinvoiceseason[i].value;
        }
      }

      this.opendata = new MatTableDataSource(this.financialresponse.seqirusFinancialDashboardData?.openInvoices);
      this.paiddata = new MatTableDataSource(this.financialresponse.seqirusFinancialDashboardData?.paidInvoices);

      console.log(this.financialresponse);
      this.opendata.filterPredicate = this.createFilter();
      this.creditavaible=new MatTableDataSource(this.financialresponse.seqirusFinancialDashboardData?.availableCreditList);
      this.creditavaible.sort = this.sort;
      this.pending=new MatTableDataSource(this.financialresponse.seqirusFinancialDashboardData?.pendingCreditList);
      this.pending.sort = this.sort;
      this.applied=new MatTableDataSource(this.financialresponse.seqirusFinancialDashboardData?.usedCreditList);
      this.applied.sort = this.sort;
      this.isLoading = false;
      this.cd.detectChanges();
    });
   
  }
  seasonchangeclick(season:string){
    this.isLoading = true;
    //const seasonelement=(event.target as HTMLInputElement);
   // console.log(seasonelement.nextElementSibling?.textContent)
    //this.selectedseason=String(seasonelement.nextElementSibling?.textContent)
    this.toggleseason=!this.toggleseason;
    this.seasonchange(season);
  }

  tooltipopen(event:Event){
    const element = (event.target as HTMLInputElement);
   if( element.nextElementSibling?.classList.contains("hide")){
    
    element.nextElementSibling?.classList.remove("hide");
    element.nextElementSibling?.classList.add("show");
    element.nextElementSibling.nextElementSibling?.classList.remove("hide");
    element.nextElementSibling.nextElementSibling?.classList.add("show");
    
    }
    else{
      element.nextElementSibling?.classList.remove("show");
      element.nextElementSibling?.classList.add("hide");
      element.nextElementSibling?.nextElementSibling?.classList.remove("show");
      element.nextElementSibling?.nextElementSibling?.classList.add("hide");
    
    }


  }

  filteropen(event:any,filterKey: string){
    const filterelement = (event.target as HTMLInputElement);
    if (event.target.checked) {
      
      filterelement.classList.add("selected_status");
       if(filterKey=='overdue'){
        this.filterValues.overdue = filterelement.value.trim().toLowerCase();
       
       }else if(filterKey=='duespon'){
        this.filterValues.duespon = filterelement.value.trim().toLowerCase();

       }else if(filterKey=='open'){
        this.filterValues.open = filterelement.value.trim().toLowerCase();
       }
       this.multifilter++;
      
     // this.opendata.filter = filterelement.value.trim().toLowerCase();

    }
    else{
      filterelement.classList.remove("selected_status")
     
      const filterValue="";
      if(filterKey=='overdue'){
        this.filterValues.overdue = filterValue.trim().toLowerCase();
       }else if(filterKey=='duespon'){
        this.filterValues.duespon = filterValue.trim().toLowerCase();

       }else if(filterKey=='open'){
        this.filterValues.open = filterValue.trim().toLowerCase();
       }
       this.multifilter--;
      
    }
  
      this.opendata.filter = JSON.stringify(this.filterValues);
    

   
    
  }
 
  financialgetDownloadInvoiceData(invnumber:string) {
    this.userInfoService.financialdownloadInvoice(invnumber)
      .subscribe(
        (data: ArrayBuffer) => {
          // Create a Blob from the ArrayBuffer
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL); // Open in a new tab
 
          const a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = 'bill.pdf';
          document.body.appendChild(a);
          a.click();
        },
        (error) => {
          console.log('getPDF error: ', error);
        }
      );

  } 
 
  createFilter(): (data: any, filter: string) => boolean {
    return (data, filter): boolean => {
      const searchTerms = JSON.parse(filter);
      if(this.multifilter==2){
        if(searchTerms.overdue && searchTerms.duespon){
          return data.status.toLowerCase().includes(searchTerms.overdue) ||
          data.status.toLowerCase().includes(searchTerms.duespon)
        }
        else if(searchTerms.duespon && searchTerms.open){
          return  data.status.toLowerCase().includes(searchTerms.duespon) || data.status.toLowerCase().includes(searchTerms.open);
        }
      return data.status.toLowerCase().includes(searchTerms.overdue) || data.status.toLowerCase().includes(searchTerms.open);
      }else{
        return data.status.toLowerCase().includes(searchTerms.overdue) &&
             data.status.toLowerCase().includes(searchTerms.duespon) && data.status.toLowerCase().includes(searchTerms.open);
      }
    };
  }
  openseason(){
    this.toggleseason=!this.toggleseason;
  }

  openinvoice(){
this.openinvoiceflag=true;
this.paidinvoiceflag=false;
  }
  paidinvoice(){
    this.openinvoiceflag=false;
this.paidinvoiceflag=true;
  }
   
  applyFilter(event: Event,table:string) {

    const filterValue =(event.target as HTMLInputElement).value;

    if(table=='available'){
    this.creditavaible.filter = filterValue.trim().toLowerCase();
    }
    else if(table=='pending'){
      this.pending.filter = filterValue.trim().toLowerCase();
    }
    else if(table=='applied')
    this.applied.filter = filterValue.trim().toLowerCase();
  }
   /** Announce the change in sort state for assistive technology. */
   announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  opencredit(idindex:number){
    const elements = document.querySelectorAll(`.financial_creditbtn`);
    elements.forEach(element => {
        this.renderer.removeClass(element, "active");
    });
    const elements1 = document.querySelectorAll(`.financial_credittable`);
    elements1.forEach(element => {
        this.renderer.removeClass(element, "open");
    });
    const btnnew= <HTMLElement> document.getElementById("financial_creditbtn-"+idindex);
    const containernew= <HTMLElement> document.getElementById("financial_credittable"+idindex);
    btnnew.classList.add("active");
    containernew.classList.add("open");

  }
 
}
