import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicalSecondComponent } from './clinical-second/clinical-second.component';

import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    ClinicalSecondComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusFlu360ContentSlideResources: {
          component: ClinicalSecondComponent
        }
      }
    } as CmsConfig)
  ]
})

export class ClinicalSupportModule { }
