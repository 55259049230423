import { ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientTokenService, OccEndpointsService } from '@spartacus/core';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-verifyprofile',
  templateUrl: './custom-verifyprofile.component.html',
  styleUrl: './custom-verifyprofile.component.scss'
})
export class CustomVerifyprofileComponent {
  setCurrentScreen: string | undefined='';
  currentRoute: string;
  emailVerificationForm: FormGroup | undefined | any;
  apiErr: string = '';
  paramsCode: string='valid';
  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public userInfoService: UserInfoService,
    private occEndpointsService: OccEndpointsService,
    private commonService: CommonApiService,
    private clientTokenService: ClientTokenService,
    private cd: ChangeDetectorRef,
  ) {
    let url = this.router.url.split('?')[0].split('/');
    this.currentRoute = url[url.length - 1];
    // console.log(this.currentRoute,url)
    this.route.queryParams.subscribe(params => {
      if (params['errorCode'] && params['errorMessage']) {
        this.paramsCode = params['errorCode'];
        // this.setCurrentScreen = 'Account activation link expired';
      }
    })
    this.setForm();
  }
  ngOnInit() {
    if (this.paramsCode!=='403002'){
      
      if (this.paramsCode=='206005'){
        this.redirectToJoinAccount();
      }
      else{
        this.redirectToProfileActivation();
      }
    }
    
    console.log(this.paramsCode);
  }
  redirectToJoinAccount() {
    this.router.navigate(['/register/join-account']);
  }
  redirectToProfileActivation() {
   /*  setTimeout(() => {
      
    }, 3000); */
    this.router.navigate(['/profileactivation']);
  }
  setForm() {
    this.emailVerificationForm = this.fb.group({
      expired_email: ['', [Validators.required, Validators.email]]
    })
  }
  customValidator(control: AbstractControl): ValidationErrors | null {
    // Your custom validation logic here
    if (control) {
      return { 'customError': 'Custom validation failed' };
    }
    return null;
  }
  getFormControl(control: string) {
    return this.emailVerificationForm!.get(control);
  }
  resendVerification() {
    if (!this.emailVerificationForm!.valid) {
      this.emailVerificationForm!.markAllAsTouched();
      return;
    }
    this.clientTokenService.getClientToken().subscribe(res => {
      if (res) {
        let token = res.access_token;
        this.passCalltoVerification(token);
      }
    })

  }
  passCalltoVerification(token: string) {
    let endpoint = this.occEndpointsService.buildUrl(`Verify/VerifyProfileEmail?emailId=${this.emailVerificationForm!.value.expired_email}`);
    let payload = {
      url: endpoint,
      token: token,
      contentType: 'text/xml',
      responseType: 'text',
    }
    this.commonService.getAPICallNoAuth(payload).subscribe({
      next: (res: any) => {
        if (res == 'success') {
          localStorage.setItem('email', this.emailVerificationForm!.value.expired_email);
          this.router.navigate(['/verificationsent']);
        }
        else {
          this.apiErr = res;
        }
        this.cd.detectChanges();
      },
      error: (e) => {
      },
    })
  }

}
