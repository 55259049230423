import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { Observable } from 'rxjs';
import { CmsService } from '@spartacus/core';

@Component({
  selector: 'app-custom-member-modal',
  templateUrl: './custom-member-modal.component.html',
  styleUrl: './custom-member-modal.component.scss'
})
export class CustomMemberModalComponent {
  newCompanyName: string = '';
  thankYouData: Observable<any> = this.cmsService.getComponentData('profileThankyouComponent');
  dataComingFromOrg: boolean=false;
  constructor(private dialog: MatDialogRef<CustomMemberModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private cmsService:CmsService,
    
  ) { }
  ngOnInit() {
    if((this.data.type=='account-thank-you') || (this.data.type=='request-thank-you')){
      this.dataComingFromOrg=true;
    }
    else{
      this.dataComingFromOrg=false;
    }
  }
  close() {
    this.dialog.close();
  }
}
