import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-uk-all-invoice-top-banner',
  templateUrl: './uk-all-invoice-top-banner.component.html',
  styleUrl: './uk-all-invoice-top-banner.component.scss'
})
export class UkAllInvoiceTopBannerComponent {

  seasonData :Observable<any> =  this.userInfoService.getseasonDropdown("2024-2025");
  dropdownDetail: any;
  openCount: string="0";
  paidCount: string="0";
  finalSeason: any;
  
  constructor(public userInfoService:UserInfoService,private cd:ChangeDetectorRef,private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(){
    this.seasonData.subscribe((res:any)=>{
      this.dropdownDetail = res;
      this.finalSeason =this.dropdownDetail.inSeason;
      this.userInfoService.sendDropdownValue(this.dropdownDetail.inSeason);
      this.cd.detectChanges();
    })
  }

  getOpenInvoiceCount(){
    this.userInfoService.invoiceCount$.subscribe(count => {
      this.openCount = count;
    })
    return this.openCount;
  }
  getPaidInvoiceCount(){
    this.userInfoService.paidInvoiceCount$.subscribe(count => {
      this.paidCount = count;
      
    })
    return this.paidCount;
  }

  selectedSeason(e :any){
    this.finalSeason =(e.target as HTMLInputElement).value
    this.userInfoService.sendDropdownValue(this.finalSeason);
    this.cd.detectChanges();
  }
}
