<section class="tabsectionuk col-sm-12">
<div class=" col-sm-12 myprofile_tabs">
    <div class=" col-sm-4 myprofile_tab "[ngClass]="enablePage.usertab?'active':''" id="myprofile_tab-1" (click)="tabclick('user')">
        <div class="myprofile_tabHeader">User</div>
    </div>
    <div class=" col-sm-4 myprofile_tab" [ngClass]="enablePage.account?'active':''" id="myprofile_tab-2" (click)="tabclick('account')">
        <div class="myprofile_tabHeader">Account</div>
    </div>
    <div class=" col-sm-4 myprofile_tab"  [ngClass]="enablePage.permissionadmin?'active':''"id="myprofile_tab-3" (click)="tabclick('rba')">
     <div class="myprofile_tabHeader permissiontext">Permissions Admin</div>
     <div class="myprofile_tabHeader nonpermissiontext">Permissions</div>
    </div>
</div>
</section>
<section class="col-sm-12 redheader" [ngClass]="enablePage.usertab?'show':''"  >
    <div class="col-sm-12 no-padding myprofile_tabContentHeader">User Details</div>
</section>