<div class="product_image-textSide flucelvax_imageside" *ngIf="node1 | async as item">
    <div class="container">
        <div class="product_image-textSide-container ">
            <div class="product_image-textSide-content">
                <div class="product_image-textHeader">{{item.headLine}}</div>
                <div [innerHTML]="item.textContent"></div>
                <a href="/products/flucelvax/real-world-evidence" class="product_box1-button">{{item.link1Name}}</a>
                <div class="product_box1-disclaimer hidden-md hidden-lg">{{item.bottomContent}}</div>
            </div>
            <div class="product_image-textSide-Image">
                <div class="simple-banner banner__component--responsive" *ngIf="item?.media">
                    <!-- <img class="js-responsive-image" alt="Young girl being given a flu vaccine" title="Young girl being given a flu vaccine" src="../../../app/../assets/images/Image_FLX_Landing_02_Desktop.webp"> -->
                    <img class="js-responsive-image young-girl" alt="Young girl being given a flu vaccine"
                        title="Young girl being given a flu vaccine" [src]="getDesktopImage(item.media)">
                </div>
            </div>
        </div>
        <!-- <div class="clearboth"></div>
        <div class="product_box1-disclaimer hidden-xs hidden-sm">{{item.bottomContent}}.</div>
    </div> -->
    </div>
</div>
<div class="clearboth"></div>
<div class="product_box1-disclaimer hidden-xs hidden-sm flucelvax-disclaimer">*2017-2018 through 2019-2020 US influenza seasons.</div>

<div class="product_image-textSide fluad_imageside" *ngIf="node1 | async as item">
    <div class="container">
        <div class="product_image-textSide-container ">
            <div class="product_image-textSide-content">
                <div class="product_image-textHeader">{{item?.headLine}}</div>
                <div [innerHTML]="item.textContent"></div>
                <a [href]="item.link1Url" class="product_box1-button product_fluad-button">{{item?.link1Name}}</a>
                <a [href]="item.link2Url" class="product_box1-button product_box1-button2">{{item?.link2Name}}</a>
                <div class="product_box1-disclaimer">{{item?.bottomContent}}</div>
            </div>
            <div class="product_image-textSide-Image">
                <div class="simple-banner banner__component--responsive" *ngIf="item?.media">
                    <img class="js-responsive-image d-none d-md-block" alt="An older man being examined by doctor"
                        title="An older man being examined by doctor" [src]="getDesktopImage(item.media)">
                    <!-- <img class="js-responsive-image d-md-none d-sm-block" alt="An older man being examined by doctor" title="An older man being examined by doctor" src="../../../assets/images/Homepage-Image2-Mobile-2x.webp"> -->
                </div>
            </div>
        </div>
        <div class="clearboth"></div>
    </div>
</div>