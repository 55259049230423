import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomInfluenzaReportedComponent } from './custom-influenza-reported/custom-influenza-reported.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomEachYearComponent } from './custom-each-year/custom-each-year.component';



@NgModule({
  declarations: [
    CustomInfluenzaReportedComponent,
    CustomEachYearComponent
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusInfluenzaEachYearComponent:{
          component:CustomEachYearComponent
       },
        SeqirusInfluenzaCdcReportedComponent:{
           component:CustomInfluenzaReportedComponent
        }
      }
    }as CmsConfig)
  ]
})
export class InfluenzaModule { }
