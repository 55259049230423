import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroOverviewComponent } from './hero-overview/hero-overview.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { OverviewLaptopComponent } from './overview-laptop/overview-laptop.component';
import { MultipleNeedsComponent } from './multiple-needs/multiple-needs.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    HeroOverviewComponent,
    OverviewLaptopComponent,
    MultipleNeedsComponent
  ],
  imports: [
    CommonModule,
    CmsCommonModule,
    RouterModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusLeftimageBannerComponent: {
          component: HeroOverviewComponent
        },
        SeqirusRightBannerComponent :{
          component: OverviewLaptopComponent
        },
        SeqirusMultipleNeedsComponent:{
          component: MultipleNeedsComponent
        }
      }
    } as CmsConfig)
  ]
})
export class OverviewModule { }
