import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomChallengesBannerComponent } from './custom-challenges-banner/custom-challenges-banner.component';
import { ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CustomChallengesBannerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusChallengesBannerComponent: {
          component: CustomChallengesBannerComponent
        },
      }
    })
  ]
})
export class FluadProductModule { }
