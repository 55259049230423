import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, switchMap, throwError, of, filter, BehaviorSubject, catchError, map } from 'rxjs';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { ViewportScroller } from '@angular/common';

declare var clickToAddress:any;

@Injectable({
  providedIn: 'root'
})
export class RegistrationUkService {

  constructor(private http: HttpClient, private occ:OccEndpointsService,private viewportScroller: ViewportScroller,private userIdService: UserIdService) { }

  getTradingYears(): Observable<any> {
    const url = this.occ.buildUrl("/users/current/register/getTradingYears?fields=DEFAULT");
    return this.http.get(url);
  }

  submitRegistration(payload: any): Observable<any> {
    const apiUrl = this.occ.buildUrl("/users/current/register/saveRegData?fields=DEFAULT");
    return this.http.post<any>(apiUrl, payload);
  }

  fetchifyCallUK(){
    return new clickToAddress({
      accessToken: '252fa-0e273-cf8ab-03341',
      countryMatchWith: 'text', 
      enabledCountries: ['United Kingdom'],
      domMode: 'name',
      gfxMode: 1,
      style: {
        ambient: 'light', // Use white as main interface color
        accent: 'default' // Use default secondary color for interface
      },
      texts: {
      default_placeholder: 'Start with post/zip code or street',
      },
      showLogo: false,
      disableAutoSearch:false,
     
    })
  }
}
