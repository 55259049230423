<div class="product_image-textCenter image-textCenter-real-world" *ngIf="fifthnode | async as item">
  <div class="simple-banner banner__component--responsive">
    <div class="simple-banner banner__component--responsive">
      <img
        class="js-responsive-image"
        alt="a woman aged 65 or older receiving a flu shot from a pharmacist"
        title="a woman aged 65 or older receiving a flu shot from a pharmacist"
        style=""
        [src]="getImage(item.media)"
      />
    </div>
  </div>
  <div class="container">
    <div class="product_image-textcontainer">
      <div class="product_image-textHeader" [innerHTML]="item.headLine">
        >
      </div>
      <a
        href="/medias/FLUAD-Quadrivalent-ACIP-Flashcard-Digital.pdf?context=bWFzdGVyfHJvb3R8MTI1MjM0MHxhcHBsaWNhdGlvbi9wZGZ8YURobEwyZzFPUzg0T1RNek1qSTNNams0T0RRMkwwWk1WVUZFSUZGMVlXUnlhWFpoYkdWdWRDQkJRMGxRSUVac1lYTm9ZMkZ5WkNBdElFUnBaMmwwWVd3dWNHUm18NzYyNjg4NGM1YTY5M2RjYzM5ZjMyZjM1NGU2Y2VmMmE1ODU4NWRlODUyMzYyNGJlNTQ4MWQyYzlhZjhkYjQ0NQ"
        target="_blank"
        download=""
      >
        <span>{{item.pdfText}}</span
        ><img
          alt="Download"
          src="../../../assets/images/product_download_icon.svg"
      /></a>
    </div>
  </div>
</div>