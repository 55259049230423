import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimeoutModalService } from '../../spartacus/services/timeout-modal.service';
import { BaseSiteService } from '@spartacus/core';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrl: './timeout-modal.component.scss'
})
export class TimeoutModalComponent implements OnInit, OnDestroy{

  private destroy$ = new Subject<void>();
  showModal = false;
  minutes = 5;
  seconds = 0;
  private countdownInterval: any;
  currentSite: string = '';

  constructor(private baseSiteService: BaseSiteService, private timeoutModalService: TimeoutModalService, private cdr: ChangeDetectorRef) {
    console.log('TimeoutModalComponent constructed');
  }

  ngOnInit(): void {
    console.log('Initializing TimeoutModalComponent');

    this.baseSiteService.getActive().pipe(
      takeUntil(this.destroy$)
    ).subscribe(site => {
      this.currentSite = site;
    });

    this.timeoutModalService.showModal$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(show => {
      console.log('Modal visibility changed:', show);
      this.showModal = show;
      if (show) {
        this.startCountdown();
      } else {
        this.stopCountdown();
      }
      this.cdr.detectChanges();
    });

    this.timeoutModalService.initialize();
  }

  private startCountdown(): void {
    this.minutes = 5;
    this.seconds = 0;
    this.countdownInterval = setInterval(() => {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          this.stopCountdown();
          this.cdr.detectChanges();
          return;
        }
        this.minutes--;
        this.seconds = 59;
      } else {
        this.seconds--;
      }
      this.cdr.detectChanges();
    }, 1000);
  }

  private stopCountdown(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  resetSession(): void {
    this.timeoutModalService.resetSession();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stopCountdown();
  }

}
