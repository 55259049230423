import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectorRef } from '@angular/core';
import { GalleryImage } from '../location-data.model';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ReturnsUkService } from '../returns-uk.service';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss']
})
export class FileUploadModalComponent {
  @Output() imagesUploaded = new EventEmitter<{ locationID: string, images: GalleryImage[], files: File[] }>();
  @Output() imageCountUpdated = new EventEmitter<{ locationID: string, count: number }>();
locationID: string;
selectedImage: GalleryImage | null = null;
selectedIndex: number = -1;
slideLeft: boolean = false;
slideRight: boolean = false;
checkboxStates: { [key: string]: boolean } = {};
checkboxErrorMessages: { [key: string]: string } = {};
imgUploadarea: boolean = true;
imgGalleryarea:boolean = false;
thumbImgarea:boolean = false;
shwDonebtn:boolean = false;

constructor(
  private dialogRef: MatDialogRef<FileUploadModalComponent>,
  private returnsUkService: ReturnsUkService,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private cdr: ChangeDetectorRef
) {
  this.locationID = data.locationID;
}

get locationImages(): GalleryImage[] {
  return this.returnsUkService.getImages(this.locationID);
}

get locationImageFiles(): File[] {
  return this.returnsUkService.getImageFiles(this.locationID);
}

onFileDrop(files: NgxFileDropEntry[]): void {
  for (const droppedFile of files) {
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        const validImageTypes = ['image/jpeg', 'image/png'];
        const maxSizeInMB = 5;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

        if (!validImageTypes.includes(file.type)) {
          alert('Only image files are allowed!');
        } else if (file.size > maxSizeInBytes) {
          alert(`The file size exceeds the maximum limit of ${maxSizeInMB}MB.`);
        } else {
          console.log('Uploaded file:', file); // Log the file details to the console
          
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const image: GalleryImage = { src: e.target.result, thumb: e.target.result, name: file.name };
            // Store the image in the service for display purposes
            this.returnsUkService.addImage(this.locationID, image);
            this.imgGalleryarea = true;
          this.imgUploadarea = false;
          this.thumbImgarea = true;
          this.shwDonebtn = true;
            this.cdr.detectChanges(); // Detect changes
            if (this.locationImages.length === 1) {
              this.selectedImage = image;
              this.selectedIndex = 0;
            }
             // Emit the updated image count
             this.imageCountUpdated.emit({ locationID: this.locationID, count: this.locationImages.length });
          };
          reader.readAsDataURL(file);

          // Store the original file in the service for payload
          this.returnsUkService.addImageFile(this.locationID, file);
          // Update checkbox state
          
          this.checkboxStates[this.locationID] = false;
          console.log('Checkbox state updated:', this.checkboxStates[this.locationID]);
          this.cdr.detectChanges(); 
          
        }
      });
    } else {
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      console.log(droppedFile.relativePath, fileEntry);
    }
  }
}

uploadMore(){
  this.imgGalleryarea = false;
  this.imgUploadarea = true;
  this.thumbImgarea = true;
  this.shwDonebtn = true;

}
selectImage(index: number): void {
  this.selectedIndex = index;
  this.selectedImage = this.locationImages[index];
  this.imgGalleryarea = true;
  this.imgUploadarea = false;
  this.thumbImgarea = true;
  this.shwDonebtn = true;
  this.cdr.detectChanges(); 
}

previousImage(): void {
  if (this.locationImages.length > 0) {
    this.slideLeft = true;
    setTimeout(() => {
      this.selectedIndex = (this.selectedIndex - 1 + this.locationImages.length) % this.locationImages.length;
      this.selectedImage = this.locationImages[this.selectedIndex];
      this.slideLeft = false;
    }, 100); // Duration of the slide animation
  }
}

nextImage(): void {
  if (this.locationImages.length > 0) {
    this.slideRight = true;
    setTimeout(() => {
      this.selectedIndex = (this.selectedIndex + 1) % this.locationImages.length;
      this.selectedImage = this.locationImages[this.selectedIndex];
      this.slideRight = false;
    }, 100); // Duration of the slide animation
  }
}

removeSelectedImage(): void {
  if (this.selectedIndex !== -1) {
    this.returnsUkService.removeImage(this.locationID, this.selectedIndex);
    this.returnsUkService.removeImageFile(this.locationID, this.selectedIndex);
    this.cdr.detectChanges();
    if (this.locationImages.length > 0) {
      this.selectedIndex = this.selectedIndex % this.locationImages.length;
      this.selectedImage = this.locationImages[this.selectedIndex];
    } else {
      this.selectedIndex = -1;
      this.selectedImage = null;
      this.imgGalleryarea = false;
      this.imgUploadarea = true;
      this.thumbImgarea = false;
      this.cdr.detectChanges(); 
    }
    // Emit the updated image count
    this.imageCountUpdated.emit({ locationID: this.locationID, count: this.locationImages.length });
  }
}

onClose(): void {
  this.imagesUploaded.emit({ locationID: this.locationID, images: this.locationImages, files: this.locationImageFiles });
  this.dialogRef.close();
}

uploadImages(): void {
  this.imagesUploaded.emit({ locationID: this.locationID, images: this.locationImages, files: this.locationImageFiles });
  this.dialogRef.close();
}

ngOnInit(): void {
  const images = this.returnsUkService.getImages(this.locationID);
  if (images.length > 0) {
    this.selectedIndex = 0;
    this.selectedImage = images[0];
    this.imgGalleryarea = true;
    this.imgUploadarea = false;
    this.thumbImgarea = true;
    this.shwDonebtn = true;
      this.cdr.detectChanges();
  }
}
}
