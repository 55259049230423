<section class="col-xs-12 col-md-12 mt-5" id="inseasonthankconfirm" style="display: block;" *ngIf="enablePage.showConfirmThankYouPage && data">
    <div class="thankorder_box">
            <span  class="thankorder_head" [innerHtml]="data.content"></span><span  class="thankorder_head">{{seasonValue | seasonspilt}} season</span>
                <div  class="thankorder_text mt-4" [innerHtml]="data.t1content"></div>
                
<!-- have to show  for both preseason -->
<div class="feedbackheader">How was your experience with the ordering process?</div>
<div style="font-family: Verdana; font-size: 12px; overflow:hidden;">
<table cellpadding="0" cellspacing="0" class="feedbacktable no-padding">
    <tbody><tr>
        <td class="feedbackbox">
            <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=2756a64e&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9NCZ0aGVybW9tZXRlcl9pZD0xNzk1MDcmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=" target="_blank" class="showPopupclick">
                <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/red.png" alt="Red Light" title="Red Light" class="_temp">
            </a>
        </td>
        <td class="feedbackbox">
            <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=d0ae2825&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MyZ0aGVybW9tZXRlcl9pZD0xNzk1MDcmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=" target="_blank" class="showPopupclick">
                <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/amber.png" alt="Yellow Light" title="Yellow Light" class="_temp">
            </a>
        </td>
        <td class="feedbackbox">
            <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=fe8f4ce9&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MiZ0aGVybW9tZXRlcl9pZD0xNzk1MDcmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=" target="_blank" class="showPopupclick">
                <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/green.png" alt="Green Light" title="Green Light" class="_temp">
            </a>
        </td>
        <td class="feedbackbox">
            <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=299143ee&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MSZ0aGVybW9tZXRlcl9pZD0xNzk1MDcmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=" target="_blank" class="showPopupclick">
                <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/gold.png" alt="Gold Star" title="Gold Star" class="_temp">
            </a>
        </td>

    </tr>

</tbody></table>
</div>


<a class="my-order-anchor showPopupclick" (click)="handleOnOrdersClick()">
                <div class="viewbutton">View Orders</div>
                </a>
            </div>
        </section>
