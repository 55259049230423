import { ChangeDetectorRef, Component } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-sorry-page',
  templateUrl: './sorry-page.component.html',
  styleUrl: './sorry-page.component.scss'
})
export class SorryPageComponent {
  enablePage: any;
  seasonValue: any;
  sorryContent: Observable<any> = this.PageParagraphComponent.data$;
  data: any;
  constructor(private userInfoService: UserInfoService,private cdr: ChangeDetectorRef, private router: Router,public PageParagraphComponent : CmsComponentData<any>) { }
  ngOnInit() {
    this.userInfoService.getstartorderseason().subscribe((seasonValue: any) => {
      this.seasonValue = seasonValue;
      this.userInfoService.getEnablePage().subscribe((data: any) => {
        this.enablePage = data;
        this.cdr.detectChanges();
      })
    })
    this.sorryContent.subscribe((res: any) => {
      this.data=res;
    });
  }
  handleOnOrdersClick() {
    const obj = {
      showSorryPage: false,
    }
    this.userInfoService.setEnablePage(obj);
    this.router.navigate(
      ['/orders/my-orders'],
      { queryParams: { season: this.seasonValue} }
   );
  }
  
}
