import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-cartbanner',
  templateUrl: './cartbanner.component.html',
  styleUrl: './cartbanner.component.scss'
})
export class CartbannerComponent {
  constructor(public PageParagraphComponent: CmsComponentData<any>, private userInfoService: UserInfoService, private cdr: ChangeDetectorRef) { }

  startcart: Observable<any> = this.PageParagraphComponent.data$;
  enablePage: any;

  ngOnInit() {

    this.userInfoService.getEnablePage().subscribe((data: any) => {
      this.enablePage = data;
      this.cdr.detectChanges();
    })
  }
}
