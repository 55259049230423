<!-- for top banner-->
<div class="fluad-top-navigation" *ngIf="topheadline | async as item">
    <!--desktop version-->
    <section class="product_common-banner fluad_common-banner desktop-banner">
        <div class="simple-banner banner__component--responsive" *ngIf="item?.media">
            <img class="js-responsive-image" alt="cellular-level depiction of circulating influenza virus"
                title="cellular-level depiction of circulating influenza virus"
                [src]="getImage(item.media)">
        </div>
        <div class="container logocontainer">
            <img class="common-banner_logo" alt="" src="assets/images/Fluad_TIV2024_US_Logo_RGB.webp">
        </div>
    </section>
    <!--for fluad mobile top banner-->
    <section class="product_common-banner fluad_common-banner mobile-banner" [ngClass]="{'logOut':!isUserLoggedIn}">
        <div class="simple-banner banner__component--responsive" *ngIf="item?.media">
            <img class="js-responsive-image" alt="cellular-level depiction of circulating influenza virus"
                title="cellular-level depiction of circulating influenza virus"
                [src]="getMobileImage(item.media)">
        </div>
        <div class="container mobilelogocontainer">
            <img class="common-banner_logo" alt="" src="assets/images/Fluad_TIV2024_US_Logo_RGB.webp">
        </div>
    </section>
</div>
