import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { FeaturesConfig, I18nConfig, I18nModule, OccConfig, provideConfig, SiteContextConfig,PageMetaConfig } from "@spartacus/core";
import { defaultB2bOccConfig } from "@spartacus/setup";
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from "@spartacus/storefront";
import { translationOverwrites } from '../translations/en/custom_asm';
 
@NgModule({
  declarations: [],
  imports: [I18nModule
  ],
  providers: [provideConfig(layoutConfig), provideConfig(mediaConfig), ...defaultCmsContentProviders, provideConfig(<SiteContextConfig>{
  
    context: {
      currency:['USD'],
      language: ['en'],
    },
  }), provideConfig({
    i18n: {
      backend: {
        loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json'
      },
      resources: {
        en: translations
      },
      chunks: {
        ...translationChunksConfig,
        dashboardPage: ['invoice','credit'],
        joinAccountPage: ['breadcrumb','form'],
        customAsm: ['asm'],
        startOrderPage: ['startOrder','memebership'],
        orgLocation:['orgLocation','orgLocform'],
        registration:['progresslabel']
      },
      fallbackLang: 'en',
    }
  }), provideConfig(<FeaturesConfig>{
    features: {
      level: '2211.31'
    }
  }), provideConfig(defaultB2bOccConfig),
  provideConfig(<PageMetaConfig>{
    pageMeta:{
      resolvers:[
        {property: 'title' , method: 'resolveTitle', },
        {property: 'heading' , method: 'resolveHeading', },
        {property: 'breadCrumbs' , method: 'resolveBreadCrumbs', },
        {property: 'description' , method: 'resolveDescription', disabledInCsr: false, },
        {property: 'image' , method: 'resolveImage', disabledInCsr: true, },
        {property: 'robots' , method: 'resolveRobots', disabledInCsr: false, },
        {property: 'canonicalUrl' , method: 'resolveCanonicalUrl', disabledInCsr: false, },

      ]
    }
  }),
  provideConfig({
    i18n: {
      resources: translationOverwrites
    },
  })]
})
export class SpartacusConfigurationModule { }