import { ChangeDetectorRef, Component } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable, combineLatest } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-fluad-rwe-second',
  templateUrl: './fluad-rwe-second.component.html',
  styleUrl: './fluad-rwe-second.component.scss'
})
export class FluadRweSecondComponent {

  cardStates: boolean[] = [];

  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, private cd: ChangeDetectorRef) {
    this.cardStates = Array(11).fill(false);
   }
  firstheadline: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];
  mergedObject2: any = [];
  mergedObject3: any = [];
    left:boolean=true;
    right:boolean=false;
    

    ngOnInit() {
      this.firstheadline.subscribe(res => {
        this.clinicaltabs(res);
      });
    }
  
    clinicaltabs(res: any) {
      // const tilesList = res.reductionList.split(" ");
      // const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));
  
      // combineLatest(observables).pipe(
      //   map((results:any) => results.flat())
      // ).subscribe((mergedResults: any) => {
      //   this.mergedObject1 = mergedResults;
      //   this.cd.detectChanges();
      //   console.log(this.mergedObject1);
      //   
      // });
      const tilesList = res.reductionList.split(" ");
      this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
       this.mergedObject1 = res.component;
       console.log(this.mergedObject1);
       this.internalcard1(this.mergedObject1[0]);
        this.internalcard2(this.mergedObject1[1]);
       this.cd.detectChanges;

      });
    }

    internalcard1(res: any) {
      
      // const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));
  
      // combineLatest(observables).pipe(
      //   map((results:any) => results.flat())
      // ).subscribe((mergedResults: any) => {
      //   this.mergedObject2 = mergedResults;
      //   this.cd.detectChanges();
      //   console.log(this.mergedObject2);
      // });
      const tilesList = res.tileList.split(" ");
      this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
       this.mergedObject2 = res.component;
       console.log(this.mergedObject2);
       this.cd.detectChanges;
      });
    }
  
    internalcard2(res: any) {
      // const tilesList = res.tileList.split(" ");
      // const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));
  
      // combineLatest(observables).pipe(
      //   map((results:any) => results.flat())
      // ).subscribe((mergedResults: any) => {
      //   this.mergedObject3 = mergedResults;
      //   this.cd.detectChanges();
      //   console.log(this.mergedObject3);
      // });
      const tilesList = res.tileList.split(" ");
      this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
       this.mergedObject3 = res.component;
       console.log(this.mergedObject3);
       this.cd.detectChanges;
    });
  }

    onClickLeft(){
      this.left = true;
      this.right = false;
    }

    onClickRight(){
      this.right=true;
      this.left=false;
    }

    onClickCard1(index: number): void {
      this.cardStates[index] = !this.cardStates[index];
    }

    

}
