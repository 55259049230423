
// import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
// import { ContentService } from '../../spartacus/services/content.service';
// import { Observable, combineLatest } from 'rxjs';
// import { CmsComponentData } from '@spartacus/storefront';
// import { CmsBannerComponent, OccConfig } from '@spartacus/core';
// import { map } from 'rxjs/operators';

// @Component({
//   selector: 'app-custom-carousel',
//   templateUrl: './custom-carousel.component.html',
//   styleUrls: ['./custom-carousel.component.scss']
// })

// export class CustomCarouselComponent implements OnInit {
//   thirdnode: Observable<any> = this.bannerComponent.data$;
//   mergedObject1: any = [];
//   toggleArray: any = [];
//   subTileData: any = [];
//   subTileData1: any = [];
//   selectedTile: any;
//   selectedTileIndex: number | null = 0; // Default to the first tile

//   constructor(
//     private accessService: ContentService,
//     public bannerComponent: CmsComponentData<CmsBannerComponent>,
//     protected config: OccConfig,
//     private cd: ChangeDetectorRef
//   ) {}

//   getImage(image: any): string {
//     return this.config.backend?.occ?.baseUrl + image.desktop.url;
//   }

//   ngOnInit() {
//     this.thirdnode.subscribe((res: any) => {
//       console.log('Response from thirdnode:', res); // Log the response
//       this.productTabs(res);
//     });
//   }

//    productTabs(res: any) {
//     // const prodList = res.productInfoList.split(" ");
//     // const observables = prodList.map((el: any) => this.accessService.opeffeaturedResources(el));

//     // combineLatest(observables).pipe(
//     //   map((results:any) => results.flat())
//     // ).subscribe((mergedResults: any) => {
//     //   this.mergedObject1 = mergedResults;
//     {
//       const tilesList = res.AllResources
//       .split(" ");
//       console.log('tilesList:', tilesList);
//       this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
      
        
//         // Check if apiRes.component is an array
//         if (Array.isArray(res.component)) {
//           this.mergedObject1 = res.component;
//         } else if (res.component) {
//           // Convert to array if it's not already
//           this.mergedObject1 = [res.component];
//         } else {
//           // Ensure it's always an array
//           this.mergedObject1 = [];
//         }
  
//         console.log('mergedObject1:', this.mergedObject1);
//         this.cd.detectChanges();  // Ensure detectChanges is called correctly
//       });
//     }
//       for(let i = 0; i < this.mergedObject1.length; i++) {
//         if (i == 0) {
//           this.toggleArray.push(true)
//         } else {
//           this.toggleArray.push(false)
//         }
//       }
//       this.cd.detectChanges();
    
//   }

//   toggleNav(i : number) {
//     for (let x = 0; x < 3; x++) {
//       if (x !== i) {
//         this.toggleArray[x] = false;
//       }
//     }
//     this.toggleArray[i] = true;
//   }
//   fetchSubTileData1(resourceString: string) {
//     const subTileIds = resourceString.split(' ');
//     const subTileObservables = subTileIds.map((id: string) => this.accessService.opeffeaturedResources(id));
//     combineLatest(subTileObservables).subscribe((subTileResults: any) => {
//       console.log('Sub Tile Results1:', subTileResults); // Log the sub-tile results
//       this.subTileData1 = subTileResults;
//       this.cd.detectChanges();
//     });
//   }
//   fetchSubTileData(resourceString: string) {
//     const subTileIds = resourceString.split(' ');
//     const subTileObservables = subTileIds.map((id: string) => this.accessService.opeffeaturedResources(id));
//     combineLatest(subTileObservables).subscribe((subTileResults: any) => {
//       console.log('Sub Tile Results:', subTileResults); // Log the sub-tile results
//       this.subTileData = subTileResults;
//       this.cd.detectChanges();
//     });
//   }
//   toggleNav1(i: number) {
//     this.toggleArray = this.toggleArray.map((_: any, index: number) => index === i);
//     this.selectedTile = this.mergedObject1[i]; // Update selected tile content
//     // Fetch sub-tile data when the fourth tile is toggled
//     if (this.mergedObject1[i].thirdCOMP) {
//       this.fetchSubTileData1(this.mergedObject1[i].thirdCOMP.vaccineFeaturedResources);
//     }
//     this.cd.detectChanges();
//   }
//   // toggleNav(i: number) {
//   //   this.toggleArray = this.toggleArray.map((_: any, index: number) => index === i);
//   //   this.selectedTile = this.mergedObject1[i]; // Update selected tile content
//   //   // Fetch sub-tile data when the fourth tile is toggled
//   //   if (this.mergedObject1[i].fourthCOMP) {
//   //     this.fetchSubTileData(this.mergedObject1[i].fourthCOMP.vaccineInfoFeaturedResourcesfactor);
//   //   }
//   //   this.cd.detectChanges();
//   // }
// }

// import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
// import { ContentService } from '../../spartacus/services/content.service';
// import { Observable, combineLatest } from 'rxjs';
// import { CmsComponentData } from '@spartacus/storefront';
// import { CmsBannerComponent, OccConfig } from '@spartacus/core';
// import { map } from 'rxjs/operators';
// @Component({
//   selector: 'app-custom-carousel',
//   templateUrl: './custom-carousel.component.html',
//   styleUrls: ['./custom-carousel.component.scss']
// })
// export class CustomCarouselComponent implements OnInit {
//   thirdnode: Observable<any> = this.bannerComponent.data$;
//   mergedObject1: any = [];
//   toggleArray: any = [];
//   activeTabIndex!: number;
//   subTileData: any = [];
//   // fourthTab:boolean = false;
//   constructor(
//     private accessService: ContentService,
//     public bannerComponent: CmsComponentData<CmsBannerComponent>,
//     protected config: OccConfig,
//     private cd: ChangeDetectorRef
//   ) {}
//   getImage(image: any): string {
//     return this.config.backend?.occ?.baseUrl + image.desktop.url;
//   }
//   ngOnInit() {
//     this.thirdnode.subscribe((res: any) => {
//       console.log('Response from thirdnode:', res); // Log the response
//       this.productTabs(res);
//     });
//   }
 
//   productTabs(res: any) {
//     const prodList = res.AllResources.split(" ");
//     console.log('Product List:', prodList); // Log the product list
//     const observables = prodList.map((el: any) => this.accessService.opeffeaturedResources(el));
//     combineLatest(observables).pipe(
//       map((results: any) => results.flat())
//     ).subscribe((mergedResults: any) => {
//       console.log('Merged Results:', mergedResults); // Log the merged results
//       this.mergedObject1 = mergedResults;
//       for (let i = 0; i < this.mergedObject1.length; i++) {
//         this.toggleArray.push(i === 0);
//       }
//       this.cd.detectChanges();
//       // Check and fetch sub-tile data for the fourth tile
//       const fourthTile = this.mergedObject1.find((item: any) => item.uid === 'aQIVCarausalComp4');
//       if (fourthTile && fourthTile.fourthCOMP) {
//         this.fetchSubTileData(fourthTile.fourthCOMP.vaccineInfoFeaturedResourcesfactor);
//       }
//     });
//   }
//   fetchSubTileData(resourceString: string) {
//     const subTileIds = resourceString.split(' ');
//     const subTileObservables = subTileIds.map((id: string) => this.accessService.opeffeaturedResources(id));
//     combineLatest(subTileObservables).subscribe((subTileResults: any) => {
//       console.log('Sub Tile Results:', subTileResults); // Log the sub-tile results
//       this.subTileData = subTileResults;
//       console.log('error handled');
//       this.cd.detectChanges();
//     });
//   }
//   toggleNav(i: number) {
//     this.toggleArray = this.toggleArray.map((_: any, index: number) => index === i);
//     // Fetch sub-tile data when the fourth tile is toggled
//     if (this.mergedObject1[i] && this.mergedObject1[i].fourthCOMP) {
//       this.fetchSubTileData(this.mergedObject1[i].fourthCOMP.vaccineInfoFeaturedResourcesfactor);
//     }
//     this.cd.detectChanges();
//   }
// //  fourthClick(){
// //   this.toggleArray[0] = false;
// //   this.toggleArray[1] = false;
// //   this.toggleArray[2] = false;
// //   this.fourthTab = ! this.fourthTab;
// //   console.log('clicked');

// //  }
//   setActiveTab(index: number) {
//     this.activeTabIndex = index;
//     this.cd.detectChanges();
//   }
  
// }
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable, combineLatest } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss']
})

export class CustomCarouselComponent implements OnInit {
  thirdnode: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];
  toggleArray: any = [];
  subTileData: any = [];
  subTileData1: any = [];
  selectedTile: any;
  selectedTileIndex: number | null = 0; // Default to the first tile

  constructor(
    private accessService: ContentService,
    public bannerComponent: CmsComponentData<CmsBannerComponent>,
    protected config: OccConfig,
    private cd: ChangeDetectorRef
  ) {}

  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }

  ngOnInit() {
    this.thirdnode.subscribe((res: any) => {
      console.log('Response from thirdnode:', res); // Log the response
      this.productTabs(res);
    });
  }

  productTabs(res: any) {
    const prodList = res.AllResources.split(" ");
    console.log('Product List:', prodList); // Log the product list
    const observables = prodList.map((el: any) => this.accessService.opeffeaturedResources(el));
    combineLatest(observables).pipe(map((results: any) => results.flat())).subscribe((mergedResults: any) => {
      console.log('Merged Results:', mergedResults); // Log the merged results
      this.mergedObject1 = mergedResults;
      this.mergedObject1.forEach((_: any, i: number) => this.toggleArray[i] = i === 0);
      this.selectedTile = this.mergedObject1[0]; // Default to the first tile
      this.cd.detectChanges();
      // Check and fetch sub-tile data for the fourth tile
      const fourthTile = this.mergedObject1.find((item: any) => item);
      if (fourthTile && fourthTile.fourthCOMP) {
        this.fetchSubTileData(fourthTile.fourthCOMP.vaccineInfoFeaturedResourcesfactor);
      }
      const thirdTile = this.mergedObject1.find((item: any) => item.uid =='QIVCCarausalComp5');
      if(thirdTile && thirdTile.thirdCOMP){
        this.fetchSubTileData1(thirdTile.thirdCOMP.vaccineFeaturedResources);
      }
    });
  }
  fetchSubTileData1(resourceString: string) {
    const subTileIds = resourceString.split(' ');
    const subTileObservables = subTileIds.map((id: string) => this.accessService.opeffeaturedResources(id));
    combineLatest(subTileObservables).subscribe((subTileResults: any) => {
      console.log('Sub Tile Results1:', subTileResults); // Log the sub-tile results
      this.subTileData1 = subTileResults;
      this.cd.detectChanges();
    });
  }
  fetchSubTileData(resourceString: string) {
    const subTileIds = resourceString.split(' ');
    const subTileObservables = subTileIds.map((id: string) => this.accessService.opeffeaturedResources(id));
    combineLatest(subTileObservables).subscribe((subTileResults: any) => {
      console.log('Sub Tile Results:', subTileResults); // Log the sub-tile results
      this.subTileData = subTileResults;
      this.cd.detectChanges();
    });
  }
  toggleNav1(i: number) {
    this.toggleArray = this.toggleArray.map((_: any, index: number) => index === i);
    this.selectedTile = this.mergedObject1[i]; // Update selected tile content
    // Fetch sub-tile data when the fourth tile is toggled
    if (this.mergedObject1[i].thirdCOMP) {
      this.fetchSubTileData1(this.mergedObject1[i].thirdCOMP.vaccineFeaturedResources);
    }
    this.cd.detectChanges();
  }
  toggleNav(i: number) {
    this.toggleArray = this.toggleArray.map((_: any, index: number) => index === i);
    this.selectedTile = this.mergedObject1[i]; // Update selected tile content
    // Fetch sub-tile data when the fourth tile is toggled
    if (this.mergedObject1[i].fourthCOMP) {
      this.fetchSubTileData(this.mergedObject1[i].fourthCOMP.vaccineInfoFeaturedResourcesfactor);
    }
    this.cd.detectChanges();
  }
}
