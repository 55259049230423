
<nav aria-label="breadcrumb">
    <ol class="breadcrumb padLeft79">
    <li class="breadcrumb-item active"><a href="/" class="blacktext">Home</a></li>
    <li class="breadcrumb-item" aria-current="page">Cookie Policy</li>
    </ol>
    </nav>    
    <div class="cookiePolicyLogout col-xs-12" *ngIf="node | async as item">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cookiePolicyLogout_tableLayout">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
    <h1 class="cookiePolicyLogout_left_header new_header_cookie">{{ item.headline }}</h1>
    <button id="ot-sdk-btn" class="ot-sdk-show-settings" (click)="showCookieSettings()">{{item.buttonLabel}}</button>
    <div id="ot-sdk-cookie-policy"></div>
    </div>
    </div>
   </div> 

  
   