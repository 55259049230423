<!-- <p>fluad-third works!</p> -->
<div class="breadcrumbs--page-nav hidden-xs hidden-sm fluad-breadcrumbs" *ngIf="!userLoggedIn">
    <div class="container">
        <p><a [routerLink]="'/'">Home</a><span> &gt; </span><a [routerLink]="'/products'">Products</a><span> &gt;
            </span><strong>FLUAD</strong></p>
    </div>
</div>
<div class="product_image-textCenter fluad_image-center" *ngIf="challengesbanner | async as item">
    <div class="simple-banner banner__component--responsive">
        <img class="js-responsive-image"
            alt="A mature woman and man, side-by-side facing the same direction, gazing into the distance"
            title="A mature woman and man, side-by-side facing the same direction, gazing into the distance"
          [src]="getImage(item.media)">
    </div>
    <!-- <div class="product_image-textinside hidden-md hidden-lg">challenges of protecting adults <span
            class="product_blue">65+</span> really add up.</div> -->
    <div class="container">
        <div class="product_image-textcontainer">
            <div class="product_image-textHeader">
                <div class="product_image-textHeader1 hidden-xs hidden-sm"[innerHTML]="item.head1Line"></div>
                <!--for mobile-->
                <!-- <div class="product_image-textHeader1 hidden-md hidden-lg">{{item.head1Line}}</div>
                <div class="product_image-subtext1" [innerHTML]="item.text1Content"></div>               -->
                <div class="product_imagePlus">+</div>
                <div class="product_image-textHeader2 hidden-xs hidden-sm" [innerHTML]="item.head2Line"></div>
                <!--for mobile-->
                <!-- <div class="product_image-textHeader2 hidden-md hidden-lg">{{item.head2Line}}</div>
                <div class="product_image-subtext2" [innerHTML]="item.text2Content"></div> -->
            </div>
            <!--subtext for laptop-->
            <div class="product_image-text1 hidden-xs hidden-sm">
                <div class="product_image-subtext1" [innerHTML]="item.text1Content"></div>
                <div class="product_image-subtext3"></div>
                <div class="product_image-subtext2" [innerHTML]="item.text2Content"></div>
            </div>
        </div>
        <div class="product_box1-disclaimer">{{item.bottomContent}}</div>
    </div>
</div>