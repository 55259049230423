import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { BannerSectionDashboardComponent } from './banner-section-dashboard/banner-section-dashboard.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { HelpSectionDashboardComponent } from './help-section-dashboard/help-section-dashboard.component';
import { RecentOrderComponent } from './recent-order/recent-order.component';
import { OpenCreditInvoiceDashboardComponent } from './open-credit-invoice-dashboard/open-credit-invoice-dashboard.component';
import { I18nModule } from '@spartacus/core';
import { NewsAnnouncementComponentComponent } from './news-announcement-component/news-announcement-component.component';
import { RouterModule } from '@angular/router';
import { PopupdashboardComponent } from './popupdashboard/popupdashboard.component';


@NgModule({
  declarations: [
    BannerSectionDashboardComponent,
    HelpSectionDashboardComponent,
    RecentOrderComponent,
    OpenCreditInvoiceDashboardComponent,
    NewsAnnouncementComponentComponent,
    PopupdashboardComponent,
   
  ],
  imports: [
    CommonModule,
    CmsCommonModule,
    I18nModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusBannerParagraphComponent: {
          component: BannerSectionDashboardComponent,
        },
        CMSTabParagraphComponent: {
          component: HelpSectionDashboardComponent
        },
        NewsAndAnnouncementComponent: {
          component: NewsAnnouncementComponentComponent
        },
       
        InvoicesAndCreditsComponent: {
          component: OpenCreditInvoiceDashboardComponent
        },
       
        RecentOrderHistoryComponent: {
          component: RecentOrderComponent,
        },
       
        DashboardPopupFlexComponent: {
          component: PopupdashboardComponent
        }


      }
    } as CmsConfig)
  ],
})
export class DashboardModule { }
