import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FluadRweFirstComponent } from './fluad-rwe-first/fluad-rwe-first.component';
import { ConfigModule } from '@spartacus/core';
import { FluadRweSecondComponent } from './fluad-rwe-second/fluad-rwe-second.component';
import { FluadRweThirdComponent } from './fluad-rwe-third/fluad-rwe-third.component';
import { FluadRweFourthComponent } from './fluad-rwe-fourth/fluad-rwe-fourth.component';
import { FluadRweFifthComponent } from './fluad-rwe-fifth/fluad-rwe-fifth.component';


@NgModule({
  declarations: [
    FluadRweFirstComponent,
    FluadRweSecondComponent,
    FluadRweThirdComponent,
    FluadRweFourthComponent,
    FluadRweFifthComponent
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusProductRWEVideoBannerComponent:{
          component:FluadRweFirstComponent
        },
        SeqirusReductionsFluComponent:{
          component:FluadRweSecondComponent
        },
        SeqirusRwePdfComponent:{
            component:FluadRweThirdComponent
        },
        SeqirusLookBothComponent:{
          component:FluadRweFourthComponent
        },
        SeqirusAcipPdfComponent:{
          component:FluadRweFifthComponent
        }
    }
})
]
})
export class FluadModule { }
