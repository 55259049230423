<!-- Multiple Sold to Popup -->

<div *ngIf="showMultipleSoldToPopup" class="modal fade in show" id="MultiSoldtoPopup" role="dialog" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" style="padding-right: 10px; display: block;" aria-labelledby="exampleModalCenterTitle">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title multisoldto_header" id="exampleModalLongTitle">Select an account to view<span class="multisoldto_closebtn"><a href="/logout">&times;</a></span></div>
          <div class="multisoldto_subheader">You're signed in as&nbsp; <span id="custEmail">{{userEmail}}</span></div>
          <div class="multisoldto_searchsection">
            <i class="fa fa-search"></i>
            <input type="text" class="multisoldto_searchbar" placeholder="Search accounts" (keyup)="applyFilter($event)"/>
          </div>
        </div>
        <div class="modal-body">
          <div id="multisold_wrapper" class="dataTables_wrapper no-footer">
            <div class="dataTables_scroll">
              
            <div class="dataTables_scrollBody" style="position: relative; overflow-y: auto;overflow-x:hidden; max-height: 230px; width: 100%;">
                <table id="multisold" class="display no-footer dataTable" mat-table [dataSource]="dataSource" role="grid" style="width:94%;">
            <!-- <thead><tr role="row" style="height: 1.33333px;">
            <th class="sorting_disabled" rowspan="1" colspan="1" style="padding-top: 0px; padding-bottom: 0px; border-top-width: 0px; border-bottom-width: 0px; height: 0px; width: 0px;"><div class="dataTables_sizing" style="height: 0px; overflow: hidden;"></div></th><th class="multisoldtoselectarrow sorting_disabled" rowspan="1" colspan="1" style="padding-top: 0px; padding-bottom: 0px; border-top-width: 0px; border-bottom-width: 0px; height: 0px; width: 0px;"><div class="dataTables_sizing" style="height: 0px; overflow: hidden;"></div></th><th class="multisoldtoacc sorting_disabled" rowspan="1" colspan="1" style="padding-top: 0px; padding-bottom: 0px; border-top-width: 0px; border-bottom-width: 0px; height: 0px; width: 0px;"><div class="dataTables_sizing" style="height: 0px; overflow: hidden;"></div>
            </th></tr>
            </thead> -->
            <ng-container matColumnDef="soldTo">
              <th mat-header-cell *matHeaderCellDef  ></th>
              <td mat-cell *matCellDef="let row" style="width: 50%;" ><div class="multi_orgcontainer"><p class="multi_orgname">{{row.organizationName}}</p><p class="multi_pincode">{{row.postalCode}}</p></div></td>
          </ng-container>

          <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef ></th>
              <td mat-cell *matCellDef="let row" class=" multisoldtoselectarrow" style="width:50%" (click)="selectSoldTo(row.accountNumber)">Select</td>
          </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> 

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell no-matching">No matching records found</td>
            </tr>
					
                </table>
            </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Multiple Sold to Popup end -->
