<!-- top header -->
  <ng-container *ngIf="!isUserLoggedIn || (isUserLoggedIn && (!userInfo?.webEnabled))" >


    <div *ngIf="(node$ | async) as navNode" >
      <div class="header d-none d-sm-none d-md-block d-lg-block" [ngClass]="{'foruk_header': Ukclass}" *ngIf="navNode.title == 'For GB Healthcare Professionals Only' || navNode.title == 'For GB Patients and members of the public'">
        <div class="container container-top-header">
          <div class="row">
            <div class="col-sm-4 leftsectionheader"> 
             
              <div *ngIf="navNode.title == 'For GB Healthcare Professionals Only' ||navNode.title == 'For GB Patients and members of the public'" class="for-healthcare-profe healthcare-US">
              
               
                <span>{{navNode.title}}</span>
                
              
            </div>
            
            </div>
            <div class="col-sm-8 rightsectionheader"> 
              <div class="header-nav">
              <ul class="login-links marginBottom" *ngFor="let nav of navigation">
                <li class="dropdown" *ngIf="nav.title != 'For GB Healthcare Professionals Only' && nav.title !='For GB Patients and members of the public'">
                  <a [routerLink]="nav.url"
                    class="dropdown-toggle droppreb" id="drop1" data-toggle="dropdown" role="button" aria-haspopup="true"
                    aria-expanded="true">
                    <ng-container *ngIf="isUserLoggedIn && (!userInfo?.webEnabled)">
                      <ng-container *ngIf="nav.title === 'Log In'">
                        <a [routerLink]="{ cxRoute: 'logout' } | cxUrl">Log Out</a>
                        </ng-container>  
                        <ng-container *ngIf="nav.title !== 'Log In'">
                        {{nav.title}}
                        </ng-container>
                      </ng-container> 
                      <ng-container *ngIf="!isUserLoggedIn">
                      {{nav.title}}
                      </ng-container>
                    <span *ngIf="nav.children" class="glyphicon glyphicon-menu-down"
                      style="top: 3px;">
                    </span>
                    <span *ngIf="nav.title === 'Log In'" class=""
                      style="margin-left: 5px; color: #2A3237 !important;">
                      <img alt="Log in icon" style="width: 28px;"
                        src="/assets/images/login_icon.png">
                    </span>
                  </a>
                  
                  
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>


    </div>    

<div *ngIf="(node$ | async) as navNode">
  <div class="header d-none d-sm-none d-md-block d-lg-block" *ngIf="navNode.title == 'For US Healthcare Professionals Only'">
    <div class="container container-top-header">
      <div class="row">
        <div class="col-sm-4 leftsectionheader"> 
          <ng-container *ngFor="let node of navigation">
          <div *ngIf="node.title == 'For US Healthcare Professionals Only'"
          class="for-healthcare-profe healthcare-US">
          <div class="button" style="cursor: pointer;" (click)="openList()"   [ngClass]="toggleListView?'open':''">
            <img src="assets/images/AmericanFlag_Icon.png">
            <span>{{node.title}}<span class="glyphicon glyphicon-menu-down"></span></span>
            
            <ul class="select-list">
              <li class="clsAnchor" [style.display]="toggleListView?'':'none'">
                <a href="{{node.children[0].url}}">
                  <img
                    src="assets/images/GBFlag_Icon.png">
                    {{node.children[0].title}}</a> 
              </li>
            </ul>
          </div>
        </div>
        </ng-container>
      </div>
        <div class="col-sm-8 rightsectionheader"> 
          <div class="header-nav">
          <ul class="login-links marginBottom" *ngFor="let nav of navigation">
            <li class="dropdown" *ngIf="nav.title != 'For US Healthcare Professionals Only'" [ngClass]="nav.title== 'About' || nav.title==  'Support'?'headerpadding':''">
              <a [routerLink]="nav.url"
                class="dropdown-toggle droppreb" id="drop1" data-toggle="dropdown" role="button" aria-haspopup="true"
                aria-expanded="true">
                <ng-container *ngIf="isUserLoggedIn && (!userInfo?.webEnabled)">
                  <ng-container *ngIf="nav.title === 'Log In'">
                    <a [routerLink]="{ cxRoute: 'logout' } | cxUrl">Log Out</a>
                    </ng-container>  
                    <ng-container *ngIf="nav.title !== 'Log In'">
                    {{nav.title}}
                    </ng-container>
                  </ng-container> 
                  <ng-container *ngIf="!isUserLoggedIn">
                  {{nav.title}}
                  </ng-container>
                <span *ngIf="nav.children" class="glyphicon glyphicon-menu-down"
                  style="top: 3px; ">
                </span>
                <span *ngIf="nav.title === 'Log In'" class=""
                  style="margin-left: 5px; color: #2A3237 !important;">
                  <img alt="Log in icon" style="width: 28px;"
                    src="/assets/images/login_icon.png">
                </span>
              </a>
              <ul class="dropdown-menu header_dropdwn" aria-labelledby="drop1" *ngIf=" nav.title != 'Prescribing Information' && nav.children else piNode;">
               <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: nav.children }">
                </ng-container>
              </ul>
              <ng-template #piNode>
                <ul class="dropdown-menu header_dropdwn" aria-labelledby="drop1">
                <ng-container *ngFor="let child of nav.children">
                  <li><a [href]="getImage(child)" target="_blank" title="{{child.title}}" [innerHTML]="child.title"></a></li>
                    </ng-container>
                  </ul>
              </ng-template> 
              
            </li>
          </ul>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div *ngIf="navNode.title == 'For US Healthcare Professionals Only'" class="header d-md-none d-lg-none d-block d-sm-block">
    <div class="for-healthcare-profe-mob col-xs-12 col-sm-12" *ngFor = "let node of navNode.children">
      <div class="healthcare-US" *ngFor="let child of node.children" >
        
        <div *ngIf="child.title == 'For US Healthcare Professionals Only'">
        <div class="button" (click)="openList()">
          <img src="assets/images/AmericanFlag_Icon.png"> <span>{{child.children[0].title}}</span><span class="glyphicon glyphicon-menu-down"></span>
      </div>
      
      <ul class="select-list">
        <li class="clsAnchor" [style.display]="toggleListView?'':'none'">
            <a [routerLink]="child.children[1].url"><img src="assets/images/GBFlag_Icon.png"><span class="">{{child.children[1].title}}</span></a>
        </li>
       
    </ul>
    
  </div>
  </div>
  </div>
  </div>
</div>



<ng-template #recursiveList let-children>
  <ng-container *ngFor="let child of children">
    <li><a [routerLink]="child.url" title="{{child.title}}" [innerHTML]="child.title"></a>
      <ul *ngIf="child.children">
        <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: child.children }"></ng-container>
      </ul>
    </li>
  </ng-container>
</ng-template>

   <!-- bottom header -->

   <ng-template #recursiveNavBarList let-children>
    <ng-container *ngFor="let child of children">
      <li *ngIf= "child.title != 'Clinical Support' && child.title != 'Financial Guidance'" class="dropdown_first_level"><a
        [routerLink]="child.url"
        class="nav-link" [innerHTML]="child.title"></a>
         <ul *ngIf="child.children">
    <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: child.children }"></ng-container>
    </ul>
    </li>
     <li *ngIf= "child.title == 'Clinical Support' || child.title == 'Financial Guidance'" class="dropdown_first_level"><a
      class="nav-link trip"
      [routerLink]="child.url">{{child.title}}</a>
      <ul class="second_level_menu">
        <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: child.children }"></ng-container>
    </ul>
    </li>
    </ng-container>
   </ng-template>
   
   <div  *ngIf="(node$ | async) as navNode">
    <header *ngIf="navNode.title != 'For US Healthcare Professionals Only'" class="headerbar d-md-block d-lg-block d-none d-sm-none">
     <nav class="container container-bottom-header">
       <div class="row headerSection">
       <div class="col-md-12 col-lg-12 navcolumn" *ngFor="let nav of navNode.children">
         <nav class="primary-nav" [ngClass]="currentSite == 'seqirusb2b-uk'?'Primary-navUk':''">
           <ul class="" *ngFor = "let node of nav.children">
            <ng-container *ngIf="(currentSite == 'seqirusb2b-uk' && node.title != 'Create Account' && isUserLoggedIn && !userInfo?.webEnabled) || !isUserLoggedIn || currentSite == 'seqirusb2b-flu360'">
             <li *ngIf = "node.title != 'Prescribing Information' && node.title != 'Important Safety Information' && node.title != 'About' && node.title != 'Support' && node.title != 'Log In'" 
             [ngClass]="node.title == 'Create Account' ? 'redborder':'nav-item dropdown has-megamenu'">
             
             <a [ngClass]="node.title == 'Create Account' ? 'create-account' : 'nav-link dropdown-toggle under_arrow'" 
             data-toggle="dropdown" 
             [routerLink]="node.children?'':(isUserLoggedIn && (!userInfo?.webEnabled)?'/register/join-account':node.url)">
             <ng-container *ngIf="!isUserLoggedIn">
              <span [innerHTML]="node.title"></span>
              </ng-container>
              <ng-container *ngIf="isUserLoggedIn && (!userInfo?.webEnabled)">
                <span [innerHTML]="node.title==='Create Account'?'Complete My Profile':node.title">
                </span>
                </ng-container>
             
              <span *ngIf ="node.children" class="glyphicon glyphicon-menu-down"></span>
              </a>

                     <div *ngIf ="node.children" class="indicator_arrow"><i class="fa fa-angle-up" aria-hidden="true"></i></div>
                     <ul class="dropdown-menu megamenu pro_menu" style="position: absolute;
                     transform: translate3d(0, 157px, 0px) !important; 
                     top: 0px; 
                     left: 0px; 
                     will-change: transform;">
                         <ng-container *ngTemplateOutlet="recursiveNavBarList; context: { $implicit: node.children }">
                         </ng-container>
                     </ul>
             </li>
            </ng-container>
           </ul>
        
         </nav>
       </div>
     </div>
   </nav>
   </header>
   </div>

   <div *ngIf="(node$ | async) as navNode">
    <div *ngIf="navNode.title == 'Main Navigation Component'" class="d-md-none d-lg-none d-block d-sm-block">
            <div class="mob-logo-wrap">
              
              <div class="col-xs-5 col-sm-6 mob-menu">
                <i class="fa" [ngClass]="iconClass" (click)="toggleSlide()"></i>
              </div>
            </div>
            <div class="mob_exp_menu">
              
              <!-- Accordion Menu START -->
              <div class="panel-group" id="accordion">
                
                  <div *ngFor = "let node of mobnav; index as i">
                    <div *ngIf="node.title != 'About' && node.title != 'Support'" class="panel panel-default">
                      <div *ngIf="node.title != 'Create Account'">
                          
                          <div *ngIf="node.children" class="panel-heading accordion-toggle collapsed"
                            data-toggle="collapse" data-parent="#accordion"
                            [attr.data-target]="'#collapse'+i" [attr.href]="'#collapse'+i" style="cursor:pointer;">
                              
                          
                          <h4 *ngIf="node.title === 'Prescribing Information' || node.title === 'Important Safety Information';else singlenode" class="panel-title-default">{{node.title}}</h4>
                              <ng-template #singlenode >
                              <h4 *ngIf ="node.children" class="panel-title" [innerHTML]="node.title">{{node.title}}</h4>
                              <!-- <h4 *ngIf ="node.title == 'Tools & Resources'" class="panel-title"><a [routerLink]="node.url" [innerHTML]="node.title">{{node.title}}</a></h4>  -->
                            </ng-template>
                          </div>
                          <div *ngIf ="node.title == 'Tools & Resources'" class="panel-heading"><h4 class="panel-title"><a [routerLink]="node.url" [innerHTML]="node.title">{{node.title}}</a></h4></div>
                            
                    <div id="collapse{{i}}" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="mob_sub_menu">
                          <ul *ngIf="node.title == 'Prescribing Information' || node.title == 'Important Safety Information';else othernode" class="second_level_mob_sub">
                                <div *ngFor="let child of node.children">
                                    <li *ngIf="node.title == 'Prescribing Information'"><a [href]="getImage(child)"
                                      target="_blank" title="{{child.title}}" [innerHTML]="child.title"></a>
                                    </li>
                                  
                                  
                                    <li *ngIf="node.title == 'Important Safety Information'"><a [routerLink]="child.url"
                                      target="_self" title="{{child.title}}" [innerHTML]="child.title"></a>
                                    </li>
                                  
                                </div>
                              </ul>
                              <ng-template #othernode>
                              <div *ngFor="let child of node.children">
                                <li ><a [routerLink]="child.url"
                                  target="_self" title="{{child.title}}" [innerHTML]="child.title">{{child.title}}</a>
                                  <ul class="second_level_mob_sub">
                                    <div *ngFor="let grandchild of child.children">
                                      <li><a
                                        [routerLink]="child.url"
                                        target="_self" title="{{grandchild.title}}">{{grandchild.title}}</a></li>
                                    </div>
                                  </ul>
                                </li>
                              </div>
                              </ng-template>
                            
                        </ul>
                      </div>
                    </div>
              </div>
            </div>
    
        <div *ngIf="node.title == 'About'" class="panel-heading">
          <h4 class="panel-title-default">
            <a [routerLink]="node.url" target="_self" title="node.title">{{node.title}}</a>
          </h4>
        </div>
    
        <div *ngIf="node.title == 'Support'" class="panel-heading">
          <h4 class="panel-title-default">
            <a [routerLink]="node.url" target="_self" title="node.title">{{node.title}}</a>
          </h4>
        </div>
    
        
                  </div>
      
        
        <!-- Accordion menu END -->
    
        <div class="default-mob-menu-item">
          <div *ngFor = "let parnode of navNode.children">
            
        <div *ngFor = "let node of parnode.children">
          <div *ngIf="node.title === 'Create Account'" class="col-xs-6">
            <div class="redborder">
            <a class="create-account" [routerLink]="node.url">{{node.title}}</a>
            </div>
          </div>
          <div *ngIf="node.title === 'Log In'"class="col-xs-6 mob-login-icon">
            <a class="log-in" [routerLink]="node.url">{{node.title}}<span
                  class="" style="top: 3px;"><img
                    alt="Log in icon" src="assets/images/login_icon.png" /></span></a>
    
          </div>
          </div>
          </div>
        </div>
              </div>
       </div>
      </div>
  </div>
</ng-container>   

<!--loggedin header-->
  <div *ngIf="isUserLoggedIn && userInfo?.webEnabled && currentSite === 'seqirusb2b-flu360'" class="d-none d-sm-none d-md-block d-lg-block">
    <header class="headerbar hidden-xs hidden-sm visible-md-* visible-lg-*">
			<nav class="container-fluid loggedIn customlog">
				<div class="row headerSection">
					<div class="col-lg-4 col-md-1 dashboard_search">
                                        </div>
                    <div class="container_outside container_outside"> <!-- account dropdown changes -->
					<div class="col-lg-1 col-md-3 dashboard_cart" *ngIf="isCartExist | async as item">
						<!-- cart icon changes-->
						
							<a class="savedbox" [routerLink]="['/start-order']" style="text-decoration: none !IMPORTANT">
								<img src="/assets/images/head_cart.png" width="27" alt="cart"><span class="savedcartdot" style="display: inline;" *ngIf="item.toLowerCase() == 'yes'"></span>&nbsp;Cart</a>
						</div>
					
					<div class="col-lg-2 col-md-3 dashboard_profile" >
					<a href="#" *ngIf="userInfo" data-toggle="collapse" data-target="#headermyprofile-one" class="profilenavlinks collapsed" aria-expanded="false"><img src="/assets/images/head_profile.png" width="25" alt="profile"> {{userInfo?.firstName+' '+ userInfo?.lastName}}<i class="fa fa-fw .fas fa-angle-down"></i></a>
							
						<ul id="headermyprofile-one" class="header_myprofile-submenu collapse" aria-expanded="false" style="height: 30px;">
							<li class="welcomemsg" *ngIf="userInfo">Good Morning, {{userInfo?.firstName+' '+ userInfo.lastName}}</li>
							<li (click)="goMyprofile()"><a style="cursor: pointer;"  class="profilenavlinks">
								<img class="headprofile" src="/assets/images/head_profile.png" width="25" alt="profile">My Profile</a></li>
							<li>
                <a [routerLink]="['/logout']" class="profilenavlinks"><i class="" style="padding: 5px;"><img src="/assets/images/logout_icon.png"></i>Log Out</a>
                </li>

						</ul>

					</div>

					</div>
					</div>
			</nav>
		</header>
    </div>

    <div *ngIf="isUserLoggedIn && userInfo?.webEnabled && currentSite === 'seqirusb2b-uk'" class="d-none d-sm-none d-md-block d-lg-block">
      {{toprbafunction()}}
      <header class="headerbar hidden-xs hidden-sm visible-md-* visible-lg-*">
        <nav class="container-fluid loggedIn customlogUK">
          <div class="row headerSection">
            <div class="dashboard_searchUK"></div>
              <div class="container_outside container_outsideUK"> <!-- account dropdown changes -->
                <div class="dashboard_cartUK">
                  <!--  add condition to show place order or not
                    -->
                     
                      <div class="dashboard_orderUK placebtn" *ngIf="rbavarible?.RBAPermissions==true && rbavarible?.showDashboardOrderUK==true">
                        <a [routerLink]="['/start-order']" style="text-decoration: none !IMPORTANT" >
                            <img
                            src="assets/images/head_cart.png"
                            width="27" alt="placeorder">
                            &nbsp; Place an Order
                        </a>
                      </div>
                      <div class="dashboard_orderUK" *ngIf="rbavarible?.RBAPermissions!=true">
                        <a [routerLink]="['/start-order']" style="text-decoration: none !IMPORTANT" >
                            <img
                            src="assets/images/head_cart.png"
                            width="27" alt="placeorder">
                            &nbsp; Place an Order
                        </a>
                      </div>
                   
             <!--        <ng-template #withoutrba>
                      <div class="dashboard_orderUK">
                        <a [routerLink]="['/start-order']" style="text-decoration: none !IMPORTANT" >
                            <img
                            src="assets/images/head_cart.png"
                            width="27" alt="placeorder">
                            &nbsp; Place an Order
                        </a>
                      </div>
  
                    </ng-template> -->
                 

                    <div class="dashboard_accountUK" *ngIf="showStatus()">
                      <a href="#" data-toggle="collapse"  class="profilenavlinks dashboard_accountUK-arrow" (click)="toggleSlideUkAcc()" [ngClass]="{'collapsed':!isCollapsed}">
                        <img [src]="iconUkAcc=='fa-angle-down'?'assets/images/Icon_Cart_Grey_110.png':'assets/images/Icon _Cart_red.png'" width="25" alt="profile"><span id="selectCompanyName">{{userInfo.orgUnit.name}}</span><i class="fa fa-fw " [ngClass]="iconUkAcc=='fa-angle-down'?'fa-angle-down':'fa-angle-up'" ></i></a>
                    
                      <div id="headeraccount-dropdown" class="collapse header_myprofile-submenuUK" [ngClass]="{'show':isCollapsed}">
                      <div class='header_dropdown-topsection'>
                          <div class='header_accountText'>Accounts</div>
                          <a [routerLink]="['/register/join-account']" class="header_joinaccountText">Link To Another Account</a>
                      </div>

                      <div *ngIf="multipleSoldTos?.length > 1;else singleAcc" class='header_multipleAccount dymanicAccount'> <!-- to be hidden for single account -->
                        <div class="header_multipleAccount-searchbar">
                           <div class="header_multipleAccount-searchicon">
                               <i class="fa fa-search"></i>
                           </div>
                           <input class="header_multipleAccount-searchfield" id="header_multipleAccount-searchfield" name="lookup" placeholder="Search accounts" type="text" [(ngModel)]="searchText" (input)="filterAccount()">                                                                   
                        </div>
                       <div class="header_multipleAccount-radiogroup"> 
                       
                       <ng-container *ngFor="let acc of filteredMultipleSoldTos;index as i">
                       <div class="col-xs-12 multipleaccount-radiocontainer">
                                   <div class="multipleaccount-radio" [ngClass]="selectedAccount === acc.accountNumber ? 'radiobtn-checked':''">
                                       <input type="radio" class="multipleaccount-radiobtn"  id="multipleaccount-radiobtn{{i}}" [checked]="selectedAccount === acc.accountNumber"  (change)="onRadiochange(acc.accountNumber)" [value]="acc.accountNumber"/>
                                       <span class="radio-button__control"></span>
                                       <label for="multipleaccount-radiobtn{{i}}">{{acc.organizationName}}</label> 
                                   </div>
                                   <label class="multipleaccount-postcode" for="multipleaccount-radiobtn{{i}}">{{acc.postalCode}}</label>
                        </div>
                        </ng-container>
                        
                      </div>
                        <button class="multipleaccount_switchAccount" [disabled]="selectedAccount === userInfo?.orgUnit.uid" (click)="changeAccount()">Switch Account</button>
                        <div class="clearboth"></div>
                      </div>
                      <ng-template #singleAcc>
                        <div class='header_singleAccount'>
                          You can have multiple accounts associated with your user profile.
                        </div>
                      </ng-template>
                      </div>
                      
                  </div>
                  </div>
                    
                  <div class="col-lg-2 col-md-3 dashboard_profileUK">
                      <!-- <c:set var="asagent" value="${agent.uid}" /> -->
                        <a href="#" data-toggle="collapse"
                          data-target="#headermyprofile-one" class="profilenavlinks"><img
                          src="assets/images/head_profile.png"
                          width="25" alt="profile"> {{userInfo?.firstName+' '+ userInfo.lastName}}<i
                          class="fa fa-fw fa-angle-down "></i></a>
                          
                        <ul id="headermyprofile-one"
                          class="collapse header_myprofile-submenuUK">
                          
                          <li><a [routerLink]="['/my-profile']"
                            class="profilenavlinks">
                            <img class="headprofileuk"
                              src="assets/images/head_profile.png"
                              width="25" alt="profile">My Profile</a></li>
                          <li><a href="/logout" class="profilenavlinks"><i
                              class="" style="padding: 5px;"><img
                                src="assets/images/logout_icon.png" /></i>Log Out</a>
                          </li>
            
                        </ul>
            
                  </div>
                      
                
                </div>
          </div>
        </nav>
      </header>
    </div>


<!--logged in mobile header-->

<div *ngIf="isUserLoggedIn && userInfo?.webEnabled" class="d-md-none d-lg-none d-block d-sm-block">
            <div class="col-xs-12 col-sm-12 mobile_view mob-menu-login">
              <a class="savedbox" [routerLink]="['/start-order']" style="text-decoration: none !IMPORTANT;">
                  <img src="/assets/images/head_cart.png" width="27" alt="cart"> <span class="savedcartdotmob" style="display: inline;"></span>
              </a>&nbsp;
              <a [routerLink]="['/my-profile']" class="profilenavlinks">
                <img src="/assets/images/head_profile.png" width="25" alt="profile">
                </a>
                <a class=" profilenavlinks">
                  <i class="fa"  [ngClass]="iconClassMob=='fa fa-times'?'fa fa-times mob_nav_click':'fa fa-bars'" (click)="toggleSlideLoggedInMob()" aria-hidden="true"></i>
                  </a> 
                
            </div>
			</div>
