import { Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alfuria-fourth',
  templateUrl: './alfuria-fourth.component.html',
  styleUrl: './alfuria-fourth.component.scss'
})
export class AlfuriaFourthComponent {

  fourthnode: Observable<any> = this.bannerComponent.data$;
  
  constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig) { }

  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
}
