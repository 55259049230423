<div class="popupCloseParent">
    <span class="imgCarousel_popClose" data-dismiss="modal" (click)="closeDialog()">x</span>
</div>
  <gallery [items]="images" [thumbs]="true"></gallery>

  
  <div class="dialog-footer">
    <button class="imgCarouselDone_btn" (click)="closeDialog()">Done</button>
  </div>
 
