import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { AllInvoicesBannerSectionComponent } from './all-invoices-banner-section/all-invoices-banner-section.component';
import { AllInvoicesLeftSectionComponent } from './all-invoices-left-section/all-invoices-left-section.component';


import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import { PaymentTestComponent } from './payment-test/payment-test.component';
import { UkAllInvoiceTopBannerComponent } from './uk-all-invoice-top-banner/uk-all-invoice-top-banner.component';
import { InvoiceDetailsSectionComponent } from './invoice-details-section/invoice-details-section.component';


@NgModule({
  declarations: [
    AllInvoicesBannerSectionComponent,
    AllInvoicesLeftSectionComponent,
    PaymentTestComponent,
    UkAllInvoiceTopBannerComponent,
    InvoiceDetailsSectionComponent
  ],
  imports: [
    CommonModule,CommonModule,
    CmsCommonModule,
    I18nModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatTooltipModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusInvoicePageBannerSectionComponent : {
          component: AllInvoicesBannerSectionComponent 
        },
        TopBannerUkAllinvoiceComponent:{
          component: UkAllInvoiceTopBannerComponent 
        },
        SeqirusUKAllInvoiceBodySectionComponent:{
          component: InvoiceDetailsSectionComponent
        },
        SeqirusInvoiceListSectionComponent : {
          component: AllInvoicesLeftSectionComponent 
        },
        SeqirusInvoicePaymentError1 :{
          component: AllInvoicesLeftSectionComponent 
        },
        SeqirusInvoicePaymentError2 :{
          component: AllInvoicesLeftSectionComponent 
        },
        SeqirusInvoicePaymentError3 :{
          component: AllInvoicesLeftSectionComponent 
        },
        SeqirusPaymentSectionComponent : {
          component: PaymentTestComponent
        }
     
      }
    } as CmsConfig)
  ]
})
export class AllInvoicesModule { }
