import { ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { OccConfig, BaseSiteService, EventService } from '@spartacus/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

declare var window:any;

@Component({
  selector: 'app-multiple-soldto-popup',
  templateUrl: './multiple-soldto-popup.component.html',
  styleUrl: './multiple-soldto-popup.component.scss'
})
export class MultipleSoldtoPopupComponent {
  showMultipleSoldToPopup: boolean=false;
  displayedColumns: string[] = ['soldTo', 'select'];
  dataSource = new MatTableDataSource();
  userEmail: any="";
  isTableHasData: boolean=false;
  soldToPopup: any;

  constructor(
    public userInfoService:UserInfoService,private eleRef:ElementRef,private renderer:Renderer2,
    private cdr:ChangeDetectorRef,private baseSiteService:BaseSiteService,private router: Router,private eventService:EventService) {
  }

  ngOnInit(){
    this.userInfoService.getMultipleSoldToData().subscribe((data)=>{
      
      this.userInfoService.getUserData().subscribe((user)=>{
        this.userEmail = user?.email;
      })
      if(data != null){
        this.checkPopUp(data);
      }
      else{
        this.userInfoService.userLoggedIn$.subscribe(res=>{
          if(res){
            this.userInfoService.fetchMultipleSoldToForUser().subscribe((data)=>{
            this.checkPopUp(data);
          })
        }
      });
    }
      this.cdr.detectChanges();
    })
  
  } 
  checkPopUp(data: any) {
    if(data?.multipleSoldToData.length > 1){
        
      this.showMultipleSoldToPopup = true;
      this.renderer.addClass(document.querySelector('body'),'modal-open');

      this.dataSource = new MatTableDataSource(data.multipleSoldToData);
      this.cdr.detectChanges();
      }
      else if(data?.multipleSoldToData.length == 1){
      this.router.navigate(['/my-account']);

      }
  }
  applyFilter(event:Event){
    const filterValue = (event.target as HTMLInputElement).value;
   
    this.dataSource.filter = filterValue || ''.trim().toLowerCase();
    if(this.dataSource.filteredData.length > 0){
    this.isTableHasData = true;
    }
    else{
      this.isTableHasData = false;
    }
  }

  selectSoldTo(accNum:string){
    this.showMultipleSoldToPopup =false;
    this.renderer.removeClass(document.querySelector('body'),'modal-open');

    this.cdr.detectChanges();
    this.userInfoService.changeB2BAccount(accNum).subscribe((data) => {
      this.router.navigate(['/my-account']);
      //window.location.href='/my-account';
    })
  }


}
