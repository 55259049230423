<ng-container *ngIf="(userLoggedIn && currentSite == 'seqirusb2b-flu360') || (currentSite == 'seqirusb2b-uk' && userLoggedIn && userInfo?.webEnabled)">
    <ng-container *ngIf="topContent | async as topData">
        <div class="col-xs-12 no-padding contact_dashboard" [ngClass]="currentSite == 'seqirusb2b-flu360' && !userInfo?.webEnabled ?'topbannerUSNonWE':''">
            <section class="col-xs-12 col-sm-12 contact_titlesection">
                <div class="col-xs-12 col-sm-12 col-md-12 contact_headerparent">
                    <h1 class="contact_header new_header_contactus">{{topData.title}}</h1>
                    <div class="contact_subheaderparent">
                        <div class="contact_subheadertext col-xs-12 col-md-6">{{topData.content}}</div>
                        <div class="contact_subheaderbutton col-xs-12 col-md-6">
                            <a [routerLink]="topData.urlLink">
                                <div class="contact_explorebutton">{{topData.subcontent}}</div>
                            </a>
                            <div class="clearboth"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </ng-container>
</ng-container>
<!--The following will be visible only when the user is not logged in and the site is uk-->
<ng-container *ngIf="(!userLoggedIn || (userLoggedIn && (!userInfo?.webEnabled) && currentSite == 'seqirusb2b-uk'))">
    <div [ngClass]="currentSite == 'seqirusb2b-flu360'?'us-Lo-contact-us':'uk-Lo-contact-us'" *ngIf="topContent | async as topData">
        <div class="container-fluid container-lg padding-L40">
            <nav class="nav_breadCrumb" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'/homepage'">Home</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="'/help-and-faq'" target="_blank">Help &amp; FAQs</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="'/contact-us'" class="active">Contact</a></li>
                </ol>
            </nav>

            <div class="Support_content  margin-T30">
                <div class="global_Lshape">
                    <div class="global_horizontalL"></div>
                    <div class="global_verticalL"></div>
                </div>
                <div class="row">
                    <div class="Support_contentbox col-lg-6 col-sm-9 col-xs-12">
                        <!-- colmd6 remove -->
                        <h1 class="Support_header">{{topData.title}}</h1>
                        <div class="Support_text">{{topData.content}}</div>
                        <a class="Support_faqButton" [routerLink]="topData.urlLink">
                            <div class="redborder">{{topData.subcontent}}</div>
                        </a>
                        <div class="clearboth"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>