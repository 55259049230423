import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';

import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { Router } from '@angular/router';
import { CmsComponentData } from '@spartacus/storefront';
import { ContentService } from '../../../spartacus/services/content.service';
@Component({
 selector: 'app-custom-tiles',
 templateUrl: './custom-tiles.component.html',
 styleUrls: ['./custom-tiles.component.scss'],
})
export class CustomTilesComponent implements OnInit {
 details: any[] = [];
 selectedType: string[] = [];
 selectedProduct: string[] = [];
 currentPage = 0;
 pageSize = 9;
 totalPages = 0;
 totalItems = 0;
 solutionSuiteOpen = false;
 productListOpen = false;
 @Output() filterChanged = new EventEmitter<{ filterType: string; value: string; isChecked: boolean }>();
 occ: any;
 productId!: string;
  showProductFilter!: boolean;
  showsolutionFilter!: false;
  showSolutionFilter!: boolean;
 constructor(
  private cmsService:CmsService, 
  protected config: OccConfig,
  public bannerComponent: CmsComponentData<CmsBannerComponent>,
   private contentService: ContentService,
   private cdr: ChangeDetectorRef,
   private router: Router
 ) {}
 ngOnInit(): void {
   this.productId = this.productId;
   this.loadProductsdetails();
   this.getResourceDetails();
   this.getSpecificContent();
 }
 /**
  * Handles checkbox changes for filtering.
  * @param filterType The type of filter (solution or product).
  * @param value The filter value.
  * @param event The event triggered by checkbox interaction.
  */
 onCheckboxChange(filterType: string, value: string, event: Event): void {
   const isChecked = (event.target as HTMLInputElement).checked;
   if (filterType === 'solution') {
     if (isChecked) {
       this.selectedType.push(value);
     } else {
       this.selectedType = this.selectedType.filter((type) => type !== value);
     }
   } else if (filterType === 'product') {
     if (isChecked) {
       this.selectedProduct.push(value);
     } else {
       this.selectedProduct = this.selectedProduct.filter((product) => product !== value);
     }
   }
   this.filterChanged.emit({ filterType, value, isChecked });
   this.loadProductsdetails();
 }
 /**
  * Toggles the visibility of the solution suite filter options.
  */
toggleFilter(filterType : string): void {
  if(filterType === 'solution'){
    this.showSolutionFilter = !this.showsolutionFilter;
  }
  else if (filterType === 'product'){
    this.showProductFilter = !this.showProductFilter;
  }
  }

 getResourceDetails(): void {
   if (!this.productId) {
     this.contentService.getResourceDetails(this.productId).subscribe(
       (response: any) => {
         console.log('Resource details response:', response);
         this.cdr.detectChanges();
       },
       (error: any) => {
         console.error('Error fetching resource details:', error);
       }
     );
   }
 }
 /**
  * Fetches specific content for the resources.
  */
 getSpecificContent(): void {
   this.contentService.getSpecificContent(this.productId).subscribe(
     (response: any) => {
       console.log('Specific content response:', response);
       this.cdr.detectChanges();
     },
     (error: any) => {
       console.error('Error fetching specific content:', error);
     }
   );
 }
 /**
  * Fetches and updates product details based on filters.
  */
 loadProductsdetails(): void {
   const selectedType = this.selectedType.join(',');
   const selectedProduct = this.selectedProduct.join(',');
   this.contentService
     .gettoolsAndResources(this.currentPage, this.pageSize, selectedType, selectedProduct)
     .subscribe(
       (response: any) => {
         if (response?.results && response?.pagination) {
           this.details = response.results;
           this.totalItems = response.pagination.totalCount;
           this.totalPages = Math.ceil(this.totalItems / this.pageSize);
         } else {
           console.error('Invalid API response:', response);
         }
         this.cdr.detectChanges();
       },
       (error: any) => {
         console.error('Error fetching product details:', error);
       }
     );
 }
 
 goToPage(page: number): void {
   if (page >= 0 && page < this.totalPages) {
     this.currentPage = page;
     this.loadProductsdetails();
   }
 }
 getImage(image: any): string {
  const imageUrl= image?.url ? this.config.backend?.occ?.baseUrl + image.url :'';
  return imageUrl;
}
}





// }
// viewProductDetails(productId: string): void {
//   // Update the route with the product ID and navigate to the resource detail page
//   this.router.navigate(['/resources/details', productId]).then(() => {
//     // After navigation, fetch product details dynamically
//     this.fetchProductDetails(productId);
//   });
//  }
//  /**
//  * Fetches product details based on the provided product ID.
//  * @param productId The ID of the product to fetch details for.
//  */
//  fetchProductDetails(productId: string): void {
//   this.contentService.getResourceDetails(productId).subscribe(
//     (response: any) => {
//       console.log('Product Details Response:', response);
//       // Update local state with the fetched details
//       this.details = [response]; // Adjust according to your response structure
//       this.cdr.detectChanges();
//     },
//     (error: any) => {
//       console.error('Error fetching product details:', error);
//     }
//   );