import { ChangeDetectorRef, Component } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-info',
  templateUrl: './custom-info.component.html',
  styleUrl: './custom-info.component.scss'
})
export class CustomInfoComponent {
  concentDetails$: Observable<any> | undefined;
  toggleEdit: boolean = false;
  consent: boolean = false;
  constructor(private occEndpointsService: OccEndpointsService,
    private commonService: CommonApiService, private cd:ChangeDetectorRef) {

  }
  edit() {
    this.toggleEdit = !this.toggleEdit;
  }
  ngOnInit() {
    this.getConcentDetails();
  }
  getConcentDetails() {
    let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/isConsentGranted`);
    let payload = {
      url: endpoint,
    }
    this.concentDetails$ = this.commonService.getAPICall(payload);
  }
  getValue(value: boolean) {
    this.consent = value;
  }
  submitConcent() {
    let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/marketingconsent/${this.consent}`);
    let payload: any = {
      url: endpoint,
    }
    this.commonService.postAPICall(payload).subscribe(res => {
      this.toggleEdit=false;
      this.getConcentDetails();
      this.cd.detectChanges();
    });

  }
}
