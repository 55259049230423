import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, ViewChild, inject } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseSiteService, OccEndpointsService } from '@spartacus/core';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-manage-location',
  templateUrl: './custom-manage-location.component.html',
  styleUrl: './custom-manage-location.component.scss'
})
export class CustomManageLocationComponent {
  locations: any=[];
  currentSite: string="";
  constructor(private occEndpointsService:OccEndpointsService,private cd:ChangeDetectorRef,
    private commonService:CommonApiService,private userInfoService:UserInfoService,private baseSiteService:BaseSiteService){

  }
  private _liveAnnouncer = inject(LiveAnnouncer);

  displayedColumns: string[] = ['type', 'name', 'address', 'status'];
  dataSource = new MatTableDataSource();
  displayedColumnsuk: string[] = ['name', 'address','type', 'email'];
 
  searchValue: string = '';
  searchResultEmpty: boolean = false;

  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.sort.sort({ id: 'type', start: 'asc', disableClear: false }); // Set initial sort state for "Type" column
  }
  ngOnInit(){
      this.getLocationData();
      this.baseSiteService.getActive().subscribe(site => {
        this.currentSite = site;
        console.log(this.currentSite);
      });
  }
getLocationData(){ 
    let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/fetchOrgLocTableData`);
    let payload = {
      url: endpoint,
    }
    this.commonService.getAPICall(payload).subscribe({
      next:(res: any) => {
        if (res) {
          this.dataSource = new MatTableDataSource(res.tableData);
          this.dataSource.sort = this.sort;
          this.cd.detectChanges();
        }
      },
      error: (e) =>{} ,
    })
  }
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  applyFilter(event: Event,search:string) {
    const filterValue =search;// (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // Check if the filtered data is empty
  this.searchResultEmpty = this.dataSource.filteredData.length === 0;
  }
}
