import { Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profilepage',
  templateUrl: './profilepage.component.html',
  styleUrl: './profilepage.component.scss'
})
export class ProfilepageComponent {
  radiovalue:any;

constructor(private router: Router){}
  /* $(".continueAccountBtn").click(function () {
		if ($('input:radio:checked').val() === 'yes') {
			window.location = ACC.config.contextPath + "/register/join-account";
		} else {
			window.location = ACC.config.contextPath + "/register";
		}
	}); */
  continuebtn(){
    this.radiovalue=document.querySelector('input[name="email-check"]:checked')
    if(this.radiovalue.value=="yes"){
 
  this.router.navigateByUrl("/register/join-account");
    }else if(this.radiovalue.value=="no"){

this.router.navigateByUrl("/register");
    }
  }
}
