<div *ngIf="isLoading" class="loading">
    <div class="loading_txt">
        <div class="load_txt">Loading</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<div *ngIf="enablePage && enablePage.showLandingPage">
<section class="col-xs-12 cart_neworder" *ngIf="startlandingcontent | async  as item">
    <!-- Provisional Hardcoded for Demo -->
            <div class="col-xs-6 cart_neworderheader">{{item.headline}}</div>
            
    <div class="col-xs-12 cart_neworderbtns">
        <div class="col-xs-12 hiddenSeasonDetail">{{landingapi?.prePreSeason | seasonspilt}}</div>
     <div class="col-md-4 greybg" *ngIf="landingapi?.placeAnOrderLeftTempHide=='false'" [ngClass]="landingapi?.prepreSeasonAllowedStatus?'active':'order_inactive'">
 
      
     <div class="col-xs-12 cart_neworderbtn cart_placeorderbtn" id="cart_neworderbtn-1" data-toggle="modal" data-target="#OM_seasonConfirmPopup" (click)="openModal(landingapi?.prePreSeason)">
     <input type="hidden" class="selectedSeason" value={{landingapi?.prePreSeason}}>
     <div>
         <div class="col-xs-12 cart_neworderbtnheader">Place an order for the</div>
            <div class="col-xs-12 cart_neworderbtndate">{{landingapi?.prePreSeason | seasonspilt}}&nbsp;Season</div>
        
     </div>
     <div class="placeorderbtnsection">
         <div class="global_blackarrow placeorderbtnarrow"></div>
     </div>
    

     </div>
     <ng-container *ngIf="landing[0]">
        <div [innerHtml]="landing[0].content"></div>
     </ng-container>


     </div>
     <div class="spacer_gap"></div>
     <div class="col-xs-12 hiddenSeasonDetail2">{{landingapi?.preSeason | seasonspilt}}</div>
            <div class="col-md-4 greybg" *ngIf="landingapi?.placeAnOrderRightTempHide=='false'">


                <!-- condition for preseason -->
    <ng-container *ngIf="landingapi?.preSeasonEnable=='true';else preseaondisable">

                
     <div class="col-xs-12 cart_neworderbtn cart_placeorderbtn" id="cart_neworderbtn-2" data-toggle="modal" data-target="#OM_seasonConfirmPopup" (click)="openModal(landingapi?.preSeason)">
     <input type="hidden" class="selectedSeason" value={{landingapi?.preSeason}}>
     <div>
      <div class="col-xs-12 cart_neworderbtnheader">Place an order for the</div>
      <div class="col-xs-12 cart_neworderbtndate">{{landingapi?.preSeason | seasonspilt}}&nbsp;Season</div>
      
      </div>
      <div class="placeorderbtnsection">
         <div class="global_blackarrow placeorderbtnarrow"></div>
     </div>
    
      </div>
   
      <ng-container *ngIf="landing[1]">
        <div [innerHtml]="landing[1].content"></div>
      </ng-container>
    </ng-container>


         <ng-template #preseaondisable>
       
<ng-container *ngIf="landingapi?.inSeasonEnable==true;else rfqform">
            <div class="col-xs-12 cart_neworderbtn cart_placeorderbtn" id="cart_neworderbtn-2" data-toggle="modal" data-target="#OM_seasonConfirmPopup" (click)="openModal(landingapi?.currentInSeason)" >
                <input type="hidden" class="selectedSeason" value={{landingapi?.currentInSeason}}>
                <div>
                 <div class="col-xs-12 cart_neworderbtnheader">Place an order for the</div>
                 <div class="col-xs-12 cart_neworderbtndate">{{landingapi?.currentInSeason | seasonspilt}}&nbsp;Season</div>
                 
                 </div>
                 <div class="placeorderbtnsection">
                    <div class="global_blackarrow placeorderbtnarrow"></div>
                </div>
               
                 </div>
              
                 <ng-container *ngIf="landing[1]">
                   <div [innerHtml]="landing[1].content"></div>
                 </ng-container>
</ng-container>
         <ng-template #rfqform>
            <a  href="/contactOrder" target="_self">			 					 
                <div class="col-xs-12 cart_neworderbtn">
                <div>
                 <div class="col-xs-12 cart_neworderbtnheader">Request doses for the</div>
                 <div class="col-xs-12 cart_neworderbtndate">{{landingapi?.preSeason | seasonspilt}}&nbsp;Season</div>
                 </div>
                 <div class="placeorderbtnsection">
                    <div class="global_blackarrow placeorderbtnarrow"></div>
                 </div>
                 </div>
                 </a>
                 <ng-container *ngIf="landing[1]">
                    <div [innerHtml]="landing[1].content"></div>
                  </ng-container>
    
        </ng-template> 
   

    </ng-template>

    

   

      </div>


     </div>
    
    <div class="clearboth"></div>
    
    <div class="view_order mycartbottombtn margin-T30">
        <a (click)="handleOnOrdersClick()" title="View My Current Orders" class="showPopupclick">View My Current Orders <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
    </div>
    
    <div class="cart_bottomsection">
        
           
         
        <div class="col-xs-12 cart_redHeader">{{item.h2content}}</div>
        <div class="col-xs-12 cart_para2Text">{{item.content}}</div>
  
    <div class="col-xs-12 Bottomcontainer">
        <div class="col-xs-3 cart4col">
            <ng-container *ngIf="landing[2]">
            <div class="itemHeader">{{landing[2].headline}}</div>
                <div class="itemBody">{{landing[2].h2content}}</div>
            </ng-container>
            </div>
        <div class="col-xs-3 cart4col">
            <ng-container *ngIf="landing[3]">
                <div class="itemHeader">{{landing[3].headline}}</div>
                    <div class="itemBody">{{landing[3].h2content}}</div>
                </ng-container>
            </div>
        <div class="col-xs-3 cart4col">
            <ng-container *ngIf="landing[4]">
                <div class="itemHeader">{{landing[4].headline}}</div>
                    <div class="itemBody">{{landing[4].h2content}}</div>
                </ng-container>
            </div>
        <div class="col-xs-3 cart4col">
            <ng-container *ngIf="landing[5]">
                <div class="itemHeader">{{landing[5].headline}}</div>
                    <div class="itemBody">{{landing[5].h2content}}</div>
                </ng-container>
            </div>
    </div>
    </div>
   

</section>

<section class="referencesHomeUK cart_reference" *ngIf="startlandingcontent | async  as data">
    <div class="referencesContainerHomeUK" [innerHtml]="data.largeparagraphcontent">
    </div>
</section>

<section>
    <div id="OM_seasonConfirmPopup" class="modal fade" role="dialog" style="padding-right: 10px;" [ngClass]="seasonModal?'show':'hide'">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-body">
                    <h4 class="modal-title OMconfirm_popHeader col-xs-10">This is an order for the {{seasonclick | seasonspilt}} season</h4>
                        <div class="col-xs-2">
                        <span class="OMconfirm_popClose" data-dismiss="modal">×</span>
                    </div>
                    <p class="OMconfirm_popSubHeader col-xs-12">Would you like to continue?</p>
                    <div class="clearboth"></div>
                    <div class="OMconfirm_PopBtnParent">
                        <button class="OM_popBackBtn seasonPopUpBackButton" data-dismiss="modal">Choose a Different Season</button>
                            <button class="OM_popContBtn" data-dismiss="modal" (click)="continue(seasonclick)">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>

