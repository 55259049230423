import { AfterViewInit, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, map, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-flucelvax-cellbasedanimation',
  templateUrl: './flucelvax-cellbasedanimation.component.html',
  styleUrl: './flucelvax-cellbasedanimation.component.scss'
})
export class FlucelvaxCellbasedanimationComponent implements AfterViewInit{
  constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>, private config: OccConfig, private accessService: ContentService, private cd: ChangeDetectorRef) { }
  animationnode: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];

  ngOnInit() {
    this.animationnode.subscribe(res => {
      this.animationlist(res);
    });
  }
  ngAfterViewInit() {
    this.initializeAnimation();
  }
  animationlist(res: any) {
    const tilesList = res.animationNavList.split(" ");
    const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));

    combineLatest(observables).pipe(
      map((results: any) => results.flat())
    ).subscribe((mergedResults: any) => {
      this.mergedObject1 = mergedResults;
      this.cd.detectChanges();
      console.log(this.mergedObject1);
    });
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  initializeAnimation() {
    if (screen.width > 1023) {
      const horizontal = document.querySelector<HTMLElement>('.animationhorizontal');
      const windowWidth: number = horizontal?.offsetWidth || 0;
      const windowWidth2: number = windowWidth * 2;
      let halfWindowWidth: number = windowWidth / 3;
      let animationScrollHeight: number = windowWidth * 3;

      if (screen.width > 900 && screen.width < 1250) {
        animationScrollHeight = windowWidth * 4;
        halfWindowWidth = windowWidth / 2;
      }

      const spaceHolder = document.querySelector<HTMLElement>('.space-holder');
      if (spaceHolder) {
        spaceHolder.style.height = `${animationScrollHeight}px`;
      }

      const opacityWidth: number = windowWidth - 950;

      document.addEventListener('scroll', (): void => {
        const sticky = document.querySelector<HTMLElement>('.Animationsticky');
        const introArrowSymbol = document.querySelector<HTMLElement>('.introarrowsymbol');
        const afterIntroText = document.querySelector<HTMLElement>('.afterintotext');
        const eggGroup = document.querySelector<HTMLElement>('#egggroup');
        const cellGroup = document.querySelector<HTMLElement>('#cellgroup');

        if (sticky) {
          const stickyOffsetTop: number = sticky.offsetTop;
          const opacityValue: number = stickyOffsetTop / 1000;
          const opacityValue1: number = (stickyOffsetTop - opacityWidth) / 1000;
          const opacityValue2: number = (stickyOffsetTop - (windowWidth + halfWindowWidth + opacityWidth)) / 1000;

          if (stickyOffsetTop <= (windowWidth2 + halfWindowWidth)) {
            if (stickyOffsetTop <= windowWidth) {
              if (introArrowSymbol) {
                introArrowSymbol.style.display = 'block';
                introArrowSymbol.style.opacity = `${1 - opacityValue}`;
                introArrowSymbol.style.transform = `translate(${-stickyOffsetTop}px, ${stickyOffsetTop / 2}px)`;
              }
              if (horizontal) {
                horizontal.style.transform = `translateX(+${stickyOffsetTop}px)`;
              }
              if (afterIntroText) {
                afterIntroText.style.opacity = `${1 - opacityValue}`;
              }
              if (eggGroup) {
                eggGroup.style.opacity = `${opacityValue1}`;
              }
            } else if (stickyOffsetTop <= (windowWidth + halfWindowWidth)) {
              if (introArrowSymbol) {
                introArrowSymbol.style.display = 'none';
              }
              if (horizontal) {
                horizontal.style.transform = `translateX(${windowWidth}px)`;
              }
              if (eggGroup) {
                eggGroup.style.opacity = '1';
              }
              // Add egg text animation logic here if needed
            } else {
              // Cell main animation
              if (cellGroup) {
                cellGroup.style.opacity = `${opacityValue2}`;
              }
              if (eggGroup) {
                eggGroup.style.opacity = `${1 - opacityValue2}`;
              }
              if (horizontal) {
                horizontal.style.transform = `translateX(${stickyOffsetTop - halfWindowWidth}px)`;
              }
            }
          } else {
            // Cell text animation
            if (cellGroup) {
              cellGroup.style.opacity = '1';
            }
            if (horizontal) {
              horizontal.style.transform = `translateX(${windowWidth2}px)`;
            }
          }
        }
      });
    }
  }
}
