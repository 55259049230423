import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-aqiv-third',
  templateUrl: './custom-aqiv-third.component.html',
  styleUrl: './custom-aqiv-third.component.scss'
})
export class CustomAqivThirdComponent {
 
  object1: Observable<any> = this.component.data$;
constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,) {
}
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
}
