<ng-container *ngIf="Eachyearnode | async as item">
    <div id="eachyearcomponent">
        <div class="bg--grey-0 influenza-results-container">
            <div class="container">
                <div class="row-flex bg--grey-0">
                    <div id="influenza-results-header" class="col-lg-12 col-md-12 col-sm-12 col-sm-12 text-center">
                        <h2 [innerHTML]="item.headLine"></h2>
                    </div>
                </div>
                <div id="facts-card-row" class="row-flex bg--grey-0 carousel--three-card-mobile">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="bg--white facts-card">
                            <div class="text-center card-content">
                                <img alt="flu360 clinical support icon" [src]=getImage(item.media1Picture)>
                                <p [innerHTML]="item.subHeadLine1" class="facts-card-subHeadline"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 px-0 mx-n-10">
                        <div class="bg--white facts-card">
                            <div class="text-center card-content">
                                <img alt="Hospital icon" [src]=getImage(item.media2Picture)>
                                <p [innerHTML]="item.subHeadLine2" class="facts-card-subHeadline"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="bg--white facts-card">
                            <div class="text-center card-content">
                                <img alt="icon of EKG readout" [src]=getImage(item.media3Picture)>
                                <p [innerHTML]="item.subHeadLine3" class="facts-card-subHeadline"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg--grey-0">
                    <p class="fact-card-footnote">{{item.bottomContent}}</p>
                </div>
            </div>
        </div>
    </div>
    <!--for mobile-->

    <div id="mobileeachyearcomponent" *ngIf="Eachyearnode | async as item">
        <div class="bg--grey-0 influenza-results-container">
            <div class="row-flex">
                <div id="influenza-results-header-mobile" class="col-md-12 col-sm-12 col-xs-12 text-center bg--grey-0">
                    <h2 [innerHTML]="item.headLine"></h2>
                </div>
            </div>
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="flase">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active bg--grey-0">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="bg--white facts-card">
                                <div class="text-center card-content">
                                    <img alt="flu360 clinical support icon" [src]=getImage(item.media1Picture)>
                                    <p [innerHTML]="item.subHeadLine1"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item bg--grey-0">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="bg--white facts-card">
                                <div class="text-center card-content">
                                    <img alt="Hospital icon" [src]=getImage(item.media2Picture)>
                                    <p [innerHTML]="item.subHeadLine2"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item bg--grey-0">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="bg--white facts-card">
                                <div class="text-center card-content">
                                    <img alt="icon of EKG readout" [src]=getImage(item.media3Picture)>
                                    <p [innerHTML]="item.subHeadLine3"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev" class="d-none">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next" class="d-none">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg--grey-0">
                <p class="fact-card-footnote">{{item.bottomContent}}</p>
            </div>
        </div>
    </div>
</ng-container>