<div *ngIf="bannerNode | async as item" class="main_inner-wrapper">
  <div *ngIf="item.uid=='SeqirusAccountDashboardBannerComponent'">
  <div >
<div class="col-xs-12" id="dashborad_topcontent">
  <div class="row dashboard-welcomesection">
    <ng-container *ngIf="currentSite === 'seqirusb2b-flu360';else ukbanner">
  <div class="col-xs-12 col-md-6 dashboradnewleft"  id="dashborad_topcontentleftcontent">
      <h1 class="col-xs-12 modified-h1">Welcome back,<span *ngIf="userData"> {{userData?.firstName}}</span></h1>
      <!-- <div class="col-xs-12 margin-T20 currently-booking" [innerHTML]= " item.content"></div> -->
      <div class="col-xs-12 margin-T20 currently-booking" >Currently booking: </div>
      <div class="col-xs-12 padding-T5 currently-booking-seson">{{item.content}}</div>
      <div class="col-xs-12 padding-T5 currently-booking-seson">{{item.Content1}}</div>
      <!-- <div class="col-xs-12 padding-T5 currently-booking-seson">{{ item.h2content }}</div> -->
  </div>
</ng-container>
<ng-template #ukbanner>
  <div class="col-xs-12 col-md-6 dashboradnewleft"  id="dashborad_topcontentleftcontentuk">
   <h1 class="col-xs-12 modified-h1">Welcome back,<span *ngIf="userData"> {{userData?.firstName}}</span></h1>
    
    <div class="col-xs-12 margin-T20 currently-booking"></div>
    
    <div class="col-xs-12 padding-T5 currently-booking-seson"></div>
</div>
</ng-template>

  <div class="col-xs-12 col-md-6 dashboardadjustableright" id="dashborad_topcontentrightcontent">
      <div *ngIf="currentSite !== 'seqirusb2b-uk'" class="row dashboard-ordervalues">
          <div class="col-md-5 dashborad_openorders" *ngIf="orderData | async as item">
            <div class="col-xs-12 dashborad_opennumbers" >{{item?item.openOrdersCount:0}}</div>
            <div class="col-xs-12">Open Orders</div>              
          </div>
          <div class="col-xs-1 dashborad_sperator"></div>
          <div class=" dashborad_openInvoices col-md-5" *ngIf="invoiceNode | async as item">
              <div class="col-xs-12 dashborad_opennumbers dashboard_openInvoiceNumbers">{{item?item?.invoiceCount:0}}</div>
              <div class="col-xs-12 no-padding">Open Invoices</div>
          </div>
</div>
</div>
</div>
</div>
</div>
</div>
