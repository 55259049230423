import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { ErrorpageContentComponent } from './errorpage-content/errorpage-content.component';
import { RouterModule } from '@angular/router';
import { ErrormessageComponent } from './errormessage/errormessage.component';
 
 
@NgModule({
  declarations: [
    ErrorpageContentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusPageNotCMSComponent:{
            component: ErrorpageContentComponent
        }
      }
    }as CmsConfig)
  ]
})
export class ErrorpageModule { }