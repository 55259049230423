<div class="product_download-section fluad-rwe-download" *ngIf="thirdnode | async as item">
    <div class="container">
        <div class="product_download-sectionCont">
            <span class="product_download-paraspan" [innerHTML]="item.content"></span>                       
        </div>
    </div>
</div>

<div class="product_download-greysection" *ngIf="thirdnode | async as item">
    <div class="container">
        <div class="product_greysection">
            <div class="pull-left" [innerHTML]="item.headLine"></div>
            <a href="/medias/Fluad-RWE-Leave-Behind-USA-FLUD-24-0022.pdf?context=bWFzdGVyfHJvb3R8MjIxNDk0NnxhcHBsaWNhdGlvbi9wZGZ8YUdObUwyZzFOUzg0T1RNek1qSTNNVFkzTnpjMEwwWnNkV0ZrTFZKWFJTMU1aV0YyWlMxQ1pXaHBibVF0VlZOQkxVWk1WVVF0TWpRdE1EQXlNaTV3WkdZfGM0NmU4YmM1MDJhMDYyZjBkMjk2ZjA0MWE3YWI1NWM0NDg5MDQzODViMWIzYzRiMzA1OTI3N2EwMmIzMGJiODM" target="_blank" class="product_download-button pull-right">
                <span>{{item.pdfText}}</span>
                <img alt="Download" src="../../../assets/images/product_download_icon.svg">
            </a>
            <div class="clearboth"></div>
        </div>
    </div>
</div>