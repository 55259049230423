<div *ngIf="isLoading" class="loading">
    <div class="loading_txt">
        <div class="load_txt">Loading</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!--OM product selection page starts here -->



<div class="main no-pad OM_doseInput" id="contact_request"
    *ngIf="productData && ((enablePage && enablePage.showProductPage) || showProductPage) && !showDeliveryPage">
    <!--OM product selection page body starts here -->
    <form #productData1="ngForm">
        <div class="prodSelectBody_container" id="OM_prodSelect"
            *ngIf="productData && productData.productList && productData.productList.length > 0">
            <!-- add soldout class in below div -->
            <div class="col-xs-6 cart_neworderheader pb-5">New {{seasonValue | seasonspilt}} Order</div>
            <div class="col-xs-12 col-md-12 no-pad OM_productContainer">
                <div class="col-xs-12 col-md-5 no-pad OM_productDetails" *ngFor="let item of productData.productList">
                    <div class="OM_productBox" [ngClass]="!item.sellable ? 'disable_product' : ''">
                        <div class="prodSelect_img">
                            <img alt="aQIV" src="../../../assets/images/aQIV-GB-10-Mockup.png" style="width: 100%;"
                                *ngIf="item.baseName === 'AQIV'">
                            <img alt="aQIV" src="../../../assets/images/QIVc-GB-10-Mockup-2022-23.png" style="width: 93%;"
                                *ngIf="item.baseName === 'QIVC'">
                        </div>
                    </div>

                    <div class="OM_productTitle" [ngClass]="!item.sellable ? 'disable_product' : ''"><span
                            *ngIf="item.baseName === 'AQIV' && seasonValue == inseason">Adjuvanted Quadrivalent
                            Influenza
                            Vaccine (aQIV)</span> <span *ngIf="item.baseName === 'QIVC' && seasonValue == inseason">Cell-Based Quadrivalent
                            Influenza
                            Vaccine
                            (QIVc)</span><span
                            *ngIf="item.baseName === 'AQIV' && seasonValue == preseason">Adjuvanted Trivalent Influenza Vaccine (aTIV)</span> <span *ngIf="item.baseName === 'QIVC' && seasonValue == preseason">Cell-Based Trivalent
                            Influenza
                            Vaccine
                            (TIVc)</span></div>
                    <div *ngIf="seasonValue == inseason" class="OM_productSubTitle" [ngClass]="!item.sellable ? 'disable_product' : ''">(surface
                        antigen, inactivated) Seqirus</div>
                    <div class="OM_descripsionText" [ngClass]="!item.sellable ? 'disable_product' : ''"><span
                            *ngIf="item.baseName === 'AQIV' && seasonValue == inseason">For people aged 65 years and
                            over</span><span
                            *ngIf="item.baseName === 'AQIV' && seasonValue == preseason">Licensed for people aged 50 years and over</span>
                            <span *ngIf="item.baseName === 'QIVC' && seasonValue === preseason">Licensed for adults and children from 6 months of age</span><span *ngIf="item.baseName === 'QIVC' && seasonValue === inseason">For adults and children from 6 months of
                                age</span></div>
                    <!-- only for preseason -->
                    <div class="OM_productCost" [ngClass]="!item.sellable ? 'disable_product' : ''"  *ngIf="seasonValue === preseason">
                        <div>{{item.perPack}} Pack</div>
                        <div class="OM_verticlDivision"></div>
                        <div class="priceDetail">&#163;<span *ngIf="item.code==productData?.priceResponse?.materials[0]?.materialID">{{productData?.priceResponse?.materials[0]?.salePrice}}</span><span *ngIf="item.code==productData?.priceResponse?.materials[1]?.materialID">{{productData?.priceResponse?.materials[1]?.salePrice}}</span> per dose</div>
                    </div>
                    <!-- preseason ends -->
                    <span class="SoldoutText unavailableText"
                        *ngIf="!item.sellable && seasonValue === inseason">Unavailable</span>
                    <div class="availableProduct" *ngIf="item.sellable && seasonValue === inseason">Available</div>
                </div>
                <!-- <div class="col-xs-12 col-md-4 no-pad OM_productDetails" id="qivc">
                <div class="OM_productBox"></div>
                <div class="OM_productTitle">Cell-Based Quadrivalent Influenza Vaccine (QIVc)</div>
                <div class="OM_productSubTitle">(surface antigen, inactivated) Seqirus</div>
                <div class="OM_descripsionText">For adults and children from 2 years of age</div>
                <div class="OM_productCost">
                    <div>10-pack</div>
                    <div class="OM_verticlDivision"></div>
                    <div class="priceDetail">&#163;<span>10.00</span> per dose</div>
                </div>
            </div> -->
            </div>
            <div class="clearboth"></div>


            <section class="col-xs-12 OM_productDoseSection" *ngIf="seasonValue === inseason">
                <div class="cart_summaryLocationselection" (click)="handleOnBackbtnClick()">
                    <div class="global_blackarrow"></div>Back to Location Selection
                </div>
                <div class="col-xs-12 OM_selectline"></div>
                <div class="OM_productTableHeader">Your Required Doses</div>
                <div class="OM_productTableSubHeader" style="width: 66%">
                    <p>Enter your requirements below, to the nearest multiple of 10. You can request&nbsp;{{minproductdose}} - <span class="createProdMaxQty">{{maxproductdose | number:'1.0'}}</span>
                        doses with <ng-container *ngIf="productData.priceResponse.SOR;else zersor">{{productData.priceResponse.SOR}}</ng-container>
                        <ng-template #zersor>0</ng-template>% SOR and&nbsp;{{productData.priceResponse.creditDays}}&nbsp;Credit days.</p>
                </div>
                <!-- Table starts -->
                <div class="locationrowall col-md-12 locationrow60149134 no-pad pl-2 pr-2">
                    <div class="tableContainer">
                        <div *ngFor="let locDetail of productData.shippingData; let i=index">
                            <div class="OM_productLocation col-xs-12" id="prodLocation1">

                                <div class="col-xs-12 col-md-5 OM_locParent">
                                    <div class="col-xs-12 OM_locationheader">Location Name</div>
                                    <div class="col-xs-12 col-md-6 OM_locationText pl-2 pr-0">
                                        {{locDetail.locname}}{{locDetail.address}}{{locDetail.state}}
                                        <!-- <div class="zipcodewrap"> GB HP18 9SA</div> -->
                                    </div>
                                </div>
                                <div *ngFor="let productList of locDetail.productList; let j=index">
                                    <div class="col-xs-12 allProd no-padding">
                                        <div class="col-xs-12 productheader">

                                            <div class="">
                                                <div class="OM_locationProdName">{{productList.baseProductName}}</div>
                                                <div class="OM_prodDes" *ngIf="productList.baseName === 'AQIV'">(65
                                                    years
                                                    &amp; above)</div>
                                                <div class="OM_prodDes" *ngIf="productList.baseName === 'QIVC'">(6
                                                    months
                                                    &amp; above)</div>
                                                <!-- year data without backet for provisional order -->
                                            </div>
                                            <!-- <div class="col-md-6">
                                <div class="OM_locationProdName">QIVc 10 Pack</div>
                                <div class="OM_prodDes">(6 months &amp; above)</div>
                            </div> -->

                                        </div>
                                        <div class="col-xs-12 prodincontainer">

                                            <div class="col-xs-12 aqivcontainer createproductsize">


                                                <div class="doseQuant aqivinputcont" style="padding-left:25px"
                                                    [ngClass]="productList.priceData.errorMsg ? 'has-error' : ''">
                                                    <input id="{{i}}{{j}}" name="{{i}}{{j}}" maxlength="6"
                                                        placeholder="0"
                                                        class="form-control aqiv_box dynDoseInput dynplacecolor"
                                                        [disabled]="!productList.sellable" type="text" value=""
                                                        autocomplete="no" #productValue
                                                        [ngModel]="productList.priceData.prodValue"
                                                        (ngModelChange)="handleOnProdValueChange($event,i,j)">
                                                    <span
                                                        class="glyphicon form-control-feedback glyphicon-exclamation-sign"
                                                        *ngIf="productList.priceData.errorMsg"></span>
                                                    <div class="totaldose pt-0 pl-1">
                                                        Total Doses</div>
                                                </div>
                                                <label id="{{i}}{{j}}" style="padding-left:25px"
                                                    class="error help-block"
                                                    for="{{i}}{{j}}">{{productList.priceData.errorMsg}}</label>
                                            </div>
                                            <!-- <div class="col-xs-12 col-md-6 aqivcontainer createproductsize pl-0">
                                <div class="doseQuant aqivinputcont pl-3">
                                    <input id="cdose[0]"
                                        name="createOrderData[0].CreateOrderProductData[1].productQuantity"
                                        placeholder="0" class="form-control qivc_box dynDoseInput onlyQivc" type="text"
                                        value="" autocomplete="no"><input
                                        id="createOrderData0.CreateOrderProductData1.productCode"
                                        name="createOrderData[0].CreateOrderProductData[1].productCode" value="10054364"
                                        type="hidden">
                                    <div class="totaldose">
                                        Total Doses</div>

                                </div>

                            </div> -->
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Table Ends -->
            </section>
            <div class="col-xs-12 OM_tableBottomBtn" *ngIf="seasonValue === inseason">
                <div class="col-xs-12 col-md-6 OM_totalCost pl-0">Total Cost: &nbsp;&nbsp;&nbsp;&#163;<span
                        class="OM_totalProductCost">{{totalCost | number : '.2-2'}}</span></div>
                <div class="col-xs-12 col-md-6 OM_rightBtnParent pr-0">
                    <a class="OM_exitBtn" (click)="handleOnExitClick()">Exit</a>
                    <button class="OM_selectWeekBtn" [disabled]="disableDeliveryBtn"
                        (click)="handleOnDeliveryBtnClick()" >Select Delivery Weeks</button>
                </div>
            </div>
            <div class="clearboth"></div>
            <div class="OM_productDoseSectionParent prebook_product" *ngIf="seasonValue === preseason">
                <section class="col-xs-12 no-pad">
                    <div class="col-xs-12 prebook_prod_box">
                        <div class="cart_summaryLocationselection" (click)="handleOnBackbtnClick()">
                            <div class="global_blackarrow"></div>Back to Location Selection
                        </div>

                        <div class="col-xs-12 OM_selectline"></div>
                        <div class="col-xs-12 no-pad" style="display: flex;">
                            <div class="col-md-8 no-pad">
                                <div class="OM_productTableHeader">Select Products</div>
                                <div class="OM_productTableSubHeader col-xs-11 no-pad">
                                    <p>On flu360, you can order&nbsp;{{minproductdose}} - <span class="createProdMaxQty">{{maxproductdose | number:'1.0'}}</span>

                                        &nbsp;doses per <span class="provisionalProduct">product per location</span>
                                        <span> with
                                            {{productData.priceResponse.SOR}}%&nbsp;Sale or Return and&nbsp;{{productData.priceResponse.creditDays}}&nbsp;credit days.</span>
                                    </p>
                                    <p>To place an order outside of these volumes<span class="provisionalHelpText"> or
                                            explore alternate terms</span>, please contact our sales team on <a
                                            class="showPopupclick removeEmailleave" href="mailto:flu.salesuk@seqirus.com" target="_blank">{{emailId}}</a> or <a
                                            href="tel:03450093804" class="showPopupclick removephoneleave">0345 009
                                            3804.</a></p>
                                </div>
                                <div class="clearboth"></div>
                                <div class="OM_productTableInst">Enter your required doses to the nearest unit of 10.
                                </div>
                            </div>
                            <div class="col-md-4 riskcontent">
                                <div class="OM_productTableHeader">Risk Free Ordering</div>
                                <div class="OM_productTableSubHeader">Should new influenza policy guidance be issued
                                    that no
                                    longer recommends either CSL Seqirus flu vaccines in their respective age group(s),
                                    you
                                    can cancel that respective part of your order.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 prebook_prod_box">

                        <div class="previous_left col-xs-10 no-padding">

                            <div class="previous_head">View your previous order quantities
                                from&nbsp;{{inseason}}&nbsp;season
                            </div>

                            <div class="previous_text">Place a new order or reorder your previous season's quantities.
                            </div>
                        </div>
                        <div class="previous_right col-xs-2">
                            <div class="previous_text">Ordering to:</div>
                            <!-- location count need to be coming from backend -->
                            <div class="previous_text"><span
                                    class="prod_loc_count">{{productData.shippingData.length}}</span> locations</div>

                        </div>


                    </div>

                    <div class="pre_repeatbtn col-xs-4" *ngIf="!showClearBtn"
                        (click)="handleOnPrevValueBtnClick('clear')">
                        Repeat previous season quantities<span class="prodtooltip"><svg class="exclamation" width="16"
                                height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8 16C6.90667 16 5.87333 15.7899 4.9 15.3696C3.92667 14.9499 3.0768 14.38 2.3504 13.66C1.62347 12.94 1.05013 12.0933 0.6304 11.12C0.210133 10.1467 0 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05013 3.90667 1.62347 3.06 2.3504 2.34C3.0768 1.62 3.92667 1.04987 4.9 0.6296C5.87333 0.209867 6.90667 0 8 0C9.12 0 10.1667 0.209867 11.14 0.6296C12.1133 1.04987 12.96 1.62 13.68 2.34C14.4 3.06 14.9667 3.90667 15.38 4.88C15.7933 5.85333 16 6.89333 16 8C16 9.10667 15.7933 10.1467 15.38 11.12C14.9667 12.0933 14.4 12.94 13.68 13.66C12.96 14.38 12.1133 14.9499 11.14 15.3696C10.1667 15.7899 9.12 16 8 16ZM8 14.4C9.78667 14.4 11.3 13.7768 12.54 12.5304C13.78 11.2835 14.4 9.77333 14.4 8C14.4 6.22667 13.78 4.71653 12.54 3.4696C11.3 2.2232 9.78667 1.6 8 1.6C6.25333 1.6 4.74987 2.2232 3.4896 3.4696C2.22987 4.71653 1.6 6.22667 1.6 8C1.6 9.77333 2.22987 11.2835 3.4896 12.5304C4.74987 13.7768 6.25333 14.4 8 14.4Z"
                                    fill="#EA181B"></path>
                                <path
                                    d="M8.39927 4.73C7.90427 4.73 7.51927 4.367 7.51927 3.883C7.51927 3.388 7.90427 3.025 8.39927 3.025C8.88327 3.025 9.27927 3.388 9.27927 3.883C9.27927 4.367 8.88327 4.73 8.39927 4.73ZM7.68427 5.753H9.12527V11H7.68427V5.753Z"
                                    fill="#EA181B"></path>
                            </svg>
                            <span>Repeat order from previous season</span>
                        </span>



                    </div>
                    <div class="pre_clearbtn col-xs-4" *ngIf="showClearBtn"
                        (click)="handleOnPrevValueBtnClick('repeat')">
                        Clear previous season quantities<span class="prodtooltip">
                            <svg class="exclamation" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8 16C6.90667 16 5.87333 15.7899 4.9 15.3696C3.92667 14.9499 3.0768 14.38 2.3504 13.66C1.62347 12.94 1.05013 12.0933 0.6304 11.12C0.210133 10.1467 0 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05013 3.90667 1.62347 3.06 2.3504 2.34C3.0768 1.62 3.92667 1.04987 4.9 0.6296C5.87333 0.209867 6.90667 0 8 0C9.12 0 10.1667 0.209867 11.14 0.6296C12.1133 1.04987 12.96 1.62 13.68 2.34C14.4 3.06 14.9667 3.90667 15.38 4.88C15.7933 5.85333 16 6.89333 16 8C16 9.10667 15.7933 10.1467 15.38 11.12C14.9667 12.0933 14.4 12.94 13.68 13.66C12.96 14.38 12.1133 14.9499 11.14 15.3696C10.1667 15.7899 9.12 16 8 16ZM8 14.4C9.78667 14.4 11.3 13.7768 12.54 12.5304C13.78 11.2835 14.4 9.77333 14.4 8C14.4 6.22667 13.78 4.71653 12.54 3.4696C11.3 2.2232 9.78667 1.6 8 1.6C6.25333 1.6 4.74987 2.2232 3.4896 3.4696C2.22987 4.71653 1.6 6.22667 1.6 8C1.6 9.77333 2.22987 11.2835 3.4896 12.5304C4.74987 13.7768 6.25333 14.4 8 14.4Z"
                                    fill="white"></path>
                                <path
                                    d="M8.39932 4.73C7.90432 4.73 7.51932 4.367 7.51932 3.883C7.51932 3.388 7.90432 3.025 8.39932 3.025C8.88332 3.025 9.27932 3.388 9.27932 3.883C9.27932 4.367 8.88332 4.73 8.39932 4.73ZM7.68432 5.753H9.12532V11H7.68432V5.753Z"
                                    fill="white"></path>
                            </svg>
                            <span>You can still adjust order quantities</span>
                        </span>



                    </div>
                    <!-- Table starts -->
                    <div class="OM_productTableContainer OM_CreateOrderflow col-xs-12 no-padding no-margin-bottom">

                        <div class="locationrowall col-md-12 locationrow60149134 no-pad pl-2 pr-2">
                            <div *ngFor="let locDetail of productData.shippingData; let i=index">
                                <div class="OM_productLocation col-xs-12" id="prodLocation1">

                                    <div class="col-xs-12 col-md-4 OM_locParent">
                                        <div class="col-xs-12 col-md-12 OM_locationText">
                                            {{locDetail.locname}}{{locDetail.address}}{{locDetail.state}}
                                            <!-- <div class="zipcodewrap"> GB HP18 9SA</div> -->
                                        </div>
                                    </div>
                                    <div *ngFor="let productList of locDetail.productList; let j=index"
                                        class="col-md-3 pr-0 pl-2">
                                        <div class="col-xs-12 allProd pb-2 pr-0">
                                            <div class="col-xs-12 productheader prebook_bottomborder pl-1">

                                                <div class="">
                                                    <div class="OM_locationProdName">{{productList.baseProductName}}
                                                    </div>
                                                    <div class="OM_prodDes" *ngIf="productList.baseName === 'AQIV'">(50
                                                        years &amp; above)<div style="visibility: hidden;">groups</div></div>
                                                        
                                                    <div class="OM_prodDes" *ngIf="productList.baseName === 'QIVC'">(18
                                                        to 64 years in eligible groups)</div>
                                                    <!-- year data without backet for provisional order -->
                                                </div>
                                                <!-- <div class="col-md-6">
                                            <div class="OM_locationProdName">QIVc 10 Pack</div>
                                            <div class="OM_prodDes">(6 months &amp; above)</div>
                                        </div> -->

                                            </div>
                                            <div class="col-xs-12 prodincontainer pl-0">

                                                <div *ngIf="!showClearBtn"
                                                    class="col-xs-12 aqivcontainer createproductsize pr-0">


                                                    <div class="doseQuant aqivinputcont pr-0 pl-0"
                                                        style="padding-left:6px"
                                                        [ngClass]="productList.priceData.errorMsg ? 'has-error' : ''">
                                                        <input id="{{i}}{{j}}" name="{{i}}{{j}}" maxlength="6"
                                                            placeholder="0" [disabled]="!productList.sellable"
                                                            class="form-control aqiv_box dynDoseInput dynplacecolor"
                                                            type="text" value="" autocomplete="no" #productValue
                                                            [ngModel]="productList.priceData.prodValue"
                                                            (ngModelChange)="handleOnProdValueChange($event,i,j)">
                                                        <span
                                                            class="glyphicon form-control-feedback glyphicon-exclamation-sign"
                                                            *ngIf="productList.priceData.errorMsg"
                                                            style="right:39%"></span>
                                                        <!-- <div class="blackprodtooltip" style="display: block;"
                                                            *ngIf="!productList.priceData.errorMsg">
                                                            <svg class="confirmicon" width="16" height="15"
                                                                viewBox="0 0 20 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
                                                                    fill="#2A3237"></path>
                                                            </svg>
                                                            <span class="glyphicon-exclamation-sign">Please add a
                                                                quantity or confirm 0</span>
                                                        </div> -->
                                                        <div class="totaldose pt-0 pl-1">
                                                            Total Doses</div>
                                                    </div>
                                                    <label id="{{i}}{{j}}" class="error help-block"
                                                        for="{{i}}{{j}}">{{productList.priceData.errorMsg}}</label>
                                                </div>
                                                <div *ngIf="showClearBtn"
                                                    class="col-xs-12 aqivcontainer createproductsize pr-0">


                                                    <div class="doseQuant aqivinputcont pr-0 pl-0"
                                                        style="padding-left:6px"
                                                        [ngClass]="(productList.priceData.errorMsg) || (isDeliveryBtnClicked && productList.priceData?.prodValue == null) ? 'has-error' : ''">
                                                        <input id="{{i}}{{j}}" name="{{i}}{{j}}" maxlength="6"
                                                            placeholder="Confirm Quantity"
                                                            [disabled]="!productList.sellable"
                                                            class="form-control aqiv_box dynDoseInput dynplacecolor"
                                                            type="text" value="" autocomplete="no" #productValue
                                                            [ngModel]="productList.priceData.prodValue"
                                                            (ngModelChange)="handleOnProdValueChange($event,i,j)">
                                                        <span
                                                            class="glyphicon form-control-feedback glyphicon-exclamation-sign"
                                                            *ngIf="productList.priceData.errorMsg"
                                                            style="right:39%"></span>
                                                        <div class="blackprodtooltip" style="display: block;"
                                                            *ngIf="!productList.priceData.errorMsg && this.showClearBtn">
                                                            <svg class="confirmicon" width="16" height="15"
                                                                viewBox="0 0 20 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
                                                                    fill="#2A3237"></path>
                                                            </svg>
                                                            <span class="glyphicon-exclamation-sign">Please add a
                                                                quantity or confirm 0</span>
                                                        </div>
                                                        <div class="totaldose pt-0 pl-1">
                                                            Total Doses</div>
                                                    </div>
                                                    <label id="{{i}}{{j}}" class="error help-block"
                                                        for="{{i}}{{j}}">{{productList.priceData.errorMsg}}</label>
                                                    <div *ngIf="showClearBtn && !locDetail.previousYearAqivQty && !locDetail.previousYearQivcQty"
                                                        class="previoustext" style="display: block;">
                                                        No order placed last year
                                                    </div>
                                                </div>
                                                <!-- <label id="dose[0]-error" class="error help-block"
                                                    for="dose[0]"></label> -->

                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-xs-12 col-md-2 no-padding remove_parent">
                                        <button class="registration_edit removeloaction"
                                            [disabled]="locDetail.disableRemove" (click)="handleOnRemoveBtn(i)">Remove
                                            <span class="editiconpadd"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 bothemptyprod" *ngIf="showErrorMsg && showClearBtn">Please confirm all quantities</div>
                </section>
                <!-- Table Ends -->

                <div class="col-xs-12 OM_tableBottomBtn">
                    <div class="col-xs-12 col-md-6 OM_totalCost pl-0">Total Cost: &nbsp;&nbsp;&nbsp;&#163;<span
                            class="OM_totalProductCost">{{totalCost | number : '.2-2'}}</span></div>
                    <div class="col-xs-12 col-md-6 OM_rightBtnParent pr-0">
                        <a class="OM_exitBtn showPopupclick" (click)="handleOnExitClick()">Exit</a>
                        <button class="OM_selectWeekBtn" [disabled]="disableDeliveryBtn" id="selectDeliveryWeeks"
                            (click)="handleOnDeliveryBtnClick()">Select
                            Delivery
                            Weeks</button>

                    </div>
                </div>
            </div>
        </div>
    </form>
    <!--OM product selection page body ends here -->

    <!-- external Popup starts -->
    <div class="OM_externalPop modal" data-backdrop="static" id="OM_externalPop">
        <div class="OM_externalPopContent">
            <div class="OM_externalPopHeader">
                <div class="OM_externalPopTitle">Are you sure you want to exit?</div>
                <div class="OM_externalPopClose">X</div>
                <div class="clearboth"></div>
            </div>
            <div class="OM_externalPopText">
                <p>You will no longer have access to your progress for this new order.</p>
            </div>
            <div class="OM_externalPopBtns">
                <button class="OM_externalPopBtn OM_externalPopBackBtn">Go Back To Order</button>
                <button class="OM_externalPopBtn OM_externalPopExitBtn">Exit &amp; Lose Progress</button>
            </div>
        </div>
    </div>
    <!-- external Popup ends -->

</div>

<!-- OM product selection page ends here -->
<div *ngIf="showDeliveryPage">
    <div class="col-xs-6 cart_neworderheader deliveryyearheader">New {{seasonValue | seasonspilt}} Order</div>
    <section class="deliverycontainer margin-R20 col-md-12">
        <div class="deliverybox">

            <div (click)="handleOnBackToProductBtnClick()"><a class="orders_backText"><img class="orders_backArrow"
                        alt="Previous screen" src="../../../assets/images/blackarrow.png">Back to
                    Product Selection</a></div>
            <div class="greyline"></div> <!-- the line code -->
            <div class="delivery_textContainer">
                <div class="deliveryheader">Select Delivery Weeks</div>
                <div class="deliveytext">Let us know when you would like to receive your doses. For each location, keep
                    the following guidelines in mind when selecting your schedule:</div>
                <ul class="deliveytext">
                    <li class="deliverylist" *ngIf="deliverydata.deliverySlotConstraintList.maxDeliverySlots>0">1 delivery permitted in the first {{deliverydata.deliverySlotConstraintList.maxDeliverySlots}} weeks


                    </li>
                    <li class="deliverylist">{{mindeliveryslot}} - {{maxdeliveryslot | number:'1.0'}} doses permitted per selected slot

                    </li>
                </ul>
                <div class="deliveytext margin-B30">We do our best to get your order to you on time, however planned
                    deliveries are subject to change. We will confirm your schedule as delivery approaches.</div>
                <div class="atcap_box">
                    <!-- capacity circle code -->
                    <div class="capacity_circle"></div>
                    <div class="capacity_text">Fully booked</div>
                    <div class="middlespace"></div>
                    <div class="Unavaible" *ngIf="deliverydata.deliverySlotConstraintList.maxDeliverySlots>0"></div>
                    <div class="capacity_text" *ngIf="deliverydata.deliverySlotConstraintList.maxDeliverySlots>0">Unavailable</div>
                </div>
            </div>
            <div id="deliveryparent">
                <form #weekselectionform="ngForm">

                    <!-- location container start from here -->
                    <!-- this code will run in loop to get multiple location -->
                    <div class="deliveylocation"
                        *ngFor=" let location of deliverydata?.deliverySlotResponseData.shippingLocations; let i= index">
                        <div class="greyline"></div><!-- the line code -->
                        <!-- location details -->
                        <!-- delivery weeks -->
                        <div class="row">
                            <div class="col-xs-1 deliverycol locationtext margin-B30">Location <span
                                    class="deliverylocno">{{(i+1)}}</span></div>
                            <div class="col-xs-1 deliverycol1 dashsymbol">|</div>

                            <div class="col-xs-11  deliverycol2 locationdetail margin-B30 " >{{locationdetails[i]}}</div>
                        </div>
                        <div class="row">
                            <div class="seasonvalue">{{deliveryseason}}</div><!-- HARDCORED -->

                        </div>
                        <div class="row">
                            <div class="col-xs-12 no-padding">

                                <table class="deliverytable">

                                    <tr id="month">
                                        <td class="delivery_date deliverycol">Delivery Date</td>
                                        <td class="deliverycol1"></td>
                                        <ng-container
                                            *ngFor="let deliverydate of location.materials[0].deliverySlots;let d=index">
                                            <ng-container
                                                *ngIf="d!=(maxslot-1)">
                                                <td class=" datetext">{{deliverydate.month}}</td>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="d==(maxslot-1)">
                                                <td class=" datetext">{{deliverydate.month}}</td>
                                                <td class="gapbet"></td>
                                            </ng-container>

                                        </ng-container>

                                        <td class="gapdose"></td>
                                        <td class="dosestext ">Doses</td>
                                        <td class=" dosestext">Doses</td>
                                        <td class="greentick"></td>

                                    </tr>
                                    <tr class="">
                                        <td class="deliveyweek deliverycol">(wk. commencing)</td>
                                        <td class="deliverycol1"></td>
                                        <ng-container
                                            *ngFor="let deliverydate of location.materials[0].deliverySlots;let d=index">
                                            <ng-container
                                                *ngIf="d!=maxslot;else gap">
                                                <td class=" datetext">{{deliverydate.date}}</td>
                                            </ng-container>
                                            <ng-template #gap>
                                                <td class="gapbet"></td>
                                                <td class=" datetext">{{deliverydate.date}}</td>
                                            </ng-template>

                                        </ng-container>


                                        <td class="gapdose"></td>
                                        <td class="dosestext ">Scheduled</td>
                                        <td class=" dosestext">Ordered</td>
                                        <td class="greentick"></td>

                                    </tr>
                                    <!-- product week start -->
                                    <ng-container *ngFor="let product of location?.materials;let k=index">
                                        <!-- aqiv or first product depend the the productlist sequence -->
                                        <ng-container *ngIf="product.materialID==deliverydata?.productList[0].code">
                                            <tr id="delivey_aQIV">

                                                <ng-container *ngIf="deliverydata?.productList[0].baseProductName=='aTIV - 10 Pack';else aqiv">
                                                    <td class="productheader deliverycol">aTIV</td>
                                                </ng-container>
                                                <ng-template #aqiv>
                                                    <td class="productheader deliverycol">aQIV</td>
                                                </ng-template>


                                                <td class="deliverycol1"></td>

                                                <ng-container
                                                *ngFor="let productinput1 of product.deliverySlots;let j=index">
                                                <ng-container *ngIf="deliverydata.deliverySlotConstraintList.maxDeliverySlots!=0;else nobusinessrule1">
                                                <ng-container
                                                    *ngIf="j < deliverydata.deliverySlotConstraintList.maxDeliverySlots;else product1gap">
                                                    <td class="deliverydate_input"
                                                        [ngClass]="productinput1.producterrorMsg ? 'has-error' : ''">
                                                        <input type="number"
                                                            [ngClass]="productinput1.available=='N'?'at_capacity':''"
                                                            [disabled]="productinput1.available=='N' || productinput1.disablerule==true"
                                                            [(ngModel)]="productinput1.quantity"
                                                            (ngModelChange)="handleOndeliveryValueChange($event,i,j,k)"
                                                            autocomplete="off"
                                                            class="delivery_data  aqivcount form-control dynvacinedoses"
                                                            id="aqivfirst[{{i}}][{{j}}]"
                                                            name="createOrderData[{{i}}].createOrderProductData[0].createOrderDeliverySlotData[{{j}}]].quantity"
                                                            (keyup)="businessrule(i,j,$event,location?.materials.length)" /><span
                                                            class="glyphicon form-control-erroricon"
                                                            [ngClass]="productinput1.producterrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                    </td>
                                                </ng-container>
                                                <ng-template #product1gap>
                                                    <td class="gapbet" *ngIf="j == deliverydata.deliverySlotConstraintList.maxDeliverySlots"></td>
                                                    <td class="deliverydate_input"
                                                        [ngClass]="productinput1.producterrorMsg ? 'has-error' : ''">
                                                        <input type="number"
                                                            [ngClass]="productinput1.available=='N'?'at_capacity':''"
                                                            [disabled]="productinput1.available=='N'"
                                                            autocomplete="off"
                                                            [(ngModel)]="productinput1.quantity"
                                                            class="delivery_data  aqivcount form-control dynvacinedoses"
                                                            id="aqivfirst[{{i}}][{{j}}]"
                                                            name="createOrderData[{{i}}].createOrderProductData[0].createOrderDeliverySlotData[{{j}}]].quantity"
                                                            (ngModelChange)="handleOndeliveryValueChange($event,i,j,k)"
                                                             /><span
                                                            class="glyphicon form-control-erroricon"
                                                            [ngClass]="productinput1.producterrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                    </td>
                                                </ng-template>
                                                </ng-container>
                                                <ng-template #nobusinessrule1>
                                                    
                                            
                                                    <td class="gapbet" *ngIf="j ==maxslot"></td>
                                                    <td class="deliverydate_input"
                                                        [ngClass]="productinput1.producterrorMsg ? 'has-error' : ''">
                                                        <input type="number"
                                                            [ngClass]="productinput1.available=='N'?'at_capacity':''"
                                                            [disabled]="productinput1.available=='N'"
                                                            autocomplete="off"
                                                            [(ngModel)]="productinput1.quantity"
                                                            class="delivery_data  aqivcount form-control dynvacinedoses"
                                                            id="aqivfirst[{{i}}][{{j}}]"
                                                            name="createOrderData[{{i}}].createOrderProductData[0].createOrderDeliverySlotData[{{j}}]].quantity"
                                                            (ngModelChange)="handleOndeliveryValueChange($event,i,j,k)"
                                                             /><span
                                                            class="glyphicon form-control-erroricon"
                                                            [ngClass]="productinput1.producterrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                    </td>
                                             
                                                </ng-template>

                                            </ng-container>

                                                <td class="gapdose"></td>
                                                <td class="deliveryallocated_input dosesbox"
                                                    [ngClass]="product.allocatederrorMsg ? 'has-error' : ''"><input
                                                        type="number" autocomplete="off"
                                                        class=" delivery_data aqiv_allocated form-control dynallacted"
                                                        id="aqivallocated[{{i}}]" [(ngModel)]="product.allocatedvalue"
                                                        name="deliveryweek.aqivallocated[{{i}}]" readonly
                                                        value="" /><span class="glyphicon form-control-erroricon"
                                                        [ngClass]="product.allocatederrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                </td>
                                                <td class="deliverydate_input dosesbox "><input type="number"
                                                        autocomplete="off"
                                                        class=" delivery_data aqivtotal form-control "
                                                        id="aqivtotal[{{i}}]" value={{product.qty}} readonly /></td>
                                                <td class="greentick" [ngClass]="product.matchtick? 'matchtick':''">
                                                </td>
                                            </tr>
                                            <tr class="aqivyear">
                                                <ng-container
                                                *ngIf="deliverydata?.productList[0].baseProductName=='aTIV - 10 Pack';else aqivpack">
                                                <td class="middletext deliverycol "><span class="tenpack">10-pack</span>
                                                    <br><span class="delivery_ProductDesc">(50 years & above)</span></td>
                                                </ng-container>
                                                    <ng-template #aqivpack>
                                                        <td class="middletext deliverycol "><span class="tenpack">10-pack</span>
                                                            <br><span class="delivery_ProductDesc">(65 years & above)</span></td>
                                                        
                                                    </ng-template>

                                                
                                                <td class="deliverycol1"></td>
                                                <td colspan="17" class="errorarea">
                                                    <ng-container *ngIf="location?.materials.length!=2">
                                                        <!-- error handle if only aqiv -->
                                                        <div class="totalcheck" *ngIf="location?.locationsumerror">
                                                            Scheduled doses must equal ordered doses</div>
                                                            <div class="allocatedcheck">
                                                            <ng-container
                                                            *ngIf="location?.locationlevelerror">{{location.locationmsg}}</ng-container></div>
                                                        <!-- <div class="allocatedcheck">Please enter a volume to the nearest unit of 10</div> -->
                                                    </ng-container>

                                                </td>


                                            </tr>

                                        </ng-container>
                                        <ng-container *ngIf="product.materialID==deliverydata?.productList[1].code">
                                            <tr id="delivey_QIVc">

                                                <ng-container
                                                    *ngIf="deliverydata?.productList[1].baseProductName=='QIVc - 10 Pack';else tivc">
                                                    <td class="productheader deliverycol">QIVc</td>
                                                </ng-container>
                                                <ng-template #tivc>
                                                    <td class="productheader deliverycol">TIVc</td>
                                                </ng-template>
                                                <td class="deliverycol1"></td>
                                                <ng-container
                                                *ngFor="let productinput2 of product.deliverySlots;let j=index">
                                                <ng-container *ngIf="deliverydata.deliverySlotConstraintList.maxDeliverySlots!=0;else nobusinessrule2">
                                                <ng-container
                                                    *ngIf="j<deliverydata.deliverySlotConstraintList.maxDeliverySlots;else product2gap">
                                                    <td class="deliverydate_input"
                                                        [ngClass]="productinput2.producterrorMsg ? 'has-error' : ''">
                                                        <input type="number"
                                                            [ngClass]="productinput2.available=='N'?'at_capacity':''"
                                                            [disabled]="productinput2.available=='N' || productinput2.disablerule==true"
                                                            autocomplete="off"
                                                            class="delivery_data qivccount  form-control dynvacinedoses"
                                                            id="qivcfirst[{{i}}][{{j}}]"
                                                            [(ngModel)]="productinput2.quantity"
                                                            (ngModelChange)="handleOndeliveryValueChange($event,i,j,k)"
                                                            name="createOrderData[{{i}}].createOrderProductData[1].createOrderDeliverySlotData[{{j}}].quantity"
                                                            (keyup)="businessrule(i,j,$event,location?.materials.length)" /><span
                                                            class="glyphicon form-control-erroricon"
                                                            [ngClass]="productinput2.producterrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                    </td>
                                                </ng-container>
                                                <ng-template #product2gap>
                                                    <td class="gapbet" *ngIf="j==deliverydata.deliverySlotConstraintList.maxDeliverySlots"></td>
                                                    <td class="deliverydate_input"
                                                        [ngClass]="productinput2.producterrorMsg ? 'has-error' : ''">
                                                        <input type="number"
                                                            [ngClass]="productinput2.available=='N'?'at_capacity':''"
                                                            [disabled]="productinput2.available=='N'"
                                                            autocomplete="off"
                                                            class="delivery_data qivccount form-control dynvacinedoses"
                                                            id="qivcfirst[{{i}}][{{j}}]"
                                                            [(ngModel)]="productinput2.quantity"
                                                            name="createOrderData[{{i}}].createOrderProductData[1].createOrderDeliverySlotData[{{j}}].quantity"
                                                            (ngModelChange)="handleOndeliveryValueChange($event,i,j,k)"
                                                            /><span
                                                            class="glyphicon form-control-erroricon"
                                                            [ngClass]="productinput2.producterrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                    </td>
                                                </ng-template>
                                                </ng-container>
                                                <ng-template #nobusinessrule2>
                                                    <td class="gapbet" *ngIf="j==maxslot"></td>
                                                    <td class="deliverydate_input"
                                                        [ngClass]="productinput2.producterrorMsg ? 'has-error' : ''">
                                                        <input type="number"
                                                            [ngClass]="productinput2.available=='N'?'at_capacity':''"
                                                            [disabled]="productinput2.available=='N'"
                                                            autocomplete="off"
                                                            class="delivery_data qivccount form-control dynvacinedoses"
                                                            id="qivcfirst[{{i}}][{{j}}]"
                                                            [(ngModel)]="productinput2.quantity"
                                                            name="createOrderData[{{i}}].createOrderProductData[1].createOrderDeliverySlotData[{{j}}].quantity"
                                                            (ngModelChange)="handleOndeliveryValueChange($event,i,j,k)"
                                                            /><span
                                                            class="glyphicon form-control-erroricon"
                                                            [ngClass]="productinput2.producterrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                    </td>
                                                </ng-template>
                                            </ng-container>

                                                <td class="gapdose"></td>
                                                <td class="deliveryallocated_input dosesbox"
                                                    [ngClass]="product.allocatederrorMsg ? 'has-error' : ''"><input
                                                        type="number" autocomplete="off"
                                                        class="  delivery_data qivcallocated form-control dynallacted"
                                                        id="qivcallocated[{{i}}]" value=""
                                                        [(ngModel)]="product.allocatedvalue"
                                                        name="deliveryweek.qivcallocated[{{i}}]" readonly /><span
                                                        class="glyphicon form-control-erroricon"
                                                        [ngClass]="product.allocatederrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                </td>
                                                <td class="deliverydate_input dosesbox "><input type="number"
                                                        autocomplete="off" class=" delivery_data qivctotal form-control"
                                                        id="qivctotal[{{i}}]" value={{product.qty}} readonly /></td>
                                                <td class="greentick" [ngClass]="product.matchtick? 'matchtick':''">
                                                </td>
                                            </tr>
                                            <tr class="qivcyear">
                                                <ng-container
                                                    *ngIf="deliverydata?.productList[1].baseProductName=='QIVc - 10 Pack';else tivcpack">
                                                    <td class="middletext deliverycol"><span class="tenpack">10-pack</span><br><span class="delivery_ProductDesc">(6 months & above)</span></td>
                                                </ng-container>
                                                <ng-template #tivcpack>
                                                    <td class="middletext deliverycol"><span class="tenpack">10-pack</span><br><span class="delivery_ProductDesc">(18 to 64 years in eligible groups)</span></td>
                                                </ng-template>

                                                <td class="deliverycol1"></td>
                                                <td colspan="17" class="errorarea">
                                                    <div class="totalcheck" *ngIf="location?.locationsumerror">Scheduled
                                                        doses must equal ordered doses</div>
                                                    <div class="allocatedcheck">
                                                        <ng-container
                                                            *ngIf="location?.locationlevelerror">{{location.locationmsg}}</ng-container>
                                                    </div>
                                                </td>
                                            </tr>

                                        </ng-container>



                                    </ng-container>




                                </table>




                            </div>


                        </div>

                    </div>


                </form>



            </div>




        </div>
        <!-- bottom section exit button and Review buttom -->
        <div class="deliveryReview_but margin-B30">
            <div class="bot_section">
                <a class="select_exit" (click)="handleOnExitClick()">Exit</a>
                <button class="select_review" [ngClass]="disabledelivery==false?'reviewbtn_disable':''"
                    (click)="handleOnReviewOrderBtnClick()">Review
                    Order</button>
            </div>
        </div>

    </section>
</div>