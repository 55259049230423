<div class="main__inner-wrapper redirect_removeoverflow">
    <div class="errorbody-section">
        <div class="col-xs-12 redirect_header">
            <img title="" alt="" [src]="getImage(mobileUKData.logo)">
        </div>
        <div class="container redirect_container">
                
                <div class="redirect_contenthead">
                    <span class="global_Lshape">
                        <div class="global_horizontalL"></div>
                        <div class="global_verticalL"></div>
                    </span>
                    <span style="margin-left:10px;" [innerHTML]="mobileUKData.headline"></span>
                </div>
                <div class="redirect_subheader">
                    {{mobileUKData.h2content}}
                </div>
                <div class="redirect_content">
                    {{mobileUKData.h3content}}
                </div>
                <div class="redirect_greybanner">
                    <div class="redirect_image" [ngStyle]="{'background-image':'url('+getImage(mobileUKData.media)+')'}"></div>
                </div>
                <div class="redirect_register">
                    <div></div>{{mobileUKData.t1text}}
                </div>
                <div class="redirect_telephone">
                    <button [innerHTML]="mobileUKData.t2text"></button>
                </div>
                <div *ngIf="!isSuccess" class="redirect_emailtext">
                    <div></div>{{mobileUKData.t3text}}
                </div>
                <form *ngIf="!isSuccess" class="redirect_emailform" id="redirect_emailform" [formGroup]="customerNotificationForm" (ngSubmit)="submitNotificationForm()">
                    <div class="form-group" [ngClass]="{'has-error':customerNotificationForm.get('email')?.dirty && customerNotificationForm.get('email')?.invalid}">
                        <input type="text" class="redirect_email form-control" id="redirect_email" name="redirect_email" placeholder="email address" formControlName="email"/>
                        <span *ngIf="customerNotificationForm.get('email')?.dirty && customerNotificationForm.get('email')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                        <label *ngIf="customerNotificationForm.get('email')?.dirty && customerNotificationForm.get('email')?.hasError('required')" id="redirect-email-error" class="error has-error help-block" for="redirect_email">Please enter your Email</label>
                        <label *ngIf="customerNotificationForm.get('email')?.dirty && customerNotificationForm.get('email')?.hasError('pattern')" id="redirect-email-error" class="error has-error help-block" for="redirect_email">Please enter valid Email</label>
                
                    </div>
                    <div class="redirect_submit">
                        <button type="submit" class="redirect_submitbtn">Submit</button>
                    </div>
                </form>
                <div *ngIf="isSuccess"class="redirect_emailsuccess">Email Sent!</div>
                <div class="redirect_disclaimer">   
                    {{mobileUKData.content}}
                </div>
        </div>
        
    </div>

</div>
<div class="row mobile_footer_spl" style="margin:0;">
    <div class="col-md-12 redirect_footerimagecontainer">
        <img class="redirect_footerlogo" title="" alt=""
            [src]="getImage(mobileUKData.bottomImage)">
    </div>
    <div class="col-md-12 redirect_footertext">{{ mobileUKData.paragraphcontent}}</div>
</div>
