import { Component } from '@angular/core';

@Component({
  selector: 'app-inactive-page',
  templateUrl: './inactive-page.component.html',
  styleUrl: './inactive-page.component.scss'
})
export class InactivePageComponent {

}
