<div *ngIf="object | async as item" class="bg--grey-0 w-100">
    <div class="container" >
       <div  class="row-flex bg--grey-0">
           <div id="influenza-impacts-header" class="col-flex-lg-12 col-flex-md-12 col-flex-sm-12 col-flex-sm-12 text-center">
               <h2 class="text"[innerHTML]="item.headLine"></h2>
                     <p class="text-gray"[innerHTML]="item.subHeadLine"></p>                                
       </div>
   </div>
    </div>
   <div class="container">
       <div id="facts-card-row" class="row-flex bg--grey-0">
           <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
               <div class="bg--white facts-card">
                   <div class="text-center card-content">
                       <img alt="Hospital icon" src="../../../app/../assets/images/hospital-icon.svg">    
                            <p class="text1"[innerHTML]="item.subHeadLine1"></p>
                       </div>
               </div>
           </div>
           <div class="col-lg-6 col-md-4 col-sm-12 col-xs-12">
               <div class="bg--white facts-card">
                    <div class="text-center card-content">
                       <!-- <img class="img">{{item.mediaPicture1}} -->
                        <img alt="icon of EKG readout" src="../../../app/../assets/images/influenza653.svg">
                           <p class="text1"[innerHTML]="item.subHeadLine2"></p>
                       </div>
               </div>
           </div>
       </div>
    </div>
   </div>