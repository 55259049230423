<!-- 
    <div class="container"> 
        <div class="logo_left"> 
            <div class="logoImg_left">
               
                <img [src]="getImage(item?.media)" > 
             </div> 
              <div class="content_right">
                 <p class="prod-sbs__header header--3">QIVc is brought to you by CSL Seqirus</p> 
                 <p class="content_para">QIVc has been recommended by UK health authorities since 2018, for eligible adults under 65 years.<sup>5-11</sup>
                </p>
            </div>
         </div>
         </div> -->
         <div class="prod_description" *ngIf="node | async as item"> 
            <div class="container">
                 <div class="logo_left">
                     <div class="logoImg_left">
                        <img [src]="getImage(item?.media)" > 
                    </div>
                 </div>  
                 <div class="content_right"> 
                    <p class="prod-sbs__header header--3">QIVc is brought to you by CSL Seqirus</p> 
                    <p class="content_para">QIVc has been recommended by UK health authorities since 2018, for eligible adults under 65 years.<sup>5-11</sup>
                    </p>
        </div> 
    </div>
 </div>
