<div class="clearboth">
</div>
<div class="PibannerUk" *ngIf="object1 | async as item">
    <a class="pibannerlink" href="/prescribinginformation">{{item.name}}
        <img alt="Next screen" src="../../../app/../assets/images/arrow-right-white.svg">
    </a>
</div>

    

