<div id="afluria">
    <div class="hero--prod hero--prod--info-hasbg" *ngIf="firstnode | async as item" role="img" aria-label="Adult holding young child" [style.backgroundImage]="'url(' + getBgImage(item.media) + ')'">
        <div class="breadcrumbs--page-nav" *ngIf="!userLoggedIn">
            <div class="container">
                <p><a href="/">Home</a><span> &gt; </span><a href="/products">Products</a><span> &gt; </span><strong>AFLURIA</strong></p>
            </div>
        </div>
        <div class="container">
            <div class="hero--prod__body">
                <div class="hero--prod__logo" *ngIf="item?.mediaPicture">
                    <img [src]="getImage(item.mediaPicture)" alt="product logo">
                </div>
                    
                <div class="hero--prod__info">
                    <h1 class="hero--prod__header header--2" [innerHTML]="item.headline"></h1>
                    <div class="dynamic-content-wrapper" [innerHTML]="item.content"></div>
                </div>
            </div>
        </div>
        <div class="hero--prod__footer">
            <div class="container" [innerHTML]="item.largeparagraphcontent">
            </div>
        </div>
    </div>
</div>