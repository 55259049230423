import { ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { BaseSiteService, CmsNavigationComponent, OccConfig, OccEndpointsService } from '@spartacus/core';
import { CmsComponentData, NavigationNode, NavigationService } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ContentService } from '../../spartacus/services/content.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-custom-left-navigation',
  templateUrl: './custom-left-navigation.component.html',
  styleUrl: './custom-left-navigation.component.scss'
})
export class CustomLeftNavigationComponent {
  currentSite: string="";
  flucelvaxPdf!: Observable<any>;
  fluadPdf!: Observable<any>;
  afluriaPdf!: Observable<any>;
  prescribingFluadPdf: any;
  prescribingFlucelvaxPdf: any;
  prescribingAfluriaPdf: any;
  constructor(public component: CmsComponentData<CmsNavigationComponent>,
    private userInfoService:UserInfoService,protected contentService: ContentService,
    protected service: NavigationService,private cd:ChangeDetectorRef,
    private usersrvice:UserInfoService,
    protected config: OccConfig, private router: Router, private route: ActivatedRoute,private renderer: Renderer2, private el: ElementRef,private baseSiteService:BaseSiteService,private occEndPointsService: OccEndpointsService) { }
  bannerNode: Observable<NavigationNode | null | undefined | any> = this.service.createNavigation(
    this.component?.data$
  );
  iconClass: string = "fa fa-bars";
  isOpen: boolean = false;
  glyphiconIcon = "glyphicon-menu-down";
  userInfo: any;

  afluriaurl: any;
  fluadurl: any;
  flucelvaxurl: any;
  rbaflag:any;

  fetchPIPdfData(){
    // if(this.currentSite == 'seqirusb2b-flu360'){
    //   this.afluriaPdf= this.contentService.getNavigation("AfluriaHeaderLink");

    //   this.fluadPdf= this.contentService.getNavigation("FluadHeaderLink");

    //   this.flucelvaxPdf= this.contentService.getNavigation("FlucelvaxHeaderLink");
    //   this.afluriaPdf.pipe(map(val => val.media?.url)).subscribe(res => {
    //     this.afluriaurl = res;
    //   });
    //   this.fluadPdf.pipe(map(val => val.media?.url)).subscribe(res => {
    //     this.fluadurl = res;
    //   });
    //   this.flucelvaxPdf.pipe(map(val => val.media?.url)).subscribe(res => {
    //     this.flucelvaxurl = res;
    //   });
    // }
    this.contentService.getpdfFlucelvax().subscribe(res =>{
      this.flucelvaxurl =res ;
      this.cd.detectChanges();
    })
    this.contentService.getpdffluad().subscribe(res =>{
      this.fluadurl =res ;
      this.cd.detectChanges();
    })
    this.contentService.getpdfafluria().subscribe(res =>{
      this.afluriaurl =res ;
      this.cd.detectChanges();
    })
   }
   isUserLoggedIn: boolean=false;
  ngOnInit(){
   
    this.fetchPIPdfData();
    this.userInfoService.getMobileHeader().subscribe((res)=>{
      this.isOpen=res;
      if(this.isOpen){
        const temp= <HTMLElement> document.getElementsByClassName("SideContent")[0];
        if(temp){
          temp.style.width='100%';
        }
        
      }
      this.cd.detectChanges();
    })
    this.baseSiteService.getActive().subscribe(siteId => {
      this.currentSite = siteId;
    
    })
    this.usersrvice.userLoggedIn$.subscribe(res=>{
      this.isUserLoggedIn=res;
    });
    this.userInfoService.getFluadPdf().subscribe(res => {
      this.prescribingFluadPdf = this.occEndPointsService.buildUrl(res);
    })
    this.userInfoService.getFlucelvaxPdf().subscribe(res => {
      this.prescribingFlucelvaxPdf = this.occEndPointsService.buildUrl(res);
    })
    this.userInfoService.getAfluriaPdf().subscribe(res => {
      this.prescribingAfluriaPdf = this.occEndPointsService.buildUrl(res);
    });
    this.rbafunction();
    
    this.cd.detectChanges();
  }
  handleIconToggle(node: any) {
    if (node.title == 'Prescribing Information') {
      node.children[0].url = this.fluadurl;
      node.children[1].url =this.afluriaurl; 
      node.children[2].url = this.flucelvaxurl;
      this.cd.detectChanges();
    }
    this.cd.detectChanges();
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  geturl(url: any): string {
    return this.config.backend?.occ?.baseUrl + url;
  }
  
  isActive(url: string): boolean { 
    if (!url) {
      return false;
    }
    const currentUrlSegment = this.router.url.split('/').pop();
  const nodeUrlSegment = url.split('/').pop();
  return currentUrlSegment === nodeUrlSegment;
    
  // return this.router.url === url || this.router.url.includes(url); 
  }

  ngAfterViewInit() {
    
    setTimeout(()=>{ 
    const childElements = this.el.nativeElement.querySelectorAll('.active');
    childElements.forEach((child: HTMLElement) => {
      
        const parent = child.closest('.collapse');
        if (parent) {
          this.renderer.addClass(parent, 'show');
        }
     
    });
  }, 5000);
  }
  
  exp:boolean=false;
  isExpanded(){
    this.exp=!this.exp;
  }

  updateUserData() {
    if((!this.router.url.includes('homepage') && !this.router.url.includes('login')) || this.currentSite=='seqirusb2b-flu360' || this.currentSite=='seqirusb2b-uk'){
     this.userInfoService.userLoggedIn$.subscribe(res=>{
       this.isUserLoggedIn=res;
       if(this.isUserLoggedIn){
         this.userInfoService.getUserData().subscribe(res=>{
           if(res){
           this.userInfo=res;
           }
         })
       }
       else{
       this.cd.detectChanges();
       }
     })
     }
   }
   rbafunction(){
    if(this.currentSite=='seqirusb2b-uk'){
      this.userInfoService.rbasiteflag().subscribe(data=>{
     
       this.rbaflag=data;
       this.cd.detectChanges();
       console.log(this.rbaflag);
        
   
      });
     
    }
 
   }
}