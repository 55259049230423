import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { ClinicalFlucelvaxFirstComponent } from './clinical-flucelvax-first/clinical-flucelvax-first.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ClinicalFlucelvaxSecondComponent } from './clinical-flucelvax-second/clinical-flucelvax-second.component';
import { ClinicalDataThirdComponent } from './clinical-data-third/clinical-data-third.component';

@NgModule({
    declarations: [
        //  ClinicalFluadSecondComponent,
        //  ClinicalFluadThirdComponent,
        ClinicalFlucelvaxFirstComponent,
        ClinicalFlucelvaxSecondComponent,
        ClinicalDataThirdComponent,
        //  TopnavigationComponent,
        //  FluadchoosenowComponent,
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        ConfigModule.withConfig({
            cmsComponents:{
                FlucelvaxChildrenAdultChart:{
                    component: ClinicalFlucelvaxFirstComponent
                },
                FlucelvaxChildrenAdultSafetyProfile:{
                    component: ClinicalFlucelvaxSecondComponent
                },
                SeqirusFlucelvaxBannerComponent:{
                    component: ClinicalDataThirdComponent
                }
            }
        })
    ]
})

export class ClinicaldataFlucelvaxModule { }
