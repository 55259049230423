<div *ngIf="isThankYouPage() | async">
<ng-container *ngIf="regThankYouData | async as data">
    
    <div class="col-xs-12 registration_thankyou_mob">
        <div class="registration_thankyou_rightheadermob"><a href="/">Home</a> &gt; <span>Create Account</span></div>
        <div class="clearboth"></div>                   
    </div>
    <div class="col-md-3 col-xs-12 registration_thankyou_leftside">
        <div class="col-xs-12 col-md-10 registration_thankyou_nagivation no-padding">
            <div class="registration_thankyou_nagivationName1">
                {{data.title}}
            </div>
            <div class="registration_thankyou_nagivationName2">
                {{data.text2}}
            </div>
        </div>
        <!-- added job bag codes -->
        <!-- <c:if test="${isEligibleForUk eq true}">
            <div class="col-xs-12 grbno no-padding margin-T10 margin-B10">
                ${jalosession.tenant.config.getParameter('joinAccountPageUK.doc.text')}
            </div>
        </c:if> -->
    </div>   
    <div class=" col-md-9  col-xs-12 registration_thankyou_rightside">
        <div class=" col-md-12 col-xs-12 registration_thankyou_parentheader no-padding-left">
            <div class="registration_thankyou_rightheader">
                
                    <a href="#">Home</a> &gt; <span>Create Account</span>
                
            </div>
            <!-- <div class="registration_thankyou_help" routerLink="/help-and-faq"  [innerHTML]="data.h2content"> -->
                <div class="registration_thankyou_help">Having trouble? &nbsp;<a routerLink="/help-and-faq"  class="showPopupclick"><span>We Can Help</span><span class="global_blackarrow"></span></a>   
                
            </div>
            <div class="clearboth"></div>                   
        </div>
        <div class="clearboth"></div>
        <div class="registration_thankyou_textheader">
            {{data.content}}
        </div>
        <div class="registration_thankyou_text">
            {{data.paragraphcontent}}
        </div>
        <div class="registration_thankyou_button">
            <a href="{{data.urlLink}}">{{data.h3content}}</a>
        </div>
    </div>
</ng-container>
</div>