import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { ExplicitFooterComponent } from './explicit-footer/explicit-footer.component';



@NgModule({
  declarations: [
    FooterComponent,
    ExplicitFooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    ConfigModule.withConfig({
      cmsComponents: {
         FooterNavigationComponent: {
          component:FooterComponent
        },
        ExplicitFooterNavigationComponent: {
          component:ExplicitFooterComponent
        }
      }
    } as CmsConfig)
  ]
})
export class CustomFooterModule { }
