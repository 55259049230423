import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomQuickCodingReferenceComponent } from './custom-quick-coding-reference/custom-quick-coding-reference.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [
    CustomQuickCodingReferenceComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    RouterModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    BrowserModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusQuickCodingReference:{
          component: CustomQuickCodingReferenceComponent
        }
      }
    }as CmsConfig)
  ]
})
export class CodingandbillingModule { }
