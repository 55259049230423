import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilepageComponent } from './profilepage/profilepage.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    ProfilepageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        
        SeqirusProfileActivationComponent:{
          component: ProfilepageComponent ,
        }
      }
    } as CmsConfig)
  
    
  ]
})
export class ProfileactivationModule { }
