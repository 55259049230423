import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, switchMap, throwError, of, filter, BehaviorSubject, catchError, map } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';
import { ApiResponse, GalleryImage, LocationData } from './location-data.model';
import { tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ReturnsUkService {


  private apiLocationreturnUrl = this.occ.buildUrl("/users/current/returnsOrders/startNewReturn/2023-2024?fields=DEFAULT");
  private apiNoreturnUrl = this.occ.buildUrl("/users/current/returnsOrders/noReturnSubmit?fields=DEFAULT");
  private apiProductstoreturn = this.occ.buildUrl("/users/current/returnsOrders/productsToReturn?fields=DEFAULT");
  private apisubmitReturnsUrl = this.occ.buildUrl("/users/current/returnsOrders/submitUKReturns?fields=DEFAULT");
  private apigetSeasonReturnOrders = this.occ.buildUrl("/users/current/returnsOrders/getSeasonReturnOrders/${season}?fields=DEFAULT");
  private apigetReturnbyLocation = this.occ.buildUrl("/users/current/returnsOrders")
  private apigetSeason = this.occ.buildUrl("/users/current/returnsOrders/seasonDropdownReturns/2023-2024?fields=DEFAULT")

  constructor(private http: HttpClient,private occ:OccEndpointsService) { }
  private showDivSource = new BehaviorSubject<boolean>(false);
  currentShowDiv = this.showDivSource.asObservable();
  private locationImages: { [key: string]: GalleryImage[] } = {};
  locationImageFiles: { [key: string]: File[] } = {};
  changeShowDiv(show: boolean) {
    this.showDivSource.next(show);
  }

  private showNewReturn = new BehaviorSubject<boolean>(false);
  currentShowStatus = this.showNewReturn.asObservable();
  private additionalReturnDataSubject = new BehaviorSubject<any>(null);
  additionalReturnData$ = this.additionalReturnDataSubject.asObservable();
  changeShowStatus(show: boolean) {
    this.showNewReturn.next(show);
  }

  getLocations(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.apiLocationreturnUrl).pipe(
      tap((data: any) => console.log('API Response:', data))
    );
  }

  getSeasonReturnOrders(season: string): Observable<any> {
   const apiUrl = `${this.apigetReturnbyLocation}/getSeasonReturnOrders/${season}?fields=DEFAULT`;
   return this.http.get<any>(apiUrl);
  }

  getReturnEligibility(season: string): Observable<any> {
    const apiUrl = `${this.apigetReturnbyLocation}/getMyReturnsInfo/${season}?fields=DEFAULT`;
    return this.http.get<any>(apiUrl);
   }

  getAdditionalReturn(returnId: string, season: string): Observable<any> {
    const apiUrl = `${this.apigetReturnbyLocation}/additionalReturn?fields=DEFAULT&returnId=${returnId}&season=${season}`;
    return this.http.get<any>(apiUrl);
  }

  deleteDosesConsent(returnId: string): Observable<any> {
    const url = `${this.apigetReturnbyLocation}/deleteDosesConsent?fields=DEFAULT&returnId=${returnId}`;
    return this.http.get(url);
  }

  setAdditionalReturnData(data: any): void {
    this.additionalReturnDataSubject.next(data);
  }

  getSeasons(): Observable<any> {
    return this.http.get<any>(this.apigetSeason);
  }

  getReturnDetails(locId: string, orderSeason: string): Observable<any> {
    const url = `${this.apigetReturnbyLocation}/getReturnsByLoc/${orderSeason}?fields=DEFAULT&locId=${locId}`;
    return this.http.get<any>(url);
  }

  noReturns(soldToID: string, locIDs: string[]): Observable<any> {
    const payload = {
      soldToID: soldToID,
      season: '2023-2024',
      shipToIdList: locIDs.map(locID => ({ shipToId: locID }))
    };
    return this.http.post(this.apiNoreturnUrl, payload, { responseType: 'text' }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error.message);
    console.error('Error details:', error); // Log detailed error information
    return throwError('Something went wrong; please try again later.');
  }

  

  submitReturns(payload: any): Observable<any> {
    const url = this.occ.buildUrl("/users/current/returnsOrders/submitUKReturns?fields=DEFAULT");
    return this.http.post(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'text' as 'json' // Specify the response type as text
    }).pipe(
      catchError(this.handleError) // Handle errors
    );
  }

  
  postProductsToReturn(locIDs: string[]): Observable<any> {
    const payload = {
      locations: locIDs.map(locID => ({ locationId: locID })),
      season: '2023-2024'
    };
    return this.http.post(this.apiProductstoreturn, payload, { responseType: 'json' }).pipe(
      catchError(this.handleError)
    );
  }

 

  getImages(locationID: string): GalleryImage[] {
    if (!this.locationImages[locationID]) {
      this.locationImages[locationID] = [];
    }
    return this.locationImages[locationID];
  }

  addImage(locationID: string, image: GalleryImage): void {
    if (!this.locationImages[locationID]) {
      this.locationImages[locationID] = [];
    }
    this.locationImages[locationID].push(image);
  }

  
  removeImage(locationID: string, index: number): void {
    if (this.locationImages[locationID]) {
      this.locationImages[locationID].splice(index, 1);
    }
  }

  // New methods for File objects
getImageFiles(locationID: string): File[] {
  if (!this.locationImageFiles[locationID]) {
    this.locationImageFiles[locationID] = [];
  }
  return this.locationImageFiles[locationID];
}

addImageFile(locationID: string, file: File): void {
  if (!this.locationImageFiles[locationID]) {
    this.locationImageFiles[locationID] = [];
  }
  this.locationImageFiles[locationID].push(file);
}

removeImageFile(locationID: string, index: number): void {
  if (this.locationImageFiles[locationID]) {
    this.locationImageFiles[locationID].splice(index, 1);
  }
}

submitImages(formData: FormData): Observable<any> {
  const url = this.occ.buildUrl("/users/current/returnsOrders/imageUploadReturns?fields=DEFAULT");
  return this.http.post(url, formData, { responseType: 'text' }).pipe(
    catchError(this.handleError)
  );
  
}

private locnameSource = new BehaviorSubject<string>('');
currentLocname = this.locnameSource.asObservable();

changeLocname(locname: string) {
  this.locnameSource.next(locname);
}
private visibilityState = new BehaviorSubject<string>('A');
  currentVisibility = this.visibilityState.asObservable();
  changeVisibility(component: string) {
    this.visibilityState.next(component);
  }

}
