import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomMemberModalComponent } from '../custom-member-modal/custom-member-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomRequestThankyouComponent } from '../custom-request-thankyou/custom-request-thankyou.component';

@Component({
  selector: 'app-custom-account-info',
  templateUrl: './custom-account-info.component.html',
  styleUrl: './custom-account-info.component.scss'
})
export class CustomAccountInfoComponent {
  accountDetails:any;
  toggleEdit: boolean = false;
  customerRegisterForm: FormGroup | any;
  statesList: string[] = ["AL", "AK", "AR", "AZ", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "GU", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NC", "ND", "NE", "NV", "NH", "NJ", "NM", "NY", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY"]
  showtool: boolean = false;
  constructor(private fb: FormBuilder, private occEndpointsService: OccEndpointsService,
    private cd: ChangeDetectorRef, private commonService: CommonApiService,private dialog: MatDialog,) {
    this.setForm();

  }
  edit() {
    this.toggleEdit = !this.toggleEdit;
  }

  item: any = {
    globalLocationNumber: '3497434973944' // Example value
  };

  formatNumber(value: string | number): string {
    if (!value) {
      return 'N/A';
    }

    // Convert the value to a string
    let strValue = value.toString();

    // Define the positions where dots should be inserted
    const positions = [7, 12]; // Example positions

    // Insert dots at the specified positions
    for (let i = 0; i < positions.length; i++) {
      const position = positions[i] + i; // Adjust position for already inserted dots
      if (position < strValue.length) {
        strValue = strValue.slice(0, position) + '.' + strValue.slice(position);
      }
    }

    return strValue;
  }
  showRequestModal(type: string) {
    this.openDialogRequest({ 
      type: type,
      company:this.getFormControl('companyname').value,
      url:this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/updateCompany`),
      payload: this.customerRegisterForm.value }, '450px');
  }
  setForm() {
    this.customerRegisterForm = this.fb.group({
      locationscount: [''],
      Accountno: [''],
      companyname: [''],
      state: [''],
      addressId:[''],
      globalLocationNumber: [''],
      line1: ['', [Validators.required]],
      line2: [''],
      country:['US'],
      city: ['', [Validators.required, Validators.pattern('^[^\s].+[a-zA-Z]+[a-zA-Z]+$')]],
      postalCode: ['', [Validators.required, Validators.pattern('^(?!0{3})[0-9]{5,5}$')]],

    })
  }
  showTooltip() {
    this.showtool = true;
  }

  hideTooltip(){
    this.showtool= false;
  }
  getFormControl(control: string) {
    return this.customerRegisterForm!.get(control);
  }
  getForm(data: any) {
    this.customerRegisterForm.patchValue({
      locationscount: data.locationCount,
      Accountno: data.accountNumber,
      addressId:data.addressId,
      companyname: data.organizationName,
      state: data.state,
      globalLocationNumber: data.globalLocationNumber,
      line1: data.line1,
      line2: data.line2,
      city: data.town,
      postalCode: data.zipCode,
    })
    this.customerRegisterForm.controls['locationscount'].disable();
    this.customerRegisterForm.controls['Accountno'].disable();
    this.customerRegisterForm.controls['companyname'].disable();
  }
  ngOnInit() {
    this.getOrgDetails();
  }
  getOrgDetails() {
    let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/orgDetails`);
    let payload = {
      url: endpoint,
    }
    this.commonService.getAPICall(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.accountDetails=res;
          this.getForm(res);
          this.cd.detectChanges();
        }
      },
      error: (e) => {
      },
    })
  }
  submitBusinessDetails() {
    const formValue: any = [];
    Object.keys(this.customerRegisterForm.controls).map((key) => {
      const parsedValue = {
        [key]: this.customerRegisterForm.get(key).value,
        changed: this.customerRegisterForm.get(key).dirty
      }
      if (parsedValue.changed) {
        formValue.push(key);
      }
    });
    if (this.customerRegisterForm.invalid) {
      this.customerRegisterForm!.markAllAsTouched();
      return;
    }
    let url = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/updateProfile`);
    let payload = {
      url: url,
      data: {
        addressType: 'Business',
        addressId: this.customerRegisterForm.value.addressId,
        globalLocationNumber: this.customerRegisterForm.value.globalLocationNumber,
        postalCode: this.customerRegisterForm.value.postalCode,
        city: this.customerRegisterForm.value.city,
        state: this.customerRegisterForm.value.state,
        country: this.customerRegisterForm.value.country,
        modifiedQualifiers: formValue.length ? formValue.toString() : '',
        orgName: this.getFormControl('companyname').value,
        line1: this.customerRegisterForm.value.line1,
        line2: this.customerRegisterForm.value.line2
      }
    }
    this.commonService.postAPICall(payload).subscribe({
      next: (res: any) => {
          this.toggleEdit = false;
          this.getOrgDetails();
        this.openDialog({ type: 'account-thank-you'}, '350px');
      },

      error: (e) => {
      },
    })
  }
  openDialog(data: any, height: string): void {
    this.dialog.open(CustomMemberModalComponent, {
      width: '600px',
      height: height,
      data: data,
    });
  }
  openDialogRequest(data: any, height: string): void {
    let dialogRef=this.dialog.open(CustomRequestThankyouComponent, {
      width: '600px',
      height: height,
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
        this.toggleEdit=false;
        this.getOrgDetails();
    });
  }
}
