import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-terms-headline',
  templateUrl: './terms-headline.component.html',
  styleUrl: './terms-headline.component.scss'
})
export class TermsHeadlineComponent {
  leftBannerNode: Observable<any> = this.bannerComponent.data$;
  userLoggedIn: boolean = false;
  constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>, private userInfoService:UserInfoService) {
     }
}
