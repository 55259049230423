import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSlideResourcesComponent } from './custom-slide-resources/custom-slide-resources.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomHeroComponent } from './custom-hero/custom-hero.component';
import { CustomSafteyInfoComponent } from './custom-saftey-info/custom-saftey-info.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CustomSlideResourcesComponent,
    CustomHeroComponent,
    CustomSafteyInfoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusFlu360VaccineSlideResources:{
          component: CustomSlideResourcesComponent ,
      },
      SeqirusHeroBackgroundBannerComponent:{
        component:  CustomHeroComponent,
    },
    SeqirusSafetyInfoComponent:{
      component:  CustomSafteyInfoComponent ,
  },
    }
  }as CmsConfig)
  ]
})
export class CustomAllproductsModule { }
