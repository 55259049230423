import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterByChangeComponent } from './filter-by-change/filter-by-change.component';
import { CustomTilesComponent } from './custom-tiles/custom-tiles.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CustomDetailedpageComponent } from './custom-detailedpage/custom-detailedpage.component';



@NgModule({
  declarations: [
    FilterByChangeComponent,
    CustomTilesComponent,
    CustomDetailedpageComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents:{
        filterByFlexComponent:{
        component:FilterByChangeComponent
        },
        toolsResourceTilesFlexComponent:{
         component:CustomTilesComponent
        },
        ToolsAndResourceDetailFlexComponent:{
          component: CustomDetailedpageComponent
        }
      }
    }as CmsConfig)
  ]
})
export class ToolsAndResourcesModule { }
