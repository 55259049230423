import { Component, ViewEncapsulation } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-alfuria-first',
  templateUrl: './alfuria-first.component.html',
  styleUrl: './alfuria-first.component.scss',
})
export class AlfuriaFirstComponent {

  firstnode: Observable<any> = this.bannerComponent.data$;
  userLoggedIn: boolean = false;
    
  constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, private userInfoService:UserInfoService) { }

  ngOnInit(): void{
    this.userInfoService.userLoggedIn$.subscribe(res=>{
     console.log('login check',res);
     this.userLoggedIn = res;
   })
  }
  
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  getBgImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
}
