<div id="influenza-carousel-desktop">
    <div class="row-flex bg--grey-0">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 icon-carousel-row">
            <div *ngFor="let item of mergedObject; let i = index"
                class="col-lg-4 col-md-4 col-sm-12 col-xs-12 center-xs icon-carousel-col"
                [class.active]="activeIndex === i" (click)="setActiveIndex(i)">
                <img [alt]="item.altText" [src]="getImage(item.media)" class="icon-carousel-img">

                <!-- <img class="hero--circlechart-chart" alt="image" [src]="getImage(item.mediaPicture)"> -->
                <p>{{ item.headline }}</p>
            </div>
        </div>
        <div class="carousel-bar-line">
            <div class="active-bar"></div>
            <div *ngFor="let item of mergedObject; let i = index" class="bar-segment" (click)="onBarLineClick(i)"></div>
        </div>
        <div class="carousel-inner">
            <div *ngFor="let item of mergedObject; let i = index" class="carousel-item"
                [ngClass]="{'active': activeIndex === i}">
                <div class="row slide--content d-flex">
                    <div class="col-lg-4 col-md-12 col-sm-12 left-column">
                        <p class="carousel-content" [innerHTML]="item.largeparagraphcontent"></p>
                        <p class="potential-factorukflu360" *ngIf="currentSite === 'seqirusb2b-uk'" [innerHTML]="item.h3content"></p>
                        <div class="link-container">
                            <a [href]="item.link" [innerHTML]="item.h2content"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="influenza-carousel-desktop-btn-container">
            <button class="custom-carousel-control-btn custom-carousel-control-prev" type="button"
                (click)="gotoprevious()">
                <span class="custom-icon prev-icon">
                    <img alt="Navigate to previous arrow" src="assets/images/prev-btn.svg">
                </span>
            </button>
            <button class="custom-carousel-control-btn custom-carousel-control-next" type="button" (click)="gotonext()">
                <span class="custom-icon next-icon">
                    <img alt="Navigate to next arrow" src="assets/images/next-btn.svg">
                </span>
            </button>
        </div>
    </div>

    <!--mobile-->

    <div class="carousel-container" *ngIf="isMobile">
        <!-- Mobile Slick Dots -->

        <!-- Carousel Items (Image + Content) -->
        <div class="carousel-content">
            <div *ngFor="let item of mergedObject; let i = index" class="carousel-item"
                [ngClass]="{'active': activeIndex === i}">
                <div class="carousel-image">
                    <img [alt]="item.altText" [src]="getImage(item.media)" class="icon-carousel-img">
                </div>
                <div class="carousel-text">
                    <p class="carousel-content" *ngIf="activeIndex === i" [innerHTML]="item.largeparagraphcontent"></p>
                    <a href [innerHTML]="item.h2content"></a>
                </div>
                <div class="slick-dots">
                    <span *ngFor="let item of mergedObject; let i = index" [class.active]="activeIndex === i"
                        (click)="onDotClick(i)"></span>
                </div>
                <div class="influenza-carousel-desktop-btn-container">
                    <button class="custom-carousel-control-btn custom-carousel-control-previous" type="button"
                        (click)="gotoprevious()">
                        <span class="custom-icon prev-icon">
                            <img alt="Navigate to previous arrow" src="assets/images/prev-btn.svg">
                        </span>
                    </button>
                    <button class="custom-carousel-control-btn custom-carousel-control-next" type="button"
                        (click)="gotonext()">
                        <span class="custom-icon next-icon">
                            <img alt="Navigate to next arrow" src="assets/images/next-btn.svg">
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>