import {
  GetAccountInfoGigyaData,
  GetAccountInfoGigyaProfile,
} from './get-account-gigya-response';
import { GigyaResponse } from './gigya-response';

export interface GigyaSocialLoginResponse extends GigyaResponse {
  result: {
    UID: string;
    data: GetAccountInfoGigyaData;
    profile: GetAccountInfoGigyaProfile;
  };
}

export enum GigyaSocialLoginEnum {
  AMAZON = 'amazon',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
  INSTAGRAM = 'instagram',
}
