    <div class="response-container">
        <div class="modal-header">
            <h4 class="modal-title myprofile_modal_header" id="myModalLabel">Request Changes</h4>
            <button (click)="close()" type="button" class="myprofile_modalclose" data-dismiss="modal"
                aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
            <p class="myprofile_modalbody_txt">Please note, some information cannot be changed via
                the website. If you need to change anything beyond what's shown, you can request
                updates through <a [routerLink]="['/contact-us']" (click)="close()">
                    <span class="mysupporttxt"><u>flu360 Customer
                            Service</u>
                    </span>.</a>
                If you're unsure of some details, you may leave
                those fields blank. </p>
            <div class="row margin-T20">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <label class="myprofile_editbusinessinfo_txt">Company Details</label>

                </div>
            </div>

            <div class="row margin-T10">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <label class="myprofile_editcompany_txt" for="company-name">Current Company
                        Name*</label>
                    <label class="myprofile_editmodalbusinessinfo_txt" style="display:block;">{{data.company}}</label>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <label class="myprofile_editcompany_txt" for="locations-count">New Company
                        Name*</label>
                    <input #company (input)="getNewCompName(company.value)" class="myprofile_input form-control" id="billingOrgName" name="orgAddressForm.billingOrgName"
                        autocomplete="no">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button [disabled]="company.value.trim().length==0" (click)="submitBusinessDetails()" type="button" class="btn btn-default myprofile_modalrequestbtn" id="request_change" value="">Request
                Changes</button>
        </div>
    </div>
