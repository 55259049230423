import { ChangeDetectorRef, Component } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-saved-card-iframe',
  templateUrl: './saved-card-iframe.component.html',
  styleUrl: './saved-card-iframe.component.scss'
})
export class SavedCardIframeComponent {
  errorCode:any;
  paymentDetails: any;
  constructor(
    public userInfoService:UserInfoService,private cd:ChangeDetectorRef) {
  }
  ngOnInit(): void {
  //   localStorage.removeItem('isCancelTransaction')
  //   let id = localStorage.getItem('checkoutId')
  //  console.log('local storage',id);
    let obj:any = localStorage.getItem('paymentInfoIframeHostedId')
    console.log('local storage', obj)
    

      this.userInfoService.paymentInformationStatusIframe(obj).subscribe(data=>{
     
       
        console.log('data',data)
        this.errorCode = data;
       // this.paymentDetails = data.paymentDetails;
        this.cd.detectChanges();
       
        localStorage.setItem('iframePaymentInfoErrorCode', this.errorCode) 
         
        
        if(this.errorCode == 4){
          window.top?.location.reload();
        }
      
    //  if(data.cancelled == true){
    //  window.top?.location.reload();
    //  }
        
      })
      
  
    
  }
  ChangeClicked(){
    window.top?.location.reload();
  }
  // cancelTransactionClicked(){
  //   window.top?.location.reload();
  //   localStorage.setItem('isCancelTransaction','true')
  // }
}
