import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JoinAccountComponent } from './join-account/join-account.component';
import { JoinAccThankyouComponent } from './join-acc-thankyou/join-acc-thankyou.component';



@NgModule({
  declarations: [JoinAccountComponent, JoinAccThankyouComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,

    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusJoinAccountComponent: {
          component:JoinAccountComponent
        },
        JAThankyouCMSTabParagraphComponent:{
          component:JoinAccThankyouComponent
        },
      }
  
    } as CmsConfig)  
],

})
export class JoinAccountModule { }
