import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@spartacus/core';
import { AlfuriaFourthComponent } from './alfuria-fourth/alfuria-fourth.component';
import { AlfuriaFirstComponent } from './alfuria-first/alfuria-first.component';
import { AlfuriaSecondComponent } from './alfuria-second/alfuria-second.component';
import { AlfuriaThirdComponent } from './alfuria-third/alfuria-third.component';

@NgModule({
    declarations: [
        AlfuriaFourthComponent,
        AlfuriaFirstComponent,
        AlfuriaSecondComponent,
        AlfuriaThirdComponent,
    ],
    imports: [
        CommonModule,
        ConfigModule.withConfig({
            cmsComponents:{
                AffluriaPortfolioFeatureResource:{
                    component: AlfuriaFirstComponent
                },
                AffluriaKeyFeaturesResource:{
                    component: AlfuriaSecondComponent
                },
                SeqirusAfluriaReferenceComp:{
                    component: AlfuriaThirdComponent
                },
                AffluriaBannerComp:{
                    component: AlfuriaFourthComponent
                }
            }
        })
    ]
})

export class AlfuriaModule { }
