<div class="container col-xs-12 col-sm-12 no-padding main_container" id="prescribingpage">
    <div class="container-fluid container-lg">
        <div class="piheadersection" *ngIf="headerItems$ | async  as item">
            <div class="prescribeinfotext">
                {{ item.headLine1 }}
            </div>
            <div class="pititle">
                {{ item.subHeadLine1 }}
            </div>

            <div class="flexbox" *ngIf="prescribingProducts.length > 0">
                <div class="piseasonbox col-xs-6 margin-R20">
                    <div class="seasonheader pipaddingbottom" [innerHTML]="prescribingProducts[0].headLine1"></div>
                    <a class="piproductanchor pimarginbottom" href="#aqivsection" (click)="scrollToSection('aqivsection', $event)">
                        <div class="piproduct" [innerHTML]="prescribingProducts[0].text1"></div>
                    </a>
                    <a class="piproductanchor" href="#qivcsection" (click)="scrollToSection('qivcsection', $event)">
                        <div class="piproduct" [innerHTML]="prescribingProducts[0].text2"></div>
                    </a>
                </div>
                <div class="piseasonbox col-xs-6 margin-L20">
                    <div class="seasonheader pipaddingbottom" [innerHTML]="prescribingProducts[1].headLine1"></div>
                    <a class="piproductanchor pimarginbottom" href="#aTIVsection" (click)="scrollToSection('aTIVsection', $event)">
                        <div class="piproduct" [innerHTML]="prescribingProducts[1].text1"></div>
                    </a>
                    <a class="piproductanchor pimarginbottom" href="#TIVcsection" (click)="scrollToSection('TIVcsection', $event)">
                        <div class="piproduct" [innerHTML]="prescribingProducts[1].text2"></div>
                    </a>
                </div>
            </div>
        </div>
        <div class="picontentsection" *ngFor="let item of contentProducts; let i = index">
            <div class="pisubheader" [ngClass]="{'newproduct_piheader': sectionIds[i] === 'aTIVsection' || sectionIds[i] === 'TIVcsection'}" [id]="sectionIds[i]">{{ contentProducts[i].header1 }}</div>
            <div class="newproduct_spc" *ngIf="contentProducts[i].subHeadline">{{ contentProducts[i].subHeadline }}</div>
            <div class="flexcont">
                <div class="piproducttext lefttext" [innerHTML]="contentProducts[i].LargeText"></div>
                <div class="piproducttext righttext">
                    <div [innerHTML]="contentProducts[i].bottomText1"></div>
                    <div class="legalcategory" [innerHTML]="contentProducts[i].MidText1"></div>
                    <div class="forfull" [innerHTML]="contentProducts[i].MidText2"></div>
                    <div class="flexelement smallscreendoc" [innerHTML]="contentProducts[i].Text1"></div>
                </div>
            </div>
            <div class="flexcont-adverse">
                <div class="adversetext">
                    {{ contentProducts[i].Text2 }}
                </div>
                <button (click)="topFunction()" id="pi_backtop" title="Go to top">
                    <i class="fa fa-long-arrow-up"></i>
                    <span [innerHTML]="contentProducts[i].bottomText2"></span>
                </button>
            </div>
        </div>
    </div>
</div>