import { Component, OnInit } from '@angular/core';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss']
})
export class AlertBannerComponent implements OnInit {
  isUserLoggedIn: boolean = false;
  isAlertVisible: boolean = true;  // Track visibility state
  loginalertcontent: Observable<any>;

  constructor(
    public PagealertComponent: CmsComponentData<CmsParagraphComponent>,
    public usersrvice: UserInfoService
  ) {
    this.loginalertcontent = this.PagealertComponent.data$;
  }

  ngOnInit() {
    this.usersrvice.userLoggedIn$.subscribe(res => {
      this.isUserLoggedIn = res;
    });
  }

  closeAlert() {
    this.isAlertVisible = false;
  }
}
