<ng-container *ngIf="userLoggedIn && userInfo?.webEnabled" class="uk-Li-contact-us">
    <!--For the general support-->
    <section class="col-xs-12 col-md-12 contact_midsection" *ngIf="ukcontactnode | async as data">
        <div *ngIf="data.uid=='contactUsSlideComponent1'">
            <div class="col-xs-12 col-md-12 contact_Support">
                <div class="contact_supportheader1">{{data?.headLine1}}</div>
                <div class="contact_textcontainer_wrapper">
                    <div class="col-xs-12 col-md-6 contact_textcontainer" id="contact_customerservicecontainer1"
                        *ngFor="let item of mergedObject1; let i= index">
                        <div class="contact_supportheader2" [innerHTML]="item?.headLine"></div>
                        <div class="contact_supporttext" [innerHTML]="item?.content" [ngClass]="{'contact_supporttext_spacing': i === mergedObject1.length - 1}"></div>
                        <div class="contact_email">
                            <div class="Support_email" [innerHTML]="item?.t1content">
                            </div>
                            <div class="Support_call" [innerHTML]="item?.t2content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--For the report and product queries-->
    <section class="col-xs-12 col-md-12 contact_midsection" *ngIf="ukcontactnode | async as data">
        <div *ngIf="data.uid=='contactUsSlideComponent2'">
            <div class="col-xs-12 col-md-12 contact_Support">
                <div class="contact_supportheader1">{{data?.headLine1}}</div>
                <div class="contact_textcontainer_wrapper">
                    <div class="col-xs-12 col-md-6 contact_textcontainer" id="contact_customerservicecontainer1"
                        *ngFor="let item of mergedObject1; let i= index">
                        <div class="contact_supportheader2" [innerHTML]="item?.headLine"></div>
                        <div class="contact_supporttext">
                            <div [innerHTML]="item?.content"></div>
                            <div>
                                <a (click)="openModal()" class="popup-link">{{item.Link}}</a>
                            </div>
                            <div [innerHTML]="item?.content2text"></div>
                        </div>
                        <div class="contact_email">
                            <div class="Support_email" [innerHTML]="item?.t1content">
                            </div>
                            <div class="Support_call" [innerHTML]="item?.t2content"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="commonleaving_abandonedPop modal  fade" role="dialog" data-backdrop="static"
                data-keyboard="false" id="commonleaving_abandonedPop" tabindex="-1">
                <div class="modal-body knowledgeHub_abandonedPopContent">
                    <div class="knowledgeHub_abandonedPopHeader">
                        <div class="knowledgeHub_abandonedPopTitle">{{data.PopUpData?.headline}}</div>
                        <div class="knowledgeHub_abandonedPopClose" (click)="closeModal()">X</div>
                        <div class="clearboth"></div>
                    </div>
                    <div class="knowledgeHub_abandonedPopText">
                        <p [innerHTML]="data.PopUpData?.message1"></p>
                        <p [innerHTML]="data.PopUpData?.message2"></p>
                    </div>
                    <div class="knowledgeHub_abandonedPopBtns">
                        <button class="knowledgeHub_abandonedPopBtn commonleave_popContBtn">
                            <a href="{{data.PopUpData?.link}}">{{data.PopUpData?.label1}}</a>
                        </button>
                        <!-- <button class="knowledgeHub_abandonedPopBtn iframe_popContBtn">Continue</button> -->
                        <button class="knowledgeHub_abandonedPopBtn knowledgeHub_popCanceltBtn"
                            (click)="closeModal()">{{data.PopUpData?.label2}}</button>
                    </div>
                    <div class="knowledgedocnumberUk">{{data.PopUpData?.jobBagCode}}</div>
                </div>
            </div>
        </div>
    </section>
</ng-container>