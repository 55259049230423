<div class="modal-content">
  <div class="modal-header">
    <div class="closebt" data-dismiss="modal" aria-label="Close" (click)="onClose()">×</div>
  </div>

  <div class="modal-body">
    <div class="drop-area" *ngIf="imgUploadarea">
      <div class="drag-form">
        <div class="dragiconsection">
          <i class="fa fa-upload"></i>
          <p>or</p>
        </div>
        <p>Drag &amp; Drop an image to upload</p>
        <ngx-file-drop (onFileDrop)="onFileDrop($event)" [showBrowseBtn]="true" browseBtnLabel="Browse" [multiple]="true">
          <ng-template ngx-file-drop-content>
            
            
            <button type="button" class="button">Browse Files</button>
          </ng-template>
        </ngx-file-drop>
 
      </div>
    </div>
    <div class="carousel-area" *ngIf="imgGalleryarea">
      <div class="selected-image-container" *ngIf="selectedImage">
       
          <i class="fa-solid fa-chevron-left" (click)="previousImage()"></i>
       
        <div class="image-wrapper">
          <img [src]="selectedImage.src" class="selected-image" [class.slide-left]="slideLeft" [class.slide-right]="slideRight">
          
        </div>
        
        
          <i class="fa-solid fa-chevron-right" (click)="nextImage()"></i>
        
      </div>


    </div>
    <div class="imgname_remove" *ngIf="imgGalleryarea">
      <div class="imgname_removeflex">
        <div class="imgname">{{ selectedImage?.name }}</div>
        <div class="removebtn" (click)="removeSelectedImage()">Remove</div>
      </div>
    </div>
    <div class="gallery" >
      <div class="gallery_container">
        <div class="gallery_leftarea" >
          <div class="imagecontainer">
            <div gallerize *ngIf="thumbImgarea">
              <div *ngFor="let img of locationImages; let i = index" class="gallery-item">
                <img [src]="img.src" (click)="selectImage(i)" [class.selected]="i === selectedIndex">
              </div>
            </div> 
          </div>
        </div>
        <div class="gallery_rightarea" >
          <div class="submitcancelsection">
            <div class="uploadmorebtn" *ngIf="imgGalleryarea" (click)="uploadMore()">Upload More</div>
            <div class="donebtn" *ngIf="shwDonebtn" id="donebtn-60149134" data-dismiss="modal" aria-label="Close" (click)="onClose()">Done</div>
          </div>
        </div>
      </div>
    </div>
   
  </div>

</div>

