import { Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alfuria-second',
  templateUrl: './alfuria-second.component.html',
  styleUrl: './alfuria-second.component.scss'
})
export class AlfuriaSecondComponent {

  secondnode: Observable<any> = this.bannerComponent.data$;

  constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig) { }
  
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
}
