<ng-container *ngIf="userLoggedIn;else logoutsideData">
    <ng-container *ngIf="sideContent | async as sideData">
    <!-- <section class="col-xs-12 col-md-12 contact_midsection contact_midsectionUS"> -->
    <!-- <div class="col-xs-12 col-md-6 contact_Support contact_SupportUS"> -->
    <div class="col-12 contact_Support contact_SupportUS">
    <div class="contact_supportheader1">Reporting & Product Queries</div>
    <div class="contact_textcontainerParent">
    <div class="col-xs-12 contact_textcontainer" id="contact_textcontainer1">
        
            <div class="contact_supportheader2">{{sideData.headline}}</div>
            <div class="contact_supporttext">{{sideData.subcontent}}</div>
            <div class="contact_email" >
                <div [innerHTML]="sideData.adverseemail"></div>
                <div [innerHTML]="sideData.adversecall"></div>
            </div>
    </div>
    <div class="col-xs-12 contact_textcontainer" id="contact_textcontainer2">
        <div class="contact_supportheader2">{{sideData.headline2}}</div>
            <div class="contact_supporttext" [innerHTML]="sideData.subcontent2"></div>
            <div class="contact_email">
            <div [innerHTML]="sideData.medicalvisit"></div>
            <div [innerHTML]="sideData.medicalemail"></div>
            <div [innerHTML]="sideData.medicalcall"></div>
            </div>
    <div class="clearboth"></div>
    </div>
</div>
</div>
<!-- </section> -->
</ng-container>
</ng-container>
<ng-template #logoutsideData>
    <div *ngIf="sideContent | async as sideData" class="padding-L40 sub-container">
        <h4 class="Support_flu360header boxHeader ">Reporting &amp; Product Queries</h4>
                <div class="Support_adverseReporting col-xs-12">
            <h4 class="Support_adverseReportingheader boxHeader">{{sideData.headline}}</h4>
                <div class="Support_adverseReportingContent boxContent">{{sideData.subcontent}}</div>
                <!-- <div class="Support_email">Email: <a href="mailto:us.medicalinformation@seqirus.com">us.medicalinformation@seqirus.com</a></div>
                <div class="Support_call">Call: <a href="tel:8553588966">(855) 358-8966</a> - Option 1 after the toll free number</div> -->
                <div [innerHTML]="sideData.adverseemail"></div>
                <div [innerHTML]="sideData.adversecall"></div>
            </div>
        <div class="Support_medicalCommunication col-xs-12 padding-R10">
            <h4 class="Support_medicalCommunicationheader boxHeader">{{sideData.headline2}}</h4>
                <div class="Support_medicalCommunicationContent boxContent" [innerHTML]="sideData.subcontent2"></div>
                <!-- <div class="Support_email">Visit: <a href="https://medinfo.seqirus.com" target="_blank">https://medinfo.seqirus.com</a> for additional inquiries</div>
                <div class="Support_call">Email: <a href="mailto:us.medicalinformation@seqirus.com">us.medicalinformation@seqirus.com</a></div>
                <div class="Support_call">Call: <a href="tel:8553588966">(855) 358-8966</a> - Option 1 after the toll free number</div></div>
         -->
         <div [innerHTML]="sideData.medicalvisit"></div>
         <div [innerHTML]="sideData.medicalemail"></div>
         <div [innerHTML]="sideData.medicalcall"></div>
                <div class="clearboth"></div>
    </div>
    </div>
</ng-template>
