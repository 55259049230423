import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { ContactUsTopbannerComponent } from './contact-us-topbanner/contact-us-topbanner.component';
import { ContactUsFormComponent } from './contact-us-form/contact-us-form.component';
import { ContactUsSideComponent } from './contact-us-side/contact-us-side.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import {FormErrorsModule} from '@spartacus/storefront';



@NgModule({
  declarations: [
    ContactUsTopbannerComponent,
    ContactUsFormComponent,
    ContactUsSideComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgxMaskModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusContactusComponent : {
          component:  ContactUsTopbannerComponent,
        },
        SeqirusFlu360ContactusComponent : {
          component:  ContactUsFormComponent,
        },
        SeqirusReportingqueriesComponent:{
          component:  ContactUsSideComponent,
        }
      }

    } as CmsConfig)
  ]
})
export class ContactUsModule { }
