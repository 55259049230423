import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-first-terms-and-conditions',
  templateUrl: './first-terms-and-conditions.component.html',
  styleUrl: './first-terms-and-conditions.component.scss'
})
export class FirstTermsAndConditionsComponent {
  node1: Observable<any> = this.bannerComponent.data$;
  userLoggedIn: boolean = false;
  constructor(private cmsService:CmsService, 
    protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>, 
    private userInfoService:UserInfoService)
     {
}
// ngOninit(){
// this.node1.subscribe(res => 
//   console.log('component',res)
// ) 
// }
}