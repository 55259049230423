<div *ngIf="isLoading" class="loading">
    <div class="loading_txt">
    <div class="load_txt">Loading</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<section *ngIf="showCreditSection" class="col-xs-12 col-sm-12 credits_invoices forUKReturns credits_UK">
    <div class="clearboth"></div>
    <div class="credits_credit">
      <div class="col-xs-12 col-sm-12 credits_headerparent no-padding">
        <div class="col-xs-12 col-sm-12 no-padding credits_invoiceheader">Credits Information</div>
      </div>
      <div class="col-xs-12 col-sm-12 credits_invoicebtns">
        <div class="col-xs-12 col-sm-4 credits_creditbtn active" id="credits_creditbtn-1" (click)="opencredit(1)">
          <div class="credits_invoicebtnheader">Available Credits</div>
        </div>
        <div class="col-xs-12 col-sm-4 credits_creditbtn" id="credits_creditbtn-2" (click)="opencredit(2)">
          <div class="credits_invoicebtnheader">Credits Processing</div>
        </div>
        <!-- refund new tab added -->
        <div class="col-xs-12 col-sm-4 credits_creditbtn" id="credits_creditbtn-3"(click)="opencredit(3)">
          <div class="credits_invoicebtnheader">Credits Applied</div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 credits_credittable open" id="credits_credittable1">
        <!-- new refund code -->
        <div class="credits_creditheaderParent">
          <div class="credits_creditheader">
            <div class="credits_leftHeader">
              <div class="credits_creditheaderText">
                Total available credits:
                <span class="credits_creditavail">{{financialresponse?.seqirusFinancialDashboardData?.credit.totalAvailableCredit | currency:'GBP':'symbol':'1.2-2'}}</span>
                
              </div>
              <div class="credits_creditheaderTooltip" id="credits_creditheaderTooltip-1">
                {{ukCreditsContent.content}}
                <span [innerHTML]="ukCreditsContent.link"></span>
              </div>
            </div>
           
          <button *ngIf="financialresponse?.isRefundWindowOpen && financialresponse?.userEligibleForApplyRefund && isAsmUser;else showSupport" class="refundRequest" (click)="openRefund()">Request a Refund</button>
              
                <ng-template #showSupport>
                 <div class="helpParent">
                   <div class="helpHeader">Need Help?</div>
                   <div class="helpsubHeader">Contact&nbsp; <a routerLink="/contact-us">flu360 | Support</a></div>
                 </div>
                </ng-template>
              
          </div>
        </div>
        <div class="credits_creditabledetail1">
          
          <table id="credits_creditavailTable-1" [ngClass]="creditavailable.data.length==0?'emptyTable':''" class="credits_Tablecredit d-none d-md-block d-lg-block" mat-table [dataSource]="creditavailable">
                <ng-container matColumnDef="creditNote">
                    <th mat-header-cell *matHeaderCellDef class="creditWhiteSpace">
                        Credit Note #
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.invoiceNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="creditAmount">
                    <th mat-header-cell *matHeaderCellDef class="creditWhiteSpace">Credit Amount
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.amoutWithTax | currency:'GBP':'symbol':'1.2-2'}}  </td>
                </ng-container>
                
                <ng-container matColumnDef="issuedDate">
                    <th mat-header-cell *matHeaderCellDef>
                        Date Issued
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.invoiceDate}} </td>
                </ng-container>

                <ng-container matColumnDef="viewCredit">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <a class="anchorlink" (click)="financialgetDownloadInvoiceData(element.invoiceNumber)"><span class="anchorunderline">View Credit Note</span><div class="global_blackarrow"></div></a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="creditcolumns;"></tr>
                <tr mat-row *matRowDef="let row; columns: creditcolumns;"></tr>
            </table>
        <!-- </div> -->
        </div>
        <div class="credits_creditavailMobiletable-1"></div>

      </div>
      <div *ngIf="showfootnote && financialresponse?.isRefundWindowOpen && financialresponse?.userEligibleForApplyRefund && isAsmUser" class="col-xs-12 credits_footnote">{{ukCreditsContent.refundText}} <a [href]="getPdfUrl(ukCreditsContent.privacyLink)" target="_blank">privacy policy</a>.</div> 

      
      <div class="col-xs-12 col-sm-12 credits_credittable" id="credits_credittable2">
        
        <div class="credits_creditheaderParent">
          <div class="credits_creditheader">
            <div class="credits_leftHeader">
              <div class="credits_creditheaderText">Total processing credits: <span class="credits_creditprocessed">{{financialresponse?.seqirusFinancialDashboardData?.credit.totatProcessingCredit | currency:'GBP':'symbol':'1.2-2'}}</span>
                <!-- add count of processed -->
              </div>
              <div class="credits_creditheaderTooltip" id="credits_creditheaderTooltip-2">
                {{ukCreditsContent.content}}
                <span [innerHTML]="ukCreditsContent.link"></span>
              </div>
            </div>
              <div class="helpParent">
                  <div class="helpHeader">Need Help?</div>
                  <div class="helpsubHeader">Contact&nbsp; <a routerLink="/contact-us">flu360 | Support</a></div>
              </div>
          </div>
        </div>
        <div class="credits_creditabledetail1">
          <table id="credits_creditprocessedTable-1" [ngClass]="creditpending.data.length==0?'emptyTable':''" class="credits_Tablecredit d-none d-md-block d-lg-block" mat-table [dataSource]="creditpending">
                <ng-container matColumnDef="creditNote">
                    <th mat-header-cell *matHeaderCellDef class="creditWhiteSpace">
                        Credit Note #
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.invoiceNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="creditAmount">
                    <th mat-header-cell *matHeaderCellDef class="creditWhiteSpace">Credit Amount
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.amoutWithTax | currency:'GBP':'symbol':'1.2-2'}} </td>
                </ng-container>
                
                <ng-container matColumnDef="issuedDate">
                    <th mat-header-cell *matHeaderCellDef>
                        Date Issued
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.invoiceDate}} </td>
                </ng-container>

                <ng-container matColumnDef="viewCredit">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <a class="anchorlink" (click)="financialgetDownloadInvoiceData(element.invoiceNumber)"><span class="anchorunderline">View Credit Note</span><div class="global_blackarrow"></div></a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="creditcolumns;"></tr>
                <tr mat-row *matRowDef="let row; columns: creditcolumns;"></tr>
        </table>
          
        </div>
        <div class="credits_creditprocessedMobiletable-1"></div>
      </div>
      
      <div class="col-xs-12 col-sm-12 credits_credittable" id="credits_credittable3">
        <div class="credits_creditheader">
          <div class="credits_creditheaderText">
            Total credits applied:
            <span class="credits_creditused">{{financialresponse?.seqirusFinancialDashboardData?.credit.totalUsedCredit | currency:'GBP':'symbol':'1.2-2'}}</span>
          </div>
          <div class="credits_creditheaderTooltip" id="credits_creditheaderTooltip-3">
            {{ukCreditsContent.content}}
            <span [innerHTML]="ukCreditsContent.link"></span>
          </div>
        </div>
        <div class="credits_creditabledetail1">
          
          <table id="credits_crediappliedTable-1" [ngClass]="creditpending.data.length==0?'emptyTable':''" class="credits_Tablecredit d-none d-md-block d-lg-block" mat-table [dataSource]="creditapplied">
            <ng-container matColumnDef="creditNote">
                <th mat-header-cell *matHeaderCellDef class="creditWhiteSpace">
                    Credit Note #
                </th>
                <td mat-cell *matCellDef="let element"> {{element.invoiceNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="creditAmount">
                <th mat-header-cell *matHeaderCellDef class="creditWhiteSpace">Credit Amount
                </th>
                <td mat-cell *matCellDef="let element"> {{element.amoutWithTax | currency:'GBP':'symbol':'1.2-2'}} </td>
            </ng-container>
            
            <ng-container matColumnDef="issuedDate">
                <th mat-header-cell *matHeaderCellDef>
                    Date Issued
                </th>
                <td mat-cell *matCellDef="let element"> {{element.invoiceDate}} </td>
            </ng-container>

            <ng-container matColumnDef="viewCredit">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <a class="anchorlink" (click)="financialgetDownloadInvoiceData(element.invoiceNumber)"><span class="anchorunderline">View Credit Note</span><div class="global_blackarrow"></div></a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="creditcolumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: creditcolumns;"></tr>
        </table>
        </div>
        <div class="credits_creditappliedMobiletable-1"></div>
      </div>
    </div>
    <div class="clearboth"></div>
       
</section>

<!-- refund section -->
<section *ngIf="showRefundSection" class="col-xs-12 col-sm-12 credits_refund credits_UK">
    <div class="col-xs-12 col-sm-12 no-padding credits_refundheader">New Credit Refund</div>
     <!-- new bank details page starts -->
    <div *ngIf="!financialresponse?.bankDetails?.payerbankAccountNumber || showEditForm" class="col-xs-12 refund_newBankData">
      <div class="refund_dataWrapper col-xs-11">
        <div class="refund_text1">Bank Details</div>
        <div class="refund_text2">Please enter your bank details as shown on your bank statement.</div>
        <form id="refundBankDetails" [formGroup]="refundCreditsForm">

        <!-- <c:choose>
        <c:when test="${bankDetails.payerbankAccountNumber eq 'null' || empty bankDetails.payerbankAccountNumber}">
           <input type="hidden" value="new" name="requestType" class="refundRequestType">
        </c:when>
        <c:otherwise>
        <input type="hidden" value="verified" name="requestType" class="refundRequestType">
        </c:otherwise>
        
        </c:choose> -->
          <div class="col-xs-12 border_box no-left-pad">
            <div class="col-xs-4 inputParent" [ngClass]="{'has-error':refundCreditsForm.get('bankAccName')?.touched && refundCreditsForm.get('bankAccName')?.invalid}">
              <label class="input_parentlabel">Account Name*</label>
              <label class="input_sublabel">(Full name or Business name)</label>
              <input type="text" name="bankAccName" class="bankAccName form-control" formControlName="bankAccName" autocomplete="off"/>
              <span *ngIf="refundCreditsForm.get('bankAccName')?.touched && refundCreditsForm.get('bankAccName')?.invalid" class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span>
              <label *ngIf="refundCreditsForm.get('bankAccName')?.touched && refundCreditsForm.get('bankAccName')?.invalid" id="bankAccName-error" class="error help-block" for="bankAccName">Please enter account name</label>
            </div>
            <div class="col-xs-4 inputParent" [ngClass]="{'has-error':refundCreditsForm.get('bankAccNo')?.touched && refundCreditsForm.get('bankAccNo')?.invalid}">
              <label>Bank Account Number*</label>
              <label class="input_sublabel"></label>
              <input type="text" name="bankAccNo" id="accountNumber" class="bankAccNo form-control starfunction" maxlength="8" formControlName="bankAccNo" autocomplete="off"/>
              <span *ngIf="refundCreditsForm.get('bankAccNo')?.touched && refundCreditsForm.get('bankAccNo')?.invalid" class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
              <label *ngIf="refundCreditsForm.get('bankAccNo')?.touched && refundCreditsForm.get('bankAccNo')?.invalid" id="accountNumber-error" class="error help-block" for="accountNumber">Please enter 8 digits</label>
            </div>
            <div class="col-xs-2 inputParent" [ngClass]="{'has-error':refundCreditsForm.get('sortCode')?.touched && refundCreditsForm.get('sortCode')?.invalid}">
              <label>Sort Code*</label>
              <label class="input_sublabel"></label>
              <input type="text" name="sortCode" id="sortCode" class="bankSortNo form-control starfunction" maxlength="6" formControlName="sortCode" autocomplete="off"/>
              <span *ngIf="refundCreditsForm.get('sortCode')?.touched && refundCreditsForm.get('sortCode')?.invalid" class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
              <label *ngIf="refundCreditsForm.get('sortCode')?.touched && refundCreditsForm.get('sortCode')?.invalid" id="sortCode-error" class="error help-block" for="sortCode">Please enter 6 digits</label>
            </div>
            <div class="col-xs-2 disableinputParent">
              <label>Country</label>
              <label class="input_sublabel"></label>
              <input type="text" name="country" class="form-control bankcountry" formControlName="country" readonly="true" value="GB"/>
            </div>
          </div>

        </form>
        <div class="clearboth"></div>
        <div class="refund_btnConatiner col-xs-12">
          <button class="refundCancel_btn" (click)="cancelEditForm()">Cancel</button>
          <button class="refundSave_btn" (click)="updateBankDetail()">Save Bank Details</button>
        </div>
      </div>
    </div>
     <!-- new bank details page ends -->
    <!-- confirm details page starts -->
    <div *ngIf="financialresponse?.bankDetails?.payerbankAccountNumber && showConfirmBank" class="col-xs-12 refund_confirmBankData">
      <div class="refund_editInnerDiv">
        <div class="refund_editHeader">
          <div class="refund_text1">Bank Details</div>
          <span class="refundEdit_btn" (click)="editBankDetails()">Edit<div></div>
          </span>
        </div>
        <div class="refund_text2">Please confirm current bank details</div>
        <div class="refund_dataWrapper col-xs-11">
            <div class="col-xs-12 border_box no-left-pad">
              <div class="inputParent col-xs-4">
                <div class="refund_BankInfoHeader">Account Name</div>
                <div class="refund_subBankinfo">(Full name or Business name)</div>
                <!-- <div class="refund_BankInfoData staticbankname">{{financialresponse?.bankDetails?.bankName}}</div> -->
                <div class="refund_BankInfoData staticbankname">{{refundCreditsForm.get('bankAccName')?.value}}</div>
              </div>
              <div class="inputParent col-xs-4">
                <div class="refund_BankInfoHeader">Bank Account Number</div>
                <div class="refund_subBankinfo"></div>
                <!-- <div class="masked4 refund_BankInfoData staticbankacc" mask="****0000">{{financialresponse?.bankDetails?.payerbankAccountNumber}}</div> -->
                <div class="masked4 refund_BankInfoData staticbankacc">{{applyAccNoMask(refundCreditsForm.get('bankAccNo')?.value)}}</div>
              </div>
              <div class="inputParent col-xs-2">
                <div class="refund_BankInfoHeader">Sort Code</div>
                <div class="refund_subBankinfo"></div>
                <!-- <div class="masked3 refund_BankInfoData staticbanksort" mask="****0000">{{financialresponse?.bankDetails?.payersortCode}}</div> -->
                <div class="masked3 refund_BankInfoData staticbanksort">{{applySortCodeMask(refundCreditsForm.get('sortCode')?.value)}}</div>
              </div>
              <div class="inputParent col-xs-2">
                <div class="refund_BankInfoHeader">Country</div>
                <div class="refund_subBankinfo"></div>
                <div class="refund_BankInfoData staticbankcountry">{{financialresponse?.bankDetails.bankCountry}}</div>
              </div>
            </div>
            <div class="confirm_checkboxparent col-xs-12 no-left-pad">
              <label class="checkbox-button">
                <input type="checkbox" name="checkbox" class="confirm_checkbox" [(ngModel)]="isChecked">
                <span class="checkbox-button__control"></span>
              </label>
              <label class="checkboxtext">I confirm these bank account details are correct.</label>
            </div>
          <div class="clearboth"></div>
        </div>
      </div>
      <div class="col-xs-12 refund_tableBottomBtn">
        <div class="refund_totalAmountParent no-left-pad">Refund Amount &nbsp;&nbsp;&nbsp; <span class="refund_totalAmount">{{financialresponse?.seqirusFinancialDashboardData?.credit.totalAvailableCredit | currency:'GBP':'symbol':'1.2-2'}}</span>
        </div>
        <div class="refund_rightBtnParent no-left-pad">
          <a class="refund_exitBtn" (click)="closeRefund()">Exit</a>
          <button class="submitRefundBtn" [disabled]="!isChecked" (click)="submitRefund()">Submit Refund Request</button>
        </div>
      </div>
    </div>
     <!-- confirm details page ends -->

    <div class="col-xs-12 credits_footnote2">{{ukCreditsContent.refundText}} <a [href]="getPdfUrl(ukCreditsContent.privacyLink)" target="_blank">privacy policy</a>.</div> 
    
</section>

<!-- Refund thank you starts -->
<section *ngIf="showThankyouSection" class="col-xs-12 col-sm-12 credits_UK credits_thankyou">
    <div class="refund_thankyouContainer">
      <div class="refund_thankyouHeader">Thank You for Submitting Your Refund Request</div>
      <div class="refund_thankyouPara">Your bank account details will need to be verified, and someone will contact you if there are any questions. You can expect a refund within 28 days.</div>
      <button *ngIf="showThankyouSection" class="viewCredit" (click)="showProcessingCredits()">View Credits</button>
    </div>
  </section>
  <!-- Refund thank you ends -->
