<ng-container *ngIf="!userLoggedIn || (userLoggedIn && (!userInfo?.webEnabled))" class="Lo-tiles">
    <div class="Support_lowerboxsUK container-lg container-fluid" *ngIf="uklocontactnode | async as a data">
        <div class=" padding-L40 sub-containerUK">
            <div class="Support_flu360 col-lg-4 col-md-4 col-sm-12 col-xs-12 no-padding padding-R20">
                <h4 class="Support_flu360header boxHeader">{{mergedObject1[0]?.headLine}}</h4>
                <div class="Support_flu360Content boxContent">{{mergedObject1[0]?.content}}</div>
                <div class="Support_email" [innerHTML]="mergedObject1[0]?.t1content"></div>
                <div class="Support_call" [innerHTML]="mergedObject1[0]?.t2content"></div>
            </div>
            <div class="Support_adverseReporting col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <h4 class="Support_adverseReportingheader boxHeader">{{mergedObject1[1]?.headLine}}</h4>
                <div class="Support_adverseReportingContent boxContent">{{mergedObject1[1]?.content}}</div>
                <div class="Support_email" [innerHTML]="mergedObject1[1]?.t1content"></div>
                <div class="Support_call" [innerHTML]="mergedObject1[1]?.t2content"></div>
            </div>
            <div class="Support_medicalCommunication col-lg-4 col-md-4 col-sm-12 col-xs-12 padding-R10">
                <h4 class="Support_medicalCommunicationheader boxHeader">{{mergedObject1[2]?.headLine}}</h4>
                <div class="Support_medicalCommunicationContent boxContent">{{mergedObject1[2]?.content}}</div>
                <div class="Support_email"  [innerHTML]="mergedObject1[2]?.t1content"></div>
                <div class="Support_call"  [innerHTML]="mergedObject1[2]?.t2content"></div>
            </div>
            <div class="clearboth"></div>
        </div>
    </div>
</ng-container>