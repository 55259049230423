import { ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, Renderer2, ViewChild  } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CmsComponentData } from '@spartacus/storefront';
import { ContentService } from '../../spartacus/services/content.service';
import { FormBuilder, FormArray } from '@angular/forms';
import { BillingPayload, Bussinesspayload, LicenceValidationPayload, Payingpayload, ShippingPayload, ShippingItem } from '../register.model';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { CmsService, OccConfig, OccEndpointsService } from '@spartacus/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-registration-component',
  templateUrl: './registration-component.component.html',
  styleUrl: './registration-component.component.scss'
})
export class RegistrationComponentComponent {
  response:string="";
  billingAddress: FormGroup;
  payerAddress: FormGroup;
  isFormSubmitted_bussiness:boolean=false;
  isFormSubmitted_billing:boolean=false;
  isFormSubmitted_paying:boolean=false;
  isFormSubmitted_shipping:boolean=false;
  cc: any;
  form: any;
  address:any;
  callFetchifyMethod:any;
  isActive_bussiness: boolean = true;
  shippingForm!: FormGroup;
  licenseErrorPopup:boolean=false;
  orgAddress:FormGroup;
  showShippingUpload:boolean = false;
  userData: any;
  isVisibleBussiness:boolean = false;
  isVisibleBilling:boolean = false;
  isVisiblePaying:boolean = false;
  isVisibleShipping:boolean = false;
  isVisibleReview:boolean = false;
  isDivVisible = false;
  isDivVisible1 = false;
  isSelected: boolean = false;
  isSelected_invoice: boolean = false;
  isActive_buss: boolean = true;
  isActive_buss_bill: boolean = true;
  isActive_bill_paying: boolean = true;
  isActive_bill_shipping: boolean = false;
  formValid: boolean = false;
  isActiveaddLocation:boolean = true;
  showShippingFormOnly:boolean = true;
  showAfterShippingUpload:boolean = false;
  state: string = '';
  formDataBussiness:  Bussinesspayload | null = null;
  formDataBilling:  BillingPayload | null = null;
  formDataPaying:  Payingpayload | null = null;
  showloader: boolean = false;
  formDataShipping:  ShippingPayload | null = null;
  isshippingformVisible:boolean  = true;
  onlyshowAddLocation:boolean = false;
  onlySaveShippingBtn:boolean = false;
  onlySubmitShippingBtn:boolean = true;
  submissionCount: number = 0;
  formCount: number = 0;
  file: File | null = null;
  fileName: string | null = null;
  errorMessage: string | null = null;
  uid: string | null = null;
  isShipformVisible:boolean = false;
  isshippingVisible:boolean = false;
  showpatch:boolean = false;
  newuserData:boolean = true;
  templateUrl:any;
  pageIndex:any;
  haveGlnnumber:boolean = true;

  isVisible = true;

  @ViewChild('fileInput') fileInput!: ElementRef;
  pageLabel:Observable<string> | undefined;
  shippingGroup: any;
  
  constructor(public component: CmsComponentData<any>,private cmsService:CmsService,private fb: FormBuilder,public contentService: ContentService,private cdr:ChangeDetectorRef,private renderer: Renderer2,private ngZone: NgZone,private router: Router,private http: HttpClient,protected config: OccConfig) {
    this.pageLabel = this.cmsService.getCurrentPage().pipe(map((pageDta) => pageDta.label || ""));
    this.orgAddress =  new FormGroup({
      addressType: new FormControl("Business"),
      orgName: new FormControl("",[Validators.required,Validators.pattern("^[a-zA-Z0-9]*$")]),
      duns: new FormControl("",[Validators.pattern('^[0-9]+$'),Validators.maxLength(10),Validators.minLength(10)]),
      membership: new FormControl(""),
      globalLocationNumber:  new FormControl(""),
      line1: new FormControl("",[Validators.required]),
      line2: new FormControl(""),
      city: new FormControl("",[Validators.required]),
      state: new FormControl("",[Validators.required]),
      postalCode: new FormControl("",[Validators.required,Validators.pattern("^\\d{5}(-\\d{4})?$")]),
    })

    const emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
    this.billingAddress =  new FormGroup({
      addressType: new FormControl("Billing"),
      addressId: new FormControl(""),
      confAcctStmtEmail: new FormControl(""),
      confInvoiceEmail: new FormControl(""),
      country: new FormControl(""),
      firstName: new FormControl("",[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]),
      lastName: new FormControl("",[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]),
      email: new FormControl("",[Validators.required,Validators.pattern(emailPattern)]),
      phone: new FormControl("",[Validators.required]),
      phoneExt: new FormControl(""),
      orgName: new FormControl("",[Validators.required,Validators.pattern("^[a-zA-Z0-9]*$")]),
      line1: new FormControl("",[Validators.required]),
      line2: new FormControl(""),
      city: new FormControl("",[Validators.required]),
      state: new FormControl("",[Validators.required]),
      postalCode: new FormControl("",[Validators.required,Validators.pattern("^\\d{5}(-\\d{4})?$")]),
      invoiceEmail:  new FormControl("",[Validators.required,Validators.pattern(emailPattern)]),
      acctStmtEmail: new FormControl("",[Validators.required,Validators.pattern(emailPattern)]),
      optNotifyAcctStmtEmail: new FormControl(false),
      optNotifyInvoiceEmail: new FormControl(false),
      isSameAsShippingAddress: new FormControl(false),
      optNotifyAcctStmtMail: new FormControl(true),
      optNotifyInvoiceMail: new FormControl(true),

    })

    this.payerAddress =  new FormGroup({
      addressType: new FormControl("Paying"),
      firstName: new FormControl("",[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]),
      lastName: new FormControl("",[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]),
      email: new FormControl("",[Validators.required,Validators.pattern(emailPattern)]),
      phone: new FormControl("",[Validators.required]),
      phoneExt: new FormControl(""),
      orgName: new FormControl("",[Validators.required,Validators.pattern("^[a-zA-Z0-9]*$")]),
      line1: new FormControl("",[Validators.required]),
      line2: new FormControl(""),
      city: new FormControl("",[Validators.required]),
      state: new FormControl("",[Validators.required]),
      postalCode: new FormControl("",[Validators.required,Validators.pattern("^\\d{5}(-\\d{4})?$")]),
    })
    
    this.shippingForm = new FormGroup({
      shippingList: new FormArray([
        this.createShippingGroup()
      ])
    });
    
    
  }

  

  regData: Observable<any> = this.component.data$;

  isRegisterPage():Observable<boolean> | undefined{
    return this.pageLabel?.pipe(map((label) => label === 'register'));
  }
  
  createShippingGroup(): FormGroup {
    const emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
    return this.fb.group({
        addressType: ['Shipping'],
        firstName: ['', [Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]],
        lastName: ['', [Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]],
        email: ['', [Validators.required,Validators.pattern(emailPattern)]],
        phone: ['', Validators.required],
        phoneExt: [''],
        orgName: new FormControl("",[Validators.required,Validators.pattern("^[a-zA-Z0-9]*$")]),
        globalLocationNumber: [''],
        addressId: [''],
        line1: ['', Validators.required],
        line2: [''],
        city: ['', Validators.required],
        state: ['', Validators.required],
        postalCode: ['', [Validators.required,Validators.pattern("^\\d{5}(-\\d{4})?$")]],
        licenseDetailsForm: this.fb.group({
          licenseName: ['',Validators.required],
          licenseNum: ['',Validators.required],
          licAddressLine1: ['',Validators.required],
          licAddressLine2: [''],
          licensePostalCode: ['',[Validators.required,Validators.pattern("^\\d{5}(-\\d{4})?$")]],
          licenseCity: ['',Validators.required],
          licenseState: ['',Validators.required],
          isMedProLicenseVerified: ['']
        }),
        checkboxControl: [false],
        submitted: [false],
        isEditing: [false]
        
    });
  }
  
  

  
  stateList = [
    { value: 'AL', label: 'AL' },
    { value: 'AK', label: 'AK' },
    { value: 'AR', label: 'AR' },
    { value: 'AZ', label: 'AZ' },
    { value: 'CA', label: 'CA' },
    { value: 'CO', label: 'CO' },
    { value: 'CT', label: 'CT' },
    { value: 'DE', label: 'DE' },
    { value: 'DC', label: 'DC' },
    { value: 'FL', label: 'FL' },
    { value: 'GA', label: 'GA' },
    { value: 'GU', label: 'GU' },
    { value: 'HI', label: 'HI' },
    { value: 'IA', label: 'IA' },
    { value: 'ID', label: 'ID' },
    { value: 'IL', label: 'IL' },
    { value: 'IN', label: 'IN' },
    { value: 'KS', label: 'KS' },
    { value: 'KY', label: 'KY' },
    { value: 'LA', label: 'LA' },
    { value: 'ME', label: 'ME' },
    { value: 'MD', label: 'MD' },
    { value: 'MA', label: 'MA' },
    { value: 'MI', label: 'MI' },
    { value: 'MN', label: 'MN' },
    { value: 'MS', label: 'MS' },
    { value: 'MO', label: 'MO' },
    { value: 'MT', label: 'MT' },
    { value: 'NC', label: 'NC' },
    { value: 'ND', label: 'ND' },
    { value: 'NE', label: 'NE' },
    { value: 'NV', label: 'NV' },
    { value: 'NH', label: 'NH' },
    { value: 'NJ', label: 'NJ' },
    { value: 'NM', label: 'NM' },
    { value: 'NY', label: 'NY' },
    { value: 'OH', label: 'OH' },
    { value: 'OK', label: 'OK' },
    { value: 'OR', label: 'OR' },
    { value: 'PA', label: 'PA' },
    { value: 'PR', label: 'PR' },
    { value: 'RI', label: 'RI' },
    { value: 'SC', label: 'SC' },
    { value: 'SD', label: 'SD' },
    { value: 'TN', label: 'TN' },
    { value: 'TX', label: 'TX' },
    { value: 'UT', label: 'UT' },
    { value: 'VA', label: 'VA' },
    { value: 'VI', label: 'VI' },
    { value: 'VT', label: 'VT' },
    { value: 'WA', label: 'WA' },
    { value: 'WI', label: 'WI' },
    { value: 'WV', label: 'WV' },
    { value: 'WY', label: 'WY' },
    
  ];
 
 
  showBussinessSection(){
    this.scrollTotop();
    this.isVisibleBussiness = true;
    this.isVisibleBilling = false;
    this.isVisiblePaying = false;
    this.isVisibleShipping = false;
    this.isVisibleReview = false;
  }

  showBillingSection(){
    this.scrollTotop();
    this.isVisibleBussiness = false;
    this.isVisibleBilling = true;
    this.isVisiblePaying = false;
    this.isVisibleShipping = false;
    this.isVisibleReview = false;
  }

  showPayingSection(){
    this.scrollTotop();
    this.isVisibleBussiness = false;
    this.isVisibleBilling = false;
    this.isVisiblePaying = true;
    this.isVisibleShipping = false;
    this.isVisibleReview = false;
  }

  showShippingSection(){
    this.scrollTotop();
    this.isVisibleBussiness = false;
    this.isVisibleBilling = false;
    this.isVisiblePaying = false;
    this.isVisibleShipping = true;
    this.isVisibleReview = false;
  }

  showReviewSection(){
    this.scrollTotop();
    this.isVisibleBussiness = false;
    this.isVisibleBilling = false;
    this.isVisiblePaying = false;
    this.isVisibleShipping = false;
    this.isVisibleReview = true;
  }

  createAccount(){
    this.router.navigate(['/register-thank-you']);
  }

  scrollTotop(): void {
    // Call this method to scroll to the top
    this.contentService.scrollToTop();
  }

  patchShippingData(data: any[]): void {
    // Clear the existing shippingList
    this.shippingList.clear();
  
    // Patch the data
    data.forEach(item => {
      const shippingGroup = this.createShippingGroup();
      shippingGroup.patchValue({
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        phone: item.phone,
        orgName: item.shippingOrganizationName,
       // globalLocationNumber: item.globalLocationNumber,
        line1: item.shippingAddressLine1,
        city: item.town,
        state: item.shippingState,
        postalCode: item.postalCode,
        licenseDetailsForm: {
          licenseName: item.licDetils.licenseName,
          licenseNum: item.licDetils.licenseStateNumber,
          licAddressLine1: item.licDetils.licenceAddressLine1,
          licensePostalCode: item.licDetils.postalCode,
          licenseCity: item.licDetils.city,
          licenseState: item.licDetils.territory
        }
      });
      this.shippingList.push(shippingGroup);
    });
  
    // Call onSubmit_shipping for each form after all forms have been patched
    this.shippingList.controls.forEach((_, index) => {
      this.onSubmit_shipping_patchvalue(index);
      
    });
    this.isVisible = !this.isVisible;
    this.isShow = !this.isShow;
    this.onlySaveShippingBtn =!this.onlySaveShippingBtn;
  }


  getNewuserDetails(){
  this.showloader = true;
    this.contentService.getnewUserData().subscribe(
      response => {  
      this.templateUrl = this.config.backend?.occ?.baseUrl + response.shippingLocationMediaURL; 
      this.newuserData = response.regSubmitted;
      this.pageIndex = response.pageIndex;
     
      if(this.newuserData){
       this.router.navigate(['/my-account']);
       this.showloader = false;
       this.cdr.detectChanges();
      }

      
      
      if(!this.newuserData){  
        if(this.pageIndex == 'business'){
          this.showBussinessSection();
          this.showloader = false;
          this.cdr.detectChanges();
        } 
      
        this.orgAddress.patchValue({
        orgName: response.orgAddress.organizationName, 
        duns: response.orgAddress.duns, 
        membership: response.orgAddress.membership, 
        globalLocationNumber: response.orgAddress.globalLocationNumber, 
        line1: response.orgAddress.line1, 
        line2: response.orgAddress.line2, 
        city: response.orgAddress.town, 
        state: response.orgAddress.state, 
        postalCode: response.orgAddress.zipCode, 
        });
        this.orgAddress.controls['globalLocationNumber']?.setErrors(null);
       
        
      

        this.billingAddress.patchValue({
        firstName : response.billingAddress.firstName,   
        lastName : response.billingAddress.lastName, 
        email : response.billingAddress.emailId, 
        phone : response.billingAddress.phone, 
        phoneExt : response.billingAddress.phoneExt,
        orgName : response.billingAddress.organizationName,
        line1 : response.billingAddress.line1,
        line2 : response.billingAddress.line2,
        city : response.billingAddress.town,
        state: response.billingAddress.state, 
        postalCode: response.billingAddress.postalCode, 
        invoiceEmail: response.billingAddress.invoiceEmailId, 
        acctStmtEmail: response.billingAddress.acctStmtEmailId, 
        optNotifyAcctStmtEmail: response.billingAddress.optNotifyAcctStmtEmail,
        optNotifyInvoiceEmail: response.billingAddress.optNotifyInvoiceEmail,
        isSameAsShippingAddress: response.billingAddress.isSameAsShippingAddress,
        })  
        
        
       
        this.payerAddress.patchValue({
          firstName : response.payerAddress.firstName,   
          lastName : response.payerAddress.lastName, 
          email : response.billingAddress.emailId, 
          phone : response.billingAddress.phone, 
          phoneExt : response.billingAddress.phoneExt,
          orgName : response.billingAddress.organizationName,
          line1 : response.billingAddress.line1,
          line2 : response.billingAddress.line2,
          city : response.billingAddress.town,
          state: response.billingAddress.state, 
          postalCode: response.billingAddress.postalCode,
        }) 
        
        
        
        if (response.billingAddress.optNotifyAcctStmtEmail) {
          this.toggleDiv(true);
          this.isSelected = true;
         
        }
        if (response.billingAddress.optNotifyInvoiceEmail) {
          this.toggleDiv1(true);
          this.isSelected_invoice = true;
         
        }

        

       this.patchShippingData(response.shippingDetailsList);
     
        setTimeout(()=>{ 
          this.showloader = true;
          
  
            if(this.pageIndex == 'review'){
            this.onSubmit_bussiness_auto();
            this.onSubmit_billing_auto();
            this.onSubmit_paying_auto();
            this.save_next_shipping_auto();
            this.showReviewSection();
            this.cdr.detectChanges();
            }  
            if(this.pageIndex == 'shipping'){
              this.onSubmit_bussiness_auto();
              this.onSubmit_billing_auto();
              this.onSubmit_paying_auto();
              this.showShippingSection();
              this.cdr.detectChanges();
            } 
            if(this.pageIndex == 'paying'){
              this.onSubmit_bussiness_auto();
              this.onSubmit_billing_auto();
              this.showPayingSection();
              this.cdr.detectChanges();
            } 
            if(this.pageIndex == 'billing'){
              this.onSubmit_bussiness_auto();
              this.showBillingSection();
              this.cdr.detectChanges();
            } 
            this.showloader = false;
          },1500);
        
     
          
            this.showloader = false;
          this.cdr.detectChanges();
      }
    });

  }
 
  
  onCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      // Copy values from form1 to form2
      this.payerAddress.patchValue(this.billingAddress.value);
    }
  }

  onCheckboxCopytoShipping(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      // Get the values from the payerAddress form
      const payerValues = this.payerAddress.value;
  
      // Get the first shipping group from the shippingList FormArray
      const shippingGroup = this.shippingForm.get('shippingList') as FormArray;
      const firstShippingGroup = shippingGroup.at(0) as FormGroup;
  
      // Patch the values to the first shipping group
      firstShippingGroup.patchValue({
        firstName: payerValues.firstName,
        lastName: payerValues.lastName,
        email: payerValues.email,
        phone: payerValues.phone,
        phoneExt: payerValues.phoneExt,
        orgName: payerValues.orgName,
        line1: payerValues.line1,
        line2: payerValues.line2,
        city: payerValues.city,
        state: payerValues.state,
        postalCode: payerValues.postalCode
      });
  
      // Mark the form as dirty to indicate changes
      firstShippingGroup.markAsDirty();
      this.cdr.detectChanges();
    }
  }
  
 
 

  toggleDiv(isVisible: boolean) {
    const emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
    this.isDivVisible = isVisible;
    this.isSelected = !this.isSelected;
    if(!isVisible){
    this.billingAddress.controls['acctStmtEmail']?.setValue("");
      this.billingAddress.controls['optNotifyAcctStmtEmail']?.setValue(false);
      this.billingAddress.get('acctStmtEmail')?.markAsDirty();
      this.billingAddress.get('acctStmtEmail')?.clearValidators();
      this.billingAddress.get('acctStmtEmail')?.updateValueAndValidity();
    }
    if(isVisible){
      
      this.billingAddress.get('acctStmtEmail')?.setValidators([Validators.required, Validators.pattern(emailPattern)]);
      this.billingAddress.get('acctStmtEmail')?.updateValueAndValidity();
       this.billingAddress.get('acctStmtEmail')?.markAsDirty();
       
      this.cdr.detectChanges();
    }
  }

  toggleDiv1(isVisible1: boolean) {
    const emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
    this.isDivVisible1 = isVisible1;
    this.isSelected_invoice = !this.isSelected_invoice;
    if(!isVisible1){
     this.billingAddress.controls['invoiceEmail']?.setValue("");
     this.billingAddress.controls['optNotifyInvoiceEmail']?.setValue(false);
      this.billingAddress.get('invoiceEmail')?.markAsDirty();
      this.billingAddress.get('invoiceEmail')?.clearValidators();
      this.billingAddress.get('invoiceEmail')?.updateValueAndValidity();
    }
    if(isVisible1){
      
      this.billingAddress.get('invoiceEmail')?.setValidators([Validators.required, Validators.pattern(emailPattern)]);
      this.billingAddress.get('invoiceEmail')?.updateValueAndValidity();
       this.billingAddress.get('invoiceEmail')?.markAsDirty();
       this.cdr.detectChanges();
    }
  }

  
 
  onSubmit_bussiness(){ 
    const isFormValid = this.orgAddress.valid;
    console.log(isFormValid);
    console.log(this.orgAddress.value);
    this.isFormSubmitted_bussiness =  true;
    if(isFormValid){
      this.showloader = true;
      this.isActive_buss = !this.isActive_buss;
      this.isActive_buss_bill = !this.isActive_buss_bill;
      this.formDataBussiness = this.orgAddress.value;
      if (this.formDataBussiness) {
        this.contentService.saveBussiness(this.formDataBussiness).subscribe(
          response => {
            console.log('Bussiness Details Saved Sucessfully:', response);
                this.showBillingSection();
                  this.scrollTotop();
                  this.showloader = false;
                  this.cdr.detectChanges();
          },
          error => {
            console.error('Error to save Bussiness Details:', error);
          }
        );
      }
     

             
    }
  }

  onSubmit_bussiness_auto(){ 
    const isFormValid = this.orgAddress.valid;
    console.log(isFormValid);
    console.log(this.orgAddress.value);
    this.isFormSubmitted_bussiness =  true;
    if(isFormValid){
      this.showloader = true;
      this.isActive_buss = !this.isActive_buss;
      this.isActive_buss_bill = !this.isActive_buss_bill;
      this.formDataBussiness = this.orgAddress.value;
      if (this.formDataBussiness) {
        this.contentService.saveBussiness(this.formDataBussiness).subscribe(
          response => {
            console.log('Bussiness Details Saved Sucessfully:', response);
                //  this.showBillingSection();
                  this.scrollTotop();
                  this.showloader = false;
                  this.cdr.detectChanges();
          },
          error => {
            console.error('Error to save Bussiness Details:', error);
          }
        );
      }
     

             
    }
  }

  onbackBtn_billing(){
    this.isActive_buss = !this.isActive_buss;
    this.isActive_buss_bill = !this.isActive_buss_bill;
  }

 
  
  onSubmit_billing(){
    const isFormValid = this.billingAddress.valid;
    console.log(isFormValid);
    console.log(this.billingAddress.value);
    this.isFormSubmitted_billing =  true;
    if(isFormValid){
      this.showloader = true;
     this.formDataBilling = this.billingAddress.value;

     if (this.formDataBilling) {
      this.contentService.SaveBilling(this.formDataBilling).subscribe(
        response => {
          console.log('Billing Details Saved Sucessfully:', response);
                this.showPayingSection();
                this.scrollTotop();
                this.showloader = false;
                this.cdr.detectChanges();
        },
        error => {
          console.error('Error to save Billing Details:', error);
        }
      );
    }
      
    
    }
  }

  onSubmit_billing_auto(){
    const isFormValid = this.billingAddress.valid;
    console.log(isFormValid);
    console.log(this.billingAddress.value);
    this.isFormSubmitted_billing =  true;
    if(isFormValid){
      this.showloader = true;
     this.formDataBilling = this.billingAddress.value;

     if (this.formDataBilling) {
      this.contentService.SaveBilling(this.formDataBilling).subscribe(
        response => {
          console.log('Billing Details Saved Sucessfully:', response);
              //  this.showPayingSection();
                this.scrollTotop();
                this.showloader = false;
                this.cdr.detectChanges();
        },
        error => {
          console.error('Error to save Billing Details:', error);
        }
      );
    }
      
    
    }
  }
  
  onbackBtn_paying(){
    this.isActive_bill_paying = !this.isActive_bill_paying;
    this.isActive_buss_bill = !this.isActive_buss_bill;
  }

 


  onSubmit_paying(){
    const isFormValid = this.payerAddress.valid;
    console.log(isFormValid);
    console.log(this.payerAddress.value);
    this.isFormSubmitted_paying =  true;
    if(isFormValid){
      this.showloader = true;
     this.isActive_bill_paying = !this.isActive_bill_paying;
     this.isActive_bill_shipping = !this.isActive_bill_shipping;
     this.contentService.getUserData().subscribe(
      data => {
        if (data && data.uid) {
          this.userData = data;
          this.uid = data.uid;
        } else {
          this.errorMessage = 'UID not found in the response';
        }
      },
      error => {
        console.error('Error fetching user data', error);
        this.errorMessage = 'Error fetching user data';
      }
    );
     
     this.formDataPaying = this.payerAddress.value;
     if (this.formDataPaying) {
      this.contentService.savePaying(this.formDataPaying).subscribe(
        response => {
          console.log('Paying Details Saved Sucessfully:', response);
                this.showloader = false;
                this.showShippingSection();
                this.scrollTotop();
                this.cdr.detectChanges();
        },
        error => {
          console.error('Error to save Paying Details:', error);
        }
      );
    }

    }
  }

  onSubmit_paying_auto(){
    const isFormValid = this.payerAddress.valid;
    console.log(isFormValid);
    console.log(this.payerAddress.value);
    this.isFormSubmitted_paying =  true;
    if(isFormValid){
      this.showloader = true;
     this.isActive_bill_paying = !this.isActive_bill_paying;
     this.isActive_bill_shipping = !this.isActive_bill_shipping;
     this.contentService.getUserData().subscribe(
      data => {
        if (data && data.uid) {
          this.userData = data;
          this.uid = data.uid;
        } else {
          this.errorMessage = 'UID not found in the response';
        }
      },
      error => {
        console.error('Error fetching user data', error);
        this.errorMessage = 'Error fetching user data';
      }
    );
     
     this.formDataPaying = this.payerAddress.value;
     if (this.formDataPaying) {
      this.contentService.savePaying(this.formDataPaying).subscribe(
        response => {
          console.log('Paying Details Saved Sucessfully:', response);
                this.showloader = false;
               // this.showShippingSection();
                this.scrollTotop();
                this.cdr.detectChanges();
        },
        error => {
          console.error('Error to save Paying Details:', error);
        }
      );
    }

    }
  }
 
  

  closeModal(): void {
    this.licenseErrorPopup = false;
  }
 

  onSubmit_shipping(index: number):void{
    const shippingForm = this.shippingList.at(index) as FormGroup;
    const isFormValid = this.shippingForm.valid;
    console.log(isFormValid);
    console.log(this.shippingForm.value);
    this.isFormSubmitted_shipping =  true;
    if(isFormValid){
      shippingForm.get('submitted')?.setValue(true);
      shippingForm.get('isEditing')?.setValue(false);
      this.modifyForm(index, shippingForm.value);
      this.shippingList.controls.forEach((_, index) => {
        this.checkLicense(index);
        this.cdr.detectChanges();
      });
    } 
      
    
  }

  onSubmit_shipping_patchvalue(index: number):void{
    const shippingForm = this.shippingList.at(index) as FormGroup;
    const isFormValid = this.shippingForm.valid;
    console.log(isFormValid);
    console.log(this.shippingForm.value);
    this.isFormSubmitted_shipping =  true;
    if(isFormValid){
      shippingForm.get('submitted')?.setValue(true);
      shippingForm.get('isEditing')?.setValue(false);
      this.modifyForm(index, shippingForm.value);
      
    } 
      
    
  }

 

  onSave_shipping():void{
    this.showloader = true;
    // Prepare the payload
    this.formDataShipping = this.shippingForm.value;
    
    // Call the service to submit the payload
    if(this.formDataShipping){
    this.contentService.saveShipping(this.formDataShipping).subscribe(
      response => {
        this.showReviewSection();
        console.log('Shipping data submitted successfully:', response);
        this.submissionCount = this.shippingList.length;
        this.licenseErrorPopup = false;
        this.isshippinglistVisible = !this.isshippinglistVisible;
        this.isVisible = !this.isVisible;
        this.formValid = false;
        this.onlySaveShippingBtn =!this.onlySaveShippingBtn;

        this.scrollTotop();
        this.showloader = false;
       
        this.cdr.detectChanges();
      },
      error => {
        console.error('Error submitting shipping data:', error);
      }
    );
  }
  }

  

  save_next_shipping():void{
    this.showloader = true;
    // Prepare the payload
    this.formDataShipping = this.shippingForm.value;
    
    // Call the service to submit the payload
    if(this.formDataShipping){
    this.contentService.saveShipping(this.formDataShipping).subscribe(
      response => {
        
        this.showReviewSection();
        console.log('Shipping data submitted successfully:', response);
        this.submissionCount = this.shippingList.length;
        
        this.scrollTotop();
        this.showloader = false;
        this.cdr.detectChanges();
      },
      error => {
        console.error('Error submitting shipping data:', error);
      }
    );
  }
  }

  save_next_shipping_auto():void{
    this.showloader = true;
    // Prepare the payload
    this.formDataShipping = this.shippingForm.value;
    
    // Call the service to submit the payload
    if(this.formDataShipping){
    this.contentService.saveShipping(this.formDataShipping).subscribe(
      response => {
        
       // this.showReviewSection();
        console.log('Shipping data submitted successfully:', response);
        this.submissionCount = this.shippingList.length;
        
        this.scrollTotop();
        this.showloader = false;
        this.cdr.detectChanges();
      },
      error => {
        console.error('Error submitting shipping data:', error);
      }
    );
  }
  }



  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const fileType = file.name.split('.').pop();
      if (fileType !== 'xls' && fileType !== 'xlsx') {
        
        this.errorMessage = 'Please upload file with .xls/xlsx extension';
        this.file = null;
        this.fileName = null;
        
      } else {
        this.contentService.getUserData().subscribe(
          data => {
            
            if (data && data.uid) {
              this.userData = data;
              this.uid = data.uid;
            } else {
              this.errorMessage = 'UID not found in the response';
            }
          },
          error => {
            console.error('Error fetching user data', error);
            this.errorMessage = 'Error fetching user data';
          }
        );
        this.file = file;
        this.fileName = file.name;
        this.errorMessage = null;
        
      }
    }
    
  }
 
  


  uploadFile(): void {
    
    if (this.file && this.uid) {
      this.showloader = true;
      this.contentService.uploadExcelFile(this.file, this.uid)
        .subscribe(response => {
          console.log('Upload successful', response);
          this.file = this.file;
          this.isActive_bill_shipping = true;
          this.submissionCount = this.shippingList.length;
          this.showloader = false;
          this.showReviewSection();
          this.cdr.detectChanges();
        }, error => {
          console.error('Upload error', error);
          this.errorMessage = 'Upload failed. Please try again.';
        });
    } else {
      this.errorMessage = 'No file selected or invalid file type.';
    }
  }

  removeUploadFile(): void{
    this.fileName = null ;
    this.file = null
   }
 
  checkLicense(index: number): void {
    this.showloader = true;
    const shippingGroup = this.shippingList.at(index) as FormGroup;
    const licenseDetails: LicenceValidationPayload = {
      licenceName: shippingGroup.get('licenseDetailsForm.licenseName')?.value,
      licenceNumber: shippingGroup.get('licenseDetailsForm.licenseNum')?.value,
      licenceState: shippingGroup.get('licenseDetailsForm.licenseState')?.value,
     
    };

    this.contentService.validateLicence(licenseDetails).subscribe(
      response => {
        console.log(`Licence validation successful for index ${index}:${response}` );
          
          this.scrollTotop();
          this.showloader = false;
          this.licenseErrorPopup = true;
          this.cdr.detectChanges();
      },
      error => {
       // console.error(`Error validating licence for index ${index}:`, error);
        console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
 
          
          this.scrollTotop();
          this.showloader = false;
          this.licenseErrorPopup = true;
          this.cdr.detectChanges();
      }
    );
  }

  
 
 
  ngOnInit(){
   // this.loadFormData();
   this.getNewuserDetails();
    this.component.data$.subscribe((data) =>{
      this.response = data;
    })
  
    this.showBussinessSection();
    this.callFetchifyMethod= this.contentService.fetchifyCall();
    this.isActive_buss_bill = !this.isActive_buss_bill;
    this.isActive_bill_paying = !this.isActive_bill_paying;
    this.isActive_bill_shipping = !this.isActive_bill_shipping;
    this.billingAddress.get('acctStmtEmail')?.clearValidators();
    this.billingAddress.get('acctStmtEmail')?.updateValueAndValidity();
    this.billingAddress.get('invoiceEmail')?.clearValidators();
    this.billingAddress.get('invoiceEmail')?.updateValueAndValidity();
    this.shippingForm = this.fb.group({
      shippingList: this.fb.array([this.createShippingGroup()])
    });
    const index = this.shippingList.length;
    console.log(index);
    

    this.shippingList.statusChanges.subscribe(status => {
      this.formValid = status === 'VALID'
    });

   
  }

  ngAfterViewInit() {
    setTimeout(()=>{

      this.shippingList.controls.forEach((group, index) => {
        const newFieldId = 'shipping_lookup' + index;
        this.attachFetchifyMethod(index,newFieldId);
        
      });
      },500);

      
     
  }



  get shippingList(): FormArray {
    return this.shippingForm.get('shippingList') as FormArray;
  }
  


  addShipping(): void {
    this.isVisible = !this.isVisible;
    this.isShow = !this.isShow;
    this.onlySaveShippingBtn =!this.onlySaveShippingBtn;
    const index = this.shippingList.length;
    this.shippingList.push(this.createShippingGroup());
    this.isFormSubmitted_shipping = !this.isFormSubmitted_shipping
    const newFieldId = 'shipping_lookup' + index; 
    this.showpatch= !this.showpatch;
    console.log(newFieldId);
    console.log(this.createShippingGroup());
    setTimeout(()=>{
      this.attachFetchifyMethod(index,newFieldId);
      this.cdr.detectChanges();
      },2000);
    this.cdr.detectChanges();
    
  }

  attachFetchifyMethod(index: number,selector: string): void {
    
   


        //Address lookup for Shipping Details
        

        this.callFetchifyMethod.attach({
          search:     selector,
          town:       'shipping_city',
          postcode:   'shipping_postalCode',
          line_1:     'shipping_line1',
          line_2:     'shipping_line2',
          county:     'shipping_state'
        },
        
        {
          onResultSelected: (c2a: any, elements: any, address: any) => {
            console.log('Address:', address);
            console.log('Index:', index);
            this.updateShippingForm(address, index);
           
          }
        }); 

        

        //Address lookup for License Shipping Details

        this.callFetchifyMethod.attach({
          search:     'shipping-lookup2',
          town:       'licenseCity',
          postcode:   'licensePostalCode',
          line_1:     'licAddressLine1',
          line_2:     'licAddressLine2',
          county:     'licenseState'
        },
        
        {
          onResultSelected: (c2a: any, elements: any, address: any) => {
            const shippingGroup = this.shippingList.at(index) as FormGroup;
    const licenseDetailsForm = shippingGroup.get('licenseDetailsForm') as FormGroup;

    licenseDetailsForm.patchValue({
      licAddressLine1: address.line_1,
      licAddressLine2: address.line_2,
      licenseCity: address.locality,
      licenseState: address.province,
      licensePostalCode: address.postal_code.slice(0, 5)
    });

    licenseDetailsForm.markAsDirty();
    this.cdr.detectChanges();

    
  }

  
          
        }); 
         //Address lookup for Bussiness Details
      this.callFetchifyMethod.attach({
        search:     'businesslookup',
        town:       'city',
        postcode:  'postalCode',
        line_1:     'line1',
        line_2:     'line2',
        county:    'state'
      },
      
      {
        onResultSelected: (c2a: any, elements: any, address: any) => {
          this.orgAddress.controls['line1']?.setValue(address.line_1);
          this.orgAddress.get('line1')?.markAsDirty();
          this.orgAddress.controls['line2']?.setValue(address.line_2);
          this.orgAddress.get('line2')?.markAsDirty();
          this.orgAddress.controls['city']?.setValue(address.locality);
          this.orgAddress.get('city')?.markAsDirty();
          this.orgAddress.controls['state']?.setValue(address.province);
          this.orgAddress.get('state')?.markAsDirty();
          this.orgAddress.controls['postalCode']?.setValue(address.postal_code);
          this.orgAddress.get('postalCode')?.markAsDirty();
          this.orgAddress.updateValueAndValidity();
          this.cdr.detectChanges();
        }
      }); 

      //Address lookup for Billing Details
      this.callFetchifyMethod.attach({
        search:     'billinglookup',
        town:       'city_billing',
        postcode:  'zipCode_billing',
        line_1:     'addressLine1_billing',
        line_2:     'addressLine2_billing',
        county:    'state_billing'
      },
      
      {
        onResultSelected: (c2a: any, elements: any, address: any) => {
          this.billingAddress.controls['line1']?.setValue(address.line_1);
          this.billingAddress.get('line1')?.markAsDirty();
          this.billingAddress.controls['line2']?.setValue(address.line_2);
          this.billingAddress.get('line2')?.markAsDirty();
          this.billingAddress.controls['city']?.setValue(address.locality);
          this.billingAddress.get('city')?.markAsDirty();
          this.billingAddress.controls['state']?.setValue(address.province);
          this.billingAddress.get('state')?.markAsDirty();
          this.billingAddress.controls['postalCode']?.setValue(address.postal_code);
          this.billingAddress.get('postalCode')?.markAsDirty();
          this.billingAddress.updateValueAndValidity();
          this.cdr.detectChanges();
        }
      }); 

      //Address lookup for Paying Details

      this.callFetchifyMethod.attach({
        search:     'paylookup',
        town:       'city_paying',
        postcode:   'zipCode_paying',
        line_1:     'addressLine1_paying',
        line_2:     'addressLine2_paying',
        county:     'state_paying'
      },
      
      {
        onResultSelected: (c2a: any, elements: any, address: any) => {
          this.payerAddress.controls['line1']?.setValue(address.line_1);
          this.payerAddress.get('line1')?.markAsDirty();
          this.payerAddress.controls['line2']?.setValue(address.line_2);
          this.payerAddress.get('line2')?.markAsDirty();
          this.payerAddress.controls['city']?.setValue(address.locality);
          this.payerAddress.get('city')?.markAsDirty();
          this.payerAddress.controls['state']?.setValue(address.province);
          this.payerAddress.get('state')?.markAsDirty();
          this.payerAddress.controls['postalCode']?.setValue(address.postal_code);
          this.payerAddress.get('postalCode')?.markAsDirty();
          this.payerAddress.updateValueAndValidity();
          this.cdr.detectChanges();
        }
      }); 
  }

 
 showAddlocation: boolean = false;
  removeShipping(index: number): void {
    this.shippingList.removeAt(index);
    console.log(index);
    if (this.shippingList.length < 1) {
      this.addShipping();
      
    }
    if (this.shippingList.length == 1) {
      this.showAddlocation = true;

    }
    else{
      this.isVisible = !this.isVisible;
    }
    
  }

  modifyForm(index: number, formDataShipping: any) {
    const shippingForm = this.shippingList.at(index) as FormGroup;
    shippingForm.patchValue(formDataShipping);
    shippingForm.get('submitted')?.setValue(true);
    shippingForm.get('isEditing')?.setValue(false);
    this.scrollTotop();
    this.showEditbtn = false;
  }

 
  updateShippingForm(address: any, index: number): void {
    const shippingGroup = this.shippingList.at(index) as FormGroup;

    shippingGroup.patchValue({
      line1: address.line_1,
      line2: address.line_2,
      city: address.locality,
      state: address.province,
      postalCode: address.postal_code
    });

    shippingGroup.markAsDirty();
    this.cdr.detectChanges();
  }

  updateShippingLicenseForm(address: any, index: number): void {
    const shippingGroup = this.shippingList.at(index) as FormGroup;

    shippingGroup.patchValue({
      licAddressLine1: address.line_1,
      licAddressLine2: address.line_2,
      licenseCity: address.locality,
      licenseState: address.province,
      licensePostalCode: address.postal_code
    });

    shippingGroup.markAsDirty();
    this.cdr.detectChanges();
  }
  
  isShow = false;
  copyValues(index: number): void {
    const shippingGroup = this.shippingList.at(index) as FormGroup;
    const licenseDetailsForm = shippingGroup.get('licenseDetailsForm') as FormGroup;
    const currentValue = this.shippingList.get('checkboxControl')?.value;
    this.shippingList.get('checkboxControl')?.setValue(!currentValue);
   
    this.isShow = !this.isShow;
    console.log(this.isShow);
    
    if(this.isShow){
      licenseDetailsForm.patchValue({
        licAddressLine1: shippingGroup.get('line1')?.value,
        licAddressLine2: shippingGroup.get('line2')?.value,
        licensePostalCode: shippingGroup.get('postalCode')?.value.slice(0, 5),
        licenseCity: shippingGroup.get('city')?.value,
        licenseState: shippingGroup.get('state')?.value,
 
      });
    }
    else{
      licenseDetailsForm.patchValue({
        licAddressLine1: '',
        licAddressLine2: '',
        licensePostalCode: '',
        licenseCity: '',
        licenseState: '',
 
      });
    }  
    
  }

  isshippinglistVisible = true;

  open_shipping_popup(){
    this.showShippingUpload = !this.showShippingUpload;
  }
  close_shipping_popup(){
    this.showShippingUpload = !this.showShippingUpload;
    this.showAfterShippingUpload = !this.showAfterShippingUpload;
    this.showShippingFormOnly = !this.showShippingFormOnly;
  }
  showEditbtn:boolean = false;
  toggleShipping(index: number): void {
    const shippingForm = this.shippingList.at(index) as FormGroup;
    const isEditing = shippingForm.get('isEditing')?.value;
    shippingForm.get('isEditing')?.setValue(!isEditing);
    this.showEditbtn = !isEditing;
    this.shippingList.get('checkboxControl')?.setValue(true);
    const newFieldId = 'shipping_lookup' + index; 
    setTimeout(()=>{
      this.attachFetchifyMethod(index,newFieldId);
      this.cdr.detectChanges();
      },2000);
    this.cdr.detectChanges();
  }
  returnToShipping():void{
    this.showAfterShippingUpload = !this.showAfterShippingUpload;
    this.showShippingFormOnly = !this.showShippingFormOnly;
  } 
    

  



}
