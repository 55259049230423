import { ChangeDetectorRef, Component, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BaseSiteService } from '@spartacus/core';
import { CmsComponentData, titleScores } from '@spartacus/storefront';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';


@Component({
  selector: 'app-org-location-uk',
  templateUrl: './org-location-uk.component.html',
  styleUrl: './org-location-uk.component.scss'
})
export class OrgLocationUkComponent {
  orgLocationData?: any;
  filteredTableData: any;
  dataSource = new MatTableDataSource<any>();

  pageSize:number=5;
  pageSizeControl=new FormControl(5);
  pageSizeOptions:number[]=[5,10,15];
  isTableHasData:boolean = false;
  
  totalLength = 0;
  currentPage: number = 1;
  

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  
  @ViewChild(MatSort)
  sort!: MatSort;

  tableDataLoaded: boolean=false;

  displayedColumns: string[] = ['name','address','type','email','phoneNumber','viewDetails']; // Define your columns here
  selectedElement: any | null =null;
  isRowClicked: boolean=false;
  editElement: any;
  orgLocationsUpdateForm: FormGroup;
  @Input() cc: any;
  editedFields: string[]=[];
  cc_Obj: any;
  isFilterOpen: boolean=false;
  isAsc: boolean=true;
  activeCol: string="";
  currentSort:string='desc';
  isLoading: boolean=false;
  


  constructor(private userService: UserInfoService,private cdr:ChangeDetectorRef,private fb:FormBuilder,public orgLocComponent: CmsComponentData<any>,private dialog:MatDialog,private router:Router,private sanitizer:DomSanitizer,private basesiteService:BaseSiteService) {
    const emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
  
    this.orgLocationsUpdateForm = this.fb.group({
      loc_type:[""],
      loc_businesstype:[''],
      loc_addressId:[""],
      loc_editfname:["",[Validators.required,this.nameValidator.bind(this)]],
      loc_editlname:["",[Validators.required,this.nameValidator.bind(this)]],
      loc_editemail:["",[Validators.required,Validators.pattern(emailPattern)]],
      loc_job:[''],
      
      loc_editphone:["",[Validators.required,Validators.pattern('^[0-9]*$'),Validators.minLength(10),Validators.maxLength(11)]],
      loc_editext:["",[Validators.pattern('^[0-9]*$'),Validators.maxLength(4)]],
      
      loc_editorgname:["",[]],
      
      loc_editaddlookup:[''],
      loc_editadd1:['',[Validators.required]],
      loc_editadd2:[''],
      loc_editcity:['',[Validators.required]],
      
      loc_editzip:['',[Validators.required,Validators.pattern(/^(([A-Za-z0-9]){2}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){3}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){4}([ ]){1}([A-Za-z0-9]){3})$/i)]],
      
      loc_editlicname:['',[Validators.required,this.nameValidator.bind(this)]],
      loc_editlicnum:['',[Validators.required,this.ukLicNumValidator.bind(this)]],
      loc_editnhsnumb:['',[this.ukNhsNumValidator()]]
    });
  }

  nameValidator(control:any){
    return /^(?!\s)(?!.*\s$)[A-Za-z][A-Za-z\-\,\s]*$/g.test(control.value)?true:{invalidName:true};
  }

  ukNhsNumValidator(){
    
    const org = this.orgLocationsUpdateForm?.get('loc_businesstype')?.value;
    return (control:AbstractControl):{ [key:string]:any} | null => {
      if (org =="gp medical practice" ){
        return /^[A-Za-z]{1}[0-9]{5}$/.test(control.value)?null:{nhserror1:true};
      }
      if (org =="pharmacy" || org =="PHARMACY"){
        return /^[F|f]([A-Za-z]|[0-9]){4}$/.test(control.value)?null:{nhserror2:true};
      }
      if (org =="hospitals - public"||org =="hospitals - private"||org =="nursing homes"||org =="correctional facility"||org =="wholesaler"){
        return /^.{6,6}$/.test(control.value)?null:{nhserror3:true};
        }
      else {
        return null;
      }
    }
    
  }
  ukLicNumValidator(control:any){
    const licNum = control.value;
    const org = this.orgLocationsUpdateForm?.get('loc_businesstype')?.value;
    
    if (org =="gp medical practice" ||org =="nursing homes" ||org =="wholesaler"){
      return /^\d{7}$/.test(licNum)?true:{invalidLicNum:true};
    }
    if (org =="pharmacy" || org =="PHARMACY" ||org =="hospitals - public" ||org =="hospitals - private"||org =="correctional facility"){
      if(licNum.length < 4){
        return {invalidLicNum:true};
      }
      else{
        return true;
      }
    }
    if (org =="pharmacy" || org =="PHARMACY" ||org =="hospitals - public" ||org =="hospitals - private"||org =="correctional facility"){
      if(licNum.slice(0,1) == 2){
        return /^[2]{1}\d{6}$/.test(licNum)?true:{invalidLicNum:true};
      }
      else{
        return true;
      }
    }
    if (org =="pharmacy" || org =="PHARMACY" ||org =="hospitals - public" || org =="hospitals - private"|| org =="correctional facility"){
      return /^.{4,7}$/.test(licNum)?true:{invalidLicNum:true};
    }
    else{
      return true;
    }

			
  }

  ngOnInit(){
   
      this.userService.getOrgLocData().subscribe((data) => {
        this.filteredTableData = data.tableData.map((item: any) => ({
          type: item.type == 'Shipping'?'Delivery':item.type,
          status: item.status,
          addressId: item.addressId,
          name: item.name,
          jobTitle:item.jobTitle,
          businessType:item.businessType,
          mainContact: (item.firstName) + " " + (item.lastName ? item.lastName : " "),
          address: item.address,
          phoneNumber: (item.phoneNumber) + " Ext." + (item.phoneExt ? item.phoneExt : " "),
          phn: item.phoneNumber,
          ext: item.phoneExt ? item.phoneExt : "",
          addrLine1: item.line1,
          addrLine2: item.line2,
          city: item.city,
          state: item.state,
          zipCode: item.zip,
          email: item.email,
          firstName: item.firstName, lastName: item.lastName,
          licenseName: item.licenseName,
          licenseNumber: item.licenseNumber,
          nhsNumber:item.nhsCode? item.nhsCode : "NA"
          
        }));
    
        this.totalLength = this.filteredTableData?.length;
    
        this.dataSource = new MatTableDataSource(this.filteredTableData);
    
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    
        this.updateTableData();
        this.tableDataLoaded = true;
        this.isTableHasData=true;
        this.cdr.detectChanges();
      });
      this.userService.filterData$.subscribe((data) => {
        console.log(data);
        this.dataSource.filter = data || ''.trim().toLowerCase();
    if(this.dataSource.filteredData.length > 0){
    this.isTableHasData = true;
    }
    else{
      this.isTableHasData = false;
    }
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage()
    }
      });

      this.orgLocationsUpdateForm.get('loc_editnhsnumb')?.valueChanges.subscribe(() => {
        if (this.orgLocationsUpdateForm.get('loc_editnhsnumb')?.value === "") {
          this.orgLocationsUpdateForm.get('loc_editnhsnumb')?.setValidators([]);
        }
        else {
          this.orgLocationsUpdateForm.get('loc_editnhsnumb')?.setValidators([this.ukNhsNumValidator()]);
        }
        this.orgLocationsUpdateForm.get('loc_editnhsnumb')?.updateValueAndValidity({ emitEvent: false });
      });
  }
  
  //Custom Pagination Changes

  onPageSizeChange(event:any){
    this.pageSize = parseInt(event.target.value,10);
    this.currentPage = 1;
    this.updateTableData();
  }
  goToLastPage(){
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    this.currentPage=totalPages;
    this.updateTableData();
  }

  goToFirstPage(){
    this.currentPage=1;
    this.updateTableData();
  }
  
  getPages(): any {
      const totalPages = Math.ceil(this.totalLength / this.pageSize);
      const pages: any = [];
      
      if(totalPages <= 5){
        for(let i=1;i<=totalPages;i++){
          pages.push(i);
        }
      return pages;
      }
      if(this.currentPage <= 5){
        for(let i=1;i<=5;i++){
          pages.push(i);
        }
        pages.push('...',totalPages);
      }
      else if(this.currentPage > totalPages-5){
        pages.push(1,'...');
        for(let i=totalPages-4;i<=totalPages;i++){
          pages.push(i);
        }
      }
      else{
        pages.push(1,'...');
        pages.push(this.currentPage -1,this.currentPage,this.currentPage+1);
        pages.push('...',totalPages);
      }
  
      return pages;
    }
    goToPage(page:number){
      this.currentPage = page;
      const starti = (page-1)*this.pageSize;
      this.dataSource.data = this.filteredTableData.slice(starti,starti+this.pageSize);
    }
  previousPage(){
    if(this.currentPage>1){
      this.goToPage(this.currentPage-1);
    }
  }
  nextPage(){
    const totlpages=Math.ceil(this.totalLength/this.pageSize);
    if(this.currentPage<totlpages){
      this.goToPage(this.currentPage+1);
    }
  }
  isFirstPage():boolean{
    return this.currentPage==1;
  }
  isLastPage():boolean{
    return this.currentPage==Math.ceil(this.totalLength/this.pageSize);
  }
  isCurrentPage(page:number):boolean{
    return this.currentPage==page;
  }
//pagination changes end

  updateTableData(){
    const starti= (this.currentPage - 1)*this.pageSize;
    const endi = starti+this.pageSize;
    this.dataSource.data = this.filteredTableData.slice(starti,endi);
  }

   onSortChange(sortState:Sort):void{
    
    if(this.activeCol == sortState.active){
      this.currentSort = this.currentSort === 'asc'?'desc':'asc';
    }
    else if(sortState.direction == ''){
      this.currentSort='asc';
    }
    
    else{
      this.activeCol = sortState.active;
      this.currentSort = 'desc';
    }
    this.filteredTableData = this.filteredTableData.sort((a:any,b:any) => {
      const isAsc = this.currentSort === 'asc';
      return (a[this.activeCol] < b[this.activeCol] ? -1 : 1)*(isAsc ? 1:-1);
    });
    //this.updateTableData();
  } 
 
  isRowExpanded(row:any):boolean{
    return this.selectedElement === row;
  }

  viewDetails(row:any){
    this.selectedElement = this.selectedElement === row?null:row;
    this.isRowClicked=!this.isRowClicked;
    this.cdr.detectChanges();
  }

  close(){
    this.selectedElement = null;
    this.editElement = null;
    this.isRowClicked=false;
  }

  onEdit(row:any){
    
    if(this.editElement == row){
      this.editElement = null;
    }
    else{
      this.editElement = row;
    }
    this.orgLocationsUpdateForm.setValue({
      loc_type:row.type,
      loc_businesstype:row.businessType,
      loc_addressId:row.addressId,
      loc_editfname:row.firstName,
      loc_editlname:row.lastName,
      loc_editemail:row.email,
      loc_editphone:row.phn,
      loc_editext:row.ext || "",
      loc_job:row.jobTitle || '',

      loc_editorgname:row.name,
      loc_editaddlookup:"",
      loc_editadd1:row.addrLine1,
      loc_editadd2:row.addrLine2 || '',
      loc_editcity:row.city,
     loc_editzip:row.zipCode,
      
      loc_editlicname:row.licenseName || '',
      loc_editlicnum:row.licenseNumber || '',
      loc_editnhsnumb:row.nhsNumber || 'NA'
      
    });
    this.orgLocationsUpdateForm.markAsUntouched();
    this.orgLocationsUpdateForm.enable();
    this.cdr.detectChanges();
    console.log(this.cc_Obj);
    this.cc_Obj?.attach({
      search:     'loc_editaddlookup',
      town:       'loc_editcity',
      postcode:  'loc_editzip',
      line_1:     'loc_editadd1',
      line_2:     'loc_editadd2'
    },
    {
      onResultSelected: (c2a: any, elements: any, address: any) => { 
        console.log(address);
       
       this.orgLocationsUpdateForm.controls['loc_editadd1']?.setValue(address.line_1);
       this.orgLocationsUpdateForm.get('loc_editadd1')?.markAsDirty();
       this.orgLocationsUpdateForm.get('loc_editadd1')?.markAsTouched();
       
       this.orgLocationsUpdateForm.controls['loc_editadd2']?.setValue(address.line_2);
       this.orgLocationsUpdateForm.get('loc_editadd2')?.markAsDirty();
       this.orgLocationsUpdateForm.get('loc_editadd2')?.value?this.orgLocationsUpdateForm.get('loc_editadd2')?.markAsTouched():'';

       this.orgLocationsUpdateForm.controls['loc_editcity']?.setValue(address.locality);
       this.orgLocationsUpdateForm.get('loc_editcity')?.markAsDirty();
       this.orgLocationsUpdateForm.get('loc_editcity')?.markAsTouched();

       
       this.orgLocationsUpdateForm.controls['loc_editzip']?.setValue(address.postal_code);
       this.orgLocationsUpdateForm.get('loc_editzip')?.markAsDirty();
       this.orgLocationsUpdateForm.get('loc_editzip')?.markAsTouched();

       this.cdr.detectChanges();
      }
    
    });  
    
    
  }
  updateLocation(){
    this.orgLocationsUpdateForm.markAllAsTouched();
    if(this.orgLocationsUpdateForm.get('loc_type')?.value === 'Invoicing' || this.orgLocationsUpdateForm.get('loc_type')?.value === 'Paying'){
      this.orgLocationsUpdateForm.get('loc_editlicname')?.disable();
      this.orgLocationsUpdateForm.get('loc_editlicnum')?.disable();
      this.orgLocationsUpdateForm.get('loc_editnhsnumb')?.disable();
    }
    
    if(this.orgLocationsUpdateForm.valid){
      this.isLoading=true;
      Object.keys(this.orgLocationsUpdateForm.controls).forEach((fieldName => {
        if(this.orgLocationsUpdateForm.get(fieldName)?.dirty){
          this.editedFields.push(fieldName.substring(fieldName.indexOf('_')+1));
        }
      }))
      
      const updateLocData = this.orgLocationsUpdateForm.value;
     if(this.orgLocationsUpdateForm.get('loc_type')?.value == 'Delivery'){
        updateLocData.loc_type = "Shipping";
     }
      updateLocData.modifiedQualifiers=this.editedFields.join(',') || ""; 
      
      this.callUpdateLocApi(updateLocData);
    }
  }
  private callUpdateLocApi(updateLocData: any) {
    this.userService.updateLocationData(updateLocData).subscribe(
      (data) => {
        console.log('success', data);

        this.dialog.closeAll();
        this.isLoading=false;
        this.cdr.detectChanges();
        const url = (this.router.url).split('?')[0].split('#')[0];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([url.substring(url.lastIndexOf('/')+1)]).then(()=>{
        this.cdr.detectChanges();

        });
      },
      (error) => {
        this.isLoading=false;
        console.log('error');
        //this.isLoading = false;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const chng = changes[propName];
      
      
      if(chng.currentValue != null){
        this.cc_Obj = chng.currentValue;
        console.log(this.cc_Obj)
        
      }
    }
  }

  filterClick(){
    this.isFilterOpen =!this.isFilterOpen;
  }

  
  sortTest(val:any){
    
    const sortState: Sort = {active: val, direction: 'asc'};    
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);

  }


}
