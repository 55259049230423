<!-- <div class="hero--prod hero--prodUK" role="img" aria-label="FLUAD QUADRIVALENT campaign flu virus cell" style="background-image: url(/medias/aQIV-Flu360-Support-Banner-1170x690.png?context=bWFzdGVyfGltYWdlc3w4MTIxNTl8aW1hZ2UvcG5nfGFHRTNMMmcxWmk4NE9UTTFOekU0Tnpnd09UVTRMMkZSU1ZZZ0xTQkdiSFV6TmpBZ1UzVndjRzl5ZEMxQ1lXNXVaWEl0TVRFM01IZzJPVEF1Y0c1bnw4NGI3ZDJjMTcwMTYwYjAzNjU3NTc2ZTExNzFlMTdkYTNlNzEwYjBkZjRiNTAwZWU3Y2IxMTZjYTdmNjc4MTU2)"> -->
<div class="container-UK" *ngIf="node | async as item">
    <div class="hero--prod hero--prodUK" role="img" aria-label="FLUAD QUADRIVALENT campaign flu virus cell" 
        [style.backgroundImage]="'url(' + getImage(item.media) + ')'">

        <div class="HeropatchUK">
            <div class="container">
                <div class="hero--prod__body">
                    <div class="hero--prod__logo hero--prod__logoUK">
                        <img src="../../../app/../assets/images/aQIV logo - white centered.png" alt="">
                    </div>
                    <div class="hero--prod__info hero--prod__infoUK" >
                        <h1 class="hero--prod__header header--2"[innerHTML]="item.headline"></h1>
                            <!-- <span
                                class="blacktriangle">▼</span -->
                                
                        <p class="hero--prod__subheader" style="color:#fff">{{item.h2content}}</p>
                        <div class="greenLine"></div>
                        <p class="hero--prod__content hero--prod__contentUK">{{item.content}}</p>
                        <p class="hero--prod__info-disclaimer"></p>
                    </div>
                </div>
            </div>
            <div class="hero--prod__footerUK">
            </div>
        </div>
    </div>
</div>