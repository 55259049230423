<section class="col-xs-12 col-md-12 mt-5" id="thanksave" style="display: block;"
  *ngIf="enablePage.showSaveThankYouPage">
  <div class="thankorder_box">
    <div class="thankorder_head"><span  [innerHtml]="data.t2content"></span>&nbsp;<span >{{seasonValue | seasonspilt}}</span>
    &nbsp;<span [innerHtml]="data.subHeadLine"></span></div>
    <div class="thanksave_text">Your order has been saved as pending and you should receive an email shortly containing your order details for your records. This information is also available in the My Orders tab here on flu360.</div>

    <div class="thankordersave" [innerHtml]="data.headLine"></div>
  
  <a class="my-order-anchor showPopupclick" (click)="handleOnOrdersClick()">
    <div class="viewbutton">View Orders</div>
  </a>
</div>
</section>

