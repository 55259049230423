import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralSupportComponent } from './general-support/general-support.component';
import { ConfigModule } from '@spartacus/core';
import { GeneralSupportLoSlidesComponent } from './general-support-lo-slides/general-support-lo-slides.component';



@NgModule({
  declarations: [
    GeneralSupportComponent,
    GeneralSupportLoSlidesComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusFlu360ContactUsResources:{
          component: GeneralSupportComponent
        },
        SeqirusLOContactUsSlides:{
          component: GeneralSupportLoSlidesComponent
        }
      }
    })
  ]
})
export class UkContactUsModule { }
