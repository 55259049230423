<section class="prod-sb">
    <div class="container prod-sb__body" *ngIf="fourthnode | async as item">
            <div class="prod-sb__left" *ngIf="item?.mediaPicture">
                <img class="prod-sb__img" alt="product logo" [src]="getImage(item.mediaPicture)">
            </div>
            <div class="prod-sb__right prod-sb__right--light">
                <h2 [innerHTML]="item.h2content"></h2>
                <a class="prod-sb__link ord_enable" href="/start-order">{{ item.content }}</a>
            </div>      
    </div>
</section>