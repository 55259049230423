import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFirstComponent } from './custom-first/custom-first.component';
import { CustomAqivSecondComponent } from './custom-aqiv-second/custom-aqiv-second.component';
import { CustomAqivThirdComponent } from './custom-aqiv-third/custom-aqiv-third.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomBannerComponent } from './custom-banner/custom-banner.component';
import { ReferenceComponent } from './reference/reference.component';
import { CustomCarouselComponent } from './custom-carousel/custom-carousel.component';
import { MatTabsModule } from '@angular/material/tabs';


@NgModule({
  declarations: [
    CustomFirstComponent,
    CustomAqivSecondComponent,
    CustomAqivThirdComponent,
    CustomBannerComponent,
       CustomCarouselComponent,
       ReferenceComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusAllProductsVaccineBannerComponent:{
          component:CustomFirstComponent
        },
        CMSLinkComponent:{
         component: CustomAqivSecondComponent
        },
        SeqirusAllProductsVaccineDescribingComponent:{
          component:CustomAqivThirdComponent
        },
        SeqirusaQIVAllDataSlides:{
          component:CustomCarouselComponent
        },
        SeqirusAllProductsChooseVaccineBannnerComponent:{
          component:CustomBannerComponent
        },
     
      }
    } as CmsConfig)
  ]
})
export class ProductAqivModule { }
