import { Component, OnInit } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
// Declare global function for OneTrust

@Component({
 selector: 'app-header',
 templateUrl: './header.component.html',
 styleUrls: ['./header.component.scss'], // Fixed `styleUrl` to `styleUrls`
})
export class HeaderComponent implements OnInit {
 node: Observable<any> = this.bannerComponent.data$;
 constructor(
   private cmsService: CmsService,
   protected config: OccConfig,
   public bannerComponent: CmsComponentData<CmsBannerComponent>
 ) {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
}