<div class="col-lg-12 col-xs-12 nopadding verifylink_setsuppbtncontainer">
    <button type="button" class="verifylink_setsuppbtn active" data-toggle="modal"
    data-target="#verifyModal" (click)="resendVerification()" data-backdrop="false">Resend Link</button>
</div>



<div id="verifyModal" class="modal fade in" role="dialog" style="padding-right: 10px;">
    <div class="modal-dialog">
        <div class="modal-content col-xs-12">
            <div class="modal-body">
                <h4 class="modal-title verifylink_modalheader col-xs-10 col-md-6">Email Sent</h4>
                <div class="col-xs-2 col-md-6 verifylink_closebtnParent nopadding">
                    <span class="verifylink_closeicon" data-dismiss="modal">×</span>
                </div>
                <p class="verifylink_subheader col-xs-12">Check your email for a new verification link.</p>
                <div class="col-lg-12 col-xs-12 nopadding verifylink_closebtncontainer">
                    <button data-dismiss="modal" type="button" class="verifylink_closebtn">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>