<div *ngIf="seasonData | async as item">
      <div class="col-xs-12" id="orders_topcontent">
        <div class="row">
        <div class="col-xs-12 col-md-7 col-lg-7" id="orders_topcontentleftcontent">
            <h1 class="col-12 orders_titeltop new_header_orders">Order Summary</h1>
        </div>
        <div class="col-xs-12 col-md-5 col-lg-5 orderSummary_right" id="orders_topcontentleftcontent">
            <div class="row">
                <div class="col-xs-12 orders_righttitle">
                    Current Season:<span class="orders_titleyear"> {{item.inSeason}}</span>
                </div>
                <div class="col-xs-12"></div>
            </div>
            <div class="row preBookClass">
                <div class="col-xs-12 orders_titlsubtext">{{preSeasonMsg?.topContent}}</div>
                <div class="col-xs-12 orders_titlsubtext_year">{{preSeasonMsg?.Content1}}</div>
            </div>
            </div>
        </div>
    </div>
</div>
    