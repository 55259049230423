<div class="orders_viewdiff d-none" id="backbtn" (click)="backbtn()"><div class="global_blackarrow mobileorder"></div>Select Different Order </div>


<div class="col-lg-5 col-md-5 col-sm-12 col-12 open-order float_left" id="leftsection">

  
    <div class="form-group">
        <div class="col-md-8 col-lg-8 col-sm-8 col-8 no-padding for_tab_view float_left">
            <div class="input-group seach_order">
                <div class="input-group-addon">
                    <i class="fa fa-search"></i>
                </div>
                <input (keyup)="inputsearch($event);applyFilter()" class="form-control" id="open_order_search" name="lookup" type="text" placeholder="Search, filter, or sort your orders" [(ngModel)]="valformsummary">
            </div>
        </div>
                     <div class="col-md-1 col-2 filterbox float_left"  (click)="filteropen()" style="background-image: url('../assets/images/filter.png');" >
            <span class="select_counter">{{count}}</span>
        </div>
        <div class="col-md-1 col-2 sortbox float_left" (click)="sortopen()" style="background-image: url('../assets/images/sort.png');" [ngClass]="togglesortView?'open':''"></div>
    
         
        <div class="filteropen" [ngClass]="togglefilterView?'open':''">
            <div class="prodsection">
                <div class="filterheader">BY PRODUCT</div>

                <label *ngFor="let item of getfilterdata?.seqirusProductList; let i=index"> <input type="checkbox" class="registration_checkbox" name="prodname"  value={{item}} (click)="incrementcountproduct($event,i);applyFilter()"> <span class="checkbox-button__control"></span>{{item}}</label>
                     
                    </div>
            <div class="statussection margin-T20">
                <div class="filterheader">BY STATUS</div>
                <div >
                    <div  *ngFor="let list of getstatus?.seqirusOrderStatusList;let i=index">
                        <label> <input type="checkbox" class="registration_checkbox" name="status" value={{list}} (click)="incrementcountstatus($event,i);applyFilter()" > <span class="checkbox-button__control" ></span>
                            {{(list == 'Canceled')?'Order Canceled':list}}
                            </label>
                    </div>
                    
                </div>
                
              
            </div>
        </div>
        <div class="sortopen" [ngClass]="togglesortView?'open':''">
            <div class="row">
                <div class="col-md-6">
                    <div class="filterheader">ORDER NUMBER</div>
                    <label> <input type="radio" class="registration_radio" name="radiobtn" value="1to9" (click)="sortTest('1to9')"> <span class="radio-button__control"></span>1 To 9</label> <label> <input type="radio" class="registration_radio" name="radiobtn" value="9to1" (click)="sortTest('9to1')"> <span class="radio-button__control"></span>9 To 1</label>
                </div>
                <div class="col-md-6">
                    <div class="filterheader">LOCATION</div>
                    <label> <input type="radio" class="registration_radio" name="radiobtn" value="AtoZ" (click)="sortTest('AtoZ')"> <span class="radio-button__control"></span>A To Z</label> <label> <input type="radio" class="registration_radio" name="radiobtn" value="ZtoA" (click)="sortTest('ZtoA')"> <span class="radio-button__control"></span>Z To A</label>
    
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-6">
                    <div class="filterheader">DATE PLACED</div>
                    <label> <input type="radio" class="registration_radio" name="radiobtn" value="MostRecent" (click)="sortTest('MostRecent')"> <span class="radio-button__control"></span>MOST RECENT</label> <label> <input type="radio" class="registration_radio" name="radiobtn" value="LeastRecent" (click)="sortTest('LeastRecent')"> <span class="radio-button__control"></span>LEAST RECENT</label>
    
    
                </div>
            </div>
        </div>
        
    </div>
    
    <div class="clearfix"></div>
    
    <hr>
    
    <div *ngIf="isLoading" class="loading">
        <div class="loading_txt">
        <div class="load_txt">Loading</div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
     <div *ngIf="!isLoading">
   
    <div class="mat-elevation-z8 " id="orderfiltertable">
        <mat-form-field class="mattablesearch">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="inputsearch($event);applyFilter()" placeholder="Search, filter, or sort your orders">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        
    
     <ng-container matColumnDef="combined" >
 <th class="odertableheader" mat-header-cell *matHeaderCellDef> order Data </th>
      <td mat-cell *matCellDef="let element" (click)="openrightsectio(element.ordernum)"> 
        <div class="tablecolum_order1">
         {{element.season}}
        <div class="order_id">{{element.ordernum}}</div>
        <div class="order_unit"><span>Unit Qty Total :</span><span class="totalorderquantity_Order" [className]=element.ordernum>{{element.unit}}</span></div>
        <div class="order_location"><span>Shipping Location:</span><span [innerHtml]="element.loc"></span></div>
        </div>
         <div class="tablecolumn_order2">{{element.status}}</div>
        </td>
          
 
      </ng-container>
      
          <!-- Header and Row Declarations -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row id="{{row.ordernum}}" *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row " *matNoDataRow>
            <td class="mat-cell orders no-matching">No data available in table</td>
          </tr>
        </table>
<!--        <mat-paginator #paginator  [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
 -->    
   <div class="custom-paginator">
    <button class="pagination-buttonOrg paddingarrow" (click)="goToFirstPage();scrollToTop()" [disabled]="isFirstPage()" >
      <img alt="Navigate to previous page" src="assets/images/pagination-double-prev-arrow-icon.svg" class="locations_arrows">
    </button>
    <button class="pagination-buttonOrg paddindsinglearrow" (click)="previousPage();scrollToTop()" [disabled]="isFirstPage()">
      <img alt="Navigate to previous page" src="assets/images/pagination-prev-btn.svg" class="locations_arrows">
    </button>
    <button class="pagination-buttonOrg " *ngFor="let pge of getPages()" [class.active-page]="isCurrentPage(pge)" (click)="goToPage(pge);scrollToTop()">
      {{pge}}
    </button>
    <button class="pagination-buttonOrg paddindsinglearrow" (click)="nextPage();scrollToTop()" [disabled]="isLastPage()">
      <img alt="Navigate to next page" src="assets/images/pagination-next-btn.svg" class="locations_arrows">
    </button>
    <button class="pagination-buttonOrg paddingarrow" (click)="goToLastPage();scrollToTop()" [disabled]="isLastPage()">
      <img alt="Navigate to last page" src="assets/images/pagination-double-next-arrow-icon.svg" class="locations_arrows">
    </button>
    </div>  
 </div>
    </div>
    </div>


    <!-- right section for my orders -->
    
    <div *ngIf="rightisLoading" class="loading">
        <div class="loading_txt">
        <div class="load_txt">Loading</div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <div class="mobileright"  id="rightsection">
    <div *ngIf="!rightisLoading">
    <div class="col-lg-7 col-md-7 col-span-12 right-order  order-details-view float_left ">
      
      <ng-container *ngIf="rightsectionresponse">
        <div class="order_details_section col-md-12" id="{{rightsectionresponse.orderID}}">
    
            <div class="order_status_table">
                <div class="row order_panel_header right-first-row">
                    <div class="col-md-5 col-7">
                        <div class="order_id">
                            Order #<span class="order_id_fetch">{{rightsectionresponse.orderID}}</span>
                        </div>
                    </div>
                    <div class="col-md-7 col-5 button_wrap" *ngIf="rightsectionresponse.status=='Ordered'">
                            <div class="order_button edit_ord"  (click)="viewEditTable()" [ngClass]="{'active':editdata}">
                                Edit&nbsp;<i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                            </div>
                            
                     </div>
                </div>
                    <!-- edit order section start's here -->
                    <div *ngIf="iseditLoading" class="loading">
                        <div class="loading_txt">
                            <div class="load_txt">Loading</div>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                    </div>
                    <div *ngIf="!iseditLoading">
                    <div *ngIf="editdata" class="editOrderArea">
                        <!--products table -->
                        <table class="editheader1">
                            <th style="width: 42%;;">PRODUCTS</th>
                            <th style="width: 27%;">PRICE</th>
                            <th style="width: 31%;">QUANTITY</th>
                        </table>
                        <div class="edittablecontainer">
                            <table mat-table [dataSource]="editdatasource" multiTemplateDataRows class="edit-table mat-elevation-z8" >
                                <!-- Product Name Column -->
                                <ng-container matColumnDef="fullProductName">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.baseName | uppercase}}&nbsp;<small>{{element.presentationName}}</small>
                                        <div class="edit_mob_hidden1"> <span>{{element.formattedValue}}</span>per
                                                                                            <!--units inside brackets-->
                                            unit&nbsp;<small class="edit_mob_inlineshow">[{{element.doses}} doses/unit]</small></div>
                                        <!--To show No stock-->
                                        <div *ngIf="!element.sellable" class="nostockbanner">SOLD OUT</div>
                                    </td>
                                </ng-container>
                                <!-- Price Column -->
                                <ng-container matColumnDef="price">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">{{element.formattedValue}}&nbsp;per unit</td>
                                </ng-container>
                                <!-- Quantity Column -->
                                <ng-container matColumnDef="totalOrderdQty">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="editdosecount">
                                            <div>
                                                <input id="prodQtyBox" type="text" [disabled]="!element.sellable" placeholder="0"
                                                    [(ngModel)]="element.quantity" maxlength="4" (keypress)="onKeyPress($event)"
                                                    [ngStyle]="{'border':element.errorMessage ? '1px solid red':'1px solid grey'}">
                                            </div>
                                            <div>units&nbsp;&nbsp;<br><span          
                                                    class="mobile_view_hide"><small>[{{element.doses}} doses/unit]</small></span>                                
                                            </div>
                                        </div>
                                </ng-container>
                                <ng-container matColumnDef="errorMessage">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element" colspan="3">
                                        <div *ngIf="element.errorMessage" class="error-message">{{element.errorMessage}}</div>
                                    </td>
                                </ng-container>
                    
                    
                                <tr class="editheader" mat-header-row *matHeaderRowDef="editcolumns"></tr>
                                <tr mat-row *matRowDef="let element; columns: ['fullProductName','price','totalOrderdQty']"></tr>
                                <tr mat-row class="errorrow" *matRowDef="let element; columns:['errorMessage']" [class.hidden]="!element.errorMessage"></tr>
                            </table>
                        </div>
                        <!--Footer area-->
                        <div class="editproductfooter">
                            <div>Total &nbsp;
                                <!--for mobile view price beside total-->
                                <span class="edit_mob_inlineshow totalprice total_order_price5">${{calculateTotalPrice()}}</span>
                            </div>
                            <!--for desktop site price-->
                            <div class="edit_mob_hidden"><span class="totalprice total_order_price1">${{calculateTotalPrice()}}</span>
                            </div>
                    
                            <div><span class="total_ord_qty total_ord_qty_edit total_order1"></span>{{calculateTotalQuantiy()}} units
                            </div>
                        </div>
                    
                        <div class="membershiparea">
                            <!--membership details-->
                            <div class="row order_panel_header editscreenbootomdetails">
                                <div class="membershipdetails col-xs-12">
                                    <div *ngIf="membershipdata.isMembershipDeclared">
                                        <div *ngIf="activemembership">
                                            <p>{{activemembership.title}} {{membershipdata.currentMembership}}</p>
                                            <div class="underline-links" [innerHTML]="activemembership.content"></div>
                                        </div>
                                       
                                    </div>
                                    <div *ngIf="!membershipdata.isMembershipDeclared">
                                        <div *ngIf="nomembership">
                                            <p>{{nomembership.title}} {{membershipdata.currentMembership}}</p>
                                              <div class="underline-links" [innerHTML]="nomembership.content"></div>
                                        </div>   
                                    </div>
                                </div>
                                <!--Edit section membership changes end's -->
                                <div class="col-md-3">
                                    <div class="order_level">P.O. Number</div>
                                    <div class="">
                                        <input type="text" class="form-control order_edit_fld" disabled="true"
                                            value="{{rightsectionresponse.poNumber}}" [ngStyle]="{'background-color': '#eeeeee'}">
                                    </div>
                                </div>
                    
                                <div class="col-md-5 mob-padding-edit">
                                    <div class="order_level">SHIPPING LOCATION</div>
                                    <div class="">
                                        <input type="text" disabled="true" class="form-control order_edit_fld"
                                            value="{{rightsectionresponse.addressLine1}} {{rightsectionresponse.addressLine2}},{{rightsectionresponse.city}},{{rightsectionresponse.state}},{{rightsectionresponse.zipCode}}" [ngStyle]="{'background-color': '#eeeeee'}">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="order_level">BILLING LOCATION</div>
                                    <div class="">
                                        <input type="text" class="form-control order_edit_fld" disabled="true"
                                            value="{{rightsectionresponse.billAddressLine1}} {{rightsectionresponse.billAddressLine2}}" [ngStyle]="{'background-color': '#eeeeee'}">
                                    </div>
                                </div>

                                <div class="membershipdetails col-xs-12">
                                    <div class="padding-T10"><a class="profilelink" target="_blank" [routerLink]="'/contact-us'">Contact
                                            us</a> to edit your P.O. Number, shipping details, or billing details.</div>
                                </div>
                            </div>

                            <div class="row order_panel_header no-border-B">
                                <div class="col-md-6">
                                    <a class="cancel_btn_edit" (click)="closeEditOrderArea()" id="1">Close and don't
                                        save</a>
                                </div>
                                <div class="col-md-6">
                                    <input type="submit" class="save_btn_edit updateOrderClick" value="Save" (click)="saveOrder()"
                                        [disabled]="!isFormValid">
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <!-- edit order section end's here -->
              
                <div class="row order_panel_header" *ngIf="!editdata">
                    <div class="col-md-2 col-4">
                        <div class="order_status_header">PLACED ON</div>
                        <div class="order_substaus">
                            {{rightsectionresponse.orderDate | dateformat}}</div>
                    </div>
                    <div class="col-md-3 col-4">
                        <div class="order_status_header">PLACED BY</div>
                    <div class="order_substaus">{{rightsectionresponse.placedBy}}</div>
                    </div>
                    <div class="col-md-2 col-4">
                        <div class="order_status_header">P.O. NUMBER</div>
                        <div class="order_substaus">{{rightsectionresponse.poNumber}}</div>
                    </div>
                    <div class="col-md-5 col-12 mobile_MT">
                        <div class="order_status_header">SHIPPING LOCATION</div>
                        <div class="order_substaus">
                            {{rightsectionresponse.addressLine1}} {{rightsectionresponse.addressLine2}},
                            {{rightsectionresponse.city}}, {{rightsectionresponse.state}}
                            &nbsp;{{rightsectionresponse.zipCode}}</div>
                    </div>
                </div>
    
                <div class="row order_panel_header no-border-B" *ngIf="!editdata">
                    <div class="col-xs-12 col-md-6"> <!-- mobile view change-->
                        <div class="order_status_header quantity_header">Order Quantity Delivered</div>
                        <div class="order_substaus margin-T10 margin-B30">
                            <strong class="crnt-ord"><span class="crnt_order_qty">{{rightsectionresponse.totalShippedQty}}</span>
                                units</strong> / <span class="total_qty total_qty0">{{rightsectionresponse.totalOrderedQty}}</span>
                        </div>
                        <div class="progress progressUS margin-B20">
                            <mat-progress-bar mode="determinate" value= {{getProgress(rightsectionresponse.totalShippedQty,rightsectionresponse.totalOrderedQty)}}></mat-progress-bar>
                        </div>
                        <div class="col-md-3 col-4 order-status-new-parent">
                            <div class="current_order_status order-status-new">
                            <strong>Status:</strong>&nbsp;<span class="ordstat">{{rightsectionresponse.status}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6"> <!-- mobile view change-->
                        <div class="order_status_header payment_header">Payment Progress</div>
                        <div class="order_substaus margin-T10 margin-B30">
                            <strong class="crnt-ord">$
                            <span class="crnt_payment_qty" *ngIf="rightsectionresponse.status=='Ordered' || rightsectionresponse.status=='Shipped' || rightsectionresponse.status=='Canceled'">0.00</span>
</strong>
                                <strong><span class="crnt_payment_qty"  *ngIf="rightsectionresponse.status=='Delivered'"> {{rightsectionresponse.totalCost | number:'1.2-2'}}</span></strong> 
                               <span class="total_payment_text"> / $</span>
                                    <span class="total_pymnt">
                                        {{rightsectionresponse.totalCost | number:'1.2-2'}}</span>
                                    <span class="total_payment_text">&nbsp;total</span>
                        </div>
                        <div class="progress progressUS margin-B20"> <!-- mobile view change-->


                            <ng-container *ngIf="rightsectionresponse.status=='Ordered' || rightsectionresponse.status=='Shipped' || rightsectionresponse.status=='Canceled'">
                            <mat-progress-bar mode="determinate" value= getProgress(0,rightsectionresponse.totalCost)></mat-progress-bar></ng-container>
                            <ng-container *ngIf="rightsectionresponse.status=='Delivered'">
                                <mat-progress-bar mode="determinate" value= {{getProgress(rightsectionresponse.totalCost,rightsectionresponse.totalCost)}}></mat-progress-bar></ng-container>
                       </div>
                        <div class="row">
                            <div class="col-md-7 col-7">
                                <div class="order_status_header invoice-header">
                                    <strong>Status:</strong>&nbsp;<span *ngIf="rightsectionresponse.invoiceAvailable=='true';else noinvoice">Invoices Available</span><ng-template #noinvoice>No invoices available</ng-template></div>
                            </div>
                            <div class="col-md-5 col-5"  *ngIf="rightsectionresponse.invoiceAvailable=='true'">
                                <a href="{{rightsectionresponse.invoiceLink}}" class="order_status_header"><strong><span class="h_viewinvoice">View invoices</span>&nbsp; →</strong></a>
                                </div>
                            <div class="col-md-5 col-5">
                                </div>
                        </div>
                    </div>
                </div>
                    
                <!--orders shipping changes starts-->
                 <div class="row order_panel_header order_shipping_details" *ngIf="!editdata">
                    <div class="col-md-12 order_id ">Shipping Details</div>


                  
               




                 
                 <div *ngIf="rightsectionresponse.status=='Ordered' && rightsectionresponse.ispromisedDateAvailable=='false'" class="col-md-12 margin-T20 no_order_shipping">Check back soon for more details.</div>
                 <div *ngIf="rightsectionresponse.status=='Canceled'" class="col-md-12 margin-T20 no_order_shipping">Order canceled. There are no scheduled shipments.</div>
                 <div *ngIf="(rightsectionresponse.status=='Ordered' && rightsectionresponse.ispromisedDateAvailable=='true') || rightsectionresponse.status=='Shipped' || rightsectionresponse.status=='Delivered'" class="col-md-12">
                    <div class="col-md-12 margin-T20 order_accordian padding-T10 panel-group" id="order_accordian_{{rightsectionresponse.orderID}}">
<!-- loop start -->

<div *ngFor="let list of rightsectionresponse?.shippingVisibilityTable?.shippingDetails;let i=index" class="panel panel-default">
    <div class="panel-heading no-padding">
        <h4 class="panel-title">
            <a data-toggle="collapse" class="accordion-toggle collapsed" [ngClass]="list.status=='Delivered'?'delivered':''" href="#accordian_header{{i}}" aria-expanded="false">{{list.statusWithDate}}</a>
        </h4>
    </div>
    <div id="accordian_header{{i}}" class="panel-collapse collapse" aria-expanded="false" style="height: 0px;">

        <div class="panel-body no-padding">
            <div class="orders_view_tracking " *ngIf="list?.tracking?.link"><a target="_blank" href="{{list?.tracking?.link}}" class="orders_view_tracking_link">View Tracking<i class="global_blackarrow"></i></a></div>
            <table style="width:100%;">
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let items of list?.productDetail">
                        <td>{{items.productName}}</td>
                        <td>{{items.itemQty}} units</td>

                    </tr>
                   <tr>
                    <td >Total </td>
                    <td>{{ getTotal(list?.productDetail)}} units</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</div>
                   
<!-- loop ends -->
                    </div>
                 </div>

                 <!-- <div class="col-md-12 margin-T20 order_accordian panel-group" id="{{'order_accordian_' + rightsectionresponse.orderID}}">
                    <div class="panel panel-default product_details_table">
                        <div class="panel-heading">
                            <h4 class="panel-title order_shipped_status">
                                <a data-toggle="collapse" class="accordion-toggle" href="#accordian_header1" aria-expanded="true">Shipped on  May 31</a>
                            </h4>
                        </div>
                        <div id="accordian_header1" class="panel-collapse collapse in" aria-expanded="true" style="">
                            <div class="panel-body">
                                <table class="shipping_table">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>AFLURIA</td>
                                            <td>1 units</td>
                                        </tr>
                                        <tr>
                                            <td>FLUCELVAX</td>
                                            <td>1 units</td>
                                        </tr>
                                        <tr>
                                            <td>FLUAD</td>
                                            <td>1 units</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td>3 units</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> -->
                    </div>
                <!--orders shipping changes Ends-->
    
    <div class="shipping_title">Product Details</div>
 
      <!-- <div class="ordertivtext" *ngIf="rightsectionresponse.preSeason==dropdownValue"><div>2024-2025 season will ship as trivalent formulations. No action is needed from you.</div>
	<a class="ordertivtextanchor" href="/digital_customer_letter_tiv_vaccines_24-25" target="blank">Read our full Customer Letter</a>
	<i class="tiv_redarrow" style="background-image: url('../assets/images/red_arrow.png');"></i>
</div>  -->
 <table mat-table
       [dataSource]="dataSource1" multiTemplateDataRows class="productdetailTable mat-elevation-z8 d-none d-md-block d-lg-block">
       <ng-container matColumnDef="fullProductName">
        <th mat-header-cell *matHeaderCellDef>PRODUCT</th>
        <td mat-cell *matCellDef="let row">{{row.fullProductName}}</td>
    </ng-container>
    <ng-container matColumnDef="totalOrderdQty">
        <th mat-header-cell *matHeaderCellDef>QUANTITY</th>
        <td mat-cell *matCellDef="let row">{{row.totalOrderdQty}} units</td>
    </ng-container>
    <ng-container matColumnDef="totalInvoiceAmount">
        <th mat-header-cell *matHeaderCellDef>INVOICED</th>
        <td mat-cell *matCellDef="let row">${{row.totalInvoiceAmount | number:'1.2-2'}}</td>
    </ng-container>
    <ng-container matColumnDef="totalShippedQty">
        <th mat-header-cell *matHeaderCellDef>DELIVERED</th>
        <td mat-cell *matCellDef="let row">{{row.totalShippedQty}}/{{row.totalOrderdQty}}</td>
    </ng-container>
    <ng-container matColumnDef="latestStatus">
        <th mat-header-cell *matHeaderCellDef>NEXT DELIVERY</th>
        <td mat-cell *matCellDef="let row" class="details-control">
             <ng-container *ngIf="row.latestStatus.toUpperCase()=='Check back soon'.toUpperCase();else expandrow">
                 <div [ngClass]="row.latestStatus=='Check back soon'?'checkback':'redcolortext'">{{row.latestStatus}} <i class="fa fa-caret-down view_status inactive" aria-hidden="true"></i></div> 
            </ng-container>
            <ng-template #expandrow>
                <ng-container *ngIf="row.latestStatus.toUpperCase()=='All deliveries completed'.toUpperCase();else expandrow1">
                <div [@detailExpand]="row.expanded ? 'expanded' : 'collapsed'"  [ngClass]="row.latestStatus=='All deliveries completed'?'checkback':''" (click)="toggleRow(row)"> {{row.latestStatus}}<i class="fa fa-caret-down view_status active" aria-hidden="true"></i></div> 
                </ng-container>
            </ng-template> 
     <ng-template #expandrow1>
            <div  [@detailExpand]="row.expanded ? 'expanded' : 'collapsed'"  [ngClass]="row.latestStatus=='Check back soon'?'checkback':'redcolortext'" (click)="toggleRow(row)">{{row.latestStatus}} <i class="fa fa-caret-down view_status active" aria-hidden="true"></i></div> 
            </ng-template>
         </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr> 
    <tr class="mat-row " *matNoDataRow>
        <td class="nodata" colspan="5">No data available in table</td>
      </tr>          
     <ng-container>
      <tr  mat-row *matRowDef="let row; columns:displayedColumns1;let i=index" [class.expanded-row]="row.expanded" ></tr>
    </ng-container> 
    
        <tr class="tabledetailrow" *matRowDef="let row; columns: [];  let i=index" [class.expanded-row]="row.expanded"> 
            <ng-container>

            <td [attr.colspan]="5">

                <table class="subtable">
                    <thead>
                        <tr>
                            <th>QUANTITY</th>
                            <th>STATUS</th>
                            <th>TRACKING</th>
                           
                        </tr>
                    </thead>
                    <!-- <ng-container > -->
                    <tr *ngFor="let data of row.shippedStatusTable" >
                        <td>{{data.quantity}} units</td>
                        <td class="product_Shipped" [ngClass]="row.latestStatus=='Check back soon'?'checkback':'redcolortext'">{{data.shipmentStatus}}</td>
                        <td><a class="productDetails_viewTracking"  *ngIf="data?.trackingLink" target="_blank" href="{{data?.trackingLink}}">view tracking <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a></td>

                    </tr>
                <!-- </ng-container> -->
                </table>
            </td>
        </ng-container>
    </tr> 
  
    
      
  
</table>

<!-- mobile view -->
<table mat-table
[dataSource]="dataSource1" multiTemplateDataRows class="productdetailTable mobiletable mat-elevation-z8 d-block d-md-none d-lg-none">
<ng-container matColumnDef="combined" >
    <th class="odertableheader d-none" mat-header-cell *matHeaderCellDef> order Data </th>
    <td mat-cell *matCellDef="let row"> 
        <div class="tabledivorders">
        <div class="mobile_prod_table">
            <div class="table_product">{{row.fullProductName}}</div>
            <div class="table_icon">
                <ng-container *ngIf="row.latestStatus.toUpperCase()=='Check back soon'.toUpperCase();else expandrow">
                    <div [ngClass]="row.latestStatus=='Check back soon'?'checkback':'redcolortext'"> <i class="fa fa-caret-down view_status inactive" aria-hidden="true"></i></div> 
               </ng-container>

               
               <ng-template #expandrow>
                <div  [@detailExpand]="row.expanded ? 'expanded' : 'collapsed'"  [ngClass]="row.latestStatus=='Check back soon'?'checkback':'redcolortext'" (click)="toggleRow(row)"> <i class="fa fa-caret-down view_status active" aria-hidden="true"></i></div> 
                </ng-template>
                
            </div>
        </div>
        <div class="mobile_prod_table">
            <div class="table_header">Quantity:</div>
            <div class="table_value">{{row.totalOrderdQty}} units</div>
        </div>
        <div class="mobile_prod_table">
            <div class="table_header">Invoiced:</div>
            <div class="table_value">${{row.totalInvoiceAmount | number:'1.2-2'}}</div>
        </div>
        <div class="mobile_prod_table">
            <div class="table_header">Delivered:</div>
            <div class="table_value">{{row.totalShippedQty}}/{{row.totalOrderdQty}} </div>
        </div>
        <div class="mobile_prod_table">
            <div class="table_header">Next Delivery:</div>
            <div class="table_value"> 
                <ng-container *ngIf="row.latestStatus.toUpperCase()=='Check back soon'.toUpperCase();else expandrow1">
                    <div [ngClass]="row.latestStatus=='Check back soon'?'checkback':'redcolortext'">{{row.latestStatus}}</div> 
                </ng-container>
               <ng-template #expandrow1>
                    <ng-container *ngIf="row.latestStatus.toUpperCase()=='All deliveries completed'.toUpperCase();else expandrow">
                        <div [@detailExpand]="row.expanded ? 'expanded' : 'collapsed'"  [ngClass]="row.latestStatus=='All deliveries completed'?'checkback':''" (click)="toggleRow(row)">{{row.latestStatus}}</div> 
                    </ng-container>
                </ng-template>
                    <ng-template #expandrow>
                         <div [@detailExpand]="row.expanded ? 'expanded' : 'collapsed'"  [ngClass]="row.latestStatus=='Check back soon'?'checkback':'redcolortext'" (click)="toggleRow(row)">{{row.latestStatus}}</div> 
                     </ng-template>
            </div>
        </div>
       </div>

    </td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> 
        
<ng-container>
<tr  mat-row *matRowDef="let row; columns:displayedColumns;let i=index" [class.expanded-row]="row.expanded" ></tr>
</ng-container> 

 <tr class="tabledetailrow" *matRowDef="let row; columns: [];  let i=index" [class.expanded-row]="row.expanded"> 
     <ng-container>

     <td [attr.colspan]="5">

         <table class="subtable">
             <thead>
                 <tr>
                     <th>QUANTITY</th>
                     <th>STATUS</th>
                     <th>TRACKING</th>
                    
                 </tr>
             </thead>
             <!-- <ng-container > -->
             <tr *ngFor="let data of row.shippedStatusTable" >
                 <td>{{data.quantity}} units</td>
                 <td class="product_Shipped" [ngClass]="row.latestStatus=='Check back soon'?'checkback':'redcolortext'">{{data.shipmentStatus}}</td>
                 <td><a class="productDetails_viewTracking"  *ngIf="data?.trackingLink" target="_blank" href="{{data?.trackingLink}}">view tracking <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a></td>
             </tr>
         <!-- </ng-container> -->
         </table>
     </td>
 </ng-container>
</tr> 




</table>
            </div>
        </div>
    </ng-container>
    </div>
</div>
</div>
    