import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLeftPanelComponent } from './custom-left-panel/custom-left-panel.component';
import { BannerModule, FormErrorsModule, OutletPosition, SpinnerModule, provideOutlet } from '@spartacus/storefront';
import { CustomUserGuideComponent } from './custom-user-guide/custom-user-guide.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { HelpWidgetComponent } from '../cms-common/help-widget/help-widget.component';
import { CustomLinkResendComponent } from './custom-link-resend/custom-link-resend.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomVerifyprofileComponent } from './custom-verifyprofile/custom-verifyprofile.component';
import { CustombreadcrumbComponent } from './custombreadcrumb/custombreadcrumb.component';


@NgModule({
  declarations: [CustomLeftPanelComponent,CustomUserGuideComponent,CustomVerifyprofileComponent, CustomLinkResendComponent, CustombreadcrumbComponent],
  imports: [
    CommonModule,
    BannerModule,
    SpinnerModule,
    I18nModule,
    UrlModule,
    FormErrorsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusNeedhelpBannerComponent: {
          component: CustomUserGuideComponent,
        },
        ResendButtonFlexComponent:{
          component:CustomLinkResendComponent,
        },
        ResendFlexComponent:{
          component:CustomVerifyprofileComponent,
        },
        UKLoginBreadCrumbComponent:{
          component:CustombreadcrumbComponent,
        }
      }
    } as CmsConfig) 

],
  providers:[
    provideOutlet({
      id: 'LeftContentSlot',
      position: OutletPosition.REPLACE,
      component: CustomLeftPanelComponent,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomCreateAccountModule { }
