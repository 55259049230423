import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OccConfig } from '@spartacus/core';
import { GalleryItem, ImageItem, GalleryConfig, Gallery } from 'ng-gallery';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrl: './image-dialog.component.scss'
})
export class ImageDialogComponent {
  images: GalleryItem[] = []; // Initialize as an empty array
  baseUrl: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,protected config: OccConfig,private dialogRef: MatDialogRef<ImageDialogComponent>,private gallery: Gallery) {
    this.baseUrl = data.baseUrl; // Get the base URL from the data
  }

  ngOnInit(): void {
    console.log('Received images:', this.data.images); // Log the received images
    if (this.data.images) {
      this.images = this.data.images.map((image: any) => new ImageItem({ src: this.baseUrl + image.url, thumb: this.baseUrl + image.url }));
    }

    // Configure gallery options
    const galleryRef = this.gallery.ref();
    galleryRef.setConfig({
      thumbPosition: 'bottom',
      imageSize: 'contain',
      thumbs: true
    });
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
}
