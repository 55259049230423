import { ChangeDetectorRef, Component, inject, ViewChild } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { OccEndpointsService } from '@spartacus/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { CustomMemberModalComponent } from '../../custom-my-profile/custom-member-modal/custom-member-modal.component';
import { CustomRequestThankyouComponent } from '../../custom-my-profile/custom-request-thankyou/custom-request-thankyou.component';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';


@Component({
  selector: 'app-accountsection',
  templateUrl: './accountsection.component.html',
  styleUrl: './accountsection.component.scss'
})
export class AccountsectionComponent {
 
  accountDetails:any;
  accountdata:any;
  nhsvalidationgp:boolean=false;
  nhsvalidationpharma:boolean=false;
  nhsvalidationall:boolean=false;
  requestbtn:boolean=false
  nhsmaxlength:number=6
  requestModal:boolean=false;
  @ViewChild('requestModal')
  
  requestModalTemplate: any;
  ACCOUNTsearchResultEmpty:boolean=false;
  toggleEdit:boolean=true;
  editview:boolean=false;
  unlinkmessage:boolean=true;
  errorshow:boolean=false;
  thankshow:boolean=false;
  dataSource = new MatTableDataSource();
  private _liveAnnouncer = inject(LiveAnnouncer);
  displayedColumnsuk: string[] = ['locID', 'locname','address', 'checked'];
  @ViewChild(MatSort) sort!: MatSort;
  customerRegisterForm: FormGroup | any;
  unlinkaccountnumber:number=0;
  constructor(private userInfoService: UserInfoService,private commonService: CommonApiService,private cd:ChangeDetectorRef,private fb: FormBuilder, private occEndpointsService: OccEndpointsService,private dialog: MatDialog,) {
    this.setForm();
  }
  ngOnInit() {
    

  this.userInfoService.getaccountdetailsuk().subscribe((data: any) => {
this.accountdata=data
this.getForm(this.accountdata);

  });
  this.accountlist();
  this.cd.detectChanges();
}

accountlist(){
  this.userInfoService.getaccountlink().subscribe((data:any)=>{
    
  this.dataSource=new MatTableDataSource(data.data);
  this.dataSource.sort = this.sort;
    });
}
/* edit() {
  this.toggleEdit = !this.toggleEdit;
} */
showedit(){
 this.editview=!this.editview; 
}

requestcheck(){
  if( this.getFormControl('nhscode').value || this.getFormControl('orgName').value || this.getFormControl('vatno').value || this.getFormControl('companyRegistration').value || this.getFormControl('orgtype').value){
    if( this.getFormControl('nhscode').valid && this.getFormControl('orgName').valid && this.getFormControl('vatno').valid && this.getFormControl('companyRegistration').valid && this.getFormControl('orgtype').valid){
      if(!this.nhsvalidationall && !this.nhsvalidationgp && !this.nhsvalidationpharma){
this.requestbtn=true;
      }
      else{
        this.requestbtn=false;
      }
      
    }
    else{
      this.requestbtn=false;
    }
  }
  else{
    this.requestbtn=false;
  }
}
nhsvalidation(event:Event){
  if(this.getFormControl('nhscode').value){
    const element = (event.target as HTMLInputElement)
    if(this.getFormControl('orgtype').value){


      if(this.getFormControl('orgtype').value=='GENERAL_PRACTICE'){
        this.nhsmaxlength=6
        var pattern = /^[A-Za-z]{1}[0-9]{5}$/;
        this.nhsvalidationgp=(this.getFormControl('nhscode').value.match(pattern)?false:true)
      }else if(this.getFormControl('orgtype').value=='PHARMACY'){
    this.nhsmaxlength=5
    var pattern = /^[F|f]([A-Za-z]|[0-9]){4}$/;
    this.nhsvalidationpharma=(this.getFormControl('nhscode').value.match(pattern)?false:true)
      }else if((this.getFormControl('orgtype').value == "HOSPITAL_PUBLIC" || this.getFormControl('orgtype').value == "HOSPITAL_PRIVATE" || this.getFormControl('orgtype').value == "NURSING_HOMES" || this.getFormControl('orgtype').value == "CORRECTIONAL_FACILITY" || this.getFormControl('orgtype').value == "WHOLESALER")){
        this.nhsmaxlength=6
        var pattern = /^.{6,6}$/;
        this.nhsvalidationall=(this.getFormControl('nhscode').value.match(pattern)?false:true)
      }
    }else{
      if((this.getFormControl('businessType').value=='GP Medical Practice' || this.getFormControl('businessType').value=='GENERAL_PRACTICE')) {
        this.nhsmaxlength=6
        var pattern = /^[A-Za-z]{1}[0-9]{5}$/;
        this.nhsvalidationgp=(this.getFormControl('nhscode').value.match(pattern)?false:true)
      }else if((this.getFormControl('businessType').value=='PHARMACY'|| this.getFormControl('businessType').value=='Pharmacy')){
    this.nhsmaxlength=5
    var pattern = /^[F|f]([A-Za-z]|[0-9]){4}$/;
    this.nhsvalidationpharma=(this.getFormControl('nhscode').value.match(pattern)?false:true)
      }else if(this.getFormControl('businessType').value == "HOSPITAL_PUBLIC" || this.getFormControl('businessType').value == "HOSPITAL_PRIVATE" || this.getFormControl('businessType').value == "NURSING_HOMES" || this.getFormControl('businessType').value == "CORRECTIONAL_FACILITY" || this.getFormControl('businessType').value == "WHOLESALER" ||this.getFormControl('businessType').value == "Hospital - Public" || this.getFormControl('businessType').value == "Hospital - Private" || this.getFormControl('businessType').value == "Nursing Homes" || this.getFormControl('businessType').value == "Correctional Facility" || this.getFormControl('businessType').value == "Wholesaler"){
        this.nhsmaxlength=6
        var pattern = /^.{6,6}$/;
        this.nhsvalidationall=(this.getFormControl('nhscode').value.match(pattern)?false:true)
      }

    }
  }
  this.cd.detectChanges();
}
showRequestModal(type: string) {
  this.openDialogRequest({ 
    type: type,
    company:this.getFormControl('companytype').value,
    url:this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/updateCompany`),
    payload: this.customerRegisterForm.value }, '450px');
}
setForm() {
  this.customerRegisterForm = this.fb.group({
   
    Accountname: [''],
    businessType: [''],
    countuk: [''],
    nhcNumber:[''],
    vatNumber:[''],
    companyRegNumber:[''],
    tradingSince:[''],
    orgAddressLine1: ['', [Validators.required]],
    orgAddressLine2: [''],
    orgAddressCity: ['', [Validators.required]],
    orgAddressZipCode: ['', [Validators.required, Validators.pattern(/^(([A-Za-z0-9]){2}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){3}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){4}([ ]){1}([A-Za-z0-9]){3})$/i)]],
    orgAddressCountryName:['', [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)[A-Za-z][A-Za-z\-\,\s]*$/g)]],
    customerDataFirstName:['', [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)[A-Za-z][A-Za-z\-\,\s]*$/g)]],
    customerDataLastName:['', [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)[A-Za-z][A-Za-z\-\,\s]*$/g)]],
    customerDataJobTitle:[''],             
    customerDataOrgAddressEmail:['', [Validators.required,Validators.email]],
    customerDataOrgAddressPhone:['', [Validators.required,Validators.pattern('^[0-9]+$'),Validators.minLength(10),Validators.maxLength(11)]],
    customerDataOrgAddressPhoneExt:['',[Validators.maxLength(4),Validators.pattern('^[0-9]+$')]],
    orgName:['', [Validators.pattern(/^(?!\s)(?!.*\s$)[A-Za-z][A-Za-z\-\,\s]*$/g)]],
    vatno:['', [Validators.pattern('^[0-9]+$'),Validators.maxLength(4)]],
    companyRegistration:['', [Validators.pattern('^[0-9]+$'),Validators.maxLength(8),Validators.minLength(7)]],
    nhscode:[''],
    orgtype:['']
  })
}
getFormControl(control: string) {
  return this.customerRegisterForm!.get(control);
}
getForm(data: any) {
  this.customerRegisterForm.patchValue({
    Accountname: data?.accountname,
    businessType: data?.businessType,
    countuk: data?.countuk,
    nhcNumber:data?.nhcNumber,
    vatNumber:data?.vatNumber,
    companyRegNumber:data?.companyRegNumber,
    tradingSince:data?.tradingSince,
    orgAddressLine1:data?.orgAddressLine1,
    orgAddressLine2:data?.orgAddressLine2,
    orgAddressCity:data?.orgAddressCity,
    orgAddressZipCode:data?.orgAddressZipCode,
    orgAddressCountryName:data?.orgAddressCountryName,
    customerDataFirstName:data?.customerDataFirstName,
    customerDataLastName:data?.customerDataLastName,
    customerDataJobTitle:data?.customerDataJobTitle,
    customerDataOrgAddressEmail:data?.customerDataOrgAddressEmail,
    customerDataOrgAddressPhone:data?.customerDataOrgAddressPhone,
    customerDataOrgAddressPhoneExt:data?.customerDataOrgAddressPhoneExt
  
  })
  this.customerRegisterForm.controls['countuk'].disable();
  this.customerRegisterForm.controls['Accountname'].disable();
  this.customerRegisterForm.controls['businessType'].disable();
  this.customerRegisterForm.controls['nhcNumber'].disable();
  this.customerRegisterForm.controls['vatNumber'].disable();
  this.customerRegisterForm.controls['companyRegNumber'].disable();
  this.customerRegisterForm.controls['tradingSince'].disable();
}

/* getOrgDetails() {
  let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/orgDetails`);
  let payload = {
    url: endpoint,
  }
  this.commonService.getAPICall(payload).subscribe({
    next: (res: any) => {
      if (res) {
        this.accountDetails=res;
        this.getForm(res);
        this.cd.detectChanges();
      }
    },
    error: (e) => {
    },
  })
} */
submitBusinessDetails() {
  
  const formValue: any = [];
  Object.keys(this.customerRegisterForm.controls).map((key) => {
    const parsedValue = {
      [key]: this.customerRegisterForm.get(key).value,
      changed: this.customerRegisterForm.get(key).dirty
    }
    if (parsedValue.changed) {
      formValue.push(key);
    }
  });
  if (this.customerRegisterForm.invalid) {
    this.customerRegisterForm!.markAllAsTouched();
    return;
  }
  let url = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/updateProfile`);
  let payload = {
    url: url,
    data: {
      addressType: 'Business',
      addressId: this.customerRegisterForm.value.addressId,
      globalLocationNumber: this.customerRegisterForm.value.globalLocationNumber,
      postalCode: this.customerRegisterForm.value.postalCode,
      city: this.customerRegisterForm.value.city,
      state: this.customerRegisterForm.value.state,
      country: this.customerRegisterForm.value.country,
      modifiedQualifiers: formValue.length ? formValue.toString() : '',
      orgName: this.getFormControl('companytype').value,
      line1: this.customerRegisterForm.value.line1,
      line2: this.customerRegisterForm.value.line2
    }
  }
   this.commonService.postAPICall(payload).subscribe({
    next: (res: any) => {
        this.toggleEdit = false;
        //this.getOrgDetails();
      this.openDialog({ type: 'account-thank-you'}, '350px');
    },

    error: (e) => {
    },
  }) 
}
openrequestmodal(){
 
  const formValue: any = [];
  Object.keys(this.customerRegisterForm.controls).map((key) => {
    const parsedValue = {
      [key]: this.customerRegisterForm.get(key).value,
      changed: this.customerRegisterForm.get(key).dirty
    }
    if (parsedValue.changed) {
      formValue.push(key);
    }
  });
  if (this.customerRegisterForm.invalid) {
    this.customerRegisterForm!.markAllAsTouched();
    return;
  }
  this.requestModal=true;
}
openDialog(data: any, height: string): void {
  this.dialog.open(CustomMemberModalComponent, {
    width: '600px',
    height: height,
    data: data,
  });
}
openDialogRequest(data: any, height: string): void {
  let dialogRef=this.dialog.open(CustomRequestThankyouComponent, {
    width: '600px',
    height: height,
    data: data,
  });
  dialogRef.afterClosed().subscribe(result => {
      this.toggleEdit=false;
     // this.getOrgDetails();
  });
}
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  accountapplyFilter(event: Event) {
    const filterValue =(event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // Check if the filtered data is empty
 this.ACCOUNTsearchResultEmpty = this.dataSource.filteredData.length === 0;
  }
  unlinkaccount(account:number){
this.unlinkaccountnumber=0;
this.unlinkaccountnumber=account;
this.unlinkmessage=true;
 this.errorshow=false;
  this.thankshow=false;
  this.cd.detectChanges();

  }
  unlinkcontine(){

    this.userInfoService.unlinkaccount(this.unlinkaccountnumber).subscribe(
      (data) => {
        console.log('success', data);
        this.unlinkmessage=false;
        this.errorshow=false;
         this.thankshow=true;
         this.accountlist();
       
      },
      (error) => {
        console.log('error');
        this.unlinkmessage=false;
        this.errorshow=true;
         this.thankshow=false;
      }
    );
    this.cd.detectChanges();
    this.unlinkaccountnumber=0;
   
  }
 /*  getCurrentOptions() {
    const options: ViewOptions = {
      sortField: this.sort.active,
      sortDirection: this.sort.direction,
     
    };

    return options;
  } */

 /*  getDefaultOptions() {
    const options: ViewOptions = {
      sortField: 'name',
      sortDirection: 'asc',
    
    }; 
    
    return options;
  }*/
} 
