import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-hero',
  templateUrl: './custom-hero.component.html',
  styleUrl: './custom-hero.component.scss'
})
export class CustomHeroComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,protected config: OccConfig, private userInfoService: UserInfoService,private renderer:Renderer2,private cd:ChangeDetectorRef){

  }
  herodata: Observable<any> = this.component.data$;
  userInfo: any;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  userLoggedIn: boolean = false;
  ngOnInit(){
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      console.log('login check',res);
      this.userLoggedIn = res;
      if(this.userLoggedIn){
        this.userInfoService.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
          }
        });
      }
      if(this.userInfo?.webEnabled === false){
        this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
       }
      this.cd.detectChanges();
    })
  }
}
