import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GigyaUidStorageService {
  private readonly gigyaUidKey = 'gigyaUid';

  constructor(protected winRef: WindowRef) {}

  public persistGigyaUid(
    gigyaUid: string,
    remember?: boolean
  ): Observable<void> {
    const storage = remember
      ? this.winRef.nativeWindow?.localStorage
      : this.winRef.nativeWindow?.sessionStorage;

    storage?.setItem(this.gigyaUidKey, gigyaUid);

    return of(undefined);
  }

  public removeGigyaUid(): Observable<void> {
    this.winRef.nativeWindow?.localStorage?.removeItem(this.gigyaUidKey);
    this.winRef.nativeWindow?.sessionStorage?.removeItem(this.gigyaUidKey);

    return of(undefined);
  }

  public getGigyaUid(): Observable<string | undefined> {
    return of(
      this.winRef.nativeWindow?.sessionStorage?.getItem(this.gigyaUidKey) ||
        this.winRef.nativeWindow?.localStorage?.getItem(this.gigyaUidKey) ||
        undefined
    );
  }
}
