import { Component } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-join-acc-thankyou',
  templateUrl: './join-acc-thankyou.component.html',
  styleUrl: './join-acc-thankyou.component.scss'
})
export class JoinAccThankyouComponent {
  joinAccResponse: any;

  constructor(private contentService:ContentService,public joinAccThankyouComponent: CmsComponentData<any>,private userService:UserInfoService){
    
  }
  joinAccthankyouData: Observable<any> = this.joinAccThankyouComponent.data$;
  
  ngOnInit(){

    this.userService.checkUserLoggedIn();
    
    this.contentService.currentJoinAccData.subscribe((data)=>{
      this.joinAccResponse = data;
      console.log(this.joinAccResponse);
    })
  }
}
