import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fluadchoosenow',
  templateUrl: './fluadchoosenow.component.html',
  styleUrl: './fluadchoosenow.component.scss'
})
export class FluadchoosenowComponent {
  constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>, private cmsService: CmsService,protected config: OccConfig) { }
  choosefluad: Observable<any> = this.bannerComponent.data$;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
}
