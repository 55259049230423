import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
 
@Component({
  selector: 'app-custom-codingless',
  templateUrl: './custom-codingless.component.html',
  styleUrls: ['./custom-codingless.component.scss']
})
export class CustomCodinglessComponent {
  leftBannerNode: Observable<any> = this.bannerComponent.data$;
  activeIndex: number = 0;
  transitioning: boolean = false;
  mergedObject1: any[] = [];
  totalSlides: number = this.mergedObject1.length;
  constructor(
    private cmsService: CmsService,
    protected config: OccConfig,
    public bannerComponent: CmsComponentData<CmsBannerComponent>,
    public accessService: ContentService,
    public cd: ChangeDetectorRef
  ) {}
//   ngOnInit() {
//    this.leftBannerNode.subscribe(res=>{
//     console.log('Received datafinal:', res);
//     const tilesList = res.carouselList.split(" ")
//     tilesList.forEach((el:any) => {this.accessService.getcarouselslides(el).subscribe(res=>{
//       this.mergedObject1 = [...this.mergedObject1, res]
//       this.cd.detectChanges();
//       console.log(this.mergedObject1);
//      })})
//    })
// } 
ngOnInit() {
  this.leftBannerNode.subscribe(res => {
    console.log('slideNode response:', res);
    this.slideresources(res);
  });
}
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  gotoprevious() {
    if (this.transitioning) return;
    this.transitioning = true;
 
    const fromIndex = this.activeIndex;
    this.activeIndex = (this.activeIndex > 0) ? this.activeIndex - 1 : this.mergedObject1.length - 1;
    this.triggerSlideAnimation(fromIndex, this.activeIndex, 'prev');
  }
 
  gotonext() {
    if (this.transitioning) return;
    this.transitioning = true;
 
    const fromIndex = this.activeIndex;
    this.activeIndex = (this.activeIndex < this.mergedObject1.length - 1) ? this.activeIndex + 1 : 0;
    this.triggerSlideAnimation(fromIndex, this.activeIndex, 'next');
  }
 
  triggerSlideAnimation(fromIndex: number, toIndex: number, direction: 'next' | 'prev') {
    const fromSlide = document.querySelectorAll('.carousel-item')[fromIndex] as HTMLElement;
    const toSlide = document.querySelectorAll('.carousel-item')[toIndex] as HTMLElement;
 
    if (fromSlide && toSlide) {
      // Apply outgoing and incoming classes for sliding effect
      toSlide.classList.add(`carousel-item-${direction}-enter`,'carousel-item-${direction}');
      fromSlide.classList.add(`carousel-item-${direction}-exit`, 'carousel-item-${direction}');
 
      setTimeout(() => {
        toSlide.classList.add('active');
        toSlide.classList.remove(`carousel-item-${direction}-enter`,'carousel-item-${direction}');
        fromSlide.classList.remove('active', `carousel-item-${direction}-exit`,'carousel-item-${direction}');
        this.transitioning = false;
      },0); 
      // Match the transition duration with CSS
    }

  }
  setActiveIndex(index: number){
    if(index!==this.activeIndex){
      const fromIndex = this.activeIndex;
      this.activeIndex=index;
      const direction = index> fromIndex ? 'next'  : 'prev';
      this.triggerSlideAnimation(fromIndex,index,direction);
    }
    // this.activeIndex = index;
  }
  getProduct(index: number): string {
    console.log('switched')

    switch (index) {
      case 0:
        return 'clinical';
      case 1:
        return 'financial';
      case 2:
        return 'clinical2';
      default:
        return 'default';
        
    }
   
  }
  slideresources(res: any) {
    const tilesList = res.carouselList.split(" ");
    console.log('tilesList:', tilesList);
    this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
    
      
      // Check if apiRes.component is an array
      if (Array.isArray(res.component)) {
        this.mergedObject1 = res.component;
      } else if (res.component) {
        // Convert to array if it's not already
        this.mergedObject1 = [res.component];
      } else {
        // Ensure it's always an array
        this.mergedObject1 = [];
      }

      console.log('mergedObject1:', this.mergedObject1);
      this.cd.detectChanges();  // Ensure detectChanges is called correctly
    });
  }
}

