import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomOpefficiencyFirstComponent } from './custom-opefficiency-first/custom-opefficiency-first.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
// import { CustomOpefficiencySecondComponent } from './custom-opefficiency-second/custom-opefficiency-second.component';
import { CustomOpefficiencyThirdComponent } from './custom-opefficiency-third/custom-opefficiency-third.component';
import { CustomCodinglessComponent } from './custom-codingless/custom-codingless.component';
import { SeqirusJoinFluComponent } from './seqirus-join-flu/seqirus-join-flu.component';
import { RouterModule } from '@angular/router';
// import { CustomOpefficiencyMultipleComponent } from './custom-opefficiency-multiple/custom-opefficiency-multiple.component';



@NgModule({
  declarations: [CustomOpefficiencyFirstComponent, CustomOpefficiencyThirdComponent, CustomCodinglessComponent, SeqirusJoinFluComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusChartBannerComponent :{
          component: CustomOpefficiencyFirstComponent,
      },
      SeqirusCodingLessComplex :{
        component: CustomCodinglessComponent,
    },
    SeqirusVaccinePortfolio :{
      component: CustomOpefficiencyThirdComponent,
    },
    SeqirusJoinFluComponent :{
      component: SeqirusJoinFluComponent,
    },
    }
  }as CmsConfig)
  ]
})
export class CustomOperationalEfficiencyModule { }
