import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPublicFirstComponent } from './custom-public-first/custom-public-first.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomPublicFooterComponent } from './custom-public-footer/custom-public-footer.component';




@NgModule({
  declarations: [
    CustomPublicFirstComponent,
    CustomPublicFooterComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusAllPublicPageComponent:{
          component: CustomPublicFirstComponent
        },
     /*    CustomFooter:{
          component: CustomPublicFooterComponent
        } */
      }
    }as CmsConfig)
  ]
})
export class CustomUkPublicModule { }
