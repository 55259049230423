<div class="prod-sbs" *ngIf="object1 |async as item">
    <div class="fluad-container">
        <div class="prod-sbs__left">
            <h3 class="prod-sbs__header header--3" [innerHTML]="item.headline"></h3>
            <!-- FLuad ACIP Recommendations Changes starts-->
            <!-- FLuad ACIP Recommendations Changes ends -->

            <div class="prod-sbs__content">
                <p class="content_para" [innerHTML]="item?.largeparagraphcontent"></p>
                <p class="content_smallpara" [innerHTML]="item?.h2content"></p>
            </div>         
            <span class="prod-sbs__key-features-header M20" [innerHTML]="item?.h3content">               
            </span>
            <p class="content_para" [innerHTML]="item?.paragraphcontent"></p> 
            <div class="italicText" [innerHTML]="item?.content">
            </div>
        </div>
        <div class="prod-sbs__right prod-sbs__rightUK">
            <p class="prod-sbs__img"><img [src]="getImage(item.mediaPicture)"></p>
        </div>
    </div>
</div>