<div class=prod-tabs>
    <div class="container">
        <nav>
            <div class="prod-tabs__tab-nav nav nav-tab" id="nav-tab" *ngFor="let item2 of mergedObject1; let i = index" role="tablist">
                <button class="nav-link" [ngClass]="toggleArray[i] == true ? 'active' : ''" data-toggle="tab"
                [attr.data-bs-target]="'#nav-content-' + i" type="button" role="tab" [attr.aria-controls]="'nav-content-' + i"
                [attr.aria-selected]="i === 0 ? 'true' : 'false'" (click)="toggleNav(i)"> 
                    <span *ngIf="item2.h2content" class="nav-tab-content">
                        {{ item2.h2content }}
                        <div class="prod-tabs__arrow">
                            <img role="presentation" alt="Next screen" src="../../../assets/images/arrow-right.svg">
                        </div>
                    </span>
                </button>
                <div class="prod-tabs__tab tab-pane fade d-lg-none d-xl-none" [ngClass]="toggleArray[i] == true ? 'show active' : ''" role="tabpanel">
                    <ng-container [attr.aria-labelledby]="'nav-tab-' + i">
                        <div class="prod-tabs__body">
                            <h3 [innerHTML]="item2.content"></h3>
                            <p class="centered-text" *ngIf="item2.mediaPdf">
                                <img [src]="getImage(item2.mediaPdf)" />
                            </p>
                            <p [innerHTML]="item2.pdfLinkContent"></p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </nav>
        <div class="prod-tabs__tab-content" id="nav-tabContent">
            <ng-container *ngFor="let item2 of mergedObject1; let i= index">
                <div class="prod-tabs__tab tab-pane fade d-none d-lg-block" [ngClass]="toggleArray[i] == true ? 'show active' : ''" role="tabpanel">
                    <ng-container [attr.aria-labelledby]="'nav-tab-' + i">
                        <div class="prod-tabs__body">
                            <h3 [innerHTML]="item2.content"></h3>
                            <p class="centered-text" *ngIf="item2.mediaPdf">
                                <img [src]="getImage(item2.mediaPdf)" />
                            </p>
                            <p [innerHTML]="item2.pdfLinkContent"></p>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>      
    </div>
</div>