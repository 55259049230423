<div *ngIf="bannerNode | async  as item" id="laptop-row" class="row"
    [ngClass]="{'laptop':currentSite=='seqirusb2b-uk'}">
    <!-- <div class="col-xs-12 col-md-6 hide-laptop-desktop">
        <img class="laptop-image-mobile" [src]="getImage(item.media)">
    </div> -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 laptopParent">
        <div class="laptop-paragraph-content">
            <h2>{{item.headline}}</h2>
            <p>{{item.h2content}}</p>
            <ul [ngClass]="{'list':currentSite=='seqirusb2b-uk'}" [innerHTML]="item.paragraphcontent"></ul>
            <!--for coding and billing-->
            <p class="coding-billing-boldContent d-none" *ngIf="item?.paragraphcontent" [innerHTML]="item.paragraphcontent">
            </p>
            <div class="button--download-outline-red d-none" *ngIf="item?.content">
                <a>{{item.content}}</a>
            </div>
        </div>
    </div>

    <div class="col-xs-12 col-md-6 hide-laptop-mobile dashlapimage" *ngIf="item?.media">
        <img class="laptop-image" alt="laptop with the flu360 account dashboard on screen" [src]="getImage(item.media)">
    </div>
    <!--for coding and billing page-->
    <div class="col-xs-12 col-md-6 col-lg-6 col-sm-12 hide-laptop-mobile dashlapimage codingnbillingimage d-none"
        *ngIf="item?.mediaPicture">
        <img class="laptop-image" alt="laptop with the flu360 account dashboard on screen"
            [src]="getcodingnbillingimage(item.mediaPicture)">
    </div>
</div>