<div class="invoice_paypopup mobsuccesscontainer" *ngIf="errorCode == 200">
	<div class="order_panel_header successcontainer">

		<div class="row">

			<div class="col-md-12">
				<div class="title_data">Thank You for Submitting Your Payment</div>
			</div>

		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="msg_data">
					You will receive an email shortly confirming your payment details. If you do not receive an email or if you require support, please contact Customer Service at &nbsp;<a href="mailto:customerservice.us@seqirus.com" target="_blank">{{emailId}}</a>.
				</div>

			</div>

		</div>
		<div class="row">
			
			<div class="col-md-6 col-xs-10 marginClass">
				<div class="Transactiontext">TRANSACTION ID<div class="tractionid">{{paymentDetails.companyCode}}/{{paymentDetails.invoiceNumber}}</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6 col-xs-6 close_container">
				<a href="/financial-dashboard/all-invoices" target="_parent" class="close_btn_thankyou mobclose_btn">Close</a>
			</div>
		</div>

	</div>
</div>
<div class="invoice_paypopup paymentfailed mobpaddingpayment" *ngIf="errorCode==3">
	<div class="order_panel_header successcontainer">
	<div class="row">
			<div class="col-md-12">
				<div class="title_data">Your payment failed</div>
			</div>

		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="msg_data">
					We have not been able to process your payment.</div>
				<div class="msg_data paymentfailtext">Please check your details and try again, or use another payment method</div>

			</div>

		</div>
		<div class="row">
			
			<div class="failerbtncont col-xs-12" id="failurebtncont">
				<div class="failerretry mobretry" id="retry" (click)="cancelClicked()">Retry or Change Method</div>
				<div class="failercancelbtn" id="cancel" (click)="cancelTransactionClicked()">Cancel Transaction</div>
			</div>
		</div>
		</div>
</div>
<div class="invoice_paypopup paymentfailed mobpaddingpayment" *ngIf="errorCode==2">
	<div class="order_panel_header successcontainer">
	<div class="row">
			<div class="col-md-12">
				<div class="title_data">Unable to Process Card</div>
			</div>

		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="msg_data">
					Please contact your bank or use another payment method.</div>
				<div class="msg_data paymentfailtext">Your bank was unable to process this card. This may be related to various reasons, including fraud, lack of funds, invaild card, etc.</div>

			</div>

		</div>
		<div class="row">
			
			<div class="failerbtncont col-xs-12" id="failurebtncont">
				<div class="failerretry mobretry" id="retry" >Retry or Change Method</div>
				<div class="failercancelbtn" id="cancel">Cancel Transaction</div>
			</div>
		</div>
		</div>
</div>
<div class="invoice_paypopup paymentfailed mobpaddingpayment" *ngIf="errorCode==1">
	<div class="order_panel_header successcontainer">
	<div class="row">
			<div class="col-md-12">
				<div class="title_data">Please retry the card. If the problem persists, please contact your bank or use another payment method.</div>
			</div>

		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="msg_data">
					Please retry the card. If the problem persists, please contact your bank or use another payment method.</div>
				<div class="msg_data paymentfailtext">Your card was unable to be authenticated by your bank. This may be related to entering a one-time password, SMS code, timeout, invalid CVV, expired card, or other bank challenge.</div>

			</div>

		</div>
		<div class="row">
			
			<div class="failerbtncont col-xs-12" id="failurebtncont">
				<div class="failerretry mobretry" id="retry" (click)="cancelClicked()">Retry or Change Method</div>
				<div class="failercancelbtn" id="cancel" (click)="cancelTransactionClicked()">Cancel Transaction</div>
			</div>
		</div>
		</div>
</div>  


