import { PlatformLocation, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { CdcConfig, CdcAuthFacade, CdcJsService, CdcConsentsLocalStorageService } from '@spartacus/cdc/root';
import { BaseSiteService, LanguageService, ScriptLoader, WindowRef, AuthService, User, EventService, GlobalMessageService, OccEndpointsService, AuthRedirectStorageService } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subscription, combineLatest, take } from 'rxjs';
import { UserInfoService } from './user-info.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CdcService extends CdcJsService {
  sub: Subscription[] = [];
  currentRoute: string="";
  
  constructor(cdcConfig: CdcConfig, baseSiteService: BaseSiteService, languageService: LanguageService, scriptLoader: ScriptLoader, winRef: WindowRef, cdcAuth: CdcAuthFacade, auth: AuthService, zone: NgZone, userProfileFacade: UserProfileFacade, platform: PlatformLocation, globalMessageService: GlobalMessageService, eventService: EventService, consentStore: CdcConsentsLocalStorageService,
    private http: HttpClient,private router:Router,protected store: Store, private occ: OccEndpointsService, private userInfoService: UserInfoService,private authRedirectStorageService:AuthRedirectStorageService) {
    super(cdcConfig,
      baseSiteService,
      languageService,
      scriptLoader,
      winRef,
      cdcAuth,
      auth,
      zone,
      userProfileFacade,
      platform,
      globalMessageService,
      eventService,
      consentStore
    )
  }
  customAddCdcEventHandlers(baseSite: string) {
    (this.winRef.nativeWindow as { [key: string]: any })?.[
      'gigya'
    ]?.accounts?.addEventHandlers({
      onLogin: (...params: any[]) => {
        this.zone.run(() => this.customOnLoginEventHandler(baseSite, ...params));
      }
      
    });
  }

  customOnLoginEventHandler(baseSite: string, response?: any) {
    //console.log(response,'gigya response');
   let url = this.router.url.split('?')[0].split('/');
    this.currentRoute = url[url.length - 1];
    if(this.currentRoute == 'login'){
      this.authRedirectStorageService.setRedirectUrl("login");
    }
    if (response) {
      // this.userInfoService.setCurrentUser(response.profile);
    }
    // this.newGigya(response)
    // if (response) {
    //   this.cdcAuth.loginWithCustomCdcFlow(
    //     response.UID,
    //     response.UIDSignature,
    //     response.signatureTimestamp,
    //     response.id_token !== undefined ? response.id_token : '',
    //     baseSite
    //   );
    //   // this.newGigya(response)
    // }
  }

  newGigya(response: any) {
    let payload = new FormData();

    payload.append('gigyaData', JSON.stringify(response))

    this.sub.push(this.gigyaLogin(payload).subscribe((res: any) => {
     // console.log(res)
    }, (err: any) => {
      //console.log(err)
    }))
  }
  gigyaLogin(response: any) {
    const url = this.occ.buildUrl('/gigyaraas/login');
    return this.http.post(url, response);
  }





  /**
   * Updates user details using the existing User API
   *
   * @param response
   */
  public override onProfileUpdateEventHandler(response?: any) {
    // console.log(response)
    if (response) {
      const userDetails: User = {};
      userDetails.firstName = response.profile.firstName;
      userDetails.lastName = response.profile.lastName;
      this.userProfileFacade.update(userDetails);
    }
    if(response.response.status === 'OK' && response.screen === 'gigya-change-password-screen'){
      
      (this.winRef.nativeWindow as { [key: string]: any })?.[
        'gigya'
      ]?.accounts?.logout({
        callback:(result:any) => {
          if(result){
            
             this.auth.coreLogout().then(()=>{
              localStorage.clear();
              sessionStorage.clear(); 
            });
            setTimeout(()=>{
            this.router.navigate(['changepasswordsuccess'])
            
            },300);
            
            this.userInfoService.checkUserLoggedIn();
            
          }
        }
      });

    }
    if(response.response.status === 'OK' && response.screen === "gigya-reset-password-screen"){
      this.router.navigate(['changepasswordsuccess']);
    }
  }

  public override ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.sub.forEach((sub: any) => {
      sub.unsubscribe();
    })
  }
}
