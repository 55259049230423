import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditTopBannerComponent } from './credit-top-banner/credit-top-banner.component';
import { CreditsSectionComponent } from './credits-section/credits-section.component';
import { ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { NgxMaskModule } from 'ngx-mask';



@NgModule({
  declarations: [
    CreditTopBannerComponent,
    CreditsSectionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    NgxMaskModule,
    ConfigModule.withConfig({
      cmsComponents: {
        TopBannerCreditsComponent:{
          component:CreditTopBannerComponent,
        },
        SeqirusFinancialCreditsComponent:{
          component:CreditsSectionComponent,
        }
      }
    })
  ]
})
export class FinancialDashboardUkModule { }
