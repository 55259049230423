<div id="errorPage" *ngIf="fourthnode | async as item">
    <div class="LO" *ngIf="!userLoggedIn">
        <div class="main__inner-wrapper">
            <div class="global-alerts" *ngIf="item.uid=='pageNotFoundComponent3'">
                <div class="alert alert-danger alert-dismissable getAccAlert">
                    <button class="close closeAccAlert" aria-hidden="true" data-dismiss="alert" type="button">×</button>
                    {{item.title}}
                </div>
            </div>
            <!--Logged Out Error code starts -->
            <div class="yCmsContentSlot col-xs-12 col-md-2 col-lg-2  sidebar hidden-xs hidden-sm visible-md-* visible-lg-*">
            </div>
            <div *ngIf="item.uid=='pageNotFoundComponent2'">
                <main role="main" class="loggedOutError col-xs-12 no-padding container main_container">
                    <!-- <div class="content" [innerHTML]="item.content">
                    </div>
                    <button class="col-lg-12 col-md-12 col-sm-12 col-xs-12 LO_errorTextParent LO_returnHomeBtn" [routerLink]="['/']">{{item.title}}</button> -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 LO_errorTextParent">
                        <div class="LO_errorText1">Something went wrong.</div>
                        <div class="LO_errorText2">Let's get you back on track.</div>
                        <button class="LO_returnHomeBtn" [routerLink]="['/']">Return to Home</button>
                    </div>
                </main>
            </div>
        </div>
    </div>
    <div class="LI" *ngIf="userLoggedIn">
        <div class="main__inner-wrapper">
            <div class="global-alerts" *ngIf="item.uid=='pageNotFoundComponent3'">
                <div class="alert alert-danger alert-dismissable getAccAlert">
                    <button class="close closeAccAlert" aria-hidden="true" data-dismiss="alert" type="button">×</button>
                    {{item.title}}
                </div>
            </div>
            <!--Logged In Error code starts -->
            <div class="col-xs-12 col-md-10 col-lg-10  main " *ngIf="item.uid=='pageNotFoundComponent1'">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 LI_errorTextParent">
                    <!-- <div [innerHTML]="item.content">
                    </div>
                    <button class="col-lg-6 col-md-6 col-sm-6 col-xs-6 LI_errorTextParent LI_returnHomeBtn" [routerLink]="['/']">{{item.title}}</button>
                    <button class="col-lg-6 col-md-6 col-sm-6 col-xs-6 LI_errorTextParent LI_errorViewBtn" [routerLink]="['/help-and-faq']">{{item.text2}}</button> -->
                    <div class="LI_errorText1">Something went wrong.</div>
                    <div class="LI_errorText2">Let's get you back on track.</div>
                    <div class="LI_errorbtnParent">
                        <button class="LI_errorReturnDashboardBtn" [routerLink]="['/my-account']">Return to Dashboard</button>
                        <button class="LI_errorViewBtn" [routerLink]="['/help-and-faq']">View FAQs</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
