import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seasonspilt'
})
export class SeasonspiltPipe implements PipeTransform {
  seasonvaluewithslash:string="";
  spiltseason:any=[]
  transform(value: any) {
    if (!value) return this.seasonvaluewithslash;
    this.seasonvaluewithslash=value;
   this.spiltseason=this.seasonvaluewithslash.split("-20");
   this.seasonvaluewithslash=this.spiltseason[0]+'/'+this.spiltseason[1]
    return this.seasonvaluewithslash;
  }

}
