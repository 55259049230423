import { Component } from '@angular/core';
import { BaseSiteService, CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CsAgentAuthService } from '@spartacus/asm/root';

@Component({
  selector: 'app-custom-top-banner',
  templateUrl: './custom-top-banner.component.html',
  styleUrl: './custom-top-banner.component.scss'
})
export class CustomTopBannerComponent {
  constructor(public userInfoService: UserInfoService , protected baseSiteService: BaseSiteService,protected csAgentAuthService: CsAgentAuthService) {
  }
  userData$:Observable<any>=this.userInfoService.getProfileData();
  profileData:Observable<any>=this.userInfoService.getUKMyProfileData();
  myProfileData:any;
  isDataAvailable = false
  currentSite :any;
  asmUser: boolean = false;
  asmEnabled: boolean = false;
  asmAgentLoggedIn: boolean = false;
  customerEmulated: boolean = false;
  ngOnInit() {
    this.baseSiteService.getActive().subscribe(res => {
      this.currentSite = res;
      console.log(this.currentSite)
    });
   
  }

  showStatus() {
    this.asmEnabled = localStorage.getItem('asm_enabled') === 'true';
    this.csAgentAuthService.isCustomerSupportAgentLoggedIn().subscribe(i => {
        this.asmAgentLoggedIn = i;
    });
    this.csAgentAuthService.isCustomerEmulated().subscribe(res => {
      this.customerEmulated = res;
    });
    if(this.asmEnabled && this.asmAgentLoggedIn && this.customerEmulated) {
      return false;
    }
    return true;
  }
  ngAfterviewInit(){
    this.userInfoService.getUKMyProfileData().subscribe(res=>{
      this.isDataAvailable = true
      this.myProfileData = res;
      
      console.log(this.myProfileData)
    })
  }
  }
    

