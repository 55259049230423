import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CSAgentLoginFormComponent } from '@spartacus/asm/components';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { AuthConfigService, UserIdService } from '@spartacus/core';

@Component({
  selector: 'app-flu360-asm-login',
  templateUrl: './flu360-asm-login.component.html',
  styleUrl: './flu360-asm-login.component.scss'
})
export class Flu360AsmLoginComponent extends CSAgentLoginFormComponent {
  override csAgentLoginForm: FormGroup = this.fb.group({
    userId: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  @Input()
  override csAgentTokenLoading = false;

  @Output()
  override submitEvent = new EventEmitter<{ userId: string; password: string }>();

  constructor(private authConfigService: AuthConfigService, protected override fb: FormBuilder, private http: HttpClient, public userInfoService: UserInfoService, public userId: UserIdService) {
    super(fb);
  }
  override onSubmit(): void {
    if (this.csAgentLoginForm.valid) {
      this.submitEvent.emit({
        userId: this.csAgentLoginForm.get('userId')?.value,
        password: this.csAgentLoginForm.get('password')?.value,
      });
    } else {
      this.csAgentLoginForm.markAllAsTouched();
    }
  }
}
