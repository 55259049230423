// import { Component } from '@angular/core';
// import { Router } from '@angular/router';
// import { BaseSiteService } from '@spartacus/core';
// import { CmsComponentData } from '@spartacus/storefront';
// @Component({
//   selector: 'app-news-announcement-component',
//   templateUrl: './news-announcement-component.component.html',
//   styleUrl: './news-announcement-component.component.scss'
// })
// export class NewsAnnouncementComponentComponent {
//   largeparagraphcontent:string="";
//   headline:any;
//   startOrder:any;
//   contents:any[]=[];
//   jobbagcode: any;
//   currentSite: string="";
//   constructor(public component: CmsComponentData<any>, private router: Router,private baseSiteService:BaseSiteService) {
    
//   }

//   ngOnInit(){
    
//     this.baseSiteService.getActive().subscribe(site => {
//       this.currentSite = site;
//     });
//     this.component.data$.subscribe((data) =>{
//       this.largeparagraphcontent = data.largeparagraphcontent;
//       this.headline = data.headline;
//       this.startOrder = data.content;
//       this.jobbagcode = data.jobBagCode;

//     });
//   }
//   routerlinkClicked(event: any) {
//     event.preventDefault();
//     this.router.navigate([event.target.attributes['href'].value]);
//   }
// }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseSiteService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-news-announcement-component',
  templateUrl: './news-announcement-component.component.html',
  styleUrls: ['./news-announcement-component.component.scss']
})
export class NewsAnnouncementComponentComponent implements OnInit {
  largeparagraphcontent: string = "";
  headline: any;
  startOrder: any;
  contents: any[] = [];
  jobbagcode: any;
  currentSite: string = "";
  // membershipFlag: boolean = true; 

  constructor(public component: CmsComponentData<any>, private router: Router, private baseSiteService: BaseSiteService) { }

  ngOnInit() {
    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
    });

    this.component.data$.subscribe((data) => {
      this.largeparagraphcontent = data.largeparagraphcontent;
      this.headline = data.headline;
      this.startOrder = data.content;
      this.jobbagcode = data.jobBagCode;
      // this.membershipFlag = data.membershipFlag; // Set the flag based on data
    });
  }

  routerlinkClicked(event: any) {
    event.preventDefault();
    this.router.navigate([event.target.attributes['href'].value]);
  }
}
