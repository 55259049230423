<div class="breadcrumbs--page-nav hidden-xs hidden-sm">
   <div class="container">
      <p><a [routerLink]="'/'">Home</a><span> &gt; </span><a [routerLink]="'/products'">Products</a><span> &gt; </span><strong>Real-World Evidence</strong></p></div>
</div>
<div class="product_realworld-clickable" *ngIf="firstheadline| async as data">
   <div class="product_image-textSide-Image">
      <div class="simple-banner banner__component--responsive">
         <img class="js-responsive-image" alt="" title="" [src]="getImage(data.media)">
      </div>
   </div>
   <div class="container">
      <div class="product_realworld-whitebox">
         <h1 class="product_efficacy-header">{{data.headline}}</h1>
         <div class="product_efficacy-text" [innerHTML]="data.h2content"></div>
            <div class="real-world_desktopSection d-none d-md-block">
               <div class="product_returnSliderparent">
                   <div class="product_textcontent product_headercontent">
                       <div class="product_text product_text1" [class.active]="activePanel === 1" style="left: 89.75px;">2017-2018</div>
                       <div class="product_text product_text2" [class.active]="activePanel === 2" style="left: 443.385px;">2018-2019</div>
                       <div class="product_text product_text3" [class.active]="activePanel === 3" style="left: 798.031px;">2019-2020</div>
                       <div class="clearboth"></div>
                   </div>
                   <div class="product_returnSlider">
                       <div class="product_middleline">
                           <div class="product_circle product_circle1" [class.active]="activePanel === 1" (click)="setActivePanel(1)">
                               <div class="product_outer-circle"></div>
                           </div>
                           <div class="product_circle product_circle2" [class.active]="activePanel === 2" (click)="setActivePanel(2)">
                               <div class="product_outer-circle"></div>
                           </div>
                           <div class="product_circle product_circle3" [class.active]="activePanel === 3" (click)="setActivePanel(3)">
                               <div class="product_outer-circle"></div>
                           </div>
                       </div>
                   </div>
                   <div class="product_textcontent">
                       <div class="product_text product_text1" [class.active]="activePanel === 1" style="left: 89.75px;">
                           <p>14%&nbsp;<img src="/medias/Egg-icon.png?context=bWFzdGVyfHJvb3R8ODA3fGltYWdlL3BuZ3xhRGxsTDJnMk9TODRPVE13T0RNM05EazFPRE00TDBWbloxOXBZMjl1TG5CdVp3fDEwNjAzNzA0ODRlOWZlNWFhMzI4NTAxNjVmMGFhZTk2NDk1YzVkOTEzZjBjYTM1OWEwODNjY2E5M2JmYTU1NjQ"></p>
                           <span [innerHTML]="mergedObject1[0].h2content" [class.active]="activePanel === 1"></span>
                       </div>
                       <div class="product_text product_text2" [class.active]="activePanel === 2" style="left: 443.385px;">
                           <p>7%</p>
                           <span [innerHTML]="mergedObject1[1].h2content" [class.active]="activePanel === 2"></span>
                       </div>
                       <div class="product_text product_text3" [class.active]="activePanel === 3" style="left: 798.031px;">
                           <p>5%</p>
                           <span [innerHTML]="mergedObject1[2].h2content" [class.active]="activePanel === 3"></span>
                       </div>
                       <div class="clearboth"></div>
                   </div>
               </div>

               <div class="panel_body-bottom panel_body-bottom1 real-world_bodyBottom" [class.active]="activePanel === 1">
                   <div class="panel_body-bottomLeft">
                       <div [innerHTML]="mergedObject1[0].paragraphcontent"></div>
                       <div [innerHTML]="mergedObject1[0].h3content"></div>
                   </div>
                   <div class="panel_body-bottomDivider"></div>
                   <div class="panel_body-bottomRight">
                       <div [innerHTML]="mergedObject1[0].content">
                       </div>
                   </div>
               </div>

               <div class="panel_body-bottom panel_body-bottom2 real-world_bodyBottom" [class.active]="activePanel === 2">
                   <div class="panel_body-bottomLeft">
                     <div [innerHTML]="mergedObject1[1].paragraphcontent"></div>
                     <div [innerHTML]="mergedObject1[1].h3content"></div>
                   </div>
                   <div class="panel_body-bottomDivider"></div>
                   <div class="panel_body-bottomRight">
                     <div [innerHTML]="mergedObject1[1].content">
                     </div>
                 </div>
               </div>

               <div class="panel_body-bottom panel_body-bottom3 real-world_bodyBottom" [class.active]="activePanel === 3">
                   <div class="panel_body-bottomLeft">
                     <div [innerHTML]="mergedObject1[2].paragraphcontent"></div>
                     <div [innerHTML]="mergedObject1[2].h3content"></div>
                   </div>
                   <div class="panel_body-bottomDivider"></div>
                   <div class="panel_body-bottomRight">
                     <div [innerHTML]="mergedObject1[2].content">
                   </div>
               </div>
           </div>
         <!--for mobile view-->
         <div class="real-world_mobileSection d-md-none">
            <div class="real-world_box real-world_box1">
               <div class="real-world_verticalLine">
                  <div class="real-world_greendot"></div>
                  <div class="real-world_grayLine"></div>
               </div>
               <div class="panel_body-bottom real-world_bodyBottom real-world_year1">
                  <div class="panel_body-headerIcon">
                     <span>2017-2018<i class="panel-body_moreless pull-right glyphicon glyphicon-plus"></i></span>
                  </div>
                  <div class="panel_body-bottomLeft">
                     <div>14%&nbsp;<img alt="Egg icon"
                           src="/medias/Egg-icon.png?context=bWFzdGVyfHJvb3R8ODA3fGltYWdlL3BuZ3xhRGxsTDJnMk9TODRPVE13T0RNM05EazFPRE00TDBWbloxOXBZMjl1TG5CdVp3fDEwNjAzNzA0ODRlOWZlNWFhMzI4NTAxNjVmMGFhZTk2NDk1YzVkOTEzZjBjYTM1OWEwODNjY2E5M2JmYTU1NjQ">
                     </div>
                     <div>fewer influenza-related hospitalizations or ER visits vs egg-based vaccines<sup>1</sup></div>
                     <div>(rVE 14.4%; 95% CI 8.8-19.6)</div>
                  </div>
                  <div class="panel_body-bottomRight">
                     <!-- <hr align="left"> -->
                     <div>
                        <label>SEASONAL MATCH STATUS:</label>
                        <div class="panel_body-red">EGG-ADAPTED MISMATCH&nbsp;<img alt="Egg icon"
                              src="/medias/Egg-icon.png?context=bWFzdGVyfHJvb3R8ODA3fGltYWdlL3BuZ3xhRGxsTDJnMk9TODRPVE13T0RNM05EazFPRE00TDBWbloxOXBZMjl1TG5CdVp3fDEwNjAzNzA0ODRlOWZlNWFhMzI4NTAxNjVmMGFhZTk2NDk1YzVkOTEzZjBjYTM1OWEwODNjY2E5M2JmYTU1NjQ">
                        </div>
                     </div>
                     <div><label>DESIGN:</label>
                        <div>Retrospective cohort study, <span class="product_bold">over 3 million persons</span> 4-64
                           years</div>
                     </div>
                     <div><label>LIMITATIONS:</label>
                        <div>Results were not adjusted by unmeasurable confounders; findings may not be representative
                           of uninsured, Medicaid, or Medicare populations</div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="real-world_box real-world_box2">
               <div class="real-world_verticalLine">
                  <div class="real-world_greendot"></div>
                  <div class="real-world_grayLine"></div>
               </div>
               <div class="panel_body-bottom real-world_bodyBottom real-world_year2">
                  <div class="panel_body-headerIcon">
                     <span>2018-2019<i class="panel-body_moreless pull-right glyphicon glyphicon-plus"></i></span>
                  </div>
                  <div class="panel_body-bottomLeft">
                     <div>7%</div>
                     <div>fewer influenza-related hospitalizations or ER visits vs egg-based vaccines<sup>2</sup></div>
                     <div>(rVE 6.5%; 95% CI 0.1-12.5)</div>
                  </div>
                  <div class="panel_body-bottomRight">
                     <!-- <hr align="left"> -->
                     <div><label>SEASONAL MATCH STATUS:</label>
                        <div class="panel_body-green">MATCH</div>
                     </div>
                     <div><label>DESIGN:</label>
                        <div>Retrospective cohort study, <span class="product_bold">over 3.7 million persons</span> 4-64
                           years</div>
                     </div>
                     <div><label>LIMITATIONS:</label>
                        <div>Results were not adjusted by unmeasurable confounders; findings may not be representative
                           of uninsured, Medicaid, or Medicare populations</div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="real-world_box real-world_box3">
               <div class="real-world_verticalLine">
                  <div class="real-world_greendot"></div>
                  <div class="real-world_grayLine"></div>
               </div>
               <div class="panel_body-bottom real-world_bodyBottom real-world_year3">
                  <div class="panel_body-headerIcon">
                     <span>2019-2020<i class="panel-body_moreless pull-right glyphicon glyphicon-plus"></i></span>
                  </div>
                  <div class="panel_body-bottomLeft">
                     <div>5%</div>
                     <div>fewer influenza-related hospitalizations or ER visits vs egg-based vaccines<sup>3</sup></div>
                     <div>(rVE 5.3%; 95% CI 0.5-9.9)</div>
                  </div>
                  <div class="panel_body-bottomRight">
                     <!-- <hr align="left"> -->
                     <div><label>SEASONAL MATCH STATUS:</label>
                        <div class="panel_body-green">MATCH</div>
                     </div>
                     <div><label>DESIGN:</label>
                        <div>Retrospective cohort study, <span class="product_bold">over 5 million persons</span> 4-64
                           years</div>
                     </div>
                     <div><label>LIMITATIONS:</label>
                        <div>Potential for residual confounding; lack of laboratory confirmation</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>