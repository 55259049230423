import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-aqiv-second',
  templateUrl: './custom-aqiv-second.component.html',
  styleUrl: './custom-aqiv-second.component.scss'
})
export class CustomAqivSecondComponent {
  object1:Observable<any> = this.bannerComponent.data$; 
  constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>) {
  }

getImage(image: any): string {
  return this.config.backend?.occ?.baseUrl + image.desktop.url;
}
}
