import { Component } from '@angular/core';
import { CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fourth',
  templateUrl: './fourth.component.html',
  styleUrl: './fourth.component.scss'
})
export class FourthComponent {
  // object1 : Observable<any> = this.cmsService.getComponentData('SeqirusInfluenzaPlusFourthComponent');
object1: Observable<any> = this.bannerComponent.data$;
  constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<any>,) {
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
}
