import { Injectable, OnDestroy } from '@angular/core';
import { AuthService, RoutingService } from '@spartacus/core';
import { BehaviorSubject, interval, Subject, Subscription, timer } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TimeoutModalService implements OnDestroy {

  private readonly INACTIVITY_TIMEOUT = 1200000; // 20 minute
  private readonly WARNING_TIMEOUT = 300000; // 5 minutes
  private destroy$ = new Subject<void>();
  private resetInactivityTimer$ = new Subject<void>();
  private userEvents = ['mousedown', 'keydown', 'touchstart', 'scroll'];

  public showModal$ = new BehaviorSubject<boolean>(false);
  public showRedirectModal$ = new BehaviorSubject<boolean>(false);
  private warningTimer: Subscription | null = null;

  constructor(private authService: AuthService, private router: Router, private routingService: RoutingService) { }

  initialize(): void {
    this.authService.isUserLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.startInactivityMonitoring();
      }
    });
  }

  private startInactivityMonitoring(): void {
    this.userEvents.forEach(event => {
      document.addEventListener(event, () => this.resetTimer());
    });
    this.startInactivityTimer();
  }
  
  private startInactivityTimer(): void {
    timer(this.INACTIVITY_TIMEOUT)
      .pipe(takeUntil(this.resetInactivityTimer$))
      .subscribe(() => {
        this.showModal$.next(true);
        this.startWarningTimer();
      });
  }

  private startWarningTimer(): void {
    if (this.warningTimer) {
      this.warningTimer.unsubscribe();
    }

    this.warningTimer = timer(this.WARNING_TIMEOUT).subscribe(() => {
      this.redirectToLogoutAndInactive();
    });
  }

  private resetTimer(): void {
    if (!this.showModal$.value) {
      this.resetInactivityTimer$.next();
      this.startInactivityTimer();
    }
  }

  resetSession(): void {
    if (this.warningTimer) {
      this.warningTimer.unsubscribe();
    }
    this.showModal$.next(false);
    this.resetTimer();
  }

  private redirectToLogoutAndInactive(): void {
    this.showModal$.next(false);
    localStorage.setItem('sessionTimedOut', 'true');

    this.authService.logout();

    this.routingService.go({ cxRoute: 'logout' });

    this.authService.isUserLoggedIn().pipe(
      filter(isLoggedIn => !isLoggedIn), 
      take(1), 
      switchMap(() => {
        return interval(200).pipe(
          filter(() => {
            let userLoggedOut = false;
            this.authService.isUserLoggedIn().subscribe(isLoggedIn => {
              userLoggedOut = !isLoggedIn;
            });
            return userLoggedOut;
          }),
          take(1)
        );
      })
    ).subscribe(
      () => {
        this.router.navigate(['/inactive']).then(() => {
          localStorage.removeItem('sessionTimedOut');
        });
      },
      error => {
        console.error("Error during logout or navigation:", error);
        this.router.navigate(['/inactive']).then(() => {
          localStorage.removeItem('sessionTimedOut');
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.userEvents.forEach(event => {
      document.removeEventListener(event, () => this.resetTimer());
    });
    if (this.warningTimer) {
      this.warningTimer.unsubscribe();
    }
  }
}
